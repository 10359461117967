import { Button } from 'antd/lib/radio';
import { map } from 'lodash';
import React from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import CommonCompanyJobCard from './CommonCompanyJobCard';

const CompanyJobs = ({ jobsData, fetchJobsLoading, jobIsEnd, loadMore }) => {
  return (
    <div className="jobs-list">
      {map(jobsData, (job) => {
        return (
          <div className="single-job">
            <CommonCompanyJobCard job={job} />
          </div>
        );
      })}
      {!fetchJobsLoading && !jobIsEnd && (
        <div className="loadMoreButton">
          <Button className="follow-btn" onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}
      {fetchJobsLoading && <LoaderComponent setHeight={10} />}
    </div>
  );
};
export default CompanyJobs;
