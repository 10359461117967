import { UserOutlined } from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';
import React from 'react';

const CommonAvatar = (props) => {
  const { size, src, onClick } = props;
  return (
    <div>
      <Avatar
        src={src}
        size={size}
        onClick={onClick}
        icon={<UserOutlined />}
        className="mr-10"
      />
    </div>
  );
};

export default CommonAvatar;
