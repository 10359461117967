import React, { useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import { sleep } from '../../../common/utils';

function SearchLocationInputSingle({
  setLocation,
  location,
  initLocation,
  isEdit = false,
  onChange,
  placeholder
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    debounce: 300
  });

  useEffect(() => {
    if (initLocation) {
      setValue(initLocation);
      sleep(1000).then(() => {
        clearSuggestions();
      });
    }
  }, [initLocation, setValue, clearSuggestions]);

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleOnFocus = () => {
    if (isEdit && location && setLocation) {
      setLocation('');
    }
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => {
        if (isEdit) {
          setLocation(results[0]?.formatted_address);
        } else {
          setLocation(results[0]?.formatted_address);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('😱 Error: ', error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id: placeId,
        structured_formatting: {
          main_text: mainText,
          secondary_text: secondaryText
        }
      } = suggestion;
      return (
        <li key={placeId} onClick={handleSelect(suggestion)}>
          <strong>{mainText}</strong> <small>{secondaryText}</small>
        </li>
      );
    });

  return (
    <div className="search-location-input">
      <input
        id="location-input"
        autoComplete="off"
        value={location || value}
        onChange={(e) => {
          handleInput(e);
          onChange();
        }}
        disabled={!ready}
        className="location-input"
        list="browsers"
        placeholder={placeholder || 'Enter Location'}
        onFocus={handleOnFocus}
      />
      {status === 'OK' && (
        <ul className="location-listing">{renderSuggestions()}</ul>
      )}
    </div>
  );
}

export default SearchLocationInputSingle;
