import { UserOutlined } from '@ant-design/icons';
import { useLazyQuery, useSubscription } from '@apollo/client';
import { Avatar, Button, Col, Divider, Row } from 'antd';
import { debounce, map, orderBy, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EditChat } from '../../../assets/svg';
import { POST_SKIP_RECORD, ROUTES, USER } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import '../chat.less';
import { GET_USER_ROOMS } from '../graphql/Queries';
import { ROOM_UPDATE } from '../graphql/subscription';

let chatDebounce;

const Chat = ({ isDrawer, changePage }) => {
  const history = useHistory();
  const [userRoomData, setUserRoomData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [moreLoading, setMoreLoading] = useState(false);
  // eslint-disable-next-line no-undef
  const { profileId } = JSON.parse(localStorage.getItem(USER));
  const { data: newData } = useSubscription(ROOM_UPDATE, {
    variables: { where: { id: profileId } }
  });
  useEffect(() => {
    let roomsArray = [...userRoomData];
    roomsArray.push(newData?.roomUpdated);
    roomsArray = orderBy(roomsArray, 'updatedAt', 'desc');
    roomsArray = uniqBy(roomsArray, 'id');
    setUserRoomData(roomsArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  const [getUserRooms] = useLazyQuery(GET_USER_ROOMS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const rooms = res?.getUserRooms;
      let roomsArray = [...(loading ? [] : userRoomData), ...rooms];
      roomsArray = orderBy(roomsArray, 'updatedAt', 'desc');
      roomsArray = uniqBy(roomsArray, 'id');
      setUserRoomData(roomsArray);
      setMoreLoading(false);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    getUserRooms({
      variables: {
        filters: {
          skip: 0,
          limit: POST_SKIP_RECORD
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (event) => {
    if (chatDebounce) {
      chatDebounce?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    chatDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !moreLoading) {
        setMoreLoading(true);
        getUserRooms({
          variables: {
            filters: {
              skip: userRoomData.length,
              limit: POST_SKIP_RECORD
            }
          }
        });
      }
    }, 500);

    chatDebounce();
  };
  // eslint-disable-next-line no-undef
  const isCompany = localStorage.getItem('isCompanyVerified');
  return (
    <div className="chat-wrapper-chat">
      <Row justify="space-between" className="header-wrapper" align="middle">
        <Col>
          <div className="header">
            <h1>Chat</h1>
          </div>
        </Col>
        <Col>
          {!isCompany && (
            <Button
              type="primary"
              className="new-msg"
              htmlType="submit"
              icon={<EditChat />}
              onClick={() => {
                if (isDrawer) {
                  changePage('NEW');
                } else {
                  history.push(ROUTES.NEW_CHAT);
                }
              }}
            >
              New message
            </Button>
          )}
        </Col>
      </Row>
      <div className="chat-main-body" onScroll={handleScroll}>
        {loading ? (
          <LoaderComponent setHeight="90" />
        ) : (
          map(userRoomData, (data) => {
            return (
              <>
                <Row
                  justify="space-between"
                  className="chat-msg pointer"
                  key={data?.id}
                  onClick={() => {
                    if (isDrawer) {
                      changePage('USER_CHAT', data?.membersData?.[0]?.id);
                    } else {
                      history.push(
                        `${ROUTES?.CHAT}/${data?.membersData?.[0]?.id}`
                      );
                    }
                  }}
                >
                  <Col className="min-60 max-60">
                    <div className="d-flex">
                      <Col>
                        {data?.membersData?.[0]?.profileUrl ? (
                          <Avatar
                            className="user-profile"
                            size={50}
                            src={data?.membersData?.[0]?.profileUrl}
                          />
                        ) : (
                          <Avatar
                            className="user-profile-empty"
                            size={50}
                            icon={<UserOutlined />}
                          />
                        )}
                      </Col>
                      <Col className="ml-10 fill-width">
                        <div className="title">
                          {data?.membersData?.[0]?.name}
                        </div>
                        <div className="msg">{data?.lastMessage}</div>
                      </Col>
                    </div>
                  </Col>
                  <Col className="justify-end">
                    <div
                      className={`time${
                        data?.unreadMessages > 0 ? ' unread' : ''
                      }`}
                    >
                      {moment(data?.updatedAt).format('LT')}
                    </div>
                    {data?.unreadMessages > 0 && (
                      <Row justify="flex-end">
                        <Avatar size={20} className="msg-count">
                          {data?.unreadMessages}
                        </Avatar>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Divider className="chat-divider" />
              </>
            );
          })
        )}
        {userRoomData.length <= 0 && <div>No Messages...</div>}
        {moreLoading && <LoaderComponent setHeight={5} />}
      </div>
    </div>
  );
};

export default Chat;
