import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_USER_POSTS = gql`
  query userPublicPosts($filters: PostFilterInput!, $where: CommonWhereInput!) {
    userPublicPosts(filters: $filters, where: $where) {
      count
      data {
        id
        text
        mediaUrl
        mediaType
        createdBy {
          id
          email
          firstName
          lastName
        }
        isLiked
        likeCount
        commentCount
        lastComment
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const GET_USER_CONNECTIONS = gql`
  query getUserConnections($filters: UserFilterInput!) {
    getUserConnections(filters: $filters) {
      id
      profileImage
      firstName
      lastName
      experience {
        company
      }
      userConnection {
        connectedBy
        connectedTo
        id
        status
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const GET_USER_FOLLOWING = gql`
  query getUserFollowings($filters: UserFilterInput!) {
    getUserFollowings(filters: $filters) {
      id
      name
      profileImage
      coverImage
      website
      isFollowed
      review {
        avgRating
      }
    }
  }
`;

export const GET_USER_ABOUT = gql`
  query userMe {
    userMe {
      id
      education {
        id
        school
        degree
        fieldOfStudy
        startDate
        endDate
        isCurrent
      }
      experience {
        id
        title
        company
        type
        startDate
        endDate
        isCurrent
        location {
          city
          state
          country
        }
      }
      resumeUrl
      resumeUpdatedOn
    }
  }
`;
