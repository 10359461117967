/* eslint-disable no-console */
import { useLazyQuery } from '@apollo/client';
import { Button, Col, Divider, Typography } from 'antd';
import AWS from 'aws-sdk';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
// import { recordJobPageVisit } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import JobCardForUser from '../../applied/component/JobCardForUser';
import RecJobCardForUser from '../../recommenders/component/RecJobCardForUser';
import { GET_RECOMMENDED_JOBS } from '../graphql/Queries';
import './Jobs.less';

const Jobs = () => {
  const jobInitialFilter = {
    skip: 0,
    limit: 10,
    sortBy: 'createdAtDESC'
  };

  // eslint-disable-next-line no-unused-vars
  const { getCurrentUser } = useContext(AppContext);
  const userData = getCurrentUser();
  const jobFilter = jobInitialFilter;
  const [recentJobsData, setRecentJobsData] = useState([]);
  const [recommendedJobs, setRecommendedJobs] = useState([]);
  const [recCardResults, setRecCardResults] = useState([]);
  const [loader, setLoader] = useState(true);
  const [jobsDebounce, setJobsDebounce] = useState(false);
  const [jobsIsEnd, setJobsIsEnd] = useState(false);
  const [recCardsFail, setRecCardsFail] = useState(false);

  const [getRecentJobs] = useLazyQuery(GET_RECOMMENDED_JOBS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      const job = res?.jobs?.data.filter((j) => j.displayJob === true);
      setRecentJobsData([...recentJobsData, ...job]);
      if (job?.length < 10) {
        setJobsIsEnd(true);
      }
      setLoader(false);
    }
  });

  const getRecommendedJobs = () => {
    const options = {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    };

    const personalizeRuntime = new AWS.PersonalizeRuntime(options);

    const params = {
      campaignArn: process.env.REACT_APP_PERSONALIZE_CAMPAIGN_ARN_JOBS,
      numResults: '5',
      userId: userData?.id // '624d9b59cd7ebee9b7d781d2' // David goncalves from prod DB, eventually will use -> userData?.id
    };

    personalizeRuntime.getRecommendations(params, function (err, data) {
      if (err) {
        console.log(err, err.stack); // an error occurred
      } else {
        // Returns an array of user_detail IDs
        const resultArray = [];
        data.itemList.forEach((item) => {
          resultArray.push(item.itemId);
        });
        setRecommendedJobs(resultArray);
      }
    });
  };

  useEffect(() => {
    getRecommendedJobs();
    getRecentJobs({
      variables: {
        filters: jobFilter
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addRecCardResult = (result) => {
    const tempArray = recCardResults;
    tempArray.push(result);
    setRecCardResults(tempArray);
    if (
      tempArray.length === recommendedJobs.length &&
      recommendedJobs.length > 0
    ) {
      const recCardsFailed = !tempArray.includes('success');
      setRecCardsFail(recCardsFailed);
    }
  };

  const loadMore = () => {
    if (!jobsIsEnd) {
      setLoader(true);
      getRecentJobs({
        variables: {
          filters: {
            limit: 10,
            sortBy: 'createdAtDESC',
            skip: (jobsDebounce + 1) * 10
          }
        }
      });
      setJobsDebounce((prevState) => prevState + 1);
    }
  };

  return (
    <>
      <div className="applied padding-width">
        {/* <Button onClick={() => recordJobPageVisit('456', '123')}>
          Test Fetch
        </Button> */}
        <div>
          <Typography.Title className="heading">
            Recommended Jobs
          </Typography.Title>
        </div>
        <Divider />
        {!recCardsFail ? (
          map(recommendedJobs, (jobId) => (
            <RecJobCardForUser
              jobId={jobId}
              addRecCardResult={addRecCardResult}
            />
          ))
        ) : (
          <Typography.Text type="secondary">
            Recommendations Not Available
          </Typography.Text>
        )}
      </div>
      <div className="applied padding-width">
        <div>
          <Typography.Title className="heading">
            Recently Added Jobs
          </Typography.Title>
        </div>
        <Divider />
        <LoaderComponent spinning={loader}>
          {map(recentJobsData, (item) => (
            <Col span={22} key={item?.id} className="margin-bottom">
              <JobCardForUser item={item} />
            </Col>
          ))}
        </LoaderComponent>
        {!loader && !jobsIsEnd && (
          <div className="loadMoreButton">
            <Button className="follow-btn" onClick={loadMore}>
              Load More
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default Jobs;
