import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import PrivacyPolicy from './component/PrivacyPolicy';
import './component/PrivacyPolicy.less';

const PrivacyPolicyWrapper = () => {
  return (
    <div className="privacy-policy">
      <Switch>
        <>
          <Route
            exact
            path={ROUTES?.PRIVACY_POLICY}
            component={PrivacyPolicy}
          />
        </>
      </Switch>
    </div>
  );
};

export default PrivacyPolicyWrapper;
