import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { POST_SKIP_RECORD } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import CompanyJobs from '../component/CompanyJobs';
import { GET_COMPANY_JOBS } from '../graphql/Queries';

const jobInitialFilter = {
  skip: 0,
  limit: POST_SKIP_RECORD,
  sortBy: 'createdAtDESC'
};

// title
// appliedcount total

const CompanyJobPage = ({ companyId }) => {
  const {
    state: { currentUser }
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [jobFilter, setJobFilter] = useState(jobInitialFilter);
  const [jobsData, setJobsData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [jobIsEnd, setJobIsEnd] = useState(false);
  const [jobDebounceCall, setJobDebounceCall] = useState(0);
  const [fetchJobsLoading, setFetchJobsLoading] = useState(false);

  const [getCompanyJobInfo] = useLazyQuery(GET_COMPANY_JOBS, {
    onCompleted: (res) => {
      setJobsData([...jobsData, ...res?.jobs?.data]);
      setLoading(false);
      setFetchJobsLoading(false);
      if (res?.jobs?.data?.length < POST_SKIP_RECORD) {
        setJobIsEnd(true);
      }
    },
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    const updatedFilter = {
      where: {
        companyId: companyId || currentUser?.id
      },
      ...jobFilter
    };
    setJobFilter(updatedFilter);
    getCompanyJobInfo({
      variables: {
        filters: updatedFilter
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    if (!jobIsEnd) {
      setFetchJobsLoading(true);
      getCompanyJobInfo({
        variables: {
          filters: {
            ...jobFilter,
            skip: (jobDebounceCall + 1) * POST_SKIP_RECORD
          },
          where: {
            id: currentUser?.id
          }
        }
      });
      setJobDebounceCall((prevState) => prevState + 1);
    }
  };
  if (loading) {
    return <LoaderComponent />;
  }
  return (
    <CompanyJobs
      jobsData={jobsData}
      jobIsEnd={jobIsEnd}
      fetchJobsLoading={fetchJobsLoading}
      loadMore={loadMore}
    />
  );
};

export default CompanyJobPage;
