import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UNARCHIVE_JOB = gql`
  mutation archiveUnarchiveJob($id: String!) {
    archiveUnarchiveJob(where: { id: $id }, action: UNARCHIVE) {
      message
    }
  }
`;
