import { BankOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Avatar, Button, Card, Col, Popover, Space, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import placeholder from '../../../../assets/images/placeholder.jpg';
import { Building } from '../../../../assets/svg';
import { ANALYTICS_EVENTS, ROUTES } from '../../../../common/constants';
import {
  CONNECTION_CANCEL,
  CONNECTION_REQUEST,
  CONNECTION_STATUS_UPDATE,
  DISCONNECT_CONNECTION
} from '../../../../common/graphql/Mutation';
import LoaderComponent from '../../../../components/LoaderComponent';
import history from '../../../../historyData';
import { analytics } from '../../../auth/Firebase';

export default function UserCard({ userData, updatePeople }) {
  const {
    dispatch,
    state: { showDrawer }
  } = useContext(AppContext);
  const [user, setUser] = useState(userData);
  const [actionLoader, setActionLoader] = useState(false);
  const [connectionRequest] = useMutation(CONNECTION_REQUEST);
  const [connectionCancel] = useMutation(CONNECTION_CANCEL);
  const [disconnectConnection] = useMutation(DISCONNECT_CONNECTION);
  const [connectionStatusUpdate] = useMutation(CONNECTION_STATUS_UPDATE);

  useEffect(() => {
    setUser(userData);
  }, [userData]);

  const renderMoreButtonsOnPending = (id) => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          // icon={<DeleteOutlined />}
          onClick={() => {
            connectionStatusUpdate({
              variables: {
                id: id,
                status: 'REJECTED'
              }
            });
            updatePeople({
              ...user,
              userConnection: {
                ...user.userConnection,
                status: 'REJECTED'
              }
            });
          }}
        >
          Decline
        </Button>
      </div>
    );
  };
  const renderMoreButtonsOnAccepted = (id) => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          // icon={<DeleteOutlined />}
          onClick={() => {
            confirm({
              okText: 'Yes, Continue',
              content: 'Are you sure you want to Disconnect?',
              centered: true,
              cancelText: 'No',
              onOk: () => {
                disconnectConnection({
                  variables: { id: id }
                });
                const updatedPerson = {
                  ...user
                };
                delete updatedPerson.userConnection;
                updatePeople(updatedPerson);
              }
            });
          }}
        >
          Disconnect
        </Button>
      </div>
    );
  };
  // eslint-disable-next-line no-undef
  const isCompany = localStorage.getItem('isCompanyVerified');

  const openDrawer = async () => {
    if (isCompany) {
      analytics.logEvent(ANALYTICS_EVENTS.MESSAGE_CANDIDATE);
    } else {
      analytics.logEvent(ANALYTICS_EVENTS.MESSAGE_PEER);
    }
    // eslint-disable-next-line no-undef
    if (window.innerWidth < 1000) {
      history.push(`${ROUTES?.CHAT}/${userData?.profileId}`);
    } else {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: !showDrawer, profileId: userData?.profileId }
      });
    }
  };
  return (
    <Col
      span={6}
      key={user.id}
      xs={{ span: 24 }}
      sm={{ span: 12 }}
      md={{ span: 8 }}
      lg={{ span: 6 }}
    >
      <Card className="pad shadow people-card-wrap">
        <div className="people-card-body">
          <div>
            <Avatar
              className="img"
              src={user.profileImage || placeholder}
              onClick={() => {
                history?.push(`${ROUTES?.PROFILE_ABOUT}/${user?.id}`);
              }}
            />
          </div>
          <div className="space-small-h" />
          <Card.Meta
            description={
              <div>
                <Typography.Title
                  className="heading pl-04"
                  level={5}
                  onClick={() => {
                    history?.push(`${ROUTES?.PROFILE_ABOUT}/${user?.id}`);
                  }}
                >
                  {user.firstName} {user.lastName}
                </Typography.Title>
                <p className="d-flex experience-cover">
                  <Building className="building" />{' '}
                  {user?.experience?.length > 0
                    ? user?.experience[0]?.company
                    : ''}
                </p>
                <Space direction="vertical">
                  {user.experience?.company && (
                    <Typography.Text type="secondary" className="p-0">
                      <BankOutlined /> {user.experience?.company || ''}
                    </Typography.Text>
                  )}
                  {!user.experience?.company && (
                    <div className="space-small-v" />
                  )}
                  <div className="viewDetailWeb">
                    {actionLoader && !isCompany && (
                      <div style={{ height: '36px', width: '8rem' }}>
                        <LoaderComponent setHeight={2} size="small" />
                      </div>
                    )}
                    {!actionLoader && isCompany && (
                      <Button
                        type="primary"
                        className="follow-btn"
                        onClick={async () => {
                          openDrawer();
                        }}
                      >
                        Message
                      </Button>
                    )}
                    {!actionLoader && !isCompany && (
                      <div>
                        {!user.userConnection && (
                          <Button
                            type="primary"
                            className="follow-btn"
                            onClick={async () => {
                              setActionLoader(true);
                              const connection = await connectionRequest({
                                variables: { id: user.id }
                              });
                              setActionLoader(false);
                              updatePeople({
                                ...user,
                                userConnection:
                                  connection.data?.connectionRequest
                              });
                            }}
                          >
                            Connect
                          </Button>
                        )}
                        {user.userConnection?.status === 'ACCEPTED' && (
                          <div className="d-flex justify-center align-center">
                            <Button
                              type="primary"
                              className="follow-btn"
                              onClick={() => {
                                openDrawer();
                              }}
                            >
                              Message
                            </Button>
                            <div className="space-small-h" />
                            <Popover
                              placement="left"
                              overlayClassName="action-button"
                              content={renderMoreButtonsOnAccepted(
                                user.userConnection?.id
                              )}
                            >
                              <EllipsisOutlined />
                            </Popover>
                          </div>
                        )}
                        {user.userConnection?.status === 'PENDING' &&
                          user.userConnection?.connectedBy !== user.id && (
                            <Button
                              type="danger"
                              className="follow-btn"
                              onClick={() => {
                                confirm({
                                  okText: 'Yes, Cancel',
                                  content:
                                    'Are you sure you want to cancel request?',
                                  centered: true,
                                  cancelText: 'No',
                                  onOk: () => {
                                    connectionCancel({
                                      variables: { id: user.userConnection?.id }
                                    });
                                    const updatedPerson = {
                                      ...user
                                    };
                                    delete updatedPerson.userConnection;
                                    updatePeople(updatedPerson);
                                  }
                                });
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        {user.userConnection?.status === 'PENDING' &&
                          user.userConnection?.connectedBy === user.id && (
                            <div className="d-flex justify-center align-center">
                              <Button
                                onClick={() => {
                                  connectionStatusUpdate({
                                    variables: {
                                      id: user.userConnection?.id,
                                      status: 'ACCEPTED'
                                    }
                                  });
                                  updatePeople({
                                    ...user,
                                    userConnection: {
                                      ...user.userConnection,
                                      status: 'ACCEPTED'
                                    }
                                  });
                                }}
                                className="follow-btn"
                              >
                                Accept
                              </Button>
                              <div className="space-small-h" />

                              <Popover
                                placement="left"
                                overlayClassName="action-button"
                                content={renderMoreButtonsOnPending(
                                  user.userConnection?.id
                                )}
                              >
                                <EllipsisOutlined />
                              </Popover>
                            </div>
                          )}
                        {user.userConnection?.status === 'REJECTED' && (
                          <Button
                            type="primary"
                            className="follow-btn"
                            disabled
                          >
                            Connect
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </Space>
              </div>
            }
          />
        </div>
        <div className="viewDetailMobile d-flex justify-center">
          {actionLoader && !isCompany && (
            <div style={{ height: '36px', width: '8rem' }}>
              <LoaderComponent setHeight={2} size="small" />
            </div>
          )}
          {!actionLoader && isCompany && (
            <Button
              type="primary"
              className="follow-btn"
              onClick={async () => {
                openDrawer();
              }}
            >
              Message
            </Button>
          )}
          {!actionLoader && !isCompany && (
            <div>
              {!user.userConnection && (
                <Button
                  type="primary"
                  className="follow-btn mt-10"
                  onClick={async () => {
                    setActionLoader(true);
                    const connection = await connectionRequest({
                      variables: { id: user.id }
                    });
                    setActionLoader(false);
                    updatePeople({
                      ...user,
                      userConnection: connection.data?.connectionRequest
                    });
                  }}
                >
                  Connect
                </Button>
              )}
              {user.userConnection?.status === 'ACCEPTED' && (
                <div className="d-flex justify-center w-100">
                  <Button
                    type="primary"
                    className="follow-btn mt-10"
                    onClick={() => {
                      openDrawer();
                    }}
                  >
                    Message
                  </Button>
                  <div className="space-small-h" />
                  <div className="space-small-h" />
                  <Button
                    type="danger"
                    onClick={() => {
                      confirm({
                        okText: 'Yes, Continue',
                        content: 'Are you sure you want to disconnect?',
                        centered: true,
                        cancelText: 'No',
                        onOk: () => {
                          disconnectConnection({
                            variables: { id: user.userConnection?.id }
                          });
                          const updatedPerson = {
                            ...user
                          };
                          delete updatedPerson.userConnection;
                          updatePeople(updatedPerson);
                        }
                      });
                    }}
                    className="follow-btn mt-10"
                  >
                    Disconnect
                  </Button>
                </div>
              )}
              {user.userConnection?.status === 'PENDING' &&
                user.userConnection?.connectedBy !== user.id && (
                  <Button
                    type="danger"
                    className="follow-btn mt-10"
                    onClick={() => {
                      confirm({
                        okText: 'Yes, Cancel',
                        content: 'Are you sure you want to cancel request?',
                        centered: true,
                        cancelText: 'No',
                        onOk: () => {
                          connectionCancel({
                            variables: { id: user.userConnection?.id }
                          });
                          const updatedPerson = {
                            ...user
                          };
                          delete updatedPerson.userConnection;
                          updatePeople(updatedPerson);
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                )}
              {user.userConnection?.status === 'PENDING' &&
                user.userConnection?.connectedBy === user.id && (
                  <div className="d-flex justify-center w-100">
                    <Button
                      onClick={() => {
                        connectionStatusUpdate({
                          variables: {
                            id: user.userConnection?.id,
                            status: 'ACCEPTED'
                          }
                        });
                        updatePeople({
                          ...user,
                          userConnection: {
                            ...user.userConnection,
                            status: 'ACCEPTED'
                          }
                        });
                      }}
                      className="follow-btn mt-10"
                    >
                      Accept
                    </Button>
                    <div className="space-small-h" />
                    <Button
                      type="danger"
                      onClick={() => {
                        connectionStatusUpdate({
                          variables: {
                            id: user.userConnection?.id,
                            status: 'REJECTED'
                          }
                        });
                        updatePeople({
                          ...user,
                          userConnection: {
                            ...user.userConnection,
                            status: 'REJECTED'
                          }
                        });
                      }}
                      className="follow-btn mt-10"
                    >
                      Reject
                    </Button>
                  </div>
                )}
            </div>
          )}
          {user.userConnection?.status === 'REJECTED' && (
            <Button type="primary" className="mt-10 follow-btn" disabled>
              Connect
            </Button>
          )}
        </div>
      </Card>
    </Col>
  );
}
