/* eslint-disable no-unused-vars */
import { UserOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Rate,
  Row,
  Space
} from 'antd';
import { remove } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import placeholder from '../../../assets/images/placeholder.jpg';
import {
  SKIP_RECORD,
  SKIP_RECORD_REVIEW,
  USER
} from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import { REVIEW_COMPANY } from '../graphql/Mutation';
import { GET_COMPANY_INFO, GET_COMPANY_REVIEW } from '../graphql/Queries';

let reviewDebounce;

const initialReviewFilter = {
  skip: 0,
  limit: 5
};
const CompanyReviewPage = () => {
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const { id } = useParams();
  const [reviewData, setReviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rating, setRating] = useState(3);
  const [myReview, setMyReview] = useState();
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [loadingMyReview, setLoadingMyReview] = useState(true);
  // eslint-disable-next-line no-undef
  const { id: companyId, id: userId } = JSON.parse(localStorage.getItem(USER));
  const [reviewFilter] = useState(initialReviewFilter);
  const desc = [1, 2, 3, 4, 5];
  const [reviewCompany] = useMutation(REVIEW_COMPANY);
  const [moreLoading, setMoreLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  // eslint-disable-next-line no-undef
  const isOnboardingCompleted = localStorage.getItem('isOnboardingCompleted');
  const [getCompany] = useLazyQuery(GET_COMPANY_INFO, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      const reviewDataCopy = reviewData.slice();
      remove(reviewDataCopy, function (review) {
        if (review?.user?.id === userId) {
          return true;
        }
      });
      setLoadingMyReview(false);
      setReviewData(reviewDataCopy);
      setMyReview(res?.company?.myReview);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const [getCompanyReviews] = useLazyQuery(GET_COMPANY_REVIEW, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (reviewData)
        setReviewData([...reviewData, ...res?.companyReviews?.data]);
      else setReviewData(res?.companyReviews?.data);
      getCompany({
        variables: {
          id: id || companyId
        }
      });
      setLoading(false);
      if (res?.companyReviews?.data?.length < SKIP_RECORD_REVIEW) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setMoreLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const onLoadMore = () => {
    setMoreLoading(true);
    getCompanyReviews({
      variables: {
        filters: {
          skip: reviewData.length,
          limit: SKIP_RECORD
        },
        where: {
          id: id || companyId
        }
      }
    });
  };

  const Suffix = (
    <Space>
      <Checkbox onChange={(e) => setIsAnonymous(e.target.checked)}>
        Anonymous
      </Checkbox>
      <span>
        Also rate us on:{' '}
        <Rate
          tooltips={desc}
          onChange={(e) => {
            setRating(e);
          }}
          value={rating}
        />
        {rating ? (
          <span className="ant-rate-text">{desc[rating - 1]}</span>
        ) : (
          ''
        )}
      </span>
    </Space>
  );
  useEffect(() => {
    getCompanyReviews({
      variables: {
        filters: reviewFilter,
        where: {
          id: id || companyId
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyChange = async (event) => {
    if (event.key === 'Enter') {
      setLoadingMyReview(true);
      await reviewCompany({
        variables: {
          data: {
            companyId: id || companyId,
            ratings: rating,
            isAnonymous: isAnonymous,
            reviewText: event.target.value
          }
        }
      });
      getCompany({
        variables: {
          id: id || companyId
        }
      });
    }
  };
  return (
    <div className="company-review">
      {loadingMyReview && (
        <div>
          <LoaderComponent setHeight={10} />
        </div>
      )}
      {isOnboardingCompleted && !loadingMyReview && !myReview && (
        <>
          <div>
            <h4>Write review:</h4>
          </div>
          <Input
            placeholder="Write Your Review"
            className="mb-10"
            onKeyDown={(e) => handleKeyChange(e)}
            suffix={Suffix}
          />
        </>
      )}
      {isOnboardingCompleted && !loadingMyReview && myReview && (
        <>
          <div>
            <h4>Your Review:</h4>
          </div>
          <Card
            hoverable
            className="mb-10 review-card"
            style={{ boxShadow: '0 4px 12px rgb(0 0 0 / 9%)' }}
          >
            <Card.Meta
              avatar={
                <Avatar src={myReview?.user?.profileImage || placeholder} />
              }
              title={
                <div className="d-flex justify-between">
                  <div>
                    <>
                      <h3>{`${myReview?.user?.firstName} ${myReview?.user?.lastName}`}</h3>
                      <p className="title-bio">{myReview?.user?.bio}</p>
                    </>
                  </div>
                  <div>
                    <Rate disabled value={myReview?.ratings} />
                    <span className="ant-rate-text">{myReview?.ratings}</span>
                  </div>
                </div>
              }
              description={<div>{myReview?.reviewText}</div>}
            />
          </Card>
        </>
      )}
      <div>
        <h4>Reviews:</h4>
      </div>
      {(!reviewData || !reviewData.length) && (
        <div className="no-review">No Reviews yet</div>
      )}
      {reviewData && reviewData.length > 0 && (
        <Row gap={16}>
          {reviewData?.map((item) => (
            <Col span={24} key={item} className="mb-10">
              <Card
                hoverable
                className="mb-10 review-card"
                style={{ boxShadow: '0 4px 12px rgb(0 0 0 / 9%)' }}
              >
                <Card.Meta
                  avatar={
                    item?.isAnonymous ? (
                      <Avatar icon={<UserOutlined />} />
                    ) : (
                      <Avatar src={item?.user?.profileImage || placeholder} />
                    )
                  }
                  title={
                    <div className="d-flex justify-between">
                      <div>
                        {item?.isAnonymous ? (
                          <h3>Anonymous</h3>
                        ) : (
                          <>
                            <h3>{`${item?.user?.firstName} ${item?.user?.lastName}`}</h3>
                            <p className="title-bio">{item?.user?.bio}</p>
                          </>
                        )}
                      </div>
                      <div>
                        <Rate disabled value={item?.ratings} />
                        <span className="ant-rate-text">{item?.ratings}</span>
                      </div>
                    </div>
                  }
                  description={<div>{item?.reviewText}</div>}
                />
              </Card>
            </Col>
          ))}
          {!moreLoading && hasMore && (
            <div className="loadMoreButton">
              <Button className="follow-btn" onClick={onLoadMore}>
                Load More
              </Button>
            </div>
          )}
          {moreLoading && hasMore && (
            <div className="loadMoreButton">
              <LoaderComponent setHeight={10} />
            </div>
          )}
        </Row>
      )}
    </div>
  );
};

export default CompanyReviewPage;
