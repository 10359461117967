import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Archive from './component/ArchiveJob';

const ArchiveWrapper = () => {
  return (
    <Switch>
      <>
        <Route exact path={ROUTES?.ARCHIVE} component={Archive} />
      </>
    </Switch>
  );
};

export default ArchiveWrapper;
