import { useLazyQuery } from '@apollo/client';
import { Avatar, Card, Skeleton } from 'antd';
import { React, useEffect, useState } from 'react';
import { ANALYTICS_EVENTS, ROUTES } from '../../../common/constants';
import { GET_USER } from '../../../common/graphql/Queries';
import history from '../../../historyData';
import { analytics } from '../../auth/Firebase';

const { Meta } = Card;

const RecommendedAppCard = ({ userId, jobId, jobTitle, addRecCardResult }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [userDescription, setUserDescription] = useState();
  const [loadFail, setLoadFail] = useState(false);

  const [getUser, { data, loading }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onError: () => {
      addRecCardResult('fail');
      setLoadFail(true);
    },
    onCompleted: () => {
      setUserInfo(data);
      addRecCardResult('success');
    }
  });

  const getRecentExpereince = () => {
    if (userInfo?.user?.experience?.length) {
      const experiences = userInfo.user.experience;
      let recentExperience = experiences[0];
      for (let i = 0; i < experiences.length; i += 1) {
        if (experiences[i].isCurrent) {
          setUserDescription(
            `${experiences[i]?.title} at ${experiences[i]?.company}`
          );
          break;
        } else if (experiences[i]?.endDate > recentExperience?.endDate) {
          recentExperience = experiences[i];
        }
      }
      setUserDescription(
        `${recentExperience?.title} at ${recentExperience?.company}`
      );
    }
  };

  useEffect(() => {
    getUser({ variables: { id: userId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userInfo) {
      getRecentExpereince();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <>
      {loadFail ? null : (
        <Card
          hoverable
          className="app-card"
          onClick={() => {
            analytics.logEvent(ANALYTICS_EVENTS.VIEWS_CANDIDATE_PROFILE, {
              job_id: jobId,
              job_title: jobTitle,
              applicant_name: `${userInfo?.user?.firstName} ${userInfo?.user?.lastName}`,
              applicant_id: userId
            });
            history?.push(`${ROUTES?.PROFILE_ABOUT}/${userId}`);
          }}
        >
          <Skeleton loading={loading} avatar active>
            <Meta
              avatar={<Avatar src={userInfo?.user?.profileImage} />} // make default avatar if none uploaded by user
              title={`${userInfo?.user?.firstName} ${userInfo?.user?.lastName}`}
              description={userDescription}
            />
          </Skeleton>
        </Card>
      )}
    </>
  );
};

export default RecommendedAppCard;
