import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Jobs from './component/Jobs';

const JobWrapper = () => {
  return (
    <Switch>
      <>
        <Route exact path={ROUTES?.JOBS} component={Jobs} />
      </>
    </Switch>
  );
};

export default JobWrapper;
