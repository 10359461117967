import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import LoaderComponent from '../../../components/LoaderComponent';
import SingleFeed from '../component/SingleFeed';
import { GET_POST } from '../graphql/Queries';

const PostDetail = (props) => {
  const { match: { params: { id } = {} } = {} } = props;

  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState(null);

  const [getPost] = useLazyQuery(GET_POST, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      setLoading(false);
      setPostData(res?.post);
    },
    onError() {
      setLoading(false);
    }
  });

  useEffect(() => {
    getPost({
      variables: {
        where: {
          id
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <SingleFeed postData={postData} />
      )}
    </div>
  );
};

export default withRouter(PostDetail);
