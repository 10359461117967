import { Button, Modal, Typography } from 'antd';
import AWS from 'aws-sdk';
import { React, useEffect, useState } from 'react';
import RecommendedAppCard from './RecommendedAppCard';
import './Recommenders.less';

const ApplicantRecommender = ({ jobId, buttonType, jobTitle }) => {
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [recommendedUserIds, setRecommendedUserIds] = useState([]);
  const [recCardsFail, setRecCardsFail] = useState(false);
  const [recCardResults, setRecCardResults] = useState([]);

  // commented out for the time being because recommender returns users that are in prod DB, not dev DB

  useEffect(() => {
    const options = {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    };

    const personalizeRuntime = new AWS.PersonalizeRuntime(options);

    const params = {
      campaignArn: process.env.REACT_APP_PERSONALIZE_CAMPAIGN_ARN_APPLICANTS,
      numResults: '4',
      userId: jobId // '6229009f1ec28b6ec0945554'
    };

    personalizeRuntime.getRecommendations(params, function (err, data) {
      if (err) {
        // eslint-disable-next-line no-console
        console.log(err, err.stack); // an error occurred
      } else {
        // Returns an array of user_detail IDs
        const resultArray = [];
        data.itemList.forEach((item) => {
          resultArray.push(item.itemId);
        });
        setRecommendedUserIds(resultArray);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addRecCardResult = (result) => {
    const tempArray = recCardResults;
    tempArray.push(result);
    setRecCardResults(tempArray);
    if (
      tempArray.length === recommendedUserIds.length &&
      recommendedUserIds.length > 0
    ) {
      const recCardsFailed = !tempArray.includes('success');
      setRecCardsFail(recCardsFailed);
    }
  };

  return (
    <>
      <Button
        type="primary"
        shape={buttonType}
        onClick={() => setShowRecommendations(true)}
      >
        Recommend Candidates
      </Button>
      <Modal
        visible={showRecommendations}
        title="Recommended Candidates for this Job Listing"
        onCancel={() => setShowRecommendations(false)}
        footer={[]}
      >
        {!recCardsFail ? (
          // recommendedUserIds.length && recommendedUserIds[0] !== undefined ? (
          recommendedUserIds.map((userId) => {
            return (
              <div key={userId} className="modal-recommender">
                <RecommendedAppCard
                  userId={userId}
                  jobId={jobId}
                  jobTitle={jobTitle}
                  addRecCardResult={addRecCardResult}
                />
              </div>
            );
          })
        ) : (
          <Typography.Text type="secondary">
            Recommendations Not Available
          </Typography.Text>
        )}
      </Modal>
    </>
  );
};

export default ApplicantRecommender;
