import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Applied from './component/Applied';

const AppliedWrapper = () => {
  return (
    <Switch>
      <>
        <Route exact path={ROUTES?.APPLIED_JOBS} component={Applied} />
      </>
    </Switch>
  );
};

export default AppliedWrapper;
