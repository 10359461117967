import { useQuery } from '@apollo/client';
import { Divider } from 'antd';
import moment from 'moment';
import { React, useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import arrowSvg from '../../../assets/svg/arrow.svg';
import LoaderComponent from '../../../components/LoaderComponent';
import { MapComponent } from '../../../components/MapComponent';
import { GET_COMPANY_INFO } from '../graphql/Queries';

const CompanyAboutPage = ({ companyId }) => {
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState(null);
  const { getCurrentUser } = useContext(AppContext);
  const [locationArray, setLocationArray] = useState([]);

  const userData = getCurrentUser();
  const { data: { company: companyData } = {} } = useQuery(GET_COMPANY_INFO, {
    variables: {
      id: companyId || userData.id
    },
    onCompleted: (res) => {
      switch (res?.company?.employeeSize) {
        case 'LESS_THAN_100':
          setEmployeeData('50+ employees');
          break;
        case 'LESS_THAN_250':
          setEmployeeData('200+ employees');
          break;
        case 'LESS_THAN_500':
          setEmployeeData('250+ employees');
          break;
        case 'LESS_THAN_1000':
          setEmployeeData('500+ employees');
          break;
        case 'LESS_THAN_5000':
          setEmployeeData('1000+ employees');
          break;
        case 'LESS_THAN_10000':
          setEmployeeData('2000+ employees');
          break;
        case 'MORE_THAN_10000':
          setEmployeeData('10000+ employees');
          break;
        default:
          break;
      }

      const locations = [res?.company?.hqLocation, ...res?.company?.branches];
      setLocationArray(locations);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  return (
    <LoaderComponent spinning={loading}>
      <div className="about-company">
        <div className="overview-funding-section">
          <div className="company-over-view">
            <span className="big-text">Overview</span>
            <br />
            <span className="small-text">{companyData?.description}</span>
          </div>
          <div className="company-funding showOnlyWeb">
            <span className="big-text">Funding</span>
            <div className="funding-info">
              <span>{companyData?.name}</span>
              <Divider type="vertical" />
              <span>{companyData?.funding?.totalRounds} Total Rounds</span>
            </div>
            <Divider />
            <div className="round-info d-flex flex-vertical">
              <span className="round-label">Last Round</span>
              <div className="d-flex mt-12">
                {companyData?.funding?.lastRoundInvestor ? (
                  <>
                    <span>{companyData?.funding?.lastRoundInvestor}</span>
                    <Divider type="vertical" />
                    <span>
                      {companyData?.funding?.lastRoundDate
                        ? moment(companyData?.funding?.lastRoundDate).format(
                            'll'
                          )
                        : ''}
                    </span>
                  </>
                ) : (
                  <span>No Rounds</span>
                )}
              </div>
            </div>
            <div className="found-amount">
              <span>
                {companyData?.funding?.fundsRaised
                  ? `US$${companyData?.funding?.fundsRaised}`
                  : ''}
              </span>
            </div>
          </div>
        </div>
        {companyData?.website && (
          <div className="website-section">
            <span className="big-text">Website</span>
            <a
              href={companyData?.website}
              target="_blank"
              rel="noreferrer"
              className="website-block mt-10"
            >
              <span>
                Visit website
                <img src={arrowSvg} alt="website" />
              </span>
            </a>
          </div>
        )}
        <div className="d-flex flex-horizontal">
          <div className="other-info">
            <span className="big-text">Company Size</span>
            <span className="small-text mt-10">{employeeData}</span>
          </div>
          <div className="other-info ml-20">
            <span className="big-text">Headquarters</span>
            <span className="small-text mt-10">
              {companyData?.hqLocation?.state},
              {companyData?.hqLocation?.country}
            </span>
          </div>
        </div>
        <div className="other-info mt-10">
          <span className="big-text">Specialties</span>
          <span className="small-text mt-10">
            {companyData?.specialties?.join()}
          </span>
        </div>

        <div className="company-funding showOnlyMobile">
          <Divider />
          <span className="big-text">Funding</span>
          <div className="funding-info">
            <span>{companyData?.name}</span>
            <Divider type="vertical" />
            <span>{companyData?.funding?.totalRounds} Total Rounds</span>
          </div>
          <div className="space-small-v" />
          <div className="round-info d-flex flex-vertical">
            <span className="round-label">Last Round</span>
            <div className="d-flex mt-12">
              {companyData?.funding?.lastRoundInvestor ? (
                <>
                  <span>{companyData?.funding?.lastRoundInvestor}</span>
                  <Divider type="vertical" />
                  <span>
                    {companyData?.funding?.lastRoundDate
                      ? moment(companyData?.funding?.lastRoundDate).format('ll')
                      : ''}
                  </span>
                </>
              ) : (
                <span>No Rounds</span>
              )}
            </div>
          </div>
          <span>
            {companyData?.funding?.fundsRaised
              ? `US $${companyData?.funding?.fundsRaised}`
              : ''}
          </span>
        </div>
        <Divider />
        <div className="other-info mt-10">
          <span className="big-text">Locations ({locationArray.length})</span>
          <span className="small-text mt-10">
            Interact with the map to explore all locations
          </span>
        </div>
        <div style={{ height: '400px' }}>
          <div className="space-very-small-v" />
          <MapComponent locationArray={locationArray} />
        </div>
        <div className="space-large-v" />
      </div>
    </LoaderComponent>
  );
};

export default CompanyAboutPage;
