import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { BREAKPOINTS, ROUTES } from '../common/constants';
import ChatDrawer from '../components/ChatDrawer';
import './App.css';
import AppHeader from './components/header/AppHeader';
import MobileHeader from './components/header/MobileHeader';
import ContentRoutes from './ContentRoutes';

const { Content } = Layout;

const App = () => {
  const isDesktopViewPort = useMedia(`(min-width: ${BREAKPOINTS?.desktop}px)`);
  const [headerVisible, setHeaderVisible] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (pathname === ROUTES.SUCCESS) {
      // eslint-disable-next-line no-undef
      localStorage?.setItem('isCompanyOnboardingCompleted', true);
      // eslint-disable-next-line no-undef
      localStorage?.setItem('isCompanyVerified', true);
      setTimeout(() => {
        history.push(ROUTES.COMPANY_FEED);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="gx-app-layout">
      {!isDesktopViewPort && (
        <MobileHeader setHeaderVisible={setHeaderVisible} />
      )}
      <Layout>
        {isDesktopViewPort && <AppHeader />}
        <Content className="gx-layout-content">
          <div className="gx-main-content-wrapper">
            <ContentRoutes />
          </div>
          <div>
            <ChatDrawer headerVisible={headerVisible} />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
