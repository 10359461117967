/* eslint-disable no-console */
import {
  BankOutlined,
  DeleteOutlined,
  EditOutlined,
  EnvironmentFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
  SolutionOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  message,
  Result,
  Space,
  Tabs,
  Typography
} from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import placeholder from '../../../assets/images/placeholder.jpg';
import {
  ANALYTICS_EVENTS,
  defaultDateFormat,
  JOB_APPLICATION_ACTION_STATUS,
  JOB_WORK_TYPE,
  ROUTES
} from '../../../common/constants';
import { getLocationStrFromLocationArray } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import { analytics } from '../../auth/Firebase';
import AppRecommenderContainer from '../../recommenders/component/AppRecommenderContainer';
import ApplicantRecommender from '../../recommenders/component/ApplicantRecommender';
import {
  ARCHIVE_JOB,
  DELETE_JOB,
  UPDATE_JOB_APPLICATION_STATUS
} from '../graphql/Mutation';
import { GET_JOB_DETAILS, JOB_APPLICATION_DETAILS } from '../graphql/Queries';
import './IndividualMyJob.less';

const IndividualMyJob = () => {
  const { TabPane } = Tabs;
  const { id: jobId } = useParams();
  const { pathname } = useLocation();

  const jobApplicationFilter = {
    skip: 0,
    limit: 10
  };

  const [jobDetails, setJobDetails] = useState(null);
  const [jobApplicationDetails, setJobApplicationDetails] = useState([]);
  const [status, setStatus] = useState(null);
  const [activeKey, setActiveKey] = useState('all');
  const [jobsDebounce, setJobsDebounce] = useState(false);
  const [jobsIsEnd, setJobsIsEnd] = useState(false);
  const [jobsLoading, setJobsLoading] = useState(true);
  const [contentLoading, setContentLoading] = useState(true);
  const [updateJobApplicationStatus] = useMutation(
    UPDATE_JOB_APPLICATION_STATUS
  );
  const [archiveJob] = useMutation(ARCHIVE_JOB);
  const [deleteJob] = useMutation(DELETE_JOB);

  const { loading } = useQuery(GET_JOB_DETAILS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      setJobDetails(res.job);
    },
    variables: {
      id: jobId
    },
    onError() {}
  });

  const [
    getJobApplicationDetails,
    { data: jobApplication, loading: detailLoading }
  ] = useLazyQuery(JOB_APPLICATION_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setContentLoading(false);
      const job = res.jobApplicationDetails.data;
      setJobApplicationDetails([...jobApplicationDetails, ...job]);
      if (job?.length < 10) {
        setJobsIsEnd(true);
      }
    },
    onError() {}
  });

  const refetchJobApplicationDetails = () => {
    setJobApplicationDetails([]);
    getJobApplicationDetails({
      variables: {
        id: jobId,
        filter: {
          ...jobApplicationFilter,
          status
        }
      }
    });
  };

  useEffect(() => {
    setJobApplicationDetails([]);
    getJobApplicationDetails({
      variables: {
        id: jobId,
        filter: {
          ...jobApplicationFilter,
          status
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (pathname?.includes('shortlisted')) {
      setActiveKey('shortlisted');
    } else if (pathname?.includes('accepted')) {
      setActiveKey('accepted');
    } else if (pathname?.includes('rejected')) {
      setActiveKey('rejected');
    } else {
      setActiveKey('all');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResumeDownload = (resumeUrl) => {
    if (!resumeUrl || resumeUrl === '' || resumeUrl === null) {
      message.error('Resume not uploaded by candidate');
    } else {
      // eslint-disable-next-line no-undef
      window.open(resumeUrl, '_blank');
    }
  };

  const handleJobApplicationAction = (action, jobApplicationId) => {
    const updatedStatus = JOB_APPLICATION_ACTION_STATUS[action];
    const displayMessage = `Are you sure want to ${updatedStatus} this application?`;

    confirm({
      okText: 'Yes',
      title: displayMessage,
      centered: true,
      cancelText: 'No',
      onOk: () => {
        updateJobApplicationStatus({
          variables: {
            id: jobApplicationId,
            status: action
          }
        }).then(() => {
          refetchJobApplicationDetails();
        });
      }
    });
  };

  if (loading) return <LoaderComponent />;

  const handleClickOnJob = (id) => {
    history.push(`${ROUTES.JOB_DETAILS}/${id}`);
  };

  const renderButtonBasedOnStatus = (item, UserData) => {
    return (
      <>
        {item?.status === 'PENDING' && (
          <>
            <Button
              className="btn-follow"
              onClick={() => {
                analytics.logEvent(ANALYTICS_EVENTS.REJECT_CANDIDATE, {
                  application_id: item?.id
                });
                handleJobApplicationAction('REJECTED', item?.id);
              }}
            >
              Reject
            </Button>
            <Button
              className="follow-btn"
              icon={<SolutionOutlined />}
              onClick={() => {
                return handleResumeDownload(UserData?.resumeUrl);
              }}
            >
              Resume/CV
            </Button>
            <Button
              className="btn-follow"
              type="primary"
              onClick={() => {
                handleJobApplicationAction('WHITELISTED', item?.id);
              }}
            >
              Shortlist
            </Button>
          </>
        )}
        {item?.status === 'WHITELISTED' && (
          <>
            <Button
              className="btn-follow"
              onClick={() => {
                analytics.logEvent(ANALYTICS_EVENTS.REJECT_CANDIDATE, {
                  application_id: item?.id
                });
                handleJobApplicationAction('REJECTED', item?.id);
              }}
            >
              Reject
            </Button>
            <Button
              className="follow-btn"
              icon={<SolutionOutlined />}
              onClick={() => {
                return handleResumeDownload(UserData?.resumeUrl);
              }}
            >
              Resume/CV
            </Button>
            <Button
              className="btn-follow"
              type="primary"
              onClick={() => {
                analytics.logEvent(ANALYTICS_EVENTS.SELECT_CANDIDATE, {
                  application_id: item?.id
                });
                handleJobApplicationAction('ACCEPTED', item?.id);
              }}
            >
              Accept
            </Button>
          </>
        )}
        {item?.status === 'REJECTED' && (
          <>
            <Button className="rejected-btn">Rejected</Button>
          </>
        )}
        {item?.status === 'ACCEPTED' && (
          <>
            <Button className="follow-btn" icon={<SolutionOutlined />} disabled>
              Resume/CV
            </Button>
            <Button className="btn-follow accepted-btn" type="primary">
              Accepted
            </Button>
          </>
        )}
      </>
    );
  };

  const loadMore = () => {
    if (!jobsIsEnd) {
      setJobsLoading(true);
      getJobApplicationDetails({
        variables: {
          id: jobId,
          filter: {
            skip: (jobsDebounce + 1) * 10,
            limit: 10,
            status
          }
        }
      });
      setJobsDebounce((prevState) => prevState + 1);
    }
  };

  return (
    <>
      {!loading && jobDetails && (
        <div className="individual-jobs pointer">
          <Space direction="vertical">
            <Typography.Title className="heading">
              {jobDetails?.title}
            </Typography.Title>
            <Space className="noMobileFlex">
              <div>
                <Button type="round" className="grey-btn">
                  Required Candidates:{' '}
                  {jobDetails?.applicationCount?.totalAccepted}
                  {' / '}
                  {jobDetails?.noOfVacancies}
                </Button>{' '}
              </div>
              <div className="mt-mobile">
                <EnvironmentFilled style={{ color: '#34A853' }} />
                <Typography.Text
                  strong
                  style={{ paddingLeft: '5px', color: '#34A853' }}
                >
                  {JOB_WORK_TYPE[jobDetails?.workType]} :{' '}
                </Typography.Text>
                <Typography.Text style={{ paddingLeft: '5px' }}>
                  {getLocationStrFromLocationArray(jobDetails?.location)}
                </Typography.Text>
              </div>
            </Space>
            <div className="round-button">
              <Space>
                <Button
                  type="round"
                  onClick={() => {
                    handleClickOnJob(jobDetails.id);
                  }}
                  icon={<EyeOutlined />}
                >
                  View Job
                </Button>
                <Button
                  type="round"
                  icon={<EyeInvisibleOutlined />}
                  onClick={() => {
                    confirm({
                      okText: 'Yes',
                      title: 'Are you sure you want to archive this job?',
                      centered: true,
                      cancelText: 'No',
                      onOk: () => {
                        archiveJob({
                          variables: {
                            id: jobDetails.id
                          }
                        }).then(() => {
                          history.push(`${ROUTES.MY_JOB}`);
                        });
                      }
                    });
                  }}
                >
                  Archive
                </Button>
                <Button
                  type="round"
                  icon={<EditOutlined />}
                  onClick={() => history.push(`${ROUTES.MY_JOB}/${jobId}/edit`)}
                >
                  Edit Job
                </Button>
                <Button
                  danger
                  type="round"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    confirm({
                      okText: 'Yes',
                      title: 'Are you sure you want to delete this job?',
                      centered: true,
                      cancelText: 'No',
                      onOk: () => {
                        deleteJob({
                          variables: {
                            id: jobDetails.id
                          }
                        }).then(() => {
                          history.push(`${ROUTES.MY_JOB}`);
                        });
                      }
                    });
                  }}
                >
                  Delete
                </Button>
                <div className="app-recommender-btn">
                  <ApplicantRecommender
                    jobId={jobDetails.id}
                    jobTitle={jobDetails?.title}
                    buttonType="round"
                  />
                </div>
              </Space>
            </div>
          </Space>
          <div className="recommend-apps-wrapper">
            <AppRecommenderContainer
              jobId={jobDetails.id}
              jobTitle={jobDetails?.title}
            />
          </div>
          <Divider />
          <Space size="large">
            <Tabs
              activeKey={activeKey}
              onChange={(key) => {
                setActiveKey(key);
                if (key === 'shortlisted') {
                  setStatus('WHITELISTED');
                } else if (key === 'accepted') {
                  setStatus('ACCEPTED');
                } else if (key === 'rejected') {
                  setStatus('REJECTED');
                } else {
                  setStatus(null);
                }
              }}
              className="mt-12"
              tabBarExtraContent={{
                left: (
                  <h3 className="mb-0 mr-25">
                    Total : {jobApplication?.jobApplicationDetails?.count || 0}
                  </h3>
                )
              }}
            >
              <TabPane
                tab="All Candidates"
                key="all"
                className="applied-jobs-tab"
              >
                <div className="applied-jobs" />
              </TabPane>
              <TabPane
                tab="Shortlisted"
                key="shortlisted"
                className="applied-jobs-tab"
              >
                <div className="applied-jobs" />
              </TabPane>
              <TabPane
                tab="Accepted"
                key="accepted"
                className="applied-jobs-tab"
              >
                <div className="applied-jobs" />
              </TabPane>
              <TabPane
                tab="Rejected"
                key="rejected"
                className="applied-jobs-tab"
              >
                <div className="applied-jobs" />
              </TabPane>
            </Tabs>
          </Space>
          {(contentLoading || detailLoading) && (
            <LoaderComponent setHeight={10} />
          )}
          <Divider />
          {!contentLoading &&
            !detailLoading &&
            jobApplicationDetails?.length === 0 && (
              <Result status="404" title="No Data" />
            )}
          {map(jobApplicationDetails, (item) => {
            const { user: UserData } = item;

            return (
              <Col span={22} className="margin-bottom" key={item.id}>
                <Card
                  className="pad shadow"
                  onClick={() => {
                    analytics.logEvent(
                      ANALYTICS_EVENTS.VIEWS_CANDIDATE_PROFILE,
                      {
                        job_id: jobDetails?.id,
                        job_title: jobDetails?.title,
                        applicant_name: `${UserData?.firstName} ${UserData?.lastName}`,
                        applicant_id: UserData?.id
                      }
                    );
                    history?.push(`${ROUTES?.PROFILE_ABOUT}/${UserData?.id}`);
                  }}
                >
                  <div className="people-card-body">
                    <Card.Meta
                      description={
                        <div className="d-flex justify-between flex-center-mobile">
                          <Space className="d-flex justify-start">
                            <div>
                              <Avatar
                                className="img"
                                src={UserData?.profileImage || placeholder}
                              />
                            </div>
                            <div style={{ paddingLeft: '10px' }}>
                              <Typography.Title
                                className="heading pl-04"
                                level={5}
                              >
                                {UserData?.firstName} {UserData?.lastName}
                              </Typography.Title>
                              <Space direction="vertical">
                                <Typography.Text
                                  type="secondary"
                                  className="p-0"
                                >
                                  <BankOutlined /> Applied on:{' '}
                                  {moment(item?.createdAt).format(
                                    defaultDateFormat
                                  )}
                                </Typography.Text>
                                <span>
                                  <EnvironmentFilled />{' '}
                                  <Typography.Text
                                    strong
                                    style={{ paddingLeft: '5px' }}
                                  >
                                    {' '}
                                    {JOB_WORK_TYPE[jobDetails?.workType]} :{' '}
                                  </Typography.Text>
                                  <Typography.Text
                                    style={{ paddingLeft: '5px' }}
                                  >
                                    {getLocationStrFromLocationArray(
                                      jobDetails?.location
                                    )}
                                  </Typography.Text>
                                </span>
                              </Space>
                            </div>
                          </Space>
                          <Space className="viewDetailWeb">
                            {renderButtonBasedOnStatus(item, UserData)}
                          </Space>
                        </div>
                      }
                    />
                    <Space
                      direction="vertical"
                      className="viewDetailMobile flex-center-mobile mt-mobile"
                    >
                      {renderButtonBasedOnStatus(item, UserData)}
                    </Space>
                  </div>
                </Card>
              </Col>
            );
          })}
        </div>
      )}
      {!jobsLoading && !jobsIsEnd && (
        <div className="loadMoreButton">
          <Button className="follow-btn" onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}
    </>
  );
};

export default IndividualMyJob;
