import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Avatar, Button, Col, Divider, Row } from 'antd';
import { filter, map, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchIcon } from '../../../assets/svg';
import { ROUTES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import SearchComponent from '../../../components/SearchComponent';
import '../chat.less';
import { GET_USER_CONNECTIONS } from '../graphql/Queries';

const NewChat = ({ isDrawer, changePage }) => {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [skip, setSkip] = useState(0);
  const [mainUserConnectionData, setMainUserConnectionData] = useState([]);
  const [userConnectionData, setUserConnectionData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [getUserConnections] = useLazyQuery(GET_USER_CONNECTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const list = orderBy(res?.getUserConnections, 'firstName');
      setMainUserConnectionData(list);
      setUserConnectionData(list);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    getUserConnections({
      variables: {
        filters: {
          skip: 0,
          limit: 1000
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (value) => {
    const list = filter(mainUserConnectionData, function (o) {
      return o.firstName.includes(value);
    });
    setUserConnectionData(list);
  };
  return (
    <div className="chat-wrapper-new-chat">
      <Row justify="space-between" className="header-wrapper" align="middle">
        <Col style={{ minWidth: '65%' }}>
          <div className="header">
            <h1>New Chat</h1>
          </div>
        </Col>
        <Col>
          <Button
            className="new-msg"
            htmlType="submit"
            icon={<LeftOutlined />}
            onClick={() => {
              if (isDrawer) {
                changePage('CHAT');
              } else {
                history.push(ROUTES.CHAT);
              }
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <div className="user-search-wrapper">
        <SearchComponent
          getData={handleSearch}
          debounceTime={1}
          className="user-search"
          prefix={<SearchIcon />}
        />
      </div>
      <div className="chat-main-body">
        {loading ? (
          <LoaderComponent setHeight="50" />
        ) : (
          map(userConnectionData, (data) => {
            return (
              <>
                <Row
                  justify="start"
                  className="chat-msg"
                  onClick={() => {
                    if (isDrawer) {
                      changePage('USER_CHAT', data?.profileId);
                    } else {
                      history.push(`${ROUTES?.CHAT}/${data?.profileId}`);
                    }
                  }}
                >
                  <Col>
                    <Row gutter={10}>
                      <Col>
                        {data?.profileImage ? (
                          <Avatar
                            className="user-profile"
                            size={40}
                            src={data?.profileImage}
                          />
                        ) : (
                          <Avatar
                            className="user-profile-empty"
                            size={40}
                            icon={<UserOutlined />}
                          />
                        )}
                      </Col>
                      <Col>
                        <div className="title">{`${data?.firstName} ${data?.lastName}`}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider className="chat-divider" />
              </>
            );
          })
        )}
      </div>
    </div>
  );
};

export default NewChat;
