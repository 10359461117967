/* eslint-disable no-undef */
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Divider, Space, Upload } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import Paragraph from 'antd/lib/typography/Paragraph';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import CoverImage from '../../assets/images/coverImage.jpg';
import placeholder from '../../assets/images/placeholder.jpg';
import starIcon from '../../assets/svg/star.svg';
import suitcaseIcon from '../../assets/svg/suitcase.svg';
import { ROUTES, USER } from '../../common/constants';
import { getBase64 } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import { GET_COMPANY_COVER_IMAGE_SIGNED_URL } from '../auth/graphql/Mutations';
import CompanyTabs from './CompanyTabs';
import { UPDATE_COMPANY } from './graphql/Mutation';
import { GET_COMPANY_INFO } from './graphql/Queries';

const CompanyProfile = () => {
  const { getCurrentUser } = useContext(AppContext);
  const { id: companyId } = useParams();
  const history = useHistory();
  const userData = getCurrentUser();
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const [getCoverImageSignedUrl] = useMutation(
    GET_COMPANY_COVER_IMAGE_SIGNED_URL
  );
  const [getCompany, { data, loading }] = useLazyQuery(GET_COMPANY_INFO, {
    variables: {
      id: companyId || userData.id
    },
    fetchPolicy: 'network-only'
  });

  const [coverSrc, setCoverSrc] = useState(
    data?.company?.coverImage || CoverImage
  );

  useEffect(() => {
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.company?.coverImage) {
      setCoverSrc(data?.company?.coverImage);
    }
  }, [data]);

  if (loading) return <LoaderComponent />;
  return (
    <div className="company-profile">
      <div className="company-profile-upper-mobile">
        <div
          className="cover-container"
          style={{
            backgroundImage: `url('${data?.company?.coverImage || CoverImage}')`
          }}
        />
        <div className="company-detail-container">
          <div className="space-large-v" />
          <div className="space-large-v" />
          <h1 className="font-600">{data?.company?.name}</h1>
          <Paragraph
            style={{ height: '30%', fontSize: '18px', overflowY: 'scroll' }}
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: 'more'
            }}
          >
            {data?.company?.bio}
          </Paragraph>
          <div className="other-info">
            <img src={starIcon} alt="rate" />
            <span>{data?.company?.review?.avgRating}</span>
            <Divider type="vertical" />
            <span>{data?.company?.review?.totalReviews} Reviews</span>
            <Divider type="vertical" />
            <img src={suitcaseIcon} alt="suitcase" className="job-icon" />
            <span>{data?.company?.totalJobCreated || 0} jobs</span>
          </div>
          <div className="space-small-v" />
          <Paragraph style={{ fontSize: '16px' }}>
            {data?.company?.followerCount || 0} Followers
          </Paragraph>
          {!companyId && (
            <Button
              className="edit-profile-button"
              onClick={() => {
                history.push(ROUTES.COMPANY_PROFILE_EDIT);
              }}
            >
              Edit Profile
            </Button>
          )}
        </div>
        <Avatar
          className="company-profile-mobile"
          size={70}
          src={data?.company?.profileImage || placeholder}
        />
      </div>
      <div className="d-flex company-profile-upper-web">
        <div className="company-details d-flex">
          <img
            src={data?.company?.profileImage || placeholder}
            alt="profile"
            className="profile-image mr-25"
          />
          <div>
            <h1 className="font-600">{data?.company?.name}</h1>
            <Paragraph
              style={{
                height: '30%',
                fontSize: '18px',
                paddingRight: '5px',
                overflowY: 'scroll'
              }}
              ellipsis={{
                rows: 4,
                expandable: true,
                symbol: 'more'
              }}
            >
              {data?.company?.bio}
            </Paragraph>
            <Space className="other-info">
              <img src={starIcon} alt="rate" />
              <span>{(data?.company?.review?.avgRating || 0).toFixed(2)}</span>
              <Divider type="vertical" />
              <span>{data?.company?.review?.totalReviews} Reviews</span>
              <Divider type="vertical" />
              <img src={suitcaseIcon} alt="suitcase" className="job-icon" />
              <span>{data?.company?.totalJobCreated || 0} jobs</span>
            </Space>
            <div className="space-small-v" />
            <div className="d-flex align-center">
              {!companyId && (
                <Button
                  className="edit-profile-button mr-25"
                  onClick={() => {
                    history.push(ROUTES.COMPANY_PROFILE_EDIT);
                  }}
                >
                  Edit Profile
                </Button>
              )}
              <p className="mb-0">
                {data?.company?.followerCount || 0} Followers
              </p>
            </div>
          </div>
        </div>
        <div className="cover-container">
          <Upload
            beforeUpload={() => {
              return false;
            }}
            accept="image/*"
            showUploadList={false}
            onChange={async (info) => {
              if (info)
                getBase64(info?.file, (image) => {
                  setCoverSrc(image);
                });
              else {
                setCoverSrc(data?.user?.coverImage || CoverImage);
              }
              const response = await getCoverImageSignedUrl({
                variables: {
                  data: {
                    fileName: info?.file?.name
                  }
                }
              });

              if (response) {
                const uploadedCoverImageResponse = await axios.put(
                  response?.data?.getCompanyCoverSignedUrl?.signedUrl,
                  info?.file,
                  {
                    headers: {
                      'access-control-allow-origin': '*',
                      'Content-type': info?.file?.type
                    }
                  }
                );

                if (uploadedCoverImageResponse.status === 200) {
                  const User = localStorage.getItem(USER);
                  const json = JSON.parse(User);
                  json.coverImage =
                    response?.data?.getCompanyCoverSignedUrl?.getUrl;

                  await updateCompany({
                    variables: {
                      data: {
                        coverImage:
                          response?.data?.getCompanyCoverSignedUrl?.getUrl
                      }
                    }
                  });

                  getCompany().then((companyData) => {
                    json.coverImage = companyData.coverImage;
                    localStorage.setItem(USER, JSON.stringify(json));
                  });
                }
              }
            }}
            className="fill-height fill-width"
          >
            <div className="container fill-height fill-width">
              <img
                src={coverSrc}
                alt="cover"
                onClick={(e) => e.stopPropagation()}
                className="fill-height fill-width"
              />
              {!companyId && (
                <Button className="change-cover-btn font-600">
                  Change Cover
                </Button>
              )}
            </div>
          </Upload>
        </div>
      </div>
      <div>
        <CompanyTabs companyId={companyId} />
      </div>
    </div>
  );
};

export default CompanyProfile;
