import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query adminUsers($filter: UserFilter!) {
    adminUsers(filter: $filter) {
      count
      data {
        id
        firstName
        lastName
        email
        phoneNo
        roles
        isActive
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query getProfile {
    getProfile {
      id
      firstName
      lastName
      email
      phoneNo
      roles
      profileImage {
        url
        key
        name
        extension
        contentType
      }
      isActive
      tenantId
    }
  }
`;
