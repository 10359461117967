import { Modal } from 'antd';
import React from 'react';
import LoaderComponent from './LoaderComponent';
import './styles/ModalComponent.less';

const ModalComponent = (props) => {
  const {
    // centered = true,
    maskClosable = false,
    loading,
    children,
    ...rest
  } = props;
  return (
    <Modal
      className="modal"
      // centered={centered} // centered modal
      maskClosable={maskClosable} // whether to close the modal when clicked outside the modal
      {...rest}
      maskStyle={{
        // backgroundColor: 'rgba(255,255,255,0.5)',
        background: 'rgba(0, 0, 0, 0.27)',
        backgroundImage:
          'linearGradient(to bottom right,rgba(255,255,255,0.2),rgba(255,255,255,0))',
        backdropFilter: 'blur(7px)',
        boxShadow: '10px 10px 10px rgba(30,30,30,0.1)'
      }}
      // getContainer="#modalMount"
    >
      {loading ? <LoaderComponent /> : <>{children}</>}
    </Modal>
  );
};

export default ModalComponent;
