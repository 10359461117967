import { WarningOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React from 'react';
import placeholder from '../../../assets/images/placeholder.jpg';
import CommonAvatar from '../../../components/CommonAvatar';

const CommonCompanyFollowerCard = ({ follower }) => {
  const followerActions = (
    <div className="d-flex flex-vertical action-popover">
      {/* <span>
        <UserDeleteOutlined /> Remove
      </span>
      <Divider /> */}
      <span>
        <WarningOutlined /> Report
      </span>
    </div>
  );
  return (
    <div className="follower-card">
      <CommonAvatar
        src={
          <img
            src={follower?.userId?.profileImage || placeholder}
            alt="profile"
          />
        }
        size={100}
      />
      <span className="follower-name">
        {follower?.userId?.firstName} {follower?.userId?.lastName}
      </span>
      <span className="follower-bio">
        {follower?.userId?.experience?.length > 0
          ? follower?.userId?.experience[0]?.title
          : ''}
      </span>
      <Popover content={followerActions}>
        <div className="actions">...</div>
      </Popover>
    </div>
  );
};

export default CommonCompanyFollowerCard;
