import { useLazyQuery } from '@apollo/client';
import { Button, Result, Row } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { SKIP_RECORD } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import UserCard from '../../search/component/people/UserCard';
import { GET_USER_CONNECTIONS } from '../graphql/Queries';

const Connections = () => {
  const connectionsInitialFilter = {
    skip: 0,
    limit: 20
  };
  const [connectionDebounceCall, setConnectionDebounceCall] = useState(0);

  const { getCurrentUser } = useContext(AppContext);
  const { id } = getCurrentUser();
  const [connectionLoading, setConnectionLoading] = useState(true);
  const [connectionsData, setConnectionsData] = useState([]);
  const [connectionsFilter] = useState(connectionsInitialFilter);

  const [connectionsIsEnd, setConnectionsIsEnd] = useState(false);

  const [getConnections] = useLazyQuery(GET_USER_CONNECTIONS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.getUserConnections?.length < SKIP_RECORD) {
        setConnectionsIsEnd(true);
      }
      // need to spread
      setConnectionsData([...connectionsData, ...res?.getUserConnections]);
      setConnectionLoading(false);
    },
    onError() {
      setConnectionLoading(false);
    }
  });
  useEffect(() => {
    getConnections({
      variables: {
        filters: connectionsFilter,
        where: { id }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    if (!connectionsIsEnd) {
      setConnectionLoading(true);
      getConnections({
        variables: {
          filters: {
            ...connectionsFilter,
            skip: (connectionDebounceCall + 1) * SKIP_RECORD
          },
          where: { id }
        }
      });
      setConnectionDebounceCall((prevState) => prevState + 1);
    }
  };

  const updatePeople = (person) => {
    const unFrozenArray = connectionsData.slice();
    for (let index = 0; index < unFrozenArray?.length; index += 1) {
      const currentPerson = unFrozenArray[index];
      if (currentPerson?.id === person?.id) {
        unFrozenArray[index] = person;
        break;
      }
    }
    setConnectionsData(unFrozenArray);
  };

  return (
    <div className="profile-connection" style={{ padding: '10px' }}>
      <div className="connection-section">
        {connectionsData?.length === 0 && !connectionLoading && (
          <Result status="404" title="No connections" />
        )}
        <Row gutter={2}>
          {map(connectionsData, (user) => {
            return (
              <UserCard
                key={user.id}
                userData={user}
                updatePeople={updatePeople}
              />
            );
          })}
        </Row>
        {!connectionLoading && !connectionsIsEnd && (
          <div className="loadMoreButton">
            <Button className="follow-btn" onClick={loadMore}>
              Load More
            </Button>
          </div>
        )}
        {connectionLoading && <LoaderComponent setHeight={10} />}
      </div>
    </div>
  );
};

export default Connections;
