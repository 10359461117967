import React from 'react';
import { Link } from 'react-router-dom';
import { MODULES, ROUTES } from '../../../common/constants';

const FeedSideBar = () => {
  return (
    <div className="feed-sidebar">
      <div className="menu">
        <Link to={ROUTES?.COMPANY_PROFILE_REVIEW}>{MODULES?.MY_REVIEWS}</Link>
      </div>
      <div className="menu">
        <Link to={ROUTES?.COMPANY_PROFILE_FOLLOWERS}>
          {MODULES?.MY_FOLLOWERS}
        </Link>
      </div>
      <div className="menu">
        <Link to={ROUTES?.COMPANY_PROFILE_ABOUT}>{MODULES?.Profile}</Link>
      </div>
    </div>
  );
};

export default FeedSideBar;
