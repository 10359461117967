import { useMutation } from '@apollo/client';
import { Button, Col, DatePicker, Divider, Form, Input, Row } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import logo from '../../assets/logo.png';
import mobileLogo from '../../assets/mobileLogo.png';
import { Company, Email, PasswordIcon, Phone } from '../../assets/svg';
import {
  ANALYTICS_EVENTS,
  BREAKPOINTS,
  MAX_PAST_DATE,
  REGEX,
  ROUTES
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { CreateCompanyWithEmailAndPassword, analytics } from './Firebase';
import { COMPANY_SIGNUP } from './graphql/Mutations';

const { required, email, password } = formValidatorRules;

const CompanySignup = () => {
  const history = useHistory();
  const { initializeAuth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [companySignup] = useMutation(COMPANY_SIGNUP);
  const [valuesChange, setValuesChange] = useState(true);
  const isLaptopViewPort = useMedia(`(max-width: ${BREAKPOINTS?.laptop}px)`);
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);

  function successCallback(
    accessToken,
    userData,
    isEmailVerified,
    isCompanyOnboardingCompleted,
    isCompanyVerified
  ) {
    initializeAuth(accessToken, userData);
    // eslint-disable-next-line no-undef
    localStorage.setItem('isEmailVerified', isEmailVerified || false);
    // eslint-disable-next-line no-undef
    localStorage.setItem('isCompanyVerified', isCompanyVerified || false);
    // eslint-disable-next-line no-undef
    localStorage.setItem(
      'isCompanyOnboardingCompleted',
      isCompanyOnboardingCompleted || false
    );
    setLoading(false);
    analytics.logEvent(ANALYTICS_EVENTS.COMPANY_SIGNUP, {
      name: userData.name,
      email: userData.email
    });
    if (!isEmailVerified) {
      history.push(ROUTES.VERIFY_EMAIL);
    } else if (!isCompanyOnboardingCompleted) {
      history.push(ROUTES.COMPANY_PROFILE);
    } else if (!isCompanyVerified) {
      history.push(ROUTES.RESULT);
    } else {
      history.push('/');
    }
  }

  useEffect(() => {
    analytics.logEvent(ANALYTICS_EVENTS.COMPANY_SIGNUP_OPENED);
  });

  const onFinish = async (formValues) => {
    try {
      const signUpSuccess = await CreateCompanyWithEmailAndPassword(
        formValues?.email,
        formValues?.password,
        setLoading
      );
      if (signUpSuccess) {
        const res = await companySignup({
          variables: {
            data: {
              token: signUpSuccess?.token,
              name: formValues?.companyName,
              establishedYear: moment(
                formValues?.establishedYear
              ).toISOString(),
              phoneNo: formValues?.phoneNo
            }
          }
        });
        if (res) {
          setLoading(false);
          successCallback(
            signUpSuccess?.token,
            res?.data?.companySignup?.data,
            res?.data?.companySignup?.isEmailVerified,
            res?.data?.companySignup?.isCompanyOnboardingCompleted,
            res?.data?.companySignup?.isCompanyVerified
          );
        }
      }
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const onChange = () => {
    setValuesChange(false);
  };

  return (
    <div
      className={`${
        isMobileViewPort ? 'signup d-flex' : 'signup d-flex flex-vertical'
      }`}
    >
      {isMobileViewPort && (
        <div className="signup-image-text">
          <img src={logo} alt="logo" className="logo" />
          <h1>
            Sophisticated, non-biased A.I. & M.L. algorithm that matches job
            openings to Black professional applicants.
          </h1>
        </div>
      )}

      {!isMobileViewPort && (
        <div className="mobile-login-header">
          <div className="d-flex align-center justify-between">
            <img src={mobileLogo} alt="logo" className="logo" height="35px" />
            <Button
              type="link"
              className="font-18 mobile-create-account-button"
              onClick={() => {
                history.push(ROUTES.COMPANY_LOGIN);
              }}
            >
              Login
            </Button>
          </div>
          <Divider />
        </div>
      )}
      <div className="signup-detail-section">
        <div className="d-flex justify-between align-center">
          <h1>Sign up for Company</h1>
          {isMobileViewPort && (
            <p className="mb-0">
              Already have a BlackUp account?
              <a href={ROUTES.COMPANY_LOGIN}> Login</a>
            </p>
          )}
        </div>
        <p className="mt-25">
          Not a company? <a href={ROUTES.SIGNUP}>Sign up</a> as a candidate.
        </p>
        <p>
          Make the world more equitable by creating jobs, events, and
          communities that celebrate diversity.
        </p>
        <Form
          layout="vertical"
          className="mt-25 signup-detail-section-form"
          onFinish={onFinish}
          onValuesChange={onChange}
        >
          <Row gutter={isLaptopViewPort ? 10 : 50}>
            <Col span={isLaptopViewPort ? 24 : 12}>
              <Form.Item
                label="Company name"
                name="companyName"
                className="capitalize-input-wrapper"
                rules={[required]}
              >
                <Input
                  placeholder="Enter Company Name"
                  prefix={<Company />}
                  onBlur={(element) => {
                    analytics.logEvent(ANALYTICS_EVENTS.COMPANY_NAME_ENTERED, {
                      name: element.target.value
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={isLaptopViewPort ? 10 : 50}>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item
                label="Established year"
                name="establishedYear"
                rules={[required]}
              >
                <DatePicker
                  placeholder="Enter established year"
                  picker="year"
                  disabledDate={(current) => {
                    return current < MAX_PAST_DATE || current > moment();
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item
                label="Phone number"
                name="phoneNo"
                rules={[
                  required,
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value.split(' ').join('');
                        const numberPattern = REGEX.PHONE;
                        if (!numberPattern.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject(
                            'should be a valid phone number'
                          );
                        }
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Input
                  placeholder="Enter your Phone Number"
                  prefix={<Phone />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={isLaptopViewPort ? 10 : 50}>
            <Col span={24}>
              <Form.Item label="Email" name="email" rules={[required, email]}>
                <Input placeholder="Enter your Email" prefix={<Email />} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={isLaptopViewPort ? 10 : 50}>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item
                label="Password"
                name="password"
                rules={[required, password]}
              >
                <Input.Password
                  placeholder="Enter your Password"
                  prefix={<PasswordIcon />}
                />
              </Form.Item>
            </Col>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item
                label="Confirm Password"
                name="confirm-password"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(new Error('Required'));
                      }
                      if (value !== getFieldValue('password')) {
                        return Promise.reject(
                          new Error('Passwords do not match')
                        );
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Input.Password
                  placeholder="Enter your Confirm Password"
                  prefix={<PasswordIcon />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={isLaptopViewPort ? 10 : 50}
            className="signup-button-div"
          >
            <Col span={isMobileViewPort ? 12 : 24}>
              <p>
                By clicking “Sign up” , you're agreeing to
                <a
                  href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  BlackUp Terms of Use
                </a>
                .
              </p>
            </Col>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item>
                <Button
                  className="fill-width"
                  htmlType="submit"
                  loading={loading}
                  disabled={valuesChange}
                >
                  Sign Up
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default CompanySignup;
