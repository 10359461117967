import { Divider, Typography } from 'antd';
import { map } from 'lodash';
import { React, useState } from 'react';
import SimilarJobCard from './SimilarJobCard';

const SimilarJobsContainer = ({ similarJobs }) => {
  const [recCardResults, setRecCardResults] = useState([]);
  const [recCardsFail, setRecCardsFail] = useState(false);

  const addRecCardResult = (result) => {
    const tempArray = recCardResults;
    tempArray.push(result);
    setRecCardResults(tempArray);
    if (tempArray.length === similarJobs.length && similarJobs.length > 0) {
      const recCardsFailed = !tempArray.includes('success');
      setRecCardsFail(recCardsFailed);
    }
  };

  return (
    <div className="recommend-section">
      <div className="header-section ">
        <span>Simliar Jobs</span>
      </div>
      <Divider className="recommend-divider" />
      <div className="job-section">
        {!recCardsFail ? (
          map(similarJobs, (item) => {
            return (
              <div key={item} className="single-job">
                <SimilarJobCard
                  jobId={item}
                  addRecCardResult={addRecCardResult}
                />
              </div>
            );
          })
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Typography.Text type="secondary">
              Recommendations Not Available
            </Typography.Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimilarJobsContainer;
