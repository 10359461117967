import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import JobDetails from './component/JobDetails';
import './component/JobDetails.less';

const JobDetailsWrapper = () => {
  return (
    <div style={{ backgroundColor: '#fff', height: '100%' }}>
      <Switch>
        <>
          <Route path={`${ROUTES?.JOB_DETAILS}/:id`} component={JobDetails} />
        </>
      </Switch>
    </div>
  );
};

export default JobDetailsWrapper;
