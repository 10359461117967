import React from 'react';
import { Link } from 'react-router-dom';
import { ANALYTICS_EVENTS, MODULES, ROUTES } from '../../../common/constants';
import { analytics } from '../../auth/Firebase';

const FeedSideBar = () => {
  return (
    <div className="feed-sidebar">
      <div className="menu">
        <Link
          to={ROUTES?.PROFILE_CONNECTIONS}
          onClick={() => {
            analytics.logEvent(ANALYTICS_EVENTS.MY_CONNECTIONS);
          }}
        >
          {MODULES?.MY_CONNECTIONS}
        </Link>
      </div>
      <div className="menu">
        <Link
          to={ROUTES?.PROFILE_FOLLOWING}
          onClick={() => {
            analytics.logEvent(ANALYTICS_EVENTS.MY_FOLLOWING);
          }}
        >
          {MODULES?.MY_FOLLOWING}
        </Link>
      </div>
      <div className="menu">
        <Link
          to={ROUTES?.PROFILE_ABOUT}
          onClick={() => {
            analytics.logEvent(ANALYTICS_EVENTS.ABOUT_ME);
          }}
        >
          {MODULES?.Profile}
        </Link>
      </div>
    </div>
  );
};

export default FeedSideBar;
