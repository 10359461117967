import { gql } from '@apollo/client';

export const GET_USER_ROOMS = gql`
  query getUserRooms($filters: MessageFilterInput!) {
    getUserRooms(filters: $filters) {
      id
      type
      membersData {
        id
        name
        profileUrl
      }
      unreadMessages
      lastMessage
      updatedAt
    }
  }
`;

export const GET_ID_FROM_PROFILE_ID = gql`
  query getIdFromProfileId($profileId: String!) {
    getIdFromProfileId(where: { id: $profileId }) {
      id
      profileId
      role
    }
  }
`;

export const GET_ROOM = gql`
  query getRoom($where: CommonWhereInput!) {
    getRoom(where: $where) {
      id
      type
      membersData {
        id
        name
        profileUrl
      }
      unreadMessages
      lastMessage
      updatedAt
    }
  }
`;

export const GET_MESSAGES = gql`
  query getMessages($where: CommonWhereInput!, $filters: MessageFilterInput!) {
    getMessages(where: $where, filters: $filters) {
      id
      text
      sender
      mediaUrl
      mediaType
      readBy
      sender
      createdAt
    }
  }
`;

export const GET_USER_CONNECTIONS = gql`
  query getUserConnections($filters: UserFilterInput!) {
    getUserConnections(filters: $filters) {
      id
      firstName
      lastName
      profileId
      phoneNo
      countryCode
      profileImage
      coverImage
      isVerified
      userConnection {
        connectedBy
        connectedTo
        status
        id
      }
    }
  }
`;
