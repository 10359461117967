/* eslint-disable no-undef */
import { EllipsisOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, message, Popover, Upload } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import confirm from 'antd/lib/modal/confirm';
import Paragraph from 'antd/lib/typography/Paragraph';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import CoverImage from '../../assets/images/coverImage.jpg';
import placeholder from '../../assets/images/placeholder.jpg';
import { Building, Map } from '../../assets/svg';
import { ANALYTICS_EVENTS, ROUTES, USER } from '../../common/constants';
import {
  CONNECTION_CANCEL,
  CONNECTION_REQUEST,
  CONNECTION_STATUS_UPDATE,
  DISCONNECT_CONNECTION
} from '../../common/graphql/Mutation';
import { GET_USER } from '../../common/graphql/Queries';
import { getBase64 } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import history from '../../historyData';
import { analytics } from '../auth/Firebase';
import {
  EDIT_USER_PROFILE,
  GET_COVER_IMAGE_SIGNED_URL
} from '../auth/graphql/Mutations';
import { GET_ROOM } from '../chat/graphql/Queries';
import UserTabs from './UserTabs';

let isMyProfile = false;
const UserProfile = () => {
  const {
    dispatch,
    getCurrentUser,
    state: { showDrawer }
  } = useContext(AppContext);
  // userId/id is "_id" from user_details collection in DB
  const { id: userId } = useParams();
  const [loadingBtn, setLoadingBtn] = useState();
  const [user, setUser] = useState();
  const [editUserProfile] = useMutation(EDIT_USER_PROFILE);
  const [getCoverImageSignedUrl] = useMutation(GET_COVER_IMAGE_SIGNED_URL);
  if (userId === getCurrentUser().id) {
    history.replace(ROUTES.PROFILE_APPLIED_JOBS);
  }

  const { id } = getCurrentUser();
  const [coverSrc, setCoverSrc] = useState(null);
  const [getUser, { data, loading }] = useLazyQuery(GET_USER, {
    variables: {
      id: userId || id
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const coverImage =
        res?.user?.coverImage || res?.user?.coverImage || CoverImage;
      setCoverSrc(coverImage);
      setUser(res?.user);
    }
  });
  if (userId === getCurrentUser().id) {
    isMyProfile = true;
  }
  const [connectionRequest] = useMutation(CONNECTION_REQUEST);
  const [connectionCancel] = useMutation(CONNECTION_CANCEL);
  const [disconnectConnection] = useMutation(DISCONNECT_CONNECTION);
  const [connectionStatusUpdate] = useMutation(CONNECTION_STATUS_UPDATE);

  const isCompany = localStorage.getItem('isCompanyVerified');

  const [getRoom] = useLazyQuery(GET_ROOM, {});
  const openDrawer = async () => {
    if (isCompany) {
      analytics.logEvent(ANALYTICS_EVENTS.MESSAGE_CANDIDATE);
    } else {
      analytics.logEvent(ANALYTICS_EVENTS.MESSAGE_PEER);
    }
    // eslint-disable-next-line no-undef
    if (window.innerWidth < 1000) {
      history.push(`${ROUTES?.CHAT}/${user?.profileId}`);
    } else {
      getRoom({
        variables: {
          where: { id: user?.profileId }
        }
      });
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: !showDrawer, profileId: user?.profileId }
      });
    }
  };
  const renderMoreButtonsOnRequested = () => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          onClick={() => {
            connectionStatusUpdate({
              variables: {
                id: user.userConnection?.id,
                status: 'REJECTED'
              }
            });
            setUser({
              ...user,
              userConnection: {
                ...user.userConnection,
                status: 'REJECTED'
              }
            });
          }}
        >
          Decline
        </Button>
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          // icon={<DeleteOutlined />}
          onClick={() => {
            openDrawer();
          }}
        >
          Message
        </Button>
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          onClick={() => {
            message.info('Thank you for reporting');
            analytics.logEvent(ANALYTICS_EVENTS.REPORT_POST);
          }}
        >
          Report
        </Button>
      </div>
    );
  };
  const renderMoreButtonsOnStrangers = () => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          // icon={<DeleteOutlined />}
          onClick={() => {
            openDrawer();
          }}
        >
          Message
        </Button>
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          onClick={() => {
            message.info('Thank you for reporting');
            analytics.logEvent(ANALYTICS_EVENTS.REPORT_POST);
          }}
        >
          Report
        </Button>
      </div>
    );
  };

  const renderMoreButtonsOnAccepted = () => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          // icon={<DeleteOutlined />}
          onClick={() => {
            confirm({
              okText: 'Yes, Continue',
              content: 'Are you sure you want to Disconnect?',
              centered: true,
              cancelText: 'No',
              onOk: () => {
                disconnectConnection({
                  variables: { id: user.userConnection.id }
                });
                const updatedPerson = {
                  ...user
                };
                delete updatedPerson.userConnection;
                setUser(updatedPerson);
              }
            });
          }}
        >
          Disconnect
        </Button>
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          onClick={() => {
            message.info('Thank you for reporting');
            analytics.logEvent(ANALYTICS_EVENTS.REPORT_POST);
          }}
        >
          Report
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (typeof userId !== 'undefined' || id) getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (data?.coverImage) {
      setCoverSrc(data?.coverImage);
    }
  }, [data]);
  if (loading) return <LoaderComponent />;

  return (
    <div className="user-profile">
      <div className="user-profile-upper-mobile">
        <div
          className="cover-container"
          style={{
            backgroundImage: `url('${user?.coverImage || CoverImage}')`
          }}
        />
        <div className="user-detail-container">
          <div className="space-large-v" />
          <div className="space-large-v" />
          <h1 className="font-600">
            {user?.firstName} {user?.lastName}
          </h1>
          <Paragraph
            style={{ height: '30%', fontSize: '18px', overflowY: 'scroll' }}
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: 'more'
            }}
          >
            {user?.bio}
          </Paragraph>
          {user?.experience[0]?.location && (
            <p className="d-flex mb-10">
              <Building className="building" /> {user?.experience[0]?.title} at{' '}
              {user?.experience[0]?.company}
            </p>
          )}
          {user?.experience[0]?.location &&
            (user?.experience[0]?.location.city ||
              user?.experience[0]?.location?.state ||
              user?.experience[0]?.location?.country) && (
              <p className="d-flex">
                <Map className="map" />
                {user?.experience[0]?.location?.city}
                {user?.experience[0]?.location?.state &&
                  `, ${user?.experience[0]?.location?.state}`}
                {user?.experience[0]?.location?.country &&
                  `, ${user?.experience[0]?.location?.country}`}
              </p>
            )}
          <Paragraph style={{ fontSize: '16px' }}>
            {user?.connectionCount || 0} Connections
          </Paragraph>
          {!userId && (
            <Button
              onClick={() => {
                // eslint-disable-next-line no-undef
                window.location.href = `${ROUTES?.PROFILE_EDIT}#profile`;
              }}
              className="edit-profile-button"
            >
              Edit Profile
            </Button>
          )}

          {userId &&
            !isCompany &&
            !isMyProfile &&
            user?.userConnection &&
            user?.userConnection.status === 'ACCEPTED' && (
              <div className="d-flex">
                <Button
                  onClick={() => openDrawer()}
                  className="edit-profile-button"
                >
                  Message
                </Button>
                <Popover
                  placement="bottom"
                  className="viewDetailWeb popover-design mr-25"
                  overlayClassName="action-button"
                  content={renderMoreButtonsOnAccepted(
                    user?.userConnection?.id
                  )}
                >
                  <EllipsisOutlined />
                </Popover>
              </div>
            )}
          {userId &&
            !isCompany &&
            !isMyProfile &&
            user?.userConnection &&
            user?.userConnection?.status === 'PENDING' &&
            user?.userConnection?.connectedTo === userId && (
              <div className="d-flex">
                <Button
                  onClick={() => {
                    confirm({
                      okText: 'Yes, Cancel',
                      content: 'Are you sure you want to cancel request?',
                      centered: true,
                      cancelText: 'No',
                      onOk: () => {
                        connectionCancel({
                          variables: { id: user.userConnection?.id }
                        });
                        const updatedPerson = {
                          ...user
                        };
                        delete updatedPerson.userConnection;
                        setUser(updatedPerson);
                      }
                    });
                  }}
                  type="danger"
                  className="follow-button"
                >
                  Cancel
                </Button>
                <Popover
                  placement="bottom"
                  className="viewDetailWeb popover-design mr-25"
                  overlayClassName="action-button"
                  content={renderMoreButtonsOnStrangers()}
                >
                  <EllipsisOutlined />
                </Popover>
              </div>
            )}
          {userId &&
            !isCompany &&
            !isMyProfile &&
            user?.userConnection &&
            user?.userConnection?.status === 'PENDING' &&
            user?.userConnection?.connectedTo !== userId && (
              <div className="d-flex">
                <Button
                  onClick={() => {
                    connectionStatusUpdate({
                      variables: {
                        id: user.userConnection?.id,
                        status: 'ACCEPTED'
                      }
                    });
                    setUser({
                      ...user,
                      userConnection: {
                        ...user.userConnection,
                        status: 'ACCEPTED'
                      }
                    });
                  }}
                  type="primary"
                  className="follow-button"
                >
                  Accept
                </Button>
                <Popover
                  placement="bottom"
                  className="viewDetailWeb popover-design mr-25"
                  overlayClassName="action-button"
                  content={renderMoreButtonsOnRequested()}
                >
                  <EllipsisOutlined />
                </Popover>
              </div>
            )}
          {userId && !isCompany && !isMyProfile && !user?.userConnection && (
            <div className="d-flex">
              <Button
                type="primary"
                loading={loadingBtn}
                onClick={async () => {
                  setLoadingBtn(true);
                  const connection = await connectionRequest({
                    variables: { id: user?.id }
                  });
                  setLoadingBtn(false);
                  setUser({
                    ...user,
                    userConnection: connection.data?.connectionRequest
                  });
                }}
                className="follow-button"
              >
                Connect
              </Button>
              <Popover
                placement="bottom"
                className="viewDetailWeb popover-design mr-25"
                overlayClassName="action-button"
                content={renderMoreButtonsOnStrangers()}
              >
                <EllipsisOutlined />
              </Popover>
            </div>
          )}
          {isCompany && (
            <div className="d-flex">
              <Button
                onClick={() => openDrawer()}
                className="edit-profile-button"
              >
                Message
              </Button>
              <Popover
                placement="bottom"
                className="viewDetailWeb popover-design mr-25"
                overlayClassName="action-button"
                content={
                  <>
                    <Button
                      id="brand-table-edit-btn"
                      className="b-0"
                      type="link"
                      onClick={() => {
                        message.info('Thank you for reporting');
                        analytics.logEvent(ANALYTICS_EVENTS.REPORT_POST);
                      }}
                    >
                      Report
                    </Button>
                  </>
                }
              >
                <EllipsisOutlined />
              </Popover>
            </div>
          )}
        </div>
        <Avatar
          className="user-profile-mobile"
          size={70}
          src={user?.profileImage || placeholder}
        />
      </div>
      <div className="d-flex user-profile-upper-web">
        <div className="user-details d-flex">
          <div>
            <img
              src={user?.profileImage || placeholder}
              alt="profile"
              className="profile-image mr-25"
            />
          </div>
          <div>
            <h1 className="font-600">
              {user?.firstName} {user?.lastName}
            </h1>
            <Paragraph
              style={{
                height: '30%',
                fontSize: '18px',
                paddingRight: '5px',
                overflowY: 'auto'
              }}
              ellipsis={{
                rows: 4,
                expandable: true,
                symbol: 'more'
              }}
            >
              {user?.bio}
            </Paragraph>
            {user?.experience[0]?.location && (
              <p className="d-flex mb-10">
                <Building className="mr-10 building" />{' '}
                {user?.experience[0]?.title} at {user?.experience[0]?.company}
              </p>
            )}
            {user?.experience[0]?.location &&
              (user?.experience[0]?.location.city ||
                user?.experience[0]?.location?.state ||
                user?.experience[0]?.location?.country) && (
                <p className="d-flex">
                  <Map className="mr-10 map" />
                  {user?.experience[0]?.location?.city}
                  {user?.experience[0]?.location?.state &&
                    `, ${user?.experience[0]?.location?.state}`}
                  {user?.experience[0]?.location?.country &&
                    `, ${user?.experience[0]?.location?.country}`}
                </p>
              )}
            <div className="d-flex align-center">
              {!userId && (
                <Button
                  onClick={() => {
                    // eslint-disable-next-line no-undef
                    window.location.href = `${ROUTES?.PROFILE_EDIT}#profile`;
                  }}
                  className="edit-profile-button mr-25"
                >
                  Edit Profile
                </Button>
              )}
              {userId &&
                !isCompany &&
                !isMyProfile &&
                user?.userConnection &&
                user?.userConnection.status === 'ACCEPTED' && (
                  <div>
                    <Button
                      onClick={() => openDrawer()}
                      className="edit-profile-button"
                    >
                      Message
                    </Button>
                    <Popover
                      placement="bottom"
                      className="viewDetailWeb popover-design mr-25"
                      overlayClassName="action-button"
                      content={renderMoreButtonsOnAccepted(
                        user?.userConnection?.id
                      )}
                    >
                      <EllipsisOutlined />
                    </Popover>
                  </div>
                )}
              {userId &&
                !isCompany &&
                !isMyProfile &&
                user?.userConnection &&
                user?.userConnection?.status === 'PENDING' &&
                user?.userConnection?.connectedTo === userId && (
                  <div>
                    <Button
                      onClick={() => {
                        confirm({
                          okText: 'Yes, Cancel',
                          content: 'Are you sure you want to cancel request?',
                          centered: true,
                          cancelText: 'No',
                          onOk: () => {
                            connectionCancel({
                              variables: { id: user.userConnection?.id }
                            });
                            const updatedPerson = {
                              ...user
                            };
                            delete updatedPerson.userConnection;
                            setUser(updatedPerson);
                          }
                        });
                      }}
                      type="danger"
                      className="follow-button"
                    >
                      Cancel
                    </Button>
                    <Popover
                      placement="bottom"
                      className="viewDetailWeb popover-design mr-25"
                      overlayClassName="action-button"
                      content={renderMoreButtonsOnStrangers()}
                    >
                      <EllipsisOutlined />
                    </Popover>
                  </div>
                )}
              {userId &&
                !isCompany &&
                !isMyProfile &&
                user?.userConnection &&
                user?.userConnection?.status === 'PENDING' &&
                user?.userConnection?.connectedTo !== userId && (
                  <div>
                    <Button
                      onClick={() => {
                        connectionStatusUpdate({
                          variables: {
                            id: user.userConnection?.id,
                            status: 'ACCEPTED'
                          }
                        });
                        setUser({
                          ...user,
                          userConnection: {
                            ...user.userConnection,
                            status: 'ACCEPTED'
                          }
                        });
                      }}
                      type="primary"
                      className="follow-button"
                    >
                      Accept
                    </Button>
                    <Popover
                      placement="bottom"
                      className="viewDetailWeb popover-design mr-25"
                      overlayClassName="action-button"
                      content={renderMoreButtonsOnRequested()}
                    >
                      <EllipsisOutlined />
                    </Popover>
                  </div>
                )}
              {userId && !isCompany && !isMyProfile && !user?.userConnection && (
                <div>
                  <Button
                    type="primary"
                    loading={loadingBtn}
                    onClick={async () => {
                      setLoadingBtn(true);
                      const connection = await connectionRequest({
                        variables: { id: user?.id }
                      });
                      setLoadingBtn(false);
                      setUser({
                        ...user,
                        userConnection: connection.data?.connectionRequest
                      });
                    }}
                    className="follow-button"
                  >
                    Connect
                  </Button>
                  <Popover
                    placement="bottom"
                    className="viewDetailWeb popover-design mr-25"
                    overlayClassName="action-button"
                    content={renderMoreButtonsOnStrangers()}
                  >
                    <EllipsisOutlined />
                  </Popover>
                </div>
              )}
              {isCompany && (
                <div>
                  <Button
                    onClick={() => openDrawer()}
                    className="edit-profile-button"
                  >
                    Message
                  </Button>
                  <Popover
                    placement="bottom"
                    className="viewDetailWeb popover-design mr-25"
                    overlayClassName="action-button"
                    content={
                      <>
                        <Button
                          id="brand-table-edit-btn"
                          className="b-0"
                          type="link"
                          onClick={() => {
                            message.info('Thank you for reporting');
                            analytics.logEvent(ANALYTICS_EVENTS.REPORT_POST);
                          }}
                        >
                          Report
                        </Button>
                      </>
                    }
                  >
                    <EllipsisOutlined />
                  </Popover>
                </div>
              )}
              <p className="mb-0">{user?.connectionCount || 0} Connections</p>
            </div>
          </div>
        </div>
        <div className="cover-container">
          <Upload
            beforeUpload={() => {
              return false;
            }}
            accept="image/*"
            showUploadList={false}
            onChange={async (info) => {
              if (info)
                getBase64(info?.file, (image) => {
                  setCoverSrc(image);
                });
              else {
                setCoverSrc(user?.coverImage || CoverImage);
              }
              const response = await getCoverImageSignedUrl({
                variables: {
                  data: {
                    fileName: info?.file?.name
                  }
                }
              });
              if (response) {
                const uploadedCoverImageResponse = await axios.put(
                  response?.data?.getUserCoverImageSignedUrl?.signedUrl,
                  info?.file,
                  {
                    headers: {
                      'access-control-allow-origin': '*',
                      'Content-type': info?.file?.type
                    }
                  }
                );

                if (uploadedCoverImageResponse.status === 200) {
                  const User = localStorage.getItem(USER);
                  const json = JSON.parse(User);
                  await editUserProfile({
                    variables: {
                      data: {
                        coverImage:
                          response?.data?.getUserCoverImageSignedUrl?.getUrl
                      }
                    }
                  });

                  getUser().then((userData) => {
                    json.coverImage = userData.coverImage;
                    localStorage.setItem(USER, JSON.stringify(json));
                  });
                }
              }
            }}
            className="fill-height"
          >
            <div className="container fill-height fill-width">
              <img
                src={coverSrc}
                alt="cover"
                onClick={(e) => e.stopPropagation()}
                className="fill-height fill-width"
              />
              {!userId && (
                <Button className="change-cover-btn font-600">
                  Change Cover
                </Button>
              )}
            </div>
          </Upload>
        </div>
      </div>
      <div>
        <UserTabs userId={userId} data={data} />
      </div>
    </div>
  );
};

export default UserProfile;
