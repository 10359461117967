import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ANALYTICS_EVENTS, ROUTES } from '../../common/constants';
import Applied from '../applied/component/Applied';
import { analytics } from '../auth/Firebase';
import About from './components/About';
import Connections from './components/Connections';
import Following from './components/Following';
import Posts from './components/Posts';

const UserTabs = ({ userId, data }) => {
  const { TabPane } = Tabs;
  const { pathname } = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState(ROUTES.PROFILE_APPLIED_JOBS);

  useEffect(() => {
    if (pathname?.includes(ROUTES.PROFILE_POSTS)) {
      setActiveKey(ROUTES.PROFILE_POSTS);
      analytics.logEvent(ANALYTICS_EVENTS.USER_TAB_POST);
    } else if (pathname?.includes(ROUTES.PROFILE_CONNECTIONS)) {
      setActiveKey(ROUTES.PROFILE_CONNECTIONS);
      analytics.logEvent(ANALYTICS_EVENTS.USER_TAB_CONNECTIONS);
    } else if (pathname?.includes(ROUTES.PROFILE_FOLLOWING)) {
      setActiveKey(ROUTES.PROFILE_FOLLOWING);
      analytics.logEvent(ANALYTICS_EVENTS.USER_TAB_FOLLOWING);
    } else if (pathname?.includes(ROUTES.PROFILE_ABOUT)) {
      setActiveKey(ROUTES.PROFILE_ABOUT);
      analytics.logEvent(ANALYTICS_EVENTS.USER_TAB_ABOUT);
    } else {
      setActiveKey(ROUTES.PROFILE_APPLIED_JOBS);
      analytics.logEvent(ANALYTICS_EVENTS.USER_TAB_APPLIED_JOB);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="user-tabs">
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          let appendId = '';
          if (userId) {
            appendId = `/${userId}`;
          }
          setActiveKey(key);
          if (key.includes(ROUTES.PROFILE_POSTS)) {
            analytics.logEvent(ANALYTICS_EVENTS.USER_TAB_POST);
          } else if (key.includes(ROUTES.PROFILE_CONNECTIONS)) {
            analytics.logEvent(ANALYTICS_EVENTS.USER_TAB_CONNECTIONS);
          } else if (key.includes(ROUTES.PROFILE_FOLLOWING)) {
            analytics.logEvent(ANALYTICS_EVENTS.USER_TAB_FOLLOWING);
          } else if (key.includes(ROUTES.PROFILE_ABOUT)) {
            analytics.logEvent(ANALYTICS_EVENTS.USER_TAB_ABOUT);
          } else {
            analytics.logEvent(ANALYTICS_EVENTS.USER_TAB_APPLIED_JOB);
          }
          history.replace(key + appendId);
        }}
        className="mt-12"
      >
        {userId && (
          <TabPane tab="About" key={ROUTES.PROFILE_ABOUT} forceRender>
            <div className="about">
              <About userId={userId} data={data?.user} />
            </div>
          </TabPane>
        )}
        {!userId && (
          <TabPane
            tab="Applied Jobs"
            key={ROUTES.PROFILE_APPLIED_JOBS}
            className="applied-jobs-tab"
          >
            <div className="applied-jobs">
              <Applied isUserProfile />
            </div>
          </TabPane>
        )}
        <TabPane tab="Posts" key={ROUTES.PROFILE_POSTS} forceRender>
          <div className="posts">
            <Posts userId={userId} />
          </div>
        </TabPane>
        {!userId && (
          <TabPane
            tab="Connections"
            key={ROUTES.PROFILE_CONNECTIONS}
            forceRender
          >
            <div className="connections">
              <Connections />
            </div>
          </TabPane>
        )}
        {!userId && (
          <TabPane tab="Following" key={ROUTES.PROFILE_FOLLOWING} forceRender>
            <div className="following">
              <Following />
            </div>
          </TabPane>
        )}
        {!userId && (
          <TabPane tab="About" key={ROUTES.PROFILE_ABOUT} forceRender>
            <div className="about">
              <About />
            </div>
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default UserTabs;
