/* eslint-disable no-undef */
import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { ROUTES } from '../common/constants';
import Error404 from '../Error404';
import AppliedWrapper from '../modules/applied';
import ArchiveWrapper from '../modules/archiveJob';
import SuccessPage from '../modules/auth/SuccessPage';
import CompanyWrapper from '../modules/companies';
import CompanyFeedWrapper from '../modules/companyFeed';
import NewJob from '../modules/companyJobs/component/NewJob';
import CompanyProfileWrapper from '../modules/companyProfile';
import CompanyEditPage from '../modules/companyProfile/pages/CompanyEditPage';
import FeedWrapper from '../modules/feed';
import JobDetailsWrapper from '../modules/jobDetails';
import JobWrapper from '../modules/jobs';
import MyJobsWrapper from '../modules/myJobs';
import PrivacyPolicyWrapper from '../modules/privacyPolicy';
import SearchWrapper from '../modules/search';
import UserProfileWrapper from '../modules/userProfile';
import UserWrapper from '../modules/users';

const ContentRoutes = () => {
  const { getCurrentUser } = useContext(AppContext);

  const userData = getCurrentUser();
  const isCompanyVerified = localStorage.getItem('isCompanyVerified');
  const companySubscriptionStatus = userData?.subscriptionStatus;

  if (
    isCompanyVerified === 'true' &&
    companySubscriptionStatus === 'INACTIVE'
  ) {
    return <CompanyEditPage />;
  }

  return (
    <>
      <Switch>
        <Route path={ROUTES?.USERS_MANAGEMENT} component={UserWrapper} />
        <Route exact path={ROUTES?.FEED} component={FeedWrapper} />
        <Route path={ROUTES?.JOBS} component={JobWrapper} />
        <Route path={ROUTES?.COMPANIES} component={CompanyWrapper} />
        <Route path={ROUTES?.COMPANY_FEED} component={CompanyFeedWrapper} />
        <Route path={ROUTES?.APPLIED_JOBS} component={AppliedWrapper} />
        <Route path={ROUTES?.SEARCH} component={SearchWrapper} />
        <Route path={ROUTES?.CREATE_JOB} component={NewJob} />
        <Route path={ROUTES?.PRIVACY_POLICY} component={PrivacyPolicyWrapper} />
        <Route path={ROUTES?.ARCHIVE} component={ArchiveWrapper} />
        <Route path={ROUTES?.MY_JOB} component={MyJobsWrapper} />
        <Route
          path={`${ROUTES?.JOB_DETAILS}/:id`}
          component={JobDetailsWrapper}
        />
        <Route
          path={ROUTES.PROFILE_APPLIED_JOBS}
          component={UserProfileWrapper}
        />
        <Route path={ROUTES.PROFILE_POSTS} component={UserProfileWrapper} />
        <Route
          path={`${ROUTES.PROFILE_POSTS}/:id`}
          component={UserProfileWrapper}
        />
        <Route path={ROUTES.PROFILE_ABOUT} component={UserProfileWrapper} />
        <Route
          path={`${ROUTES.PROFILE_ABOUT}/:id`}
          component={UserProfileWrapper}
        />
        <Route
          path={ROUTES.PROFILE_CONNECTIONS}
          component={UserProfileWrapper}
        />
        <Route path={ROUTES.PROFILE_EDIT} component={UserProfileWrapper} />
        <Route path={ROUTES.PROFILE_FOLLOWING} component={UserProfileWrapper} />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_ABOUT}/:id`}
          component={CompanyProfileWrapper}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_ABOUT}`}
          component={CompanyProfileWrapper}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_FOLLOWERS}`}
          component={CompanyProfileWrapper}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_JOBS}/:id`}
          component={CompanyProfileWrapper}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_JOBS}`}
          component={CompanyProfileWrapper}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_REVIEW}/:id`}
          component={CompanyProfileWrapper}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_REVIEW}`}
          component={CompanyProfileWrapper}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_EDIT}`}
          component={CompanyProfileWrapper}
        />
        <Route path={ROUTES.SUCCESS} component={SuccessPage} />
        <Route
          exact
          path={ROUTES?.MAIN}
          render={() => <Redirect to={ROUTES?.FEED} />}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
