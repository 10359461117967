import { gql } from '@apollo/client';

export const NEW_MESSAGE = gql`
  subscription newMessage($where: CommonWhereInput!) {
    newMessage(where: $where) {
      id
      text
      sender
      mediaUrl
      mediaType
      readBy
      sender
      createdAt
    }
  }
`;

export const ROOM_UPDATE = gql`
  subscription roomUpdated($where: CommonWhereInput!) {
    roomUpdated(where: $where) {
      id
      type
      membersData {
        id
        name
        profileUrl
      }
      unreadMessages
      lastMessage
      updatedAt
    }
  }
`;
