import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_JOB = gql`
  query job($id: String!) {
    job(where: { id: $id }) {
      id
      title
      workType
      salary {
        minimum
        maximum
        currency
      }
      createdBy {
        id
        name
        profileImage
      }
      location {
        city
        state
        country
        coordinates {
          latitude
          longitude
        }
      }
      about
      responsibilities
      minQualification
      preferredQualification
      application {
        isApplied
        status
      }
      createdAt
      displayJob
    }
  }
`;
