import { useLazyQuery } from '@apollo/client';
import { Button, Result, Row } from 'antd';
import { map } from 'lodash';
import { React, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { SKIP_RECORD } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import CompanyCard from '../../search/component/companies/CompanyCard';
import { GET_USER_FOLLOWING } from '../graphql/Queries';

const Following = () => {
  const followingInitialFilter = {
    skip: 0,
    limit: 20
  };
  const [followingDebounceCall, setFollowingDebounceCall] = useState(0);
  const { getCurrentUser } = useContext(AppContext);
  const { id } = getCurrentUser();
  const [followingLoading, setFollowingLoading] = useState(true);
  const [followingsData, setFollowingsData] = useState([]);
  const [followingsFilter] = useState(followingInitialFilter);

  const [followingsIsEnd, setFollowingsIsEnd] = useState(false);

  const [getFollowing] = useLazyQuery(GET_USER_FOLLOWING, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.getUserFollowings?.length < SKIP_RECORD) {
        setFollowingsIsEnd(true);
      }
      setFollowingsData([...followingsData, ...res?.getUserFollowings]);
      setFollowingLoading(false);
    },
    onError() {
      setFollowingLoading(false);
    }
  });
  useEffect(() => {
    getFollowing({
      variables: {
        filters: followingsFilter,
        where: { id }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCompanies = (company) => {
    const unFrozenArray = followingsData.slice();
    for (let index = 0; index < unFrozenArray?.length; index += 1) {
      const currentCompany = unFrozenArray[index];
      if (currentCompany?.id === company?.id) {
        unFrozenArray[index] = company;
        break;
      }
    }
    setFollowingsData(unFrozenArray);
  };

  const loadMore = () => {
    if (!followingsIsEnd) {
      setFollowingLoading(true);
      getFollowing({
        variables: {
          filters: {
            ...followingsFilter,
            skip: (followingDebounceCall + 1) * SKIP_RECORD
          },
          where: { id }
        }
      });
      setFollowingDebounceCall((prevState) => prevState + 1);
    }
  };

  return (
    <div className="connection-section">
      <div className="site-card-wrapper company company-card">
        {followingsData?.length === 0 && !followingLoading && (
          <Result status="404" title="No followings" />
        )}
        <Row className="companies-grid" gutter={12}>
          {map(followingsData, (company) => {
            return (
              <CompanyCard
                key={company.id}
                updateCompanies={updateCompanies}
                company={company}
              />
            );
          })}
        </Row>
        {!followingLoading && !followingsIsEnd && (
          <div className="loadMoreButton">
            <Button className="follow-btn" onClick={loadMore}>
              Load More
            </Button>
          </div>
        )}
        {followingLoading && <LoaderComponent setHeight={10} />}
      </div>
    </div>
  );
};

export default Following;
