import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import CompanyProfile from './CompanyProfile';
import CompanyEditPage from './pages/CompanyEditPage';

function CompanyProfileWrapper() {
  return (
    <div className="company-editable-profile">
      <Switch>
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_JOBS}/:id`}
          component={CompanyProfile}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_JOBS}`}
          component={CompanyProfile}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_REVIEW}/:id`}
          component={CompanyProfile}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_REVIEW}`}
          component={CompanyProfile}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_FOLLOWERS}`}
          component={CompanyProfile}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_ABOUT}/:id`}
          component={CompanyProfile}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_ABOUT}`}
          component={CompanyProfile}
        />
        <Route
          path={`${ROUTES?.COMPANY_PROFILE_EDIT}`}
          component={CompanyEditPage}
        />
      </Switch>
    </div>
  );
}

export default CompanyProfileWrapper;
