import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
  Upload
} from 'antd';
import axios from 'axios';
import {
  debounce,
  filter,
  forEach,
  includes,
  isObject,
  map,
  split
} from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import placeholder from '../../../assets/images/placeholder.jpg';
import {
  Building,
  Degree,
  Email,
  Graduation,
  Phone,
  SuitCase,
  Trash,
  User
} from '../../../assets/svg';
import {
  ANALYTICS_EVENTS,
  BREAKPOINTS,
  defaultDateFormat,
  EMPLOYEE_TYPE,
  ISOStringDateFormat,
  MAX_PAST_DATE,
  REGEX,
  ROUTES,
  SKIP_RECORD,
  USER
} from '../../../common/constants';
import { GET_SIGNED_URL } from '../../../common/graphql/Mutation';
import { GET_USER_ME } from '../../../common/graphql/Queries';
import {
  formValidatorRules,
  getBase64,
  getLocationStrFromLocationObj,
  sleep
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import EditableTagGroup from '../../auth/EditableTags';
import { analytics } from '../../auth/Firebase';
import {
  EDIT_USER_PROFILE,
  GET_RESUME_SIGNED_URL
} from '../../auth/graphql/Mutations';
import { GET_INDUSTRIES, GET_SKILLS } from '../../auth/graphql/Queries';
import SearchLocationInput from '../../auth/SearchLocation';

let industryDebounceJob;

const { required } = formValidatorRules;

const initialIndustryFilter = {
  skip: 0,
  limit: 20,
  search: ''
};

const UpdateProfileForm = () => {
  const { dispatch, getCurrentUser } = useContext(AppContext);
  const [form] = Form.useForm();
  const { profileImage } = getCurrentUser();
  const [skills, setSkills] = useState([]);
  const [src, setSrc] = useState(profileImage || placeholder);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [experienceIndex, setExperienceIndex] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const { TextArea } = Input;
  const { Option } = Select;
  const isLaptopViewPort = useMedia(`(max-width: ${BREAKPOINTS?.laptop}px)`);
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);
  const [fetchLoading, setFetchLoading] = useState(true);
  const [bookMarkKey, setBookMarkKey] = useState('profile');
  // const [initialValues, setInitialValues] = useState(null);
  const [locationArray, setLocationArray] = useState([]);
  const [educationIndex, setEducationIndex] = useState([]);

  const [getUserProfileSignedUrl] = useMutation(GET_SIGNED_URL);
  const [getUserResumeSignedUrl] = useMutation(GET_RESUME_SIGNED_URL);
  const updateEducation = (educations) => {
    const selectedIndex = [];
    for (let index = 0; index < educations?.length; index += 1) {
      const education = educations[index];
      if (education.isCurrent) {
        selectedIndex.push(index);
        form.setFields([
          {
            name: ['education', index, 'endDate'],
            value: null
          }
        ]);
      }
    }
    setEducationIndex([...educationIndex, ...selectedIndex]);
  };

  const updateExperience = (experiences) => {
    const selectedIndex = [];
    for (let index = 0; index < experiences?.length; index += 1) {
      const experience = experiences[index];
      if (experience.isCurrent) {
        selectedIndex.push(index);
        form.setFields([
          {
            name: ['experience', index, 'endDate'],
            value: null
          }
        ]);
      }
    }
    setExperienceIndex([...experienceIndex, ...selectedIndex]);
  };

  const [getUser] = useLazyQuery(GET_USER_ME, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const experience = [];
      const education = [];
      const initLocationArray = [];

      // eslint-disable-next-line no-undef
      localStorage.setItem(USER, JSON.stringify(res?.userMe));
      dispatch({ type: 'SET_CURRENT_USER', data: res?.userMe });
      forEach(res?.userMe?.experience, (item) => {
        let type;
        if (item?.type) {
          type = EMPLOYEE_TYPE?.filter(
            (singleSize) => singleSize?.key === item?.type
          );
        }
        const location = getLocationStrFromLocationObj(item?.location);
        experience?.push({
          title: item?.title,
          type: type[0]?.key,
          company: item?.company,
          industry: item?.industry?.id,
          startDate: moment(item?.startDate, ISOStringDateFormat),
          endDate: item?.endDate
            ? moment(item?.endDate, ISOStringDateFormat)
            : null,
          location,
          isCurrent: !item?.endDate
        });
        initLocationArray.push(location);
      });
      forEach(res?.userMe?.education, (item) => {
        education?.push({
          degree: item?.degree,
          fieldOfStudy: item?.fieldOfStudy,
          company: item?.company,
          school: item?.school,
          startDate: moment(item?.startDate, ISOStringDateFormat),
          endDate: item?.endDate
            ? moment(item?.endDate, ISOStringDateFormat)
            : null,
          isCurrent: !item?.endDate
        });
      });
      if (res?.userMe?.skills) {
        setSkills(res?.userMe?.skills);
      }
      form.setFieldsValue({
        phoneNo: res?.userMe?.phoneNo,
        profileImage: res?.userMe?.profileImage,
        bio: res?.userMe?.bio,
        email: res?.userMe?.email,
        firstName: res?.userMe?.firstName,
        lastName: res?.userMe?.lastName,
        DOB: moment(res?.userMe?.DOB, ISOStringDateFormat),
        experience: experience,
        education: education
      });

      // const initialValueCopy = {
      //   phoneNo: res?.userMe?.phoneNo,
      //   profileImage: res?.userMe?.profileImage,
      //   bio: res?.userMe?.bio,
      //   email: res?.userMe?.email,
      //   firstName: res?.userMe?.firstName,
      //   lastName: res?.userMe?.lastName,
      //   DOB: moment(res?.userMe?.DOB, ISOStringDateFormat),
      //   experience: experience,
      //   education: education
      // };

      sleep(500).then(() => {
        updateEducation(education);
        updateExperience(experience);
      });
      setLocationArray(initLocationArray);
      // setInitialValues(initialValueCopy);

      // setTimeout(() => {
      //   form.validateFields();
      // }, 1000);
      setFetchLoading(false);
    }
  });

  const [fetchSkills, { loading: skillsLoading }] = useLazyQuery(GET_SKILLS, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        skip: 0
      }
    },
    onCompleted: (res) => {
      setSkillsData(res?.getSkills?.skills);
    }
  });
  const [editUserProfile] = useMutation(EDIT_USER_PROFILE, {
    onCompleted: () => {
      getUser();
    }
  });

  const [industryData, setIndustryData] = useState([]);
  const [industryLoading, setIndustryLoading] = useState(false);
  const [industrySearchFlag, setIndustrySearchFlag] = useState(false);
  const [industryIsEnd, setIndustryIsEnd] = useState(false);
  const [industryDebounceCall, setIndustryDebounceCall] = useState(0);
  const [newSkill, setNewSkill] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [skillToBeAdded, setSkillToBeAdded] = useState('');

  const onFinish = async (formValues) => {
    try {
      setLoading(true);
      const experienceArray = [];
      const educationArray = [];
      const variables = {
        ...formValues,
        skills: skills || []
      };
      map(formValues?.experience, (item, index) => {
        const formattedObj = {
          ...item,
          startDate: moment(item?.startDate, defaultDateFormat).toISOString(),
          endDate: item?.endDate
            ? moment(item?.endDate, defaultDateFormat).toISOString()
            : null,
          location: {
            city: split(locationArray[index], ',')[0]
              ? split(locationArray[index], ',')[0].trim()
              : null,
            state: split(locationArray[index], ',')[1]
              ? split(locationArray[index], ',')[1].trim()
              : null,
            country: split(locationArray[index], ',')[2]
              ? split(locationArray[index], ',')[2].trim()
              : null
          }
        };
        experienceArray.push(formattedObj);
        variables.experience = experienceArray;
      });

      map(formValues?.education, (item) => {
        const formattedObj = {
          ...item,
          startDate: moment(item?.startDate, defaultDateFormat).toISOString(),
          endDate: item?.endDate
            ? moment(item?.endDate, defaultDateFormat).toISOString()
            : null
        };
        educationArray.push(formattedObj);
        variables.education = educationArray;
      });

      if (formValues?.profileImage) {
        if (isObject(formValues?.profileImage)) {
          const response = await getUserProfileSignedUrl({
            variables: {
              data: {
                fileName: formValues?.profileImage?.file?.name
              }
            }
          });

          if (response) {
            const uploadImgResponse = await axios.put(
              response?.data?.getUserProfileSignedUrl?.signedUrl,
              formValues?.profileImage?.file,
              {
                headers: {
                  'access-control-allow-origin': '*',
                  'Content-type': formValues?.profileImage?.file?.type
                }
              }
            );

            if (uploadImgResponse.status === 200) {
              variables.profileImage =
                response?.data?.getUserProfileSignedUrl?.getUrl;
            }
          }
        } else {
          variables.profileImage = null;
          delete variables.profileImage;
        }
      }

      if (formValues?.resumeUrl) {
        if (isObject(formValues?.resumeUrl)) {
          const response = await getUserResumeSignedUrl({
            variables: {
              data: {
                fileName: formValues?.resumeUrl?.file?.name
              }
            }
          });

          if (response) {
            const uploadedResumeResponse = await axios.put(
              response?.data?.getUserResumeSignedUrl?.signedUrl,
              formValues?.resumeUrl?.file,
              {
                headers: {
                  'access-control-allow-origin': '*',
                  'Content-type': formValues?.resumeUrl?.file?.type
                }
              }
            );

            if (uploadedResumeResponse.status === 200) {
              variables.resumeUrl =
                response?.data?.getUserResumeSignedUrl?.getUrl;
            }
          }
        }
      }
      delete variables.email;
      const response = await editUserProfile({
        variables: {
          data: variables
        }
      });
      if (response) {
        setLoading(false);
        analytics.logEvent(ANALYTICS_EVENTS.USER_UPDATE_PROFILE);
        getUser();
        history.push(ROUTES.PROFILE_APPLIED_JOBS);
        // eslint-disable-next-line no-undef
        window.location.reload(true);
      }
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  const [fetchIndustries] = useLazyQuery(GET_INDUSTRIES, {
    variables: {
      filter: {
        skip: 0
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.getIndustries?.industries?.length < SKIP_RECORD) {
        setIndustryIsEnd(true);
      }
      if (industrySearchFlag) {
        setIndustryData([...res?.getIndustries?.industries]);
      } else {
        setIndustryData([...industryData, ...res?.getIndustries?.industries]);
      }
      setIndustryLoading(false);
    },
    onError() {
      setIndustryLoading(false);
    }
  });

  const industryScroll = (event) => {
    setIndustrySearchFlag(false);
    if (industryDebounceJob) {
      industryDebounceJob?.cancel();
    }

    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;

      if (scrolledToBottom && !industryIsEnd) {
        setIndustryLoading(true);
        setIndustryDebounceCall((prevState) => prevState + 1);
        fetchIndustries({
          variables: {
            filter: {
              ...initialIndustryFilter,
              skip: (industryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            }
          }
        });
      }
    }, 500);
    industryDebounceJob();
  };

  const handleIndustryChange = (value) => {
    setIndustrySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setIndustryLoading(true);
      fetchIndustries({
        variables: {
          filter: {
            ...initialIndustryFilter,
            search: value
          }
        }
      });
    } else {
      setIndustryLoading(false);
      fetchIndustries({
        variables: {
          filter: {
            ...initialIndustryFilter,
            search: value
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedProductTypeHandler = useCallback(
    debounce(handleIndustryChange, 500),
    []
  );

  const handleIndustryBlur = () => {
    setSearchValue('');
    setIndustryIsEnd(false);
    setIndustryDebounceCall(0);
  };

  useEffect(() => {
    fetchIndustries();
    fetchSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSkillChange = (value) => {
    if (value)
      if (skillsData.includes(value)) {
        setNewSkill(false);
        fetchSkills({
          variables: {
            filter: {
              skip: 0,
              search: value
            }
          }
        });
      } else {
        setNewSkill(true);
        setSkillToBeAdded(value);
      }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSkill = useCallback(debounce(handleSkillChange, 500), []);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        backgroundColor: 'white'
      }}
      className={`${
        isMobileViewPort
          ? 'fill-profile d-flex'
          : 'fill-profile d-flex flex-vertical'
      }`}
    >
      <div className="editProfileSideBard">
        <div
          className={`editProfileSideBard-option ${
            bookMarkKey === 'profile' ? 'active' : ''
          }`}
        >
          <a
            href="#profile"
            onClick={() => {
              setBookMarkKey('profile');
            }}
          >
            Profile
          </a>
        </div>
        <div
          className={`editProfileSideBard-option ${
            bookMarkKey === 'experience' ? 'active' : ''
          }`}
        >
          <a
            onClick={() => {
              setBookMarkKey('experience');
            }}
            href="#experience"
          >
            Experience
          </a>
        </div>
        <div
          className={`editProfileSideBard-option ${
            bookMarkKey === 'education' ? 'active' : ''
          }`}
        >
          <a
            href="#education"
            onClick={() => {
              setBookMarkKey('education');
            }}
          >
            Education
          </a>
        </div>
        <div
          className={`editProfileSideBard-option ${
            bookMarkKey === 'skills' ? 'active' : ''
          }`}
        >
          <a
            onClick={() => {
              setBookMarkKey('skills');
            }}
            href="#skills"
          >
            Skills
          </a>
        </div>
      </div>
      {fetchLoading ? (
        <div style={{ width: '100%' }}>
          <LoaderComponent />
        </div>
      ) : (
        <Form
          className="fill-profile-form editProfile"
          layout="vertical"
          // initialValues={initialValues}
          onFinish={onFinish}
          form={form}
        >
          <div id="profile" />
          <Form.Item className="d-flex mt-50" name="profileImage">
            <Upload
              beforeUpload={() => {
                return false;
              }}
              accept="image/*"
              showUploadList={false}
              onChange={(info) => {
                getBase64(info?.file, (image) => {
                  setSrc(image);
                });
              }}
            >
              <img src={src} alt="profile" className="profile-image mr-25" />
              <Button className="upload-button">Upload Picture</Button>
            </Upload>
          </Form.Item>
          <Row gutter={isLaptopViewPort ? 10 : 50}>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item
                label="First Name"
                name="firstName"
                className="capitalize-input-wrapper"
                rules={[required]}
              >
                <Input placeholder="Enter your First Name" prefix={<User />} />
              </Form.Item>
            </Col>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item
                label="Last Name"
                name="lastName"
                className="capitalize-input-wrapper"
                rules={[required]}
              >
                <Input placeholder="Enter your Last Name" prefix={<User />} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={isLaptopViewPort ? 10 : 50}>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item label="Date Of Birth" name="DOB" rules={[required]}>
                <DatePicker
                  placeholder="Enter DOB"
                  format={defaultDateFormat}
                  disabledDate={(current) => {
                    return current < MAX_PAST_DATE || current > moment();
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item
                label="Phone Number"
                name="phoneNo"
                rules={[
                  required,
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value.split(' ').join('');
                        const numberPattern = REGEX.PHONE;
                        if (!numberPattern.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject(
                            'should be a valid phone number'
                          );
                        }
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Input
                  placeholder="Enter your Phone Number"
                  prefix={<Phone />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={isLaptopViewPort ? 10 : 50}>
            <Col span={24}>
              <Form.Item label="Email" name="email">
                <Input
                  placeholder="Enter your email"
                  prefix={<Email />}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Bio" name="bio" rules={[required]}>
            <TextArea />
          </Form.Item>
          <div id="experience" />
          <Form.List name="experience">
            {(fields, { add, remove }) => {
              return (
                <div className="mt-50">
                  <div className="d-flex justify-between align-center">
                    <h2 className="font-600 mb-0">Experience</h2>
                    <Button
                      className="add-experience-button"
                      onClick={() => {
                        add();
                      }}
                    >
                      + Add Experience
                    </Button>
                  </div>
                  <Divider />
                  {fields.map((field, index) => (
                    <>
                      <div
                        key={field.key}
                        className={`${
                          isMobileViewPort
                            ? 'experience-block d-flex align-center justify-between'
                            : 'd-flex flex-vertical align-center experience-block'
                        }`}
                      >
                        <div className="width-percent-90">
                          <Form.Item
                            name={[field.name, 'title']}
                            fieldKey={[field.fieldKey, 'title']}
                            label="Title"
                            className="capitalize-input-wrapper"
                            rules={[required]}
                          >
                            <Input
                              prefix={<SuitCase />}
                              placeholder="Enter Title"
                              autoFocus={index > 0}
                            />
                          </Form.Item>
                          <Row gutter={20}>
                            <Col span={isMobileViewPort ? 12 : 24}>
                              <Form.Item
                                name={[field.name, 'type']}
                                fieldKey={[field.fieldKey, 'type']}
                                label="Employment Type"
                                rules={[required]}
                              >
                                <Select
                                  allowClear
                                  placeholder="Select Employment type"
                                >
                                  {map(EMPLOYEE_TYPE, (employeeType) => {
                                    return (
                                      <Option
                                        key={employeeType?.key}
                                        value={employeeType?.key}
                                      >
                                        {employeeType?.value}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 12 : 24}>
                              <Form.Item
                                name={[field.name, 'company']}
                                fieldKey={[field.fieldKey, 'company']}
                                label="Company Name"
                                className="capitalize-input-wrapper"
                                rules={[required]}
                              >
                                <Input
                                  prefix={<Building />}
                                  placeholder="Enter Company Name"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col span={isMobileViewPort ? 12 : 24}>
                              <Form.Item
                                name={[field.name, 'industry']}
                                fieldKey={[field.fieldKey, 'industry']}
                                label="Industry"
                                rules={[required]}
                              >
                                <Select
                                  loading={industryLoading}
                                  showSearch
                                  allowClear
                                  placeholder="Select Industry"
                                  optionFilterProp="children"
                                  getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                  }
                                  onPopupScroll={industryScroll}
                                  onClear={handleIndustryBlur}
                                  onSearch={debouncedProductTypeHandler}
                                  notFoundContent={
                                    industryLoading ? (
                                      <LoaderComponent
                                        size="small"
                                        setHeight={10}
                                      />
                                    ) : (
                                      <Empty />
                                    )
                                  }
                                >
                                  {map(industryData, (item) => (
                                    <Option key={item?.key} value={item?.id}>
                                      {item?.value}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 12 : 24}>
                              <Form.Item
                                name={[field.name, 'location']}
                                fieldKey={[field.fieldKey, 'location']}
                                label="Location"
                              >
                                <SearchLocationInput
                                  setLocationArray={setLocationArray}
                                  locationArray={locationArray}
                                  location={locationArray[index]}
                                  index={index}
                                  isEdit
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20} className="d-flex align-center">
                            <Col span={isMobileViewPort ? 10 : 24}>
                              <Form.Item
                                name={[field.name, 'startDate']}
                                fieldKey={[field.fieldKey, 'startDate']}
                                label="Start Date"
                                rules={[required]}
                              >
                                <DatePicker
                                  format={defaultDateFormat}
                                  disabledDate={(current) =>
                                    current < MAX_PAST_DATE ||
                                    current > moment()
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 4 : 24}>
                              <Form.Item
                                name={[field.name, 'isCurrent']}
                                fieldKey={[field.fieldKey, 'isCurrent']}
                                valuePropName="checked"
                                // initialValue={false}
                              >
                                <Checkbox
                                  className="mr-5"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setExperienceIndex([
                                        ...experienceIndex,
                                        index
                                      ]);
                                      form.setFields([
                                        {
                                          name: [
                                            'experience',
                                            index,
                                            'endDate'
                                          ],
                                          value: null
                                        }
                                      ]);
                                      form.validateFields([
                                        [field.name, 'endDate']
                                      ]);
                                    } else {
                                      setExperienceIndex(
                                        filter(
                                          experienceIndex,
                                          (val) => val !== index
                                        )
                                      );
                                    }
                                  }}
                                >
                                  Present
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 10 : 24}>
                              <Form.Item
                                name={[field.name, 'endDate']}
                                fieldKey={[field.fieldKey, 'endDate']}
                                label="End Date"
                                rules={[
                                  !includes(experienceIndex, index) && required,
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        !value ||
                                        !getFieldValue([
                                          'experience',
                                          [index],
                                          'startDate'
                                        ])
                                      ) {
                                        return Promise?.resolve();
                                      }
                                      if (
                                        getFieldValue([
                                          'experience',
                                          [index],
                                          'startDate'
                                        ]) &&
                                        value <
                                          getFieldValue([
                                            'experience',
                                            [index],
                                            'startDate'
                                          ])
                                      ) {
                                        // eslint-disable-next-line prefer-promise-reject-errors
                                        return Promise?.reject(
                                          'End Date should not be less than Start Date'
                                        );
                                      }
                                      return Promise?.resolve();
                                    }
                                  })
                                ]}
                              >
                                <DatePicker
                                  format={defaultDateFormat}
                                  disabled={includes(experienceIndex, index)}
                                  disabledDate={(current) => {
                                    return (
                                      current < MAX_PAST_DATE ||
                                      current > moment() ||
                                      current <
                                        form.getFieldValue([
                                          'experience',
                                          [index],
                                          'startDate'
                                        ])
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <Tooltip title="Delete">
                            <Trash
                              onClick={() => {
                                remove(field.name);
                              }}
                              className="pointer"
                            />
                          </Tooltip>
                        </div>
                      </div>
                      <Divider />
                    </>
                  ))}
                </div>
              );
            }}
          </Form.List>
          <div id="education" />
          <Form.List name="education">
            {(fields, { add, remove }) => {
              return (
                <div className="mt-50">
                  <div className="d-flex justify-between align-center">
                    <h2 className="font-600 mb-0">Education</h2>
                    <Button
                      className="add-experience-button"
                      onClick={() => {
                        add();
                      }}
                    >
                      + Add Education
                    </Button>
                  </div>
                  <Divider />
                  {fields.map((field, index) => (
                    <>
                      <div
                        key={field.key}
                        className={`${
                          isMobileViewPort
                            ? 'experience-block d-flex align-center justify-between'
                            : 'd-flex flex-vertical align-center experience-block'
                        }`}
                      >
                        <div className="width-percent-90">
                          <Form.Item
                            name={[field.name, 'school']}
                            fieldKey={[field.fieldKey, 'school']}
                            label="School"
                            className="capitalize-input-wrapper"
                            rules={[required]}
                          >
                            <Input
                              prefix={<Building />}
                              placeholder="Enter School Name"
                              autoFocus={index > 0}
                            />
                          </Form.Item>
                          <Row gutter={20}>
                            <Col span={isMobileViewPort ? 12 : 24}>
                              <Form.Item
                                name={[field.name, 'degree']}
                                fieldKey={[field.fieldKey, 'degree']}
                                label="Degree"
                                rules={[required]}
                              >
                                <Input
                                  prefix={<Degree />}
                                  placeholder="Enter Degree"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 12 : 24}>
                              <Form.Item
                                name={[field.name, 'fieldOfStudy']}
                                fieldKey={[field.fieldKey, 'fieldOfStudy']}
                                label="Field Of Study"
                                rules={[required]}
                              >
                                <Input
                                  prefix={<Graduation />}
                                  placeholder="Enter Field of study"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20} className="d-flex align-center">
                            <Col span={isMobileViewPort ? 10 : 24}>
                              <Form.Item
                                name={[field.name, 'startDate']}
                                fieldKey={[field.fieldKey, 'startDate']}
                                label="Start Date"
                                rules={[required]}
                                disabledDate={(current) =>
                                  current < MAX_PAST_DATE || current > moment()
                                }
                              >
                                <DatePicker format={defaultDateFormat} />
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 4 : 24}>
                              <Form.Item
                                name={[field.name, 'isCurrent']}
                                fieldKey={[field.fieldKey, 'isCurrent']}
                                valuePropName="checked"
                                // initialValue={false}
                              >
                                <Checkbox
                                  className="mr-5"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setEducationIndex([
                                        ...educationIndex,
                                        index
                                      ]);
                                      form.setFields([
                                        {
                                          name: ['education', index, 'endDate'],
                                          value: null
                                        }
                                      ]);
                                      form.validateFields([
                                        [field.name, 'endDate']
                                      ]);
                                    } else {
                                      setEducationIndex(
                                        filter(
                                          educationIndex,
                                          (val) => val !== index
                                        )
                                      );
                                    }
                                  }}
                                >
                                  Present
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 10 : 24}>
                              <Form.Item
                                name={[field.name, 'endDate']}
                                fieldKey={[field.fieldKey, 'endDate']}
                                label="End Date"
                                rules={[
                                  !includes(educationIndex, index) && required,
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        !value ||
                                        !getFieldValue([
                                          'education',
                                          [index],
                                          'startDate'
                                        ])
                                      ) {
                                        return Promise?.resolve();
                                      }
                                      if (
                                        getFieldValue([
                                          'education',
                                          [index],
                                          'startDate'
                                        ]) &&
                                        value <
                                          getFieldValue([
                                            'education',
                                            [index],
                                            'startDate'
                                          ])
                                      ) {
                                        // eslint-disable-next-line prefer-promise-reject-errors
                                        return Promise?.reject(
                                          'End Date should not be less than Start Date'
                                        );
                                      }
                                      return Promise?.resolve();
                                    }
                                  })
                                ]}
                              >
                                <DatePicker
                                  format={defaultDateFormat}
                                  disabled={includes(educationIndex, index)}
                                  disabledDate={(current) => {
                                    return (
                                      current < MAX_PAST_DATE ||
                                      current > moment() ||
                                      current <
                                        form.getFieldValue([
                                          'education',
                                          [index],
                                          'startDate'
                                        ])
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          {index !== 0 && (
                            <Tooltip title="Delete">
                              <Trash
                                onClick={() => {
                                  remove(field.name);
                                }}
                                className="pointer"
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <Divider />
                    </>
                  ))}
                </div>
              );
            }}
          </Form.List>
          <div id="skills" />
          <h2 className="font-600">Skills</h2>
          <EditableTagGroup
            setSkills={setSkills}
            skillsData={skillsData}
            loading={skillsLoading}
            debouncedSkill={debouncedSkill}
            newSkill={newSkill}
            setNewSkill={setNewSkill}
            skills={skills}
            skillToBeAdded={skillToBeAdded}
          />
          <Form.Item name="resumeUrl">
            <Upload
              className="resume-upload mt-25"
              beforeUpload={() => {
                return false;
              }}
              accept=".pdf"
              showUploadList={false}
              onChange={(info) => {
                if (info) setFile(info?.file?.name);
                else {
                  setFile();
                }
              }}
            >
              <Button className="fill-width resume-button" type="dashed">
                {file || 'Upload Your Resume'}
              </Button>
            </Upload>
          </Form.Item>
          <div
            className={`${
              isMobileViewPort
                ? 'd-flex justify-between align-center pt-20 pb-40'
                : 'd-flex flex-vertical align-center pt-20 pb-40'
            }`}
          >
            <p
              className={`${
                isMobileViewPort ? 'width-percent-50' : 'fill-width'
              }`}
            >
              By clicking “Submit” you're{' '}
              <a
                href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                agreeing{' '}
              </a>{' '}
              that the above information is accurate
            </p>
            <Button
              type="primary"
              className={`${
                isMobileViewPort ? 'width-percent-20' : 'fill-width'
              }`}
              htmlType="submit"
              loading={loading}
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default UpdateProfileForm;
