import { Card, Space } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';

const AboutCard = ({ data, title }) => {
  return (
    <div className="shadow">
      <Card className="about-card">
        <div style={{ display: 'flex', width: '100%' }}>
          <Paragraph
            className="company-name"
            style={{ fontSize: '18px' }}
            ellipsis={{
              rows: 2,
              expandable: false
            }}
          >
            {title}
          </Paragraph>
        </div>
        <div className="space-very-small-v" />
        <div className="d-flex justify-between flex-vertical body-style">
          <Space>
            <span>{data[0].icon}</span>
            <span>{data[0].info}</span>
          </Space>
          <div className="space-very-small-v" />
          <Space>
            <span>{data[1].icon}</span>
            <span>{data[1].info}</span>
          </Space>
          <div className="space-very-small-v" />
          <Space>
            <span>{data[2].icon}</span>
            <span>{data[2].info}</span>
          </Space>
        </div>
      </Card>
    </div>
  );
};

export default AboutCard;
