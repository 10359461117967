/* eslint-disable no-undef */
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Col,
  Divider,
  Empty,
  Popover,
  Result,
  Select,
  Slider
} from 'antd';
import { debounce, map } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  EMPLOYEE_TYPE,
  SKIP_RECORD,
  WORK_TYPE
} from '../../../../common/constants';
import LoaderComponent from '../../../../components/LoaderComponent';
import JobCardForUser from '../../../applied/component/JobCardForUser';
import { GET_INDUSTRIES } from '../../../auth/graphql/Queries';
import { GET_JOBS } from '../../graphql/Queries';
import '../Search.less';

let industryDebounceJob = null;

const Jobs = ({ setResultShowingJobs }) => {
  const initialIndustryFilter = {
    skip: 0,
    limit: 20,
    search: ''
  };
  const { search } = useLocation();
  const [industryDebounceCall, setIndustryDebounceCall] = useState(0);
  const [industryLoading, setIndustryLoading] = useState(false);
  const [jobDebounceCall, setJobDebounceCall] = useState(0);
  const [industrySearchFlag, setIndustrySearchFlag] = useState(false);
  const [industryIsEnd, setIndustryIsEnd] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [industryData, setIndustryData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [jobsEnd, setJobsEnd] = useState(false);
  const [jobLoading, setJobLoading] = useState(true);
  const [jobsData, setJobsData] = useState([]);
  const [filterChange, setFilterChange] = useState(false);
  const [whereFilter, setWhereFilter] = useState({});

  const [fetchIndustries] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.getIndustries?.industries?.length < SKIP_RECORD) {
        setIndustryIsEnd(true);
      }
      if (industrySearchFlag) {
        setIndustryData([...res?.getIndustries?.industries]);
      } else {
        setIndustryData([...industryData, ...res?.getIndustries?.industries]);
      }
      setIndustryLoading(false);
    },
    onError() {
      setIndustryLoading(false);
    }
  });

  const [fetchJobs] = useLazyQuery(GET_JOBS, {
    fetchPolicy: 'network-only',
    variables: {
      filters: {
        skip: 0,
        limit: 20,
        sortBy: 'createdAtDESC'
      }
    },
    onCompleted(res) {
      const displayJobs = res?.jobs?.data.filter(
        (job) => job.displayJob === true
      );
      setJobLoading(false);
      setResultShowingJobs(displayJobs.length);
      if (filterChange) {
        setJobsData(displayJobs);
      } else {
        setJobsData([...jobsData, ...displayJobs]);
      }
      if (displayJobs.length < SKIP_RECORD) {
        setJobsEnd(true);
      }
    },
    onError() {
      setJobLoading(false);
    }
  });

  useEffect(() => {
    fetchJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchJobs({
      variables: {
        filters: {
          skip: 0,
          limit: 20,
          sortBy: 'createdAtDESC',
          search: search.split('?query=')[1],
          where: {
            salary: {
              minimum: 0,
              maximum: 3500
            }
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    fetchIndustries({
      variables: {
        filter: initialIndustryFilter
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIndustryBlur = () => {
    setSearchValue('');
    setIndustryIsEnd(false);
    setIndustryDebounceCall(0);
  };

  const loadMore = () => {
    if (!jobsEnd) {
      setJobLoading(true);
      fetchJobs({
        variables: {
          filters: {
            limit: 10,
            skip: (jobDebounceCall + 1) * SKIP_RECORD,
            sortBy: 'createdAtDESC'
          }
        }
      });
      setJobDebounceCall((prevState) => prevState + 1);
    }
  };

  const industryScroll = (event) => {
    setIndustrySearchFlag(false);
    if (industryDebounceJob) {
      industryDebounceJob?.cancel();
    }

    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;

      if (scrolledToBottom && !industryIsEnd) {
        setIndustryLoading(true);
        setIndustryDebounceCall((prevState) => prevState + 1);
        fetchIndustries({
          variables: {
            filter: {
              ...initialIndustryFilter,
              skip: (industryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            }
          }
        });
      }
    }, 500);
    industryDebounceJob();
  };

  const handleIndustryChange = (value) => {
    setIndustrySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setIndustryLoading(true);
      fetchIndustries({
        variables: {
          filter: {
            ...initialIndustryFilter,
            search: value
          }
        }
      });
    } else {
      setIndustryLoading(false);
      fetchIndustries({
        variables: {
          filter: {
            ...initialIndustryFilter,
            search: value
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedProductTypeHandler = useCallback(
    debounce(handleIndustryChange, 500),
    []
  );

  const handleVisibleChange = (value) => {
    setVisible(value);
  };

  const handleJobChange = (e) => {
    if (e) {
      localStorage.setItem('jobFilter', e);
      setFilterChange(true);
      setJobLoading(true);
      const whereCurrentFilter = {
        ...whereFilter,
        type: e
      };
      fetchJobs({
        variables: {
          filters: {
            limit: 10,
            skip: 0,
            sortBy: 'createdAtDESC',
            search: search.split('?query=')[1] || '',
            where: whereCurrentFilter
          }
        }
      });
      setWhereFilter(whereCurrentFilter);
    } else {
      localStorage.removeItem('jobFilter');
      setFilterChange(false);
      setJobsData([]);
      setJobLoading(true);
      const whereCurrentFilter = {
        ...whereFilter
      };
      fetchJobs({
        variables: {
          filters: {
            limit: 20,
            skip: 0,
            sortBy: 'createdAtDESC',
            search: search.split('?query=')[1] || '',
            where: whereCurrentFilter
          }
        }
      });
      setWhereFilter(whereCurrentFilter);
    }
  };

  const handleOnSiteChange = (e) => {
    if (e) {
      localStorage.setItem('siteFilter', e);
      setFilterChange(true);
      setJobLoading(true);
      const whereCurrentFilter = {
        ...whereFilter,
        workType: e
      };
      fetchJobs({
        variables: {
          filters: {
            limit: 10,
            skip: 0,
            sortBy: 'createdAtDESC',
            search: search.split('?query=')[1] || '',
            where: whereCurrentFilter
          }
        }
      });
      setWhereFilter(whereCurrentFilter);
    } else {
      localStorage.removeItem('siteFilter');
      const whereCurrentFilter = {
        ...whereFilter
      };
      delete whereCurrentFilter.workType;
      setFilterChange(false);
      setJobsData([]);
      setJobLoading(true);
      fetchJobs({
        variables: {
          filters: {
            limit: 20,
            skip: 0,
            sortBy: 'createdAtDESC',
            search: search.split('?query=')[1] || '',
            where: whereCurrentFilter
          }
        }
      });
      setWhereFilter(whereCurrentFilter);
    }
  };

  const handleIndustryFilterChange = (e) => {
    if (e) {
      localStorage.setItem('industryFilter', e);
      const whereCurrentFilter = {
        ...whereFilter,
        industry: e
      };
      setFilterChange(true);
      setJobLoading(true);
      fetchJobs({
        variables: {
          filters: {
            limit: 10,
            skip: 0,
            sortBy: 'createdAtDESC',
            search: search.split('?query=')[1] || '',
            where: whereCurrentFilter
          }
        }
      });
      setWhereFilter(whereCurrentFilter);
    } else {
      localStorage.removeItem('industryFilter');
      const whereCurrentFilter = {
        ...whereFilter
      };
      delete whereCurrentFilter.industry;
      setFilterChange(false);
      setJobsData([]);
      setJobLoading(true);
      fetchJobs({
        variables: {
          filters: {
            limit: 20,
            skip: 0,
            sortBy: 'createdAtDESC',
            search: search.split('?query=')[1] || '',
            where: whereCurrentFilter
          }
        }
      });
      setWhereFilter(whereCurrentFilter);
    }
  };

  const handleRange = (e) => {
    if (e) {
      const whereCurrentFilter = {
        ...whereFilter,
        salary: {
          minimum: e[0],
          maximum: e[1]
        }
      };
      setFilterChange(true);
      setJobLoading(true);
      fetchJobs({
        variables: {
          filters: {
            limit: 10,
            skip: 0,
            sortBy: 'createdAtDESC',
            search: search.split('?query=')[1] || '',
            where: whereCurrentFilter
          }
        }
      });
      setWhereFilter(whereCurrentFilter);
    } else {
      setFilterChange(false);
      setJobsData([]);
      setJobLoading(true);
      const whereCurrentFilter = {
        ...whereFilter
      };
      delete whereCurrentFilter.salary;
      fetchJobs({
        variables: {
          filters: {
            limit: 20,
            skip: 0,
            sortBy: 'createdAtDESC',
            search: search.split('?query=')[1] || '',
            where: whereCurrentFilter
          }
        }
      });
      setWhereFilter(whereCurrentFilter);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceRange = useCallback(debounce(handleRange, 1000), []);

  const slider = (
    <>
      <p className="d-flex fill-width align-center mb-0 black-color">
        $0
        <Slider
          range
          onChange={debounceRange}
          defaultValue={[0, 3500]}
          min={0}
          max={3500}
          className="fill-width"
        />
        $3500
      </p>
    </>
  );

  return (
    <div className="job">
      <div className="filters">
        <Select
          placeholder="Select Job Type"
          onChange={handleJobChange}
          className="mr-10"
          allowClear
          value={localStorage.getItem('jobFilter') || undefined}
        >
          {map(EMPLOYEE_TYPE, (item) => (
            <Select.Option
              value={item?.key}
              key={item?.key}
              label={item?.value}
            >
              <div>{item?.value}</div>
            </Select.Option>
          ))}
        </Select>
        <Select
          placeholder="OnSite/Remote"
          className="mr-10"
          onChange={handleOnSiteChange}
          allowClear
          value={localStorage.getItem('siteFilter') || undefined}
        >
          {map(WORK_TYPE, (item) => (
            <Select.Option
              value={item?.key}
              key={item?.key}
              label={item?.value}
            >
              <div>{item?.value}</div>
            </Select.Option>
          ))}
        </Select>
        <Select
          loading={industryLoading}
          showSearch
          allowClear
          className="mr-10"
          placeholder="Select Industry"
          optionFilterProp="children"
          onChange={handleIndustryFilterChange}
          getPopupContainer={(trigger) => trigger.parentNode}
          onPopupScroll={industryScroll}
          onClear={handleIndustryBlur}
          onSearch={debouncedProductTypeHandler}
          notFoundContent={
            industryLoading ? (
              <LoaderComponent size="small" setHeight={10} />
            ) : (
              <Empty />
            )
          }
          value={localStorage.getItem('industryFilter') || undefined}
        >
          {map(industryData, (item) => (
            <Select.Option value={item?.id} key={item?.key}>
              {item?.value}
            </Select.Option>
          ))}
        </Select>
        <Popover
          className="slider"
          content={slider}
          placement="bottom"
          trigger="click"
          visible={visible}
          arrowPointAtCenter
          onVisibleChange={handleVisibleChange}
        >
          <Button
            placeholder="Salary Range"
            className="Salary-button"
            allowClear
          >
            Select Range{' '}
            {`${(whereFilter.salary || { minimum: 0 }).minimum} - ${
              (whereFilter.salary || { maximum: 3500 }).maximum
            }`}
          </Button>
        </Popover>
        <Divider />
      </div>
      <div className="mt">
        {jobLoading && <LoaderComponent setHeight={10} />}
        {map(jobsData, (item) => (
          <Col span={22} key={item?.id} className="margin-bottom">
            <JobCardForUser item={item} />
          </Col>
        ))}
        {!jobLoading && jobsData?.length === 0 && (
          <Result status="404" title="No results found, try something else" />
        )}
        {!jobLoading && !jobsEnd && (
          <div className="loadMoreButton">
            <Button className="follow-btn" onClick={loadMore}>
              Load More
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Jobs;
