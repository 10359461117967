/* eslint-disable no-nested-ternary */
import { useLazyQuery } from '@apollo/client';
import { Button, Result, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SKIP_RECORD } from '../../../../common/constants';
import { GET_USERS } from '../../../../common/graphql/Queries';
import LoaderComponent from '../../../../components/LoaderComponent';
import '../Search.less';
import UserCard from './UserCard';

const People = (props) => {
  const initialPeopleFilter = {
    skip: 0,
    limit: 20
  };
  const { updateResultShowing } = props;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [peopleLoading, setPeopleLoading] = useState(false);
  const [peopleDebounce, setPeopleDebounce] = useState(0);
  const [peopleIsEnd, setPeopleIsEnd] = useState(false);
  const [people, setPeople] = useState([]);
  const [count, setCount] = useState(0);
  const [peopleFilter, setPeopleFilter] = useState(initialPeopleFilter);
  const [getPeople] = useLazyQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setPeopleFilter({ ...peopleFilter, search: query.get('query') || '' });
      const users = res?.searchUsers.data;
      setCount(res?.searchUsers?.count);
      updateResultShowing(res?.searchUsers?.count);
      if (users?.length < SKIP_RECORD) {
        setPeopleIsEnd(true);
      }
      setPeople([...people, ...users]);
      setPeopleLoading(false);
    },
    onError() {
      setPeopleLoading(false);
    }
  });

  const loadMore = () => {
    if (!peopleIsEnd) {
      setPeopleLoading(true);
      getPeople({
        variables: {
          filters: {
            ...peopleFilter,
            skip: (peopleDebounce + 1) * SKIP_RECORD
          }
        }
      });
      setPeopleDebounce((prevState) => prevState + 1);
    }
  };
  useEffect(() => {
    setPeopleLoading(true);
    getPeople({
      variables: {
        filters: { ...peopleFilter, search: query.get('query') || '' }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (peopleLoading && count === 0) {
    return <LoaderComponent />;
  }
  if (count === 0) {
    return <Result status="404" title="No results found, try something else" />;
  }

  const updatePeople = (person) => {
    const unFrozenArray = people.slice();
    for (let index = 0; index < unFrozenArray?.length; index += 1) {
      const currentPerson = unFrozenArray[index];
      if (currentPerson?.id === person?.id) {
        unFrozenArray[index] = person;
        break;
      }
    }
    setPeople(unFrozenArray);
  };

  return (
    <div className="site-card-wrapper people">
      <Row
        gutter={12}
        style={{
          height:
            // eslint-disable-next-line no-undef
            count > 12 || window.innerWidth < 990
              ? 'auto'
              : count <= 4
              ? '22vh'
              : count <= 8
              ? '44vh'
              : count <= 12
              ? '70vh'
              : '100vh'
        }}
        className="people-grid"
      >
        {people?.map((user) => (
          <UserCard key={user.id} userData={user} updatePeople={updatePeople} />
        ))}
      </Row>
      {!peopleLoading && !peopleIsEnd && (
        <div className="loadMoreButton">
          <Button className="follow-btn" onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}
      {peopleLoading && <LoaderComponent setHeight={10} />}
    </div>
  );
};

export default People;
