/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_MY_JOBS = gql`
  query jobs($filters: JobFilterInput!) {
    jobs(filters: $filters) {
      count
      data {
        id
        title
        workType
        location {
          city
          state
          country
        }
        preferredQualification
        noOfVacancies
        applicationCount {
          totalApplied
          totalAccepted
        }
        createdAt
      }
    }
  }
`;

export const GET_JOB_DETAILS = gql`
  query job($id: String!) {
    job(where: { id: $id }) {
      id
      title
      workType
      noOfVacancies
      applicationCount {
        totalApplied
        totalAccepted
      }
      location {
        city
        state
        country
      }
    }
  }
`;

export const JOB_APPLICATION_DETAILS = gql`
  query jobApplicationDetails(
    $id: String!
    $filter: JobApplicationDetailFilter!
  ) {
    jobApplicationDetails(where: { id: $id }, filter: $filter) {
      count
      data {
        id
        user {
          id
          firstName
          lastName
          resumeUrl
          profileImage
        }
        status
        createdAt
      }
    }
  }
`;
