import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_COMPANIES = gql`
  query searchCompanies($filters: CompanyFilterInput!) {
    searchCompanies(filters: $filters) {
      count
      data {
        id
        name
        bio
        description
        profileImage
        isFollowed
        review {
          totalReviews
          avgRating
        }
        followerCount
        totalJobCreated
        displayCompany
      }
    }
  }
`;
