import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_RECOMMENDED_JOBS = gql`
  query jobs($filters: JobFilterInput!) {
    jobs(filters: $filters) {
      count
      data {
        id
        title
        workType
        createdBy {
          id
          name
          profileImage
        }
        location {
          city
          state
          country
        }
        minQualification
        preferredQualification
        application {
          isApplied
          status
        }
        createdAt
        displayJob
      }
    }
  }
`;
