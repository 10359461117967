import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Companies from './component/Companies';
import './component/Companies.less';

const CompanyWrapper = () => {
  return (
    <div>
      <Switch>
        <>
          <Route exact path={ROUTES?.COMPANIES} component={Companies} />
        </>
      </Switch>
    </div>
  );
};

export default CompanyWrapper;
