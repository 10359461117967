import { gql } from '@apollo/client';

export const UPDATE_COMPANY = gql`
  mutation updateCompany($data: CompanyUpdateInput!) {
    updateCompany(data: $data) {
      message
    }
  }
`;

export const REVIEW_COMPANY = gql`
  mutation reviewCompany($data: ReviewCompanyInput!) {
    reviewCompany(data: $data) {
      message
    }
  }
`;

export const CHECK_UPDATE_PLAN = gql`
  mutation checkForUpdatedPlan($employeeSize: EmployeeSize) {
    checkForUpdatedPlan(employeeSize: $employeeSize) {
      id
      currency
      amount
      type
    }
  }
`;

export const CANCEL_MY_SUBSCRIPTION = gql`
  mutation cancelMySubscription {
    cancelMySubscription {
      message
    }
  }
`;

export const GET_ACTIVATION_PLAN = gql`
  mutation getActivationPlan {
    getActivationPlan {
      message
      plans {
        id
        currency
        amount
        type
      }
    }
  }
`;
