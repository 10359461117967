import { Input } from 'antd';
import React from 'react';
import './styles/InputComponent.less';

const InputComponent = (props) => {
  const { name = '', className = '', placeholder, ...rest } = props;

  return (
    <Input
      className={`input-component ${className}`}
      type="text"
      allowClear
      autoComplete="off"
      placeholder={placeholder || `Enter ${name}`}
      {...rest}
    />
  );
};

export default InputComponent;

InputComponent.TextArea = Input.TextArea;
