import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Tooltip,
  Upload
} from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import axios from 'axios';
import { debounce, filter, includes, isObject, map, split } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import placeholder from '../../assets/images/placeholder.jpg';
import logo from '../../assets/logo.png';
import mobileLogo from '../../assets/mobileLogo.png';
import {
  Building,
  Degree,
  Graduation,
  SuitCase,
  Trash
} from '../../assets/svg';
import {
  ANALYTICS_EVENTS,
  BREAKPOINTS,
  defaultDateFormat,
  EMPLOYEE_TYPE,
  MAX_PAST_DATE,
  ROUTES,
  SKIP_RECORD,
  USER
} from '../../common/constants';
import { GET_SIGNED_URL } from '../../common/graphql/Mutation';
import { GET_USER_ME } from '../../common/graphql/Queries';
import { formValidatorRules, getBase64 } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import EditableTagGroup from './EditableTags';
import { analytics } from './Firebase';
import { GET_RESUME_SIGNED_URL, ONBOARD_USER } from './graphql/Mutations';
import { GET_INDUSTRIES, GET_SKILLS } from './graphql/Queries';
import SearchLocationInput from './SearchLocation';

let industryDebounceJob;

const { required } = formValidatorRules;

const initialIndustryFilter = {
  skip: 0,
  limit: 20,
  search: ''
};

const FillProfile = () => {
  const { getCurrentUser } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const { profileImage } = getCurrentUser();
  const [skills, setSkills] = useState([]);
  const [src, setSrc] = useState(profileImage || placeholder);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [experienceIndex, setExperienceIndex] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const { TextArea } = Input;
  const { Option } = Select;
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);

  const [getUserProfileSignedUrl] = useMutation(GET_SIGNED_URL);
  const [getUserResumeSignedUrl] = useMutation(GET_RESUME_SIGNED_URL);
  const [onboardUser] = useMutation(ONBOARD_USER);
  const [fetchSkills, { loading: skillsLoading }] = useLazyQuery(GET_SKILLS, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        skip: 0
      }
    },
    onCompleted: (res) => {
      setSkillsData(res?.getSkills?.skills);
    }
  });

  const [industryData, setIndustryData] = useState([]);
  const [industryLoading, setIndustryLoading] = useState(false);
  const [industrySearchFlag, setIndustrySearchFlag] = useState(false);
  const [industryIsEnd, setIndustryIsEnd] = useState(false);
  const [industryDebounceCall, setIndustryDebounceCall] = useState(0);
  const [newSkill, setNewSkill] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [skillToBeAdded, setSkillToBeAdded] = useState('');
  const [educationIndex, setEducationIndex] = useState([]);
  const [locationArray, setLocationArray] = useState([]);

  const [getUser] = useLazyQuery(GET_USER_ME, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      // eslint-disable-next-line no-undef
      localStorage.setItem('isOnboardingCompleted', 'true');
      // eslint-disable-next-line no-undef
      localStorage.setItem(USER, JSON.stringify(res?.userMe));
      history.replace(ROUTES.MAIN);
    },
    onError: () => {}
  });

  const [fetchIndustries] = useLazyQuery(GET_INDUSTRIES, {
    variables: {
      filter: {
        skip: 0
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.getIndustries?.industries?.length < SKIP_RECORD) {
        setIndustryIsEnd(true);
      }
      if (industrySearchFlag) {
        setIndustryData([...res?.getIndustries?.industries]);
      } else {
        setIndustryData([...industryData, ...res?.getIndustries?.industries]);
      }
      setIndustryLoading(false);
    },
    onError() {
      setIndustryLoading(false);
    }
  });

  const industryScroll = (event) => {
    setIndustrySearchFlag(false);
    if (industryDebounceJob) {
      industryDebounceJob?.cancel();
    }

    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;

      if (scrolledToBottom && !industryIsEnd) {
        setIndustryLoading(true);
        setIndustryDebounceCall((prevState) => prevState + 1);
        fetchIndustries({
          variables: {
            filter: {
              ...initialIndustryFilter,
              skip: (industryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            }
          }
        });
      }
    }, 500);
    industryDebounceJob();
  };

  const handleIndustryChange = (value) => {
    setIndustrySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setIndustryLoading(true);
      fetchIndustries({
        variables: {
          filter: {
            ...initialIndustryFilter,
            search: value
          }
        }
      });
    } else {
      setIndustryLoading(false);
      fetchIndustries({
        variables: {
          filter: {
            ...initialIndustryFilter,
            search: value
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedProductTypeHandler = useCallback(
    debounce(handleIndustryChange, 500),
    []
  );

  const handleIndustryBlur = () => {
    setSearchValue('');
    setIndustryIsEnd(false);
    setIndustryDebounceCall(0);
  };

  useEffect(() => {
    fetchIndustries();
    fetchSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSkillChange = (value) => {
    if (value)
      if (skillsData.includes(value)) {
        setNewSkill(false);
        fetchSkills({
          variables: {
            filter: {
              skip: 0,
              search: value
            }
          }
        });
      } else {
        setNewSkill(true);
        setSkillToBeAdded(value);
      }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSkill = useCallback(debounce(handleSkillChange, 500), []);

  const onFinish = async (formValues) => {
    try {
      setLoading(true);
      const experienceArray = [];
      const educationArray = [];
      const variables = {
        ...formValues,
        skills: skills || []
      };

      map(formValues?.experience, (item, index) => {
        const formattedObj = {
          ...item,
          startDate: moment(item?.startDate, defaultDateFormat).toISOString(),
          endDate: item?.endDate
            ? moment(item?.endDate, defaultDateFormat).toISOString()
            : null,
          location: {
            city: split(locationArray[index], ',')[0]
              ? split(locationArray[index], ',')[0].trim()
              : null,
            state: split(locationArray[index], ',')[1]
              ? split(locationArray[index], ',')[1].trim()
              : null,
            country: split(locationArray[index], ',')[2]
              ? split(locationArray[index], ',')[2].trim()
              : null
          }
        };
        experienceArray.push(formattedObj);
        variables.experience = experienceArray;
      });

      map(formValues?.education, (item) => {
        const formattedObj = {
          ...item,
          startDate: moment(item?.startDate, defaultDateFormat).toISOString(),
          endDate: item?.endDate
            ? moment(item?.endDate, defaultDateFormat).toISOString()
            : null
        };
        educationArray.push(formattedObj);
        variables.education = educationArray;
      });

      if (formValues?.profileImage) {
        if (isObject(formValues?.profileImage)) {
          const response = await getUserProfileSignedUrl({
            variables: {
              data: {
                fileName: formValues?.profileImage?.file?.name
              }
            }
          });

          if (response) {
            const uploadImgResponse = await axios.put(
              response?.data?.getUserProfileSignedUrl?.signedUrl,
              formValues?.profileImage?.file,
              {
                headers: {
                  'access-control-allow-origin': '*',
                  'Content-type': formValues?.profileImage?.file?.type
                }
              }
            );

            if (uploadImgResponse.status === 200) {
              variables.profileImage =
                response?.data?.getUserProfileSignedUrl?.getUrl;
            }
          }
        }
      }

      if (formValues?.resumeUrl) {
        const response = await getUserResumeSignedUrl({
          variables: {
            data: {
              fileName: formValues?.resumeUrl?.file?.name
            }
          }
        });

        if (response) {
          const uploadedResumeResponse = await axios.put(
            response?.data?.getUserResumeSignedUrl?.signedUrl,
            formValues?.resumeUrl?.file,
            {
              headers: {
                'access-control-allow-origin': '*',
                'Content-type': formValues?.resumeUrl?.file?.type
              }
            }
          );

          if (uploadedResumeResponse.status === 200) {
            variables.resumeUrl =
              response?.data?.getUserResumeSignedUrl?.getUrl;
          }
        }
      }

      const response = await onboardUser({
        variables: {
          data: variables
        }
      });
      if (response) {
        setLoading(false);
        analytics.logEvent(ANALYTICS_EVENTS.USER_PROFILE_SUBMIT);
        getUser();
      }
    } catch (err) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.log(err);
      throw err;
    }
  };

  return (
    <div
      className={`${
        isMobileViewPort
          ? 'fill-profile d-flex'
          : 'fill-profile d-flex flex-vertical'
      }`}
    >
      {isMobileViewPort && (
        <div className="fill-profile-image-text">
          <img src={logo} alt="logo" className="logo" />
          <h1>
            Sophisticated, non-biased A.I. & M.L. algorithm that matches job
            openings to Black professional applicants.
          </h1>
        </div>
      )}
      {!isMobileViewPort && (
        <div className="mobile-login-header">
          <div className="d-flex align-center justify-between">
            <img src={mobileLogo} alt="logo" className="logo" height="35px" />
          </div>
          <Divider />
        </div>
      )}
      <div className="fill-profile-detail-section">
        <h1>Enter your Candidate profile</h1>
        <p className="mt-10">
          Make the world more equitable by creating jobs, events, and
          communities that celebrate diversity.
        </p>

        <Form
          className="fill-profile-form"
          layout="vertical"
          initialValues={{ experience: [{}], education: [{}] }}
          onFinish={onFinish}
          form={form}
        >
          <div
            className={`${
              isMobileViewPort
                ? 'd-flex  mt-62 align-center'
                : 'd-flex  mt-62 flex-vertical'
            }`}
          >
            <h2 className="mb-0 font-600 mr-60">Do you identify as Black ? </h2>
            <Form.Item name="isBlackUp" className="mb-0" rules={[required]}>
              <Radio.Group
                onChange={(element) => {
                  if (element.target.value) {
                    analytics.logEvent(ANALYTICS_EVENTS.USER_IDENTIFIED_BLACK, {
                      isBlack: true
                    });
                  } else {
                    analytics.logEvent(ANALYTICS_EVENTS.USER_IDENTIFIED_BLACK, {
                      isBlack: false
                    });
                  }
                }}
              >
                <Radio value>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Form.Item className="d-flex mt-50" name="profileImage">
            <Upload
              beforeUpload={() => {
                return false;
              }}
              accept="image/*"
              showUploadList={false}
              onChange={(info) => {
                getBase64(info?.file, (image) => {
                  setSrc(image);
                  analytics.logEvent(
                    ANALYTICS_EVENTS.USER_PROFILE_PICTURE_ADDED
                  );
                });
              }}
            >
              <Avatar src={src} alt="profile" className="profile-image mr-25" />
              <Button className="upload-button">Upload Picture</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Bio" name="bio" rules={[required]}>
            <TextArea placeholder="Please tell us about yourself?" />
          </Form.Item>
          <Form.List name="experience">
            {(fields, { add, remove }) => {
              return (
                <div className="mt-50">
                  <div className="d-flex justify-between align-center">
                    <h2 className="font-600 mb-0">Experience</h2>
                    <Button
                      className="add-experience-button"
                      onClick={() => {
                        analytics.logEvent(
                          ANALYTICS_EVENTS.USER_EXPERIENCE_ADDED
                        );
                        add();
                      }}
                    >
                      + Add Experience
                    </Button>
                  </div>
                  <Divider />
                  {fields.map((field, index) => (
                    <>
                      <div
                        key={field.key}
                        className={`${
                          isMobileViewPort
                            ? 'experience-block d-flex align-center justify-between'
                            : 'd-flex flex-vertical align-center experience-block'
                        }`}
                      >
                        <div className="width-percent-90">
                          <Form.Item
                            name={[field.name, 'title']}
                            fieldKey={[field.fieldKey, 'title']}
                            label="Title"
                            className="capitalize-input-wrapper"
                            rules={[required]}
                          >
                            <Input
                              prefix={<SuitCase />}
                              placeholder="Enter Title"
                              autoFocus={index > 0}
                            />
                          </Form.Item>
                          <Row gutter={20}>
                            <Col span={isMobileViewPort ? 12 : 24}>
                              <Form.Item
                                name={[field.name, 'type']}
                                fieldKey={[field.fieldKey, 'type']}
                                label="Employment Type"
                                rules={[required]}
                              >
                                <Select
                                  allowClear
                                  placeholder="Select Employment type"
                                >
                                  {map(EMPLOYEE_TYPE, (employeeType) => {
                                    return (
                                      <Option
                                        key={employeeType?.key}
                                        value={employeeType?.key}
                                      >
                                        {employeeType?.value}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 12 : 24}>
                              <Form.Item
                                name={[field.name, 'company']}
                                fieldKey={[field.fieldKey, 'company']}
                                label="Company Name"
                                className="capitalize-input-wrapper"
                                rules={[required]}
                              >
                                <Input
                                  prefix={<Building />}
                                  placeholder="Enter Company Name"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col span={isMobileViewPort ? 12 : 24}>
                              <Form.Item
                                name={[field.name, 'industry']}
                                fieldKey={[field.fieldKey, 'industry']}
                                label="Industry"
                                rules={[required]}
                              >
                                <Select
                                  loading={industryLoading}
                                  showSearch
                                  allowClear
                                  placeholder="Select Industry"
                                  optionFilterProp="children"
                                  getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                  }
                                  onPopupScroll={industryScroll}
                                  onClear={handleIndustryBlur}
                                  onSearch={debouncedProductTypeHandler}
                                  notFoundContent={
                                    industryLoading ? (
                                      <LoaderComponent
                                        size="small"
                                        setHeight={10}
                                      />
                                    ) : (
                                      <Empty />
                                    )
                                  }
                                >
                                  {map(industryData, (item) => (
                                    <Option key={item?.key} value={item?.id}>
                                      {item?.value}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 12 : 24}>
                              <Form.Item
                                name={[field.name, 'location']}
                                fieldKey={[field.fieldKey, 'location']}
                                label="Location"
                              >
                                <SearchLocationInput
                                  setLocationArray={setLocationArray}
                                  locationArray={locationArray}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20} className="d-flex align-center">
                            <Col span={isMobileViewPort ? 10 : 24}>
                              <Form.Item
                                name={[field.name, 'startDate']}
                                fieldKey={[field.fieldKey, 'startDate']}
                                label="Start Date"
                                rules={[required]}
                              >
                                <DatePicker
                                  format={defaultDateFormat}
                                  disabledDate={(current) => {
                                    return (
                                      current < MAX_PAST_DATE ||
                                      current > moment()
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 4 : 24}>
                              <Form.Item
                                name={[field.name, 'isCurrent']}
                                fieldKey={[field.fieldKey, 'isCurrent']}
                                valuePropName="checked"
                                initialValue={false}
                              >
                                <Checkbox
                                  className="mr-5"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setExperienceIndex([
                                        ...experienceIndex,
                                        index
                                      ]);
                                      form.setFields([
                                        {
                                          name: [
                                            'experience',
                                            index,
                                            'endDate'
                                          ],
                                          value: null
                                        }
                                      ]);
                                      form.validateFields([
                                        [field.name, 'endDate']
                                      ]);
                                    } else {
                                      setExperienceIndex(
                                        filter(
                                          experienceIndex,
                                          (val) => val !== index
                                        )
                                      );
                                    }
                                  }}
                                >
                                  Present
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 10 : 24}>
                              <Form.Item
                                name={[field.name, 'endDate']}
                                fieldKey={[field.fieldKey, 'endDate']}
                                label="End Date"
                                rules={[
                                  !includes(experienceIndex, index) && required,
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        !value ||
                                        !getFieldValue([
                                          'experience',
                                          [index],
                                          'startDate'
                                        ])
                                      ) {
                                        return Promise?.resolve();
                                      }
                                      if (
                                        getFieldValue([
                                          'experience',
                                          [index],
                                          'startDate'
                                        ]) &&
                                        value <
                                          getFieldValue([
                                            'experience',
                                            [index],
                                            'startDate'
                                          ])
                                      ) {
                                        // eslint-disable-next-line prefer-promise-reject-errors
                                        return Promise?.reject(
                                          'End Date should not be less than Start Date'
                                        );
                                      }
                                      return Promise?.resolve();
                                    }
                                  })
                                ]}
                              >
                                <DatePicker
                                  format={defaultDateFormat}
                                  disabled={includes(experienceIndex, index)}
                                  disabledDate={(current) => {
                                    return (
                                      current < MAX_PAST_DATE ||
                                      current > moment() ||
                                      current <
                                        form.getFieldValue([
                                          'experience',
                                          [index],
                                          'startDate'
                                        ])
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <Tooltip title="Delete">
                            <Trash
                              onClick={() => {
                                analytics.logEvent(
                                  ANALYTICS_EVENTS.USER_EXPERIENCE_REMOVED
                                );
                                remove(field.name);
                              }}
                              className="pointer"
                            />
                          </Tooltip>
                        </div>
                      </div>
                      <Divider />
                    </>
                  ))}
                </div>
              );
            }}
          </Form.List>
          <Form.List name="education">
            {(fields, { add, remove }) => {
              return (
                <div className="mt-50">
                  <div className="d-flex justify-between align-center">
                    <h2 className="font-600 mb-0">Education</h2>
                    <Button
                      className="add-experience-button"
                      onClick={() => {
                        analytics.logEvent(
                          ANALYTICS_EVENTS.USER_EDUCATION_ADDED
                        );
                        add();
                      }}
                    >
                      + Add Education
                    </Button>
                  </div>
                  <Divider />
                  {fields.map((field, index) => (
                    <>
                      <div
                        key={field.key}
                        className={`${
                          isMobileViewPort
                            ? 'experience-block d-flex align-center justify-between'
                            : 'd-flex flex-vertical align-center experience-block'
                        }`}
                      >
                        <div className="width-percent-90">
                          <Form.Item
                            name={[field.name, 'school']}
                            fieldKey={[field.fieldKey, 'school']}
                            label="School"
                            className="capitalize-input-wrapper"
                            rules={[required]}
                          >
                            <Input
                              prefix={<Building />}
                              placeholder="Enter School Name"
                              autoFocus={index > 0}
                            />
                          </Form.Item>
                          <Row gutter={20}>
                            <Col span={isMobileViewPort ? 12 : 24}>
                              <Form.Item
                                name={[field.name, 'degree']}
                                fieldKey={[field.fieldKey, 'degree']}
                                label="Degree"
                                rules={[required]}
                              >
                                <Input
                                  prefix={<Degree />}
                                  placeholder="Enter Degree"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 12 : 24}>
                              <Form.Item
                                name={[field.name, 'fieldOfStudy']}
                                fieldKey={[field.fieldKey, 'fieldOfStudy']}
                                label="Field Of Study"
                                rules={[required]}
                              >
                                <Input
                                  prefix={<Graduation />}
                                  placeholder="Enter Field of study"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20} className="d-flex align-center">
                            <Col span={isMobileViewPort ? 10 : 24}>
                              <Form.Item
                                name={[field.name, 'startDate']}
                                fieldKey={[field.fieldKey, 'startDate']}
                                label="Start Date"
                                rules={[required]}
                              >
                                <DatePicker
                                  format={defaultDateFormat}
                                  disabledDate={(current) =>
                                    current < MAX_PAST_DATE ||
                                    current > moment()
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 4 : 24}>
                              <Form.Item
                                name={[field.name, 'isCurrent']}
                                fieldKey={[field.fieldKey, 'isCurrent']}
                                initialValue={false}
                              >
                                <Checkbox
                                  className="mr-5"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setEducationIndex([
                                        ...educationIndex,
                                        index
                                      ]);
                                      form.setFields([
                                        {
                                          name: ['education', index, 'endDate'],
                                          value: null
                                        }
                                      ]);
                                      form.validateFields([
                                        [field.name, 'endDate']
                                      ]);
                                    } else {
                                      setEducationIndex(
                                        filter(
                                          educationIndex,
                                          (val) => val !== index
                                        )
                                      );
                                    }
                                  }}
                                >
                                  Present
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={isMobileViewPort ? 10 : 24}>
                              <Form.Item
                                name={[field.name, 'endDate']}
                                fieldKey={[field.fieldKey, 'endDate']}
                                label="End Date"
                                rules={[
                                  !includes(educationIndex, index) && required,
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        !value ||
                                        !getFieldValue([
                                          'education',
                                          [index],
                                          'startDate'
                                        ])
                                      ) {
                                        return Promise?.resolve();
                                      }
                                      if (
                                        getFieldValue([
                                          'education',
                                          [index],
                                          'startDate'
                                        ]) &&
                                        value <
                                          getFieldValue([
                                            'education',
                                            [index],
                                            'startDate'
                                          ])
                                      ) {
                                        // eslint-disable-next-line prefer-promise-reject-errors
                                        return Promise?.reject(
                                          'End Date should not be less than Start Date'
                                        );
                                      }
                                      return Promise?.resolve();
                                    }
                                  })
                                ]}
                              >
                                <DatePicker
                                  format={defaultDateFormat}
                                  disabled={includes(educationIndex, index)}
                                  disabledDate={(current) => {
                                    return (
                                      current < MAX_PAST_DATE ||
                                      current > moment() ||
                                      current <
                                        form.getFieldValue([
                                          'education',
                                          [index],
                                          'startDate'
                                        ])
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          {index !== 0 && (
                            <Tooltip title="Delete">
                              <Trash
                                onClick={() => {
                                  analytics.logEvent(
                                    ANALYTICS_EVENTS.USER_EDUCATION_REMOVED
                                  );
                                  remove(field.name);
                                }}
                                className="pointer"
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <Divider />
                    </>
                  ))}
                </div>
              );
            }}
          </Form.List>
          <h2 className="font-600">Skills</h2>
          <EditableTagGroup
            setSkills={setSkills}
            skillsData={skillsData}
            loading={skillsLoading}
            debouncedSkill={debouncedSkill}
            newSkill={newSkill}
            setNewSkill={setNewSkill}
            skillToBeAdded={skillToBeAdded}
            skills={skills}
            addSkillEvent={ANALYTICS_EVENTS.USER_SKILL_ADD}
            removeSkillEvent={ANALYTICS_EVENTS.USER_SKILL_REMOVE}
          />
          <Form.Item name="resumeUrl">
            <Upload
              className="resume-upload mt-25"
              beforeUpload={() => {
                return false;
              }}
              accept=".pdf"
              showUploadList={false}
              onChange={(info) => {
                if (info) setFile(info?.file?.name);
                else {
                  setFile();
                }
              }}
            >
              <Button className="fill-width resume-button" type="dashed">
                {file || 'Upload Your Resume'}
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="How Did You Hear About Us ?"
            className="mt-25"
            name="hearAboutUs"
            rules={[required]}
          >
            <Input placeholder="Eg. Social Media.." />
          </Form.Item>
          <div
            className={`${
              isMobileViewPort
                ? 'd-flex justify-between align-center pt-20 pb-40'
                : 'd-flex flex-vertical align-center pt-20 pb-40'
            }`}
          >
            <p
              className={`${
                isMobileViewPort ? 'width-percent-50' : 'fill-width'
              }`}
            >
              By clicking “Submit” you're{' '}
              <a
                href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                agreeing{' '}
              </a>{' '}
              that the above information is accurate
            </p>
            <Button
              type="primary"
              className={`${
                isMobileViewPort ? 'width-percent-20' : 'fill-width'
              }`}
              htmlType="submit"
              loading={loading}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FillProfile;
