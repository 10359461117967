import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_NOTIFICATIONS = gql`
  query notifications {
    notifications {
      id
      isSeen
      actionId
      action
      image
      message
      createdAt
    }
  }
`;

export const GET_FLAGS = gql`
  query getFlags {
    getFlags {
      notificationFlag
      messageFlag
    }
  }
`;
