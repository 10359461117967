/* eslint-disable no-unused-vars */
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { map as _map } from 'lodash';
import React, { useCallback, useState } from 'react';

const containerStyle = {
  width: '100%',
  height: '400px'
};
const mapOptions = {
  clickableIcons: false,
  disableDefaultUI: true,
  zoomControl: true
};

export const CreateMarkerFromLocationArray = ({ locations }) => {
  return (
    <>
      {_map(locations, (item) => {
        return (
          <Marker
            position={{
              lat: item?.coordinates?.latitude || 37.09024,
              lng: item?.coordinates?.longitude || -95.712891
            }}
          />
        );
      })}
    </>
  );
};

export const MapComponent = ({ locationArray }) => {
  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  });

  const [map, setMap] = useState(null);

  // eslint-disable-next-line no-shadow
  const onLoad = useCallback(function callback(map) {
    // eslint-disable-next-line no-undef
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  // eslint-disable-next-line no-shadow
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  const center = {
    lat: locationArray[0]?.coordinates?.latitude || 37.09024,
    lng: locationArray[0]?.coordinates?.longitude || -95.712891
  };

  return isLoaded && locationArray && locationArray.length > 0 ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={12}
      options={mapOptions}
      center={center}
      // onLoad={onLoad}
      // onUnmount={onUnmount}
    >
      <CreateMarkerFromLocationArray locations={locationArray} />
    </GoogleMap>
  ) : (
    <></>
  );
};
