import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Search from './component/Search';

const SearchWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.SEARCH} component={Search} />
      <Route exact path={ROUTES?.SEARCH_JOB} component={Search} />
      <Route exact path={ROUTES?.SEARCH_COMPANY} component={Search} />
      <Route exact path={ROUTES?.SEARCH_PEOPLE} component={Search} />
    </Switch>
  );
};

export default SearchWrapper;
