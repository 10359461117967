import { Button, Col, Result, Row } from 'antd';
import { map } from 'lodash';
import React from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import CommonCompanyFollowerCard from './CommonFollowerCard';

const CompanyFollowers = ({
  followerData,
  loadMore,
  fetchFollowerLoading,
  followerIsEnd,
  count
}) => {
  // const { onRadioChange, followerData, setFollowerFilter } = props;
  if (count === 0) {
    return <Result status="404" title="No results found, try something else" />;
  }
  return (
    <div className="follower-list">
      <Row>
        {map(followerData, (follower) => {
          return (
            <Col
              key={follower?.id}
              span={6}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 4 }}
            >
              <CommonCompanyFollowerCard follower={follower} />
            </Col>
          );
        })}
      </Row>
      {!fetchFollowerLoading && !followerIsEnd && (
        <div className="loadMoreButton">
          <Button className="follow-btn" onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}
      {fetchFollowerLoading && <LoaderComponent setHeight={10} />}
    </div>
  );
};

export default CompanyFollowers;
