/* eslint-disable no-console */
import { useLazyQuery } from '@apollo/client';
import AWS from 'aws-sdk';
import { debounce, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  BIG_SCREEN,
  POST_SKIP_RECORD,
  SMALL_TABLET,
  TABLET_BREAKPOINT
} from '../../../common/constants';
import UserFeedRecommendJobs from '../../recommenders/component/UserFeedRecommendJobs';
import FeedList from '../component/FeedList';
import FeedSideBar from '../component/FeedSideBar';
import RecentJobs from '../component/RecentJobs';
import { GET_POSTS, JOBS_YOU_MIGHT_LIKE } from '../graphql/Queries';

let postDebounceJob;

const postInitialFilter = {
  skip: 0,
  limit: POST_SKIP_RECORD,
  scope: 'GLOBAL'
};
const FeedPage = () => {
  const {
    dispatch,
    state: { windowWidth }
  } = useContext(AppContext);
  const { getCurrentUser } = useContext(AppContext);
  const userData = getCurrentUser();
  const [postData, setPostData] = useState([]);
  const [postLoading, setPostLoading] = useState(true);
  const [postIsEnd, setPostIsEnd] = useState(false);
  const [postDebounceCall, setPostDebounceCall] = useState(0);
  const [postFilter, setPostFilter] = useState(postInitialFilter);
  const [scopeLoading, setScopeLoading] = useState(false);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [recentJobs, setRecentJobs] = useState([]);
  const [recentJobLoader, setRecentJobLoader] = useState(true);
  const [recommendedJobs, setRecommendedJobs] = useState([]);

  const [getPosts] = useLazyQuery(GET_POSTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.posts?.data?.length < POST_SKIP_RECORD) {
        setPostIsEnd(true);
      }
      if (scrollFlag) {
        setPostData(uniqBy([...postData, ...res?.posts?.data], 'id'));
      } else {
        setPostData(uniqBy([...postData, ...res?.posts?.data], 'id'));
      }
      setPostLoading(false);
      setScopeLoading(false);
    },
    onError() {
      setPostLoading(false);
    }
  });

  const [getRecentJobs] = useLazyQuery(JOBS_YOU_MIGHT_LIKE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      const displayJobs = res?.jobs?.data.filter(
        (job) => job.displayJob === true
      );
      setRecentJobs(displayJobs);
      setRecentJobLoader(false);
    }
  });

  const getRecommendedJobs = () => {
    const options = {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    };

    const personalizeRuntime = new AWS.PersonalizeRuntime(options);

    const params = {
      campaignArn: process.env.REACT_APP_PERSONALIZE_CAMPAIGN_ARN_JOBS,
      numResults: '3',
      userId: userData?.id // '624d9b59cd7ebee9b7d781d2' // David goncalves from prod DB, eventually will use -> userData?.id
    };

    personalizeRuntime.getRecommendations(params, function (err, data) {
      if (err) {
        // eslint-disable-next-line no-console
        console.log(err, err.stack); // an error occurred
      } else {
        // Returns an array of user_detail IDs
        const resultArray = [];
        data.itemList.forEach((item) => {
          resultArray.push(item.itemId);
        });
        setRecommendedJobs(resultArray);
      }
    });
  };

  useEffect(() => {
    if (windowWidth < SMALL_TABLET) {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: false, profileId: '' }
      });
    } else if (windowWidth < TABLET_BREAKPOINT) {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: false, profileId: '' }
      });
    } else if (windowWidth < BIG_SCREEN) {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: false, profileId: '' }
      });
    } else {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: false, profileId: '' }
      });
    }
    // dispatch({
    //   type: 'SET_DRAWER',
    //   data: { showDrawer: false, profileId: '' }
    // });
    getPosts({
      variables: {
        filters: postFilter
      }
    });

    getRecommendedJobs();
    getRecentJobs({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPostScroll = (event) => {
    if (postDebounceJob) {
      postDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    postDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !postIsEnd && !postLoading) {
        setPostLoading(true);
        setPostDebounceCall((prevState) => prevState + 1);
        getPosts({
          variables: {
            filters: {
              ...postFilter,
              skip: (postDebounceCall + 1) * POST_SKIP_RECORD
              // search: searchValue
            }
          }
        });
      }
    }, 500);

    postDebounceJob();
  };

  const updateResultShowingForPeople = (data) => {
    setPostData([]);
    setPostData(data);
  };

  return (
    <div onScroll={onPostScroll} className="wrapper-class">
      <FeedSideBar />
      <FeedList
        postData={postData}
        updatePostDataFromChild={updateResultShowingForPeople}
        setPostLoading={setPostLoading}
        postLoading={postLoading}
        getPosts={getPosts}
        postFilter={postFilter}
        setPostFilter={setPostFilter}
        scopeLoading={scopeLoading}
        setScopeLoading={setScopeLoading}
        setScrollFlag={setScrollFlag}
        scrollFlag={scrollFlag}
      />
      <div className="recommend-wrapper">
        <UserFeedRecommendJobs recommendedJobs={recommendedJobs} />
        <RecentJobs loading={recentJobLoader} data={recentJobs} />
      </div>
    </div>
  );
};

export default FeedPage;
