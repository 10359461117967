/* eslint-disable jsx-a11y/media-has-caption */
import {
  DeleteOutlined,
  EllipsisOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Divider, message, Popover } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import placeholder from '../../../assets/images/placeholder.jpg';
import { ANALYTICS_EVENTS, ROUTES } from '../../../common/constants';
import CommonAvatar from '../../../components/CommonAvatar';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import { analytics } from '../../auth/Firebase';
import { DELETE_POST } from '../graphql/Mutations';
import { GET_POSTS } from '../graphql/Queries';
import CommentModal from './CommentModal';

const postInitialFilter = {
  skip: 0,
  limit: 20
};

const CommonPost = ({ postObj, postLoading }) => {
  const {
    state: { currentUser }
  } = useContext(AppContext);

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [commentCount, setCommentCount] = useState(postObj?.commentCount);

  const userTitle = postObj?.createdBy?.experience[0]; // LATEST are at TOP

  const [deletePost] = useMutation(DELETE_POST, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
    refetchQueries: [
      {
        query: GET_POSTS,
        variables: {
          filters: postInitialFilter
        }
      }
    ]
  });

  const handleMediaClick = () => {
    history?.push(`${ROUTES?.COMPANY_FEED}/${postObj?.id}`);
  };

  const handleDeletePost = () => {
    setLoading(true);
    deletePost({
      variables: {
        where: {
          id: postObj?.id
        }
      }
    });
  };

  const renderActionButtons = () => {
    return (
      <div className="d-flex flex-vertical post-action-buttons">
        {postObj?.createdBy?.id === currentUser?.id && (
          <Button
            id="brand-table-edit-btn"
            className="b-0"
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeletePost()}
          >
            Delete
          </Button>
        )}
        <Button
          id="brand-table-edit-btn"
          className="b-0 width-decrease"
          type="link"
          icon={<InfoCircleOutlined />}
          onClick={() => {
            message.info('Thank you for reporting');
            analytics.logEvent(ANALYTICS_EVENTS.REPORT_POST);
          }}
        >
          Report
        </Button>
      </div>
    );
  };

  return (
    <div className="post-wrapper">
      <LoaderComponent spinning={loading || postLoading}>
        {showCommentModal && (
          <CommentModal
            showModal={showCommentModal}
            setShowModal={setShowCommentModal}
            postId={postObj?.id}
            setCommentCount={setCommentCount}
          />
        )}
        <div className="post-header d-flex justify-between">
          <div className="d-flex align-center">
            <CommonAvatar
              src={
                <img
                  src={postObj?.createdBy?.profileImage || placeholder}
                  alt="profile"
                  onError={(e) => {
                    e.target.src = placeholder;
                  }}
                />
              }
              size={40}
              onClick={() => {
                history?.push(
                  `${ROUTES?.PROFILE_ABOUT}/${postObj?.createdBy?.id}`
                );
              }}
            />
            <div className="text-section">
              <span
                className="title"
                onClick={() => {
                  history?.push(
                    `${ROUTES?.PROFILE_ABOUT}/${postObj?.createdBy?.id}`
                  );
                }}
              >{`${postObj?.createdBy?.firstName} ${postObj?.createdBy?.lastName}`}</span>
              <span className="sub-title">{userTitle?.title}</span>
            </div>
          </div>
          <div className="d-flex align-center">
            <Popover
              placement="bottom"
              overlayClassName="action-button"
              content={renderActionButtons()}
            >
              <EllipsisOutlined />
            </Popover>
          </div>
        </div>
        {postObj?.text && (
          <div className="image-text">
            <span onClick={() => handleMediaClick()}>{postObj?.text}</span>
          </div>
        )}
        {postObj?.mediaUrl && postObj?.mediaType === 'PHOTO' && (
          <div className="post-image">
            <img
              onClick={() => handleMediaClick()}
              src={postObj?.mediaUrl}
              alt={postObj?.mediaUrl}
            />
          </div>
        )}

        {postObj?.mediaUrl && postObj?.mediaType === 'VIDEO' && (
          <div onClick={() => handleMediaClick()} className="post-video">
            {/* <iframe
              title="post-video"
              className="video"
              src={postObj?.mediaUrl}
              alt="post"
            /> */}
            <video className="video" type="video/mp4" controls>
              <source src={postObj?.mediaUrl} />
            </video>
          </div>
        )}
        {!postObj?.mediaUrl && <Divider className="post-divider" />}
        <div className="bio-section mt-20">
          {commentCount > 0 ? postObj?.lastComment : 'No Comments Yet'}
        </div>
      </LoaderComponent>
    </div>
  );
};

export default CommonPost;
