/* eslint-disable no-unused-vars */
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Tag,
  Tooltip,
  Upload
} from 'antd';
import axios from 'axios';
import { debounce, isObject, map, omit, split } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import { toast } from '../../apollo';
import placeholder from '../../assets/images/placeholder.jpg';
import logo from '../../assets/logo.png';
import mobileLogo from '../../assets/mobileLogo.png';
import { Building, CloseIcon, DollarSign, LinkIcon } from '../../assets/svg';
import {
  ANALYTICS_EVENTS,
  BREAKPOINTS,
  EMPLOYEE_SIZE,
  ISOStringDateFormat,
  MAX_PAST_DATE,
  REGEX,
  ROUTES,
  SKIP_RECORD,
  defaultDateFormat
} from '../../common/constants';
import { GET_COMPANY_PROFILE_SIGNED_URL } from '../../common/graphql/Mutation';
import { GET_COMPANY } from '../../common/graphql/Queries';
import {
  formValidatorRules,
  getBase64,
  getLocationStrFromLocationObj
} from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import PaymentModal from '../../components/PaymentModal';
import SearchLocationInputSingle from '../companyProfile/component/SearchLocation';
import { analytics } from './Firebase';
import SearchLocationInput from './SearchLocation';
import { ONBOARD_COMPANY } from './graphql/Mutations';
import { GET_INDUSTRIES } from './graphql/Queries';

let industryDebounceJob;

const { required, number } = formValidatorRules;

const initialIndustryFilter = {
  skip: 0,
  limit: 20,
  search: ''
};

const CompanyProfile = () => {
  const { getCurrentUser } = useContext(AppContext);
  const { pathname } = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { profileImage } = getCurrentUser();
  const [src, setSrc] = useState(profileImage || placeholder);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { TextArea } = Input;
  const { Option } = Select;
  const requirePayment = process.env.REACT_APP_REQUIRE_COMPANY_PMT === 'true';

  const [getCompanyProfileSignedUrl] = useMutation(
    GET_COMPANY_PROFILE_SIGNED_URL
  );
  const [onboardCompany] = useMutation(ONBOARD_COMPANY);
  const [initialData, setInitialData] = useState();

  const [locationObject, setLocationObject] = useState([]);
  const [moreLocationObject, setMoreLocationObject] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [industryLoading, setIndustryLoading] = useState(false);
  const [industrySearchFlag, setIndustrySearchFlag] = useState(false);
  const [industryIsEnd, setIndustryIsEnd] = useState(false);
  const [industryDebounceCall, setIndustryDebounceCall] = useState(0);
  const [monthlyAmount, setMonthlyAmount] = useState(0);
  const [yearlyAmount, setYearlyAmount] = useState(0);
  const [monthlyId, setMonthlyId] = useState(0);
  const [yearlyId, setYearlyId] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [getCompany] = useLazyQuery(GET_COMPANY, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setDataLoading(false);
      let locationTemp = null;
      if (res?.companyMe?.hqLocation) {
        const locationStr = getLocationStrFromLocationObj(
          res?.companyMe?.hqLocation
        );

        locationTemp = {
          city: split(locationStr, ',')[0]
            ? split(locationStr, ',')[0].trim()
            : null,
          state: split(locationStr, ',')[1]
            ? split(locationStr, ',')[1].trim()
            : null,
          country: split(locationStr, ',')[2]
            ? split(locationStr, ',')[2].trim()
            : null
        };
        setLocationObject(locationStr);
      }
      if (res?.companyMe?.branches) {
        const moreLocationTemp = [];
        map(res?.companyMe?.branches, (branch) => {
          const locationStr = getLocationStrFromLocationObj(branch);
          moreLocationTemp.push(locationStr);
          setMoreLocationObject([...moreLocationTemp]);
        });
      }
      const initialValueCopy = {
        profileImage: res?.companyMe?.profileImage,
        bio: res?.companyMe?.bio,
        name: res?.companyMe?.name,
        establishedYear: moment(
          new Date(res?.companyMe?.establishedYear),
          defaultDateFormat
        ),
        description: res?.companyMe?.description,
        industry: res?.companyMe?.industry?.id,
        employeeSize: res?.companyMe?.employeeSize,
        website: res?.companyMe?.website,
        specialties: res?.companyMe?.specialties,
        totalRounds:
          res?.companyMe?.funding?.totalRounds === 0
            ? null
            : res?.companyMe?.funding?.totalRounds,
        fundsRaised:
          res?.companyMe?.funding?.fundsRaised === 0
            ? null
            : res?.companyMe?.funding?.fundsRaised,
        lastRoundInvestor: res?.companyMe?.funding?.lastRoundInvestor,
        lastRoundDate: res?.companyMe?.funding?.lastRoundDate
          ? moment(res?.companyMe?.funding?.lastRoundDate, ISOStringDateFormat)
          : null,
        hqLocation: locationTemp,
        email: res?.companyMe?.email
      };

      if (res?.companyMe?.profileImage) {
        setSrc(res?.companyMe?.profileImage);
      }

      setInitialData(initialValueCopy);
      // TODO: NEED TO UPDATE APP CONTEXT USER DATA
    }
  });

  const [searchValue, setSearchValue] = useState('');
  const isLaptopViewPort = useMedia(`(max-width: ${BREAKPOINTS?.laptop}px)`);
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);

  const handleClose = (removedTag) => {
    setMoreLocationObject(
      moreLocationObject.filter((tag) => tag !== removedTag)
    );
  };
  const [fetchIndustries] = useLazyQuery(GET_INDUSTRIES, {
    variables: {
      filter: {
        skip: 0
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.getIndustries?.industries?.length < SKIP_RECORD) {
        setIndustryIsEnd(true);
      }
      if (industrySearchFlag) {
        setIndustryData([...res?.getIndustries?.industries]);
      } else {
        setIndustryData([...industryData, ...res?.getIndustries?.industries]);
      }
      setIndustryLoading(false);
    },
    onError() {
      setIndustryLoading(false);
    }
  });

  useEffect(() => {
    if (pathname === ROUTES.CANCEL) {
      setTimeout(() => {
        history.push(ROUTES.COMPANY_PROFILE);
        toast({
          message: 'Complete The Payment',
          type: 'error'
        });
      }, 500);
    }
    if (pathname === ROUTES.SUCCESS) {
      // eslint-disable-next-line no-undef
      localStorage?.setItem('isCompanyOnboardingCompleted', true);
      // eslint-disable-next-line no-undef
      localStorage?.setItem('isCompanyVerified', true);
      setTimeout(() => {
        history.push(ROUTES.COMPANY_FEED);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const industryScroll = (event) => {
    setIndustrySearchFlag(false);
    if (industryDebounceJob) {
      industryDebounceJob?.cancel();
    }

    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;

      if (scrolledToBottom && !industryIsEnd) {
        setIndustryLoading(true);
        setIndustryDebounceCall((prevState) => prevState + 1);
        fetchIndustries({
          variables: {
            filter: {
              ...initialIndustryFilter,
              skip: (industryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            }
          }
        });
      }
    }, 500);
    industryDebounceJob();
  };

  const handleIndustryChange = (value) => {
    setIndustrySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setIndustryLoading(true);
      fetchIndustries({
        variables: {
          filter: {
            ...initialIndustryFilter,
            search: value
          }
        }
      });
    } else {
      setIndustryLoading(false);
      fetchIndustries({
        variables: {
          filter: {
            ...initialIndustryFilter,
            search: value
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedProductTypeHandler = useCallback(
    debounce(handleIndustryChange, 500),
    []
  );

  const handleIndustryBlur = () => {
    setSearchValue('');
    setIndustryIsEnd(false);
    setIndustryDebounceCall(0);
  };

  useEffect(() => {
    getCompany();
    fetchIndustries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onFinish = async (formValues) => {
    try {
      setLoading(true);
      const variables = {
        ...formValues,
        employeeSize: formValues?.employeeSize,
        funding: {
          totalRounds: Number(formValues?.totalRounds),
          lastRoundInvestor: formValues?.lastRoundInvestor,
          lastRoundDate: formValues?.lastRoundDate,
          fundsRaised: Number(formValues?.fundsRaised)
        }
      };

      if (locationObject) {
        variables.hqLocation = {
          city: split(locationObject, ',')[0]
            ? split(locationObject, ',')[0].trim()
            : null,
          state: split(locationObject, ',')[1]
            ? split(locationObject, ',')[1]?.trim()
            : null,
          country: split(locationObject, ',')[2]
            ? split(locationObject, ',')[2]?.trim()
            : null
        };
      }
      if (moreLocationObject) {
        variables.branches = map(moreLocationObject, (val) => ({
          city: split(val, ',')[0] ? split(val, ',')[0].trim() : null,
          state: split(val, ',')[1] ? split(val, ',')[1]?.trim() : null,
          country: split(val, ',')[2] ? split(val, ',')[2]?.trim() : null
        }));
      }
      if (formValues?.specialties) {
        variables.specialties = split(formValues?.specialties, ',');
      }
      if (formValues?.profileImage) {
        if (isObject(formValues?.profileImage)) {
          const response = await getCompanyProfileSignedUrl({
            variables: {
              data: {
                fileName: formValues?.profileImage?.file?.name
              }
            }
          });

          if (response) {
            const uploadImgResponse = await axios.put(
              response?.data?.getCompanyProfileSignedUrl?.signedUrl,
              formValues?.profileImage?.file,
              {
                headers: {
                  'access-control-allow-origin': '*',
                  'Content-type': formValues?.profileImage?.file?.type
                }
              }
            );

            if (uploadImgResponse.status === 200) {
              variables.profileImage =
                response?.data?.getCompanyProfileSignedUrl?.getUrl;
            }
          }
        } else {
          delete variables.profileImage;
        }
      }

      const data = omit(variables, [
        'totalRounds',
        'lastRoundInvestor',
        'lastRoundDate',
        'fundsRaised'
      ]);
      const response = await onboardCompany({
        variables: {
          data: data
        }
      });
      if (response) {
        setLoading(false);
        analytics.logEvent(ANALYTICS_EVENTS.COMPANY_SUBMITS_APPLICATION);
        if (requirePayment) {
          setShowModal(true);
          map(response?.data?.onboardCompany?.plans, (plan) => {
            if (plan?.type === 'MONTHLY') {
              setMonthlyAmount(plan?.amount);
              setMonthlyId(plan?.id);
            } else if (plan?.type === 'YEARLY') {
              setYearlyAmount(plan?.amount);
              setYearlyId(plan?.id);
            }
          });
        } else {
          // eslint-disable-next-line no-undef
          localStorage.setItem('isCompanyOnboardingCompleted', true);
          // Route to notified when approved page
          history.push(ROUTES.MAIN);
        }
      }
    } catch (err) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.log(err);
      throw err;
    }
  };

  return (
    <div
      className={`${
        isMobileViewPort
          ? 'company-profile d-flex'
          : 'company-profile d-flex flex-vertical'
      }`}
    >
      {showModal && (
        <PaymentModal
          showModal={showModal}
          setShowModal={setShowModal}
          monthlyAmount={monthlyAmount}
          yearlyAmount={yearlyAmount}
          yearlyId={yearlyId}
          monthlyId={monthlyId}
        />
      )}
      {isMobileViewPort && (
        <div className="company-profile-image-text">
          <img src={logo} alt="logo" className="logo" />
          <h1>
            Sophisticated, non-biased A.I. & M.L. algorithm that matches job
            openings to Black professional applicants.
          </h1>
        </div>
      )}
      {!isMobileViewPort && (
        <div className="mobile-login-header">
          <div className="d-flex align-center justify-between">
            <img src={mobileLogo} alt="logo" className="logo" height="35px" />
          </div>
          <Divider />
        </div>
      )}
      <div className="company-profile-detail-section">
        <h1>Enter your Company profile</h1>
        <p className="mt-10">
          Make the world more equitable by creating jobs, events, and
          communities that celebrate diversity.
        </p>
        {dataLoading ? (
          <LoaderComponent />
        ) : (
          <Form
            className="company-profile-form"
            layout="vertical"
            initialValues={initialData}
            onFinish={onFinish}
            form={form}
            onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
          >
            <Form.Item className="d-flex mt-50" name="profileImage">
              <Upload
                beforeUpload={() => {
                  return false;
                }}
                accept="image/*"
                showUploadList={false}
                onChange={(info) => {
                  getBase64(info?.file, (image) => {
                    setSrc(image);
                    analytics.logEvent(ANALYTICS_EVENTS.COMPANY_LOGO_ADDED);
                  });
                }}
              >
                <img src={src} alt="profile" className="profile-image mr-25" />
                <Button className="upload-button">Upload Picture</Button>
              </Upload>
            </Form.Item>
            <Form.Item label="Bio" name="bio" rules={[required]}>
              <TextArea
                placeholder="e.g a Technology Company"
                onBlur={(element) => {
                  if (element.target.value)
                    analytics.logEvent(ANALYTICS_EVENTS.COMPANY_INFO_ADDED, {
                      field_name: 'bio'
                    });
                }}
              />
            </Form.Item>
            <div className="d-flex justify-between align-center">
              <h2 className="font-600 mb-0">Company Details</h2>
            </div>
            <Divider />
            <Row gutter={50}>
              <Col span={24}>
                <Form.Item
                  label="Overview (Limit 500 characters)"
                  name="description"
                  rules={[required]}
                >
                  <TextArea
                    placeholder="Enter your Overview"
                    maxLength="500"
                    rows={5}
                    onBlur={(element) => {
                      if (element.target.value)
                        analytics.logEvent(
                          ANALYTICS_EVENTS.COMPANY_INFO_ADDED,
                          {
                            field_name: 'overview'
                          }
                        );
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={isLaptopViewPort ? 10 : 50}>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item label="Industry" name="industry" rules={[required]}>
                  <Select
                    loading={industryLoading}
                    showSearch
                    allowClear
                    placeholder="Industry"
                    optionFilterProp="children"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onPopupScroll={industryScroll}
                    onClear={handleIndustryBlur}
                    onBlur={(element) => {
                      if (element.target.value)
                        analytics.logEvent(
                          ANALYTICS_EVENTS.COMPANY_INFO_ADDED,
                          {
                            field_name: 'industry'
                          }
                        );
                    }}
                    onSearch={debouncedProductTypeHandler}
                    notFoundContent={
                      industryLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty />
                      )
                    }
                  >
                    {map(industryData, (item) => (
                      <Option key={item?.key} value={item?.id}>
                        {item?.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item
                  label="Company Size"
                  name="employeeSize"
                  rules={[required]}
                >
                  <Select
                    allowClear
                    placeholder="Enter your company size"
                    onBlur={(element) => {
                      if (element.target.value)
                        analytics.logEvent(
                          ANALYTICS_EVENTS.COMPANY_INFO_ADDED,
                          {
                            field_name: 'company_size'
                          }
                        );
                    }}
                  >
                    {map(EMPLOYEE_SIZE, (size) => {
                      return (
                        <Option key={size?.key} value={size?.key}>
                          {size?.value}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={50}>
              <Col span={24}>
                <Form.Item
                  label="Website"
                  name="website"
                  rules={[
                    required,
                    { pattern: REGEX.WEB_URL, message: 'enter proper URL' }
                  ]}
                >
                  <Input
                    placeholder="Enter Company's website link"
                    onBlur={(element) => {
                      if (element.target.value)
                        analytics.logEvent(
                          ANALYTICS_EVENTS.COMPANY_INFO_ADDED,
                          {
                            field_name: 'website'
                          }
                        );
                    }}
                    prefix={<LinkIcon />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={isLaptopViewPort ? 10 : 50}>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item label="Headquarters location" name="hqLocation">
                  <SearchLocationInputSingle
                    onChange={() => null}
                    setLocation={setLocationObject}
                    location={locationObject}
                    placeholder="Enter Headquarters Location"
                    onBlur={(element) => {
                      if (element.target.value)
                        analytics.logEvent(
                          ANALYTICS_EVENTS.COMPANY_INFO_ADDED,
                          {
                            field_name: 'headquarter'
                          }
                        );
                    }}
                    isEdit
                  />
                </Form.Item>
              </Col>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item
                  label="Specialties"
                  name="specialties"
                  rules={[required]}
                >
                  <Input
                    placeholder="Enter your Specialties"
                    prefix={<Building />}
                    onBlur={(element) => {
                      if (element.target.value)
                        analytics.logEvent(
                          ANALYTICS_EVENTS.COMPANY_INFO_ADDED,
                          {
                            field_name: 'specialties'
                          }
                        );
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={50}>
              <Col span={24}>
                <Form.Item label="More Company location" name="branches">
                  <SearchLocationInput
                    onChange={() => null}
                    setLocationArray={setMoreLocationObject}
                    locationArray={moreLocationObject}
                    isMultiLocation
                  />
                  {moreLocationObject.map((tag) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                      <Tag
                        className="edit-tag"
                        key={tag}
                        closable
                        onClose={() => handleClose(tag)}
                        closeIcon={<CloseIcon />}
                      >
                        <span>
                          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </span>
                      </Tag>
                    );
                    return isLongTag ? (
                      <Tooltip title={tag} key={tag}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                </Form.Item>
              </Col>
            </Row>
            <div className="d-flex justify-between align-center">
              <h2 className="font-600 mb-0">Funding</h2>
            </div>
            <Divider />

            <Row gutter={isLaptopViewPort ? 10 : 50}>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item
                  label="Total rounds"
                  name="totalRounds"
                  rules={[number]}
                >
                  <Input
                    placeholder="Enter your total rounds"
                    type="number"
                    prefix={<Building />}
                    onBlur={(element) => {
                      if (element.target.value)
                        analytics.logEvent(
                          ANALYTICS_EVENTS.COMPANY_INFO_ADDED,
                          {
                            field_name: 'funding_total_rounds'
                          }
                        );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item label="Last round investor" name="lastRoundInvestor">
                  <Input
                    placeholder="Enter Last round investor"
                    prefix={<DollarSign />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={isLaptopViewPort ? 10 : 50}>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item label="Last round date" name="lastRoundDate">
                  <DatePicker
                    format={defaultDateFormat}
                    placeholder="Enter Last round date"
                    disabledDate={(current) => {
                      return current < MAX_PAST_DATE || current > moment();
                    }}
                    onBlur={() => {
                      analytics.logEvent(ANALYTICS_EVENTS.COMPANY_INFO_ADDED, {
                        field_name: 'funding_last_round'
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item
                  label="Funds raised"
                  name="fundsRaised"
                  rules={[number]}
                >
                  <Input
                    placeholder="Enter Last funds raised"
                    type="number"
                    prefix={<DollarSign />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div
              className={`${
                isMobileViewPort
                  ? 'd-flex justify-between align-center pt-20 pb-40'
                  : 'd-flex flex-vertical align-center pt-20 pb-40'
              }`}
            >
              <p
                className={`${
                  isMobileViewPort ? 'width-percent-50' : 'fill-width'
                }`}
              >
                By clicking “Submit” you're{' '}
                <a
                  href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  agreeing{' '}
                </a>{' '}
                that the above information is accurate
              </p>
              <Button
                type="primary"
                className={`${
                  isMobileViewPort ? 'width-percent-20' : 'fill-width'
                }`}
                htmlType="submit"
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default CompanyProfile;
