import { useLazyQuery } from '@apollo/client';
import { Button, Col, Divider, Result, Tabs } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SKIP_RECORD } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import { GET_USER_JOBS } from '../graphql/Queries';
import './Applied.less';
import JobCardForUser from './JobCardForUser';

const Applied = ({ isUserProfile }) => {
  const { TabPane } = Tabs;
  const { pathname } = useLocation();
  const [activeKey, setActiveKey] = useState('all');
  const [status, setStatus] = useState(null);
  const [jobsLoading, setJobsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [jobsDebounce, setJobsDebounce] = useState(false);
  const [jobsIsEnd, setJobsIsEnd] = useState(false);
  const [getUserJobs, { data }] = useLazyQuery(GET_USER_JOBS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setJobsLoading(false);
      const job = res?.getUserJobs?.data;
      setJobs([...jobs, ...job]);
      if (job?.length < SKIP_RECORD) {
        setJobsIsEnd(true);
      }
    },
    onError() {
      setJobsLoading(false);
    }
  });

  useEffect(() => {
    setJobsLoading(true);
    setJobs([]);
    getUserJobs({
      variables: {
        filters: { skip: 0, limit: 20, status }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (pathname?.includes('shortlisted')) {
      setActiveKey('shortlisted');
    } else if (pathname?.includes('accepted')) {
      setActiveKey('accepted');
    } else if (pathname?.includes('rejected')) {
      setActiveKey('rejected');
    } else {
      setActiveKey('all');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    if (!jobsIsEnd) {
      setJobsLoading(true);
      getUserJobs({
        variables: {
          filters: {
            limit: 20,
            status: status,
            skip: (jobsDebounce + 1) * SKIP_RECORD
          }
        }
      });
      setJobsDebounce((prevState) => prevState + 1);
    }
  };

  return (
    <div className={`${isUserProfile ? 'applied' : 'applied padding-width'}`}>
      {!isUserProfile && (
        <>
          <div>
            <h1 className="heading">Applied Jobs</h1>
            {/* <Typography.Text type="secondary">
              Based on your profile and search history
            </Typography.Text> */}
          </div>
          <Divider />
        </>
      )}

      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key);
          if (key === 'shortlisted') {
            setStatus('WHITELISTED');
          } else if (key === 'accepted') {
            setStatus('ACCEPTED');
          } else if (key === 'rejected') {
            setStatus('REJECTED');
          } else {
            setStatus(null);
          }
        }}
        className="mt-12"
        tabBarExtraContent={{
          left: (
            <h3 className="mb-0 mr-25">
              Total : {data?.getUserJobs?.count || 0}
            </h3>
          )
        }}
      >
        <TabPane tab="All" key="all" className="applied-jobs-tab">
          <div className="applied-jobs" />
        </TabPane>
        <TabPane
          tab="Shortlisted"
          key="shortlisted"
          className="applied-jobs-tab"
        >
          <div className="applied-jobs" />
        </TabPane>
        <TabPane tab="Accepted" key="accepted" className="applied-jobs-tab">
          <div className="applied-jobs" />
        </TabPane>
        <TabPane tab="Rejected" key="rejected" className="applied-jobs-tab">
          <div className="applied-jobs" />
        </TabPane>
      </Tabs>
      {map(jobs, (item) => (
        <Col span={22} key={item?.id} className="margin-bottom">
          <JobCardForUser item={item} />
        </Col>
      ))}
      {jobsLoading && <LoaderComponent setHeight={10} />}
      {!jobsLoading && jobs?.length === 0 && (
        <Result status="404" title="No Jobs Found" />
      )}
      {!jobsLoading && !jobsIsEnd && (
        <div className="loadMoreButton">
          <Button className="follow-btn" onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default Applied;
