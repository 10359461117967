/* eslint-disable jsx-a11y/media-has-caption */
import {
  DeleteOutlined,
  EllipsisOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Divider, Popover, Typography, message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import commentSvg from '../../../assets/images/comment.svg';
import placeholder from '../../../assets/images/placeholder.jpg';
import likeFillSvg from '../../../assets/svg/like-fill.svg';
import likeSvg from '../../../assets/svg/like.svg';
import { ANALYTICS_EVENTS, ROUTES } from '../../../common/constants';
import CommonAvatar from '../../../components/CommonAvatar';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import { analytics } from '../../auth/Firebase';
import { DELETE_POST, LIKE_POST, USER_CONNECTION } from '../graphql/Mutations';
import { GET_POSTS } from '../graphql/Queries';
import CommentModal from './CommentModal';

const { Paragraph } = Typography;

const CommonPost = ({
  postObj,
  postFilter,
  handleConnectionButton,
  removePost,
  updatePost
}) => {
  const {
    state: { currentUser }
  } = useContext(AppContext);

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [liked, setLiked] = useState(postObj?.isLiked);
  const [connectionLoading, setConnectionLoading] = useState(false);
  const [showConnectionBtn, setShowConnectionBtn] = useState(
    postObj?.createdBy?.userConnection
  );

  const userTitle = postObj?.createdBy?.experience[0]; // LATEST WOULD 1st

  const [deletePost] = useMutation(DELETE_POST, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const [userConnection] = useMutation(USER_CONNECTION, {
    onCompleted: (res) => {
      setShowConnectionBtn(false);
      setConnectionLoading(false);
      const { createdBy } = postObj;
      const temp = {
        ...createdBy,
        userConnection: res?.connectionRequest
      };
      // createdBy.userConnection = res;
      handleConnectionButton(temp);
    },
    refetchQueries: [
      {
        query: GET_POSTS,
        variables: {
          filters: postFilter
        }
      }
    ]
  });

  const [likePost] = useMutation(LIKE_POST, {
    onCompleted: () => {
      setLoading(false);
    },
    refetchQueries: [
      {
        query: GET_POSTS,
        variables: {
          filters: postFilter
        }
      }
    ]
  });

  const handleMediaClick = () => {
    analytics.logEvent(ANALYTICS_EVENTS.POST_CLICKED, {
      id: postObj?.id
    });
    history?.push(`${ROUTES?.FEED}/${postObj?.id}`);
  };

  const handleConnectionRequest = () => {
    setConnectionLoading(true);
    userConnection({
      variables: {
        data: {
          connectedTo: postObj?.createdBy?.id
        }
      }
    });
  };
  const setCommentCount = (comment) => {
    updatePost({
      ...postObj,
      commentCount: postObj?.commentCount + 1,
      lastComment: comment
    });
  };

  const handleLike = () => {
    setLiked(!liked);
    if (liked) {
      likePost({
        variables: {
          where: {
            id: postObj?.id
          },
          action: 'UNLIKE'
        }
      });
      updatePost({
        ...postObj,
        likeCount: postObj?.likeCount - 1,
        isLiked: false
      });
    } else {
      analytics.logEvent(ANALYTICS_EVENTS.LIKE);
      likePost({
        variables: {
          where: {
            id: postObj?.id
          },
          action: 'LIKE'
        }
      });
      updatePost({
        ...postObj,
        likeCount: postObj?.likeCount + 1,
        isLiked: true
      });
    }
    // debouncedLikeHandler();
  };

  const handleDeletePost = () => {
    setLoading(true);
    deletePost({
      variables: {
        where: {
          id: postObj?.id
        }
      }
    });
    removePost(postObj?.id);
  };

  const renderActionButtons = () => {
    return (
      <div className="d-flex flex-vertical">
        {postObj?.createdBy?.id === currentUser?.id && (
          <Button
            id="brand-table-edit-btn"
            className="b-0"
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => {
              confirm({
                okText: 'Yes, Continue',
                content: 'Are you sure you want to delete?',
                centered: true,
                cancelText: 'No',
                onOk: () => {
                  handleDeletePost();
                }
              });
            }}
          >
            Delete
          </Button>
        )}
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          icon={<InfoCircleOutlined />}
          onClick={() => {
            message.info('Thank you for reporting');
            analytics.logEvent(ANALYTICS_EVENTS.REPORT_POST);
          }}
        >
          Report
        </Button>
      </div>
    );
  };

  return (
    <div className="post-wrapper pointer">
      <LoaderComponent spinning={loading}>
        {showCommentModal && (
          <CommentModal
            showModal={showCommentModal}
            setShowModal={setShowCommentModal}
            postId={postObj?.id}
            setCommentCount={setCommentCount}
          />
        )}
        <div className="post-header d-flex justify-between">
          <div className="d-flex align-center">
            <CommonAvatar
              src={
                <img
                  src={postObj?.createdBy?.profileImage || placeholder}
                  alt="profile"
                  onError={(e) => {
                    e.target.src = placeholder;
                  }}
                />
              }
              size={40}
              onClick={() => {
                history?.push(
                  `${ROUTES?.PROFILE_ABOUT}/${postObj?.createdBy?.id}`
                );
              }}
            />
            <div className="text-section">
              <span
                className="title"
                onClick={() => {
                  history?.push(
                    `${ROUTES?.PROFILE_ABOUT}/${postObj?.createdBy?.id}`
                  );
                }}
              >{`${postObj?.createdBy?.firstName} ${postObj?.createdBy?.lastName}`}</span>
              <span className="sub-title">{userTitle?.title}</span>
            </div>
          </div>
          <div className="d-flex align-center">
            {postObj?.createdBy?.id !== currentUser?.id &&
            showConnectionBtn === null ? (
              <Button
                onClick={handleConnectionRequest}
                className="connect-button"
                type="primary"
                loading={connectionLoading}
              >
                Connect
              </Button>
            ) : (
              ''
            )}
            <Popover
              placement="bottom"
              overlayClassName="action-button"
              content={renderActionButtons()}
            >
              <EllipsisOutlined />
            </Popover>
          </div>
        </div>
        {postObj?.text && (
          <div className="image-text" onClick={() => handleMediaClick()}>
            <Paragraph
              ellipsis={{
                rows: 4,
                expandable: true,
                symbol: 'more'
              }}
            >
              {postObj?.text}
            </Paragraph>
          </div>
        )}
        {postObj?.mediaUrl && postObj?.mediaType === 'PHOTO' && (
          <div className="post-image">
            <img
              onClick={() => handleMediaClick()}
              src={postObj?.mediaUrl}
              // onError={(e) => {
              //   e.target.src = textImage;
              // }}
              alt={postObj?.mediaUrl}
            />
          </div>
        )}

        {postObj?.mediaUrl && postObj?.mediaType === 'VIDEO' && (
          <div onClick={() => handleMediaClick()} className="post-video">
            {/* <iframe
              title="post-video"
              className="video"
              src={postObj?.mediaUrl}
              alt="post"
            /> */}
            <video className="video" type="video/mp4" controls>
              <source src={postObj?.mediaUrl} />
            </video>
          </div>
        )}

        <div className="like-section d-flex justify-between">
          <div className="d-flex justify-center align-center">
            <img
              className="image"
              // src={postObj?.isLiked ? likeFillSvg : likeSvg}
              src={liked ? likeFillSvg : likeSvg}
              onClick={() => handleLike(liked)}
              alt="like"
            />
            <span className="info-section">{postObj?.likeCount}</span>
          </div>
          <div
            onClick={() => setShowCommentModal(true)}
            className="d-flex justify-center align-center pointer"
          >
            <img className="image" src={commentSvg} alt="lik" />
            <span className="info-section">
              {postObj?.commentCount}{' '}
              {postObj?.commentCount === 0 || postObj?.commentCount > 1
                ? 'Comments'
                : 'Comment'}
            </span>
          </div>
        </div>
        <Divider className="post-divider" />

        <div className="bio-section">
          {postObj?.commentCount > 0 ? postObj?.lastComment : 'No Comments Yet'}
        </div>
      </LoaderComponent>
    </div>
  );
};
export default CommonPost;
