import { useMutation } from '@apollo/client';
import { Button, Card, Col, Divider, Form, message, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { Checked } from '../assets/svg';
import { BREAKPOINTS, ROUTES } from '../common/constants';
import { sleep } from '../common/utils';
import history from '../historyData';
import { GET_STRIPE_SESSION } from '../modules/auth/graphql/Mutations';
import LoaderComponent from './LoaderComponent';

const PaymentModal = ({
  showModal,
  setShowModal,
  monthlyAmount,
  yearlyAmount,
  yearlyId,
  monthlyId,
  loading
}) => {
  const [selectedPlan, setSelectedPlan] = useState('MONTHLY');
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);
  const [getStripeSession, { loading: sessionLoading }] = useMutation(
    GET_STRIPE_SESSION
  );
  const [form] = Form.useForm();
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOk = async () => {
    try {
      if (selectedPlan === 'MONTHLY') {
        const data = await getStripeSession({
          variables: {
            tokenId: monthlyId
          }
        });
        if (data) {
          // eslint-disable-next-line no-undef
          window.location = data?.data?.getStripeSession?.url;
        }
      }
      if (selectedPlan === 'YEARLY') {
        const data = await getStripeSession({
          variables: {
            tokenId: yearlyId
          }
        });

        if (data) {
          // eslint-disable-next-line no-undef
          window.location = data?.data?.getStripeSession?.url;
          // eslint-disable-next-line no-undef
          localStorage?.setItem('isCompanyVerified', true);
        }
      }
    } catch (error) {
      message.error(error.message);
      if (error.message === 'You have already subscribed') {
        setShowModal(false);
        message.info('REDIRECTING TO HOME PAGE....');
        await sleep(500);
        // eslint-disable-next-line no-undef
        localStorage.setItem('isCompanyOnboardingCompleted', true);
        // eslint-disable-next-line no-undef
        localStorage.setItem('isCompanyVerified', true);
        history.push(ROUTES.COMPANY_FEED);
      }
    }
  };

  return (
    <>
      <Modal
        visible={showModal}
        centered
        onCancel={handleCancel}
        footer={null}
        className="payment-modal"
        onOk={() => {
          form.submit();
        }}
      >
        <h1 className="font-700">Make Payment</h1>
        {loading ? (
          <LoaderComponent setHeight={50} />
        ) : (
          <>
            <Form form={form} onFinish={handleOk}>
              <Row gutter={20} className="select-year">
                <Col span={12}>
                  <Card
                    className={`${
                      selectedPlan === 'MONTHLY' ? 'selected-plan' : ''
                    }`}
                    onClick={() => {
                      setSelectedPlan('MONTHLY');
                    }}
                  >
                    <h2>Monthly</h2>
                    <h1>$ {monthlyAmount}</h1>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    className={`${
                      selectedPlan === 'YEARLY' ? 'selected-plan' : ''
                    }`}
                    onClick={() => {
                      setSelectedPlan('YEARLY');
                    }}
                  >
                    <h2>Yearly</h2>
                    <h1>$ {yearlyAmount}</h1>
                  </Card>
                </Col>
              </Row>
              <Divider />
              <div>
                <h2 className="font-700">You will have access to :</h2>
                <Row gutter={20} className="access-list">
                  <Col span={`${isMobileViewPort ? 12 : 24}`}>
                    <p>
                      <Checked /> The BlackUp AI Platform
                    </p>
                    <p>
                      <Checked /> AI instant candidates matching
                    </p>
                    <p>
                      <Checked /> Diversity analytics
                    </p>
                    <p>
                      <Checked /> Quarterly reports and reviews
                    </p>
                  </Col>
                  <Col span={`${isMobileViewPort ? 12 : 24}`}>
                    <p>
                      <Checked /> The BlackUp pool of Black professionals
                    </p>
                    <p>
                      <Checked /> Unlimited job posting
                    </p>
                    <p>
                      <Checked /> Black diversity data, metrics
                    </p>
                    <p>
                      <Checked /> Training videos for Black employees
                    </p>
                  </Col>
                </Row>
              </div>
              <Card className="note-card">
                <h3>Please Note:</h3>
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua{' '}
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua
                  </li>
                  <li>
                    Terms are upfront payments to access the BlackUp Platform.
                  </li>
                  <li>All fees are in US dollars.</li>
                </ul>
              </Card>
              <Button
                className="purchase-button"
                htmlType="submit"
                loading={sessionLoading}
              >
                Purchase Now
              </Button>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default PaymentModal;
