import { EnvironmentOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Col, Divider, Result, Space, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { JOB_WORK_TYPE, ROUTES, SKIP_RECORD } from '../../../common/constants';
import { getLocationStrFromLocationArray } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import { UNARCHIVE_JOB } from '../graphql/Mutation';
import { GET_ARCHIVE_JOB } from '../graphql/Queries';
import './ArchiveJob.less';

const Archive = () => {
  const archiveJobInitialFilter = {
    skip: 0,
    limit: SKIP_RECORD
  };
  // Archive job
  const [loader, setLoader] = useState(true);
  const [archiveJobData, setArchiveJobData] = useState([]);
  const [jobsDebounce, setJobsDebounce] = useState(false);
  const [jobsIsEnd, setJobsIsEnd] = useState(false);
  const [getArchiveJobs] = useLazyQuery(GET_ARCHIVE_JOB, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.archiveJob?.length < SKIP_RECORD) {
        setJobsIsEnd(true);
      }
      setArchiveJobData([...archiveJobData, ...res?.archiveJob]);
      setLoader(false);
    },
    onError() {
      setLoader(false);
    }
  });

  const [unarchiveJob] = useMutation(UNARCHIVE_JOB);

  useEffect(() => {
    getArchiveJobs({
      variables: {
        filter: archiveJobInitialFilter
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    if (!jobsIsEnd) {
      setLoader(true);
      getArchiveJobs({
        variables: {
          filter: {
            limit: SKIP_RECORD,
            skip: (jobsDebounce + 1) * SKIP_RECORD
          }
        }
      });
      setJobsDebounce((prevState) => prevState + 1);
    }
  };

  return (
    <div className="archive">
      <div>
        <Typography.Title className="heading">Archive Jobs</Typography.Title>
        {/* <p>Based on your profile and search history</p> */}
      </div>
      <Divider />
      <LoaderComponent spinning={loader} />
      {!loader && archiveJobData?.length === 0 && (
        <Result status="404" title="No Data" />
      )}
      {map(archiveJobData, (item) => {
        return (
          <Col span={22} className="margin-bottom" key={item}>
            <Card
              className="shadow"
              type="inner"
              title={
                <Space direction="vertical">
                  <Typography.Title level={5} className="heading">
                    {item.title}
                  </Typography.Title>
                  <Space className="noMobileFlex">
                    <span>
                      <EnvironmentOutlined style={{ color: '#34A853' }} />{' '}
                      <Typography.Text
                        strong
                        style={{ paddingLeft: '5px', color: '#34A853' }}
                      >
                        {' '}
                        {JOB_WORK_TYPE[item?.workType]} :{' '}
                      </Typography.Text>
                      <Typography.Text style={{ paddingLeft: '5px' }}>
                        {getLocationStrFromLocationArray(item?.location)}
                      </Typography.Text>
                    </span>
                  </Space>
                </Space>
              }
              extra={
                <div className="viewDetailWeb">
                  <Button
                    className="archive-btn"
                    onClick={() => {
                      confirm({
                        okText: 'Yes',
                        title: 'Are you sure you want to unarchive this job?',
                        centered: true,
                        cancelText: 'No',
                        onOk: () => {
                          unarchiveJob({
                            variables: {
                              id: item.id
                            }
                          }).then(() => {
                            history.push(`${ROUTES.MY_JOB}`);
                          });
                        }
                      });
                    }}
                    icon={<EyeInvisibleOutlined twoToneColor="#3B7DDD" />}
                  >
                    Unarchive
                  </Button>
                </div>
              }
            >
              <div>
                <h3>Qualifications:</h3>
                <ul>
                  <Space direction="vertical">
                    <Typography.Paragraph
                      type="secondary"
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: 'more'
                      }}
                    >
                      {item?.preferredQualification}
                    </Typography.Paragraph>
                  </Space>
                </ul>

                <div className="align-center-text viewDetailMobile">
                  <div>
                    <Button
                      className="archive-btn"
                      icon={<EyeInvisibleOutlined twoToneColor="#3B7DDD" />}
                    >
                      Unarchive
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        );
      })}
      {!loader && !jobsIsEnd && (
        <div className="loadMoreButton">
          <Button className="follow-btn" onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default Archive;
