/* eslint-disable jsx-a11y/media-has-caption */
import {
  DeleteOutlined,
  EllipsisOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Divider, message, Popover, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import commentSvg from '../../../assets/images/comment.svg';
import likeFillSvg from '../../../assets/svg/like-fill.svg';
import likeSvg from '../../../assets/svg/like.svg';
import { ANALYTICS_EVENTS, ROUTES } from '../../../common/constants';
import { analytics } from '../../auth/Firebase';
import { DELETE_POST, LIKE_POST } from '../../feed/graphql/Mutations';
import { GET_POSTS } from '../../feed/graphql/Queries';

const CommonPostCard = ({ postObj, removePost, updatePost }) => {
  const [deletePost] = useMutation(DELETE_POST, {
    onCompleted: () => {},
    onError: () => {}
  });
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const history = useHistory();
  const [liked, setLiked] = useState(postObj?.isLiked);
  const { Paragraph } = Typography;

  const postInitialFilter = {
    skip: 0,
    limit: 20
  };

  const handleMediaClick = () => {
    analytics.logEvent(ANALYTICS_EVENTS.POST_CLICKED, {
      id: postObj?.id
    });
    history?.push(`${ROUTES?.FEED}/${postObj?.id}`);
  };

  const [likePost] = useMutation(LIKE_POST, {
    onCompleted: () => {},
    refetchQueries: [
      {
        query: GET_POSTS,
        variables: {
          filters: postInitialFilter
        }
      }
    ]
  });

  const handleLike = () => {
    setLiked(!liked);
    if (liked) {
      likePost({
        variables: {
          where: {
            id: postObj?.id
          },
          action: 'UNLIKE'
        }
      });
      updatePost({
        ...postObj,
        likeCount: postObj?.likeCount - 1,
        isLiked: false
      });
    } else {
      analytics.logEvent(ANALYTICS_EVENTS.LIKE);
      likePost({
        variables: {
          where: {
            id: postObj?.id
          },
          action: 'LIKE'
        }
      });
      updatePost({
        ...postObj,
        likeCount: postObj?.likeCount + 1,
        isLiked: true
      });
    }
    // debouncedLikeHandler();
  };
  const handleDeletePost = () => {
    deletePost({
      variables: {
        where: {
          id: postObj?.id
        }
      }
    });
    removePost(postObj?.id);
  };

  const renderActionButtons = () => {
    return (
      <div className="d-flex flex-vertical">
        {postObj?.createdBy?.id === currentUser?.id && (
          <Button
            id="brand-table-edit-btn"
            className="b-0"
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => {
              confirm({
                okText: 'Yes, Continue',
                content: 'Are you sure you want to delete?',
                centered: true,
                cancelText: 'No',
                onOk: () => {
                  handleDeletePost();
                }
              });
            }}
          >
            Delete
          </Button>
        )}
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          icon={<InfoCircleOutlined />}
          onClick={() => {
            message.info('Thank you for reporting');
            analytics.logEvent(ANALYTICS_EVENTS.REPORT_POST);
          }}
        >
          Report
        </Button>
      </div>
    );
  };

  return (
    <div className="common-post-card-wrapper">
      <Card className="common-post-card">
        {postObj?.text && !postObj?.mediaUrl && (
          <div
            className="image-text pointer"
            onClick={() => handleMediaClick()}
          >
            <Paragraph
              ellipsis={{
                rows: 10,
                expandable: true,
                symbol: 'more'
              }}
            >
              {postObj?.text}
            </Paragraph>
          </div>
        )}
        {postObj?.mediaUrl && postObj?.mediaType === 'PHOTO' && (
          <div className="post-image pointer">
            <img
              onClick={() => handleMediaClick()}
              src={postObj?.mediaUrl}
              // onError={(e) => {
              //   e.target.src = textImage;
              // }}
              alt={postObj?.mediaUrl}
            />
          </div>
        )}

        {postObj?.mediaUrl && postObj?.mediaType === 'VIDEO' && (
          <div
            onClick={() => handleMediaClick()}
            className="post-video pointer"
          >
            <video className="video" type="video/mp4" controls>
              <source src={postObj?.mediaUrl} />
            </video>
          </div>
        )}
        <div>
          <div className="like-section d-flex justify-between">
            <div className="d-flex justify-center align-center">
              <div className="mr-10">
                <img
                  className="image mr-5"
                  src={liked ? likeFillSvg : likeSvg}
                  onClick={() => handleLike(liked)}
                  alt="like"
                />
                <span className="info-section">{postObj?.likeCount}</span>
              </div>
              <img className="image mr-5" src={commentSvg} alt="lik" />
              <span className="info-section">{postObj?.commentCount} </span>
            </div>
            <div>
              <Popover
                placement="bottom"
                overlayClassName="action-button"
                content={renderActionButtons()}
                className="pointer"
              >
                <EllipsisOutlined />
              </Popover>
            </div>
          </div>
          <Divider className="post-divider" />

          <div className="bio-section">
            {postObj?.commentCount > 0 ? (
              <Paragraph
                ellipsis={{
                  rows: 4,
                  expandable: true,
                  symbol: 'more'
                }}
                onClick={() => handleMediaClick()}
              >
                {postObj?.lastComment}
              </Paragraph>
            ) : (
              'No Comments Yet'
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CommonPostCard;
