import { Button } from 'antd';
import firebase from 'firebase/compat/app';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import logo from '../../assets/logo.png';
import { BREAKPOINTS, ROUTES } from '../../common/constants';

const UnderReview = () => {
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);
  const history = useHistory();
  return (
    <div
      className={`${
        isMobileViewPort
          ? 'under-review d-flex'
          : 'under-review d-flex flex-vertical'
      }`}
    >
      {isMobileViewPort && (
        <div className="under-review-image-text">
          <img src={logo} alt="logo" className="logo" />
          <h1>
            Sophisticated, non-biased A.I. & M.L. algorithm that matches job
            openings to Black professional applicants.
          </h1>
        </div>
      )}
      <div className="under-review-detail-section">
        <div className="d-flex justify-between align-center">
          <h1>Account Under Review</h1>

          <div className="mb-0 pointer font-18">
            <Button
              type="link"
              className="font-18 mobile-create-account-button"
              onClick={() => {
                firebase.auth().signOut();
                // eslint-disable-next-line no-undef
                localStorage.clear();
                history.push(ROUTES.COMPANY_LOGIN);
              }}
            >
              Logout
            </Button>
          </div>
        </div>
        <p>
          Make the world more equitable by creating jobs, events, and
          communities that celebrate diversity.
        </p>

        <div>
          <p className="under-review-text">
            We will email you once your account is approved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnderReview;
