import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import NewJob from '../companyJobs/component/NewJob';
import IndividualMyJob from './component/IndividualMyJob';
import MyJobs from './component/MyJobs';

const MyJobsWrapper = () => {
  return (
    <div>
      <Switch>
        <>
          <Route exact path={ROUTES?.MY_JOB} component={MyJobs} />
          <Route
            exact
            path={ROUTES?.INDIVIDUAL_MY_JOB}
            component={IndividualMyJob}
          />
          <Route
            exact
            path={ROUTES?.INDIVIDUAL_MY_JOB_EDIT}
            component={NewJob}
          />
        </>
      </Switch>
    </div>
  );
};

export default MyJobsWrapper;
