/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { SearchOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Avatar, Divider, Modal, Popover } from 'antd';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import chatIcon from '../../../assets/images/chatIcon.svg';
import notification from '../../../assets/images/notification.svg';
import placeholder from '../../../assets/images/placeholder.jpg';
import chatDotIcon from '../../../assets/svg/chatDotIcon.svg';
import mobileSearch from '../../../assets/svg/mobileSearch.svg';
import notificationDot from '../../../assets/svg/notificationDot.svg';
import {
  ANALYTICS_EVENTS,
  BREAKPOINTS,
  ROUTES
} from '../../../common/constants';
import { GET_COMPANY, GET_USER_ME } from '../../../common/graphql/Queries';
import LoaderComponent from '../../../components/LoaderComponent';
import SearchComponent from '../../../components/SearchComponent';
import history from '../../../historyData';
import { analytics } from '../../../modules/auth/Firebase';
import { ADD_NOTIFICATION_TO_SEEN } from '../graphql/Mutation';
import { GET_FLAGS, GET_NOTIFICATIONS } from '../graphql/Queries';
import NotificationCard from './NotificationCard';

const UserProfile = () => {
  const {
    dispatch,
    getCurrentUser,
    state: { showDrawer }
  } = useContext(AppContext);
  // eslint-disable-next-line no-undef
  const isCompany = localStorage.getItem('isCompanyVerified');
  const path = history?.location?.pathname;
  const userData = getCurrentUser();
  const [visible, setVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [messageFlag, setMessageFlag] = useState(false);
  const [profileImage, setProfileImage] = useState(placeholder);
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);
  const [showModal, setShowModal] = useState(false);
  const [getUser, { data }] = useLazyQuery(GET_USER_ME, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setProfileImage(res?.userMe?.profileImage);
    }
  });

  const [notificationFlag, setNotificationFlag] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [getFlags] = useLazyQuery(GET_FLAGS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setNotificationFlag(res?.getFlags?.notificationFlag);
      setMessageFlag(res?.getFlags?.messageFlag);
    }
  });

  const [addNotificationToSeen] = useMutation(ADD_NOTIFICATION_TO_SEEN, {
    onCompleted() {
      setNotificationFlag(false);
    }
  });

  const [getNotification, { loading }] = useLazyQuery(GET_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network-only',
    onCompleted: (res) => {
      setNotificationData(res?.notifications);
    }
  });

  const [getCompany, { data: companyData }] = useLazyQuery(GET_COMPANY, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setProfileImage(res?.companyMe?.profileImage);
    }
  });

  useEffect(() => {
    if (isCompany) {
      getCompany();
    } else {
      getUser();
    }

    getNotification();
    getFlags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompany]);

  const handleVisibleChange = (value) => {
    setVisible(value);
  };

  // eslint-disable-next-line no-unused-vars
  const handleNotificationVisibleChange = async (value) => {
    if (value) {
      analytics.logEvent(ANALYTICS_EVENTS.NOTIFICATION_VIEWED, {
        as: isCompany ? 'COMPANY' : 'CANDIDATE'
      });
    }
    setNotificationVisible(value);

    await addNotificationToSeen();
    if (notificationFlag === false) getFlags();
  };

  const handleRedirectProfile = () => {
    if (isCompany) {
      history?.push(`${ROUTES?.COMPANY_PROFILE_JOBS}`);
      setVisible(false);
    } else {
      history.push(ROUTES.PROFILE_APPLIED_JOBS);
      setVisible(false);
    }
  };

  const name = isCompany
    ? companyData?.companyMe?.name
    : `${data?.userMe?.firstName} ${data?.userMe?.lastName}`;
  const title =
    data?.userMe?.experience?.length > 0
      ? data?.userMe?.experience[0]?.title
      : '';
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          setVisible(false);
          handleRedirectProfile();
        }}
      >
        <div className="user-div">
          <Avatar
            src={<img src={profileImage} alt="profile" style={{ width: 32 }} />}
            className="gx-size-35 gx-pointer icon mr-10"
            alt="Avatar"
          />
          <div>
            <h4 className="d-flex font-600 mb-0">
              {name?.length > 12 ? `${name?.substring(0, 12)}...` : name}
            </h4>
            <p className="mb-0">
              {title?.length > 12 ? `${title?.substring(0, 12)}...` : title}
            </p>
          </div>
        </div>
      </li>
      <Divider className="m-10" />
      <div className="mt-10">
        <li onClick={() => handleRedirectProfile()}>Profile</li>
        <li
          onClick={() => {
            history.push(ROUTES.PRIVACY_POLICY);
            setVisible(false);
          }}
        >
          Privacy Policy
        </li>
        <li
          onClick={() => {
            firebase.auth().signOut();
            // eslint-disable-next-line no-undef
            localStorage.clear();
            if (isCompany) {
              history.push(ROUTES.COMPANY_LOGIN);
            } else {
              history.push(ROUTES.LOGIN);
            }
          }}
          className="logout"
        >
          Logout
        </li>
      </div>
    </ul>
  );

  const notificationList = (
    <div className="notification-content">
      <h2 className="font-600">Notification</h2>
      <Divider style={{ margin: '5px 0' }} />
      {loading ? (
        <LoaderComponent setHeight={10} />
      ) : (
        <>
          {map(notificationData, (item) => {
            return (
              <NotificationCard
                setNotificationVisible={setNotificationVisible}
                data={item}
                key={item?.id}
              />
            );
          })}
        </>
      )}
    </div>
  );

  useEffect(() => {
    if (path === ROUTES?.FEED || path === ROUTES?.COMPANY_FEED) {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: true, profileId: '' }
      });
    } else {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: false, profileId: '' }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDrawer = () => {
    if (!showDrawer) {
      analytics.logEvent(ANALYTICS_EVENTS.NOTIFICATION_VIEWED, {
        as: isCompany ? 'COMPANY' : 'CANDIDATE'
      });
    }

    // eslint-disable-next-line no-undef
    if (window.innerWidth < 1000) {
      history.push(ROUTES.CHAT);
    } else {
      dispatch({
        type: 'SET_DRAWER',
        data: {
          showDrawer: !showDrawer,
          profileId: ''
        }
      });
    }
  };

  const handleSearch = (e) => {
    const value = e.trim();
    // eslint-disable-next-line no-undef
    const { pathname } = window.location;
    let key = isCompany ? ROUTES.SEARCH_PEOPLE : ROUTES.SEARCH_JOB;
    if (pathname.includes(ROUTES.SEARCH_PEOPLE)) {
      key = ROUTES.SEARCH_PEOPLE;
    } else if (pathname.includes(ROUTES.SEARCH_COMPANY)) {
      key = ROUTES.SEARCH_COMPANY;
    }
    if (value) {
      analytics.logEvent(ANALYTICS_EVENTS.SEARCH, {
        as: isCompany ? 'COMPANY' : 'CANDIDATE',
        value
      });
      history.replace('');
      history.replace(`${key}?query=${value}`);
    } else {
      history.push(ROUTES.FEED);
    }
    setShowModal(false);
  };

  return (
    <div className="d-flex right-section">
      {showModal && (
        <Modal
          footer={null}
          centered
          title="Search"
          visible={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
        >
          <SearchComponent
            prefix={<SearchOutlined />}
            getData={handleSearch}
            className="height-50"
            placeholder="Search"
          />
        </Modal>
      )}
      {isMobileViewPort && (
        <SearchComponent
          prefix={<SearchOutlined />}
          getData={handleSearch}
          className={
            path !== ROUTES?.FEED
              ? 'search-section search-style'
              : 'search-style'
          }
          placeholder="Search"
        />
      )}
      <div
        className={`${
          isMobileViewPort
            ? 'd-flex justify-center align-center justify-around icon-section'
            : 'd-flex justify-center align-center justify-around mr-0'
        }`}
      >
        {!isMobileViewPort && (
          <img
            height={20}
            width={20}
            onClick={() => {
              setShowModal(true);
            }}
            src={mobileSearch}
            className={isMobileViewPort ? 'display-none' : 'mr-10'}
            alt="chat"
          />
        )}
        {isCompany ? (
          <img
            height={20}
            width={20}
            onClick={() => {
              openDrawer();
              if (messageFlag === false) {
                getFlags();
              }
              setMessageFlag(false);
            }}
            src={messageFlag ? chatDotIcon : chatIcon}
            className={
              path === ROUTES?.COMPANY_FEED && isMobileViewPort
                ? 'display-none'
                : 'mr-10'
            }
            alt="chat"
          />
        ) : (
          <img
            height={20}
            width={20}
            onClick={() => {
              openDrawer();
              if (messageFlag === false) {
                getFlags();
              }
              setMessageFlag(false);
            }}
            src={messageFlag ? chatDotIcon : chatIcon}
            className={
              path === ROUTES?.FEED && isMobileViewPort
                ? 'display-none'
                : 'mr-10'
            }
            alt="chat"
          />
        )}
        {/* <div className="notification-popover"> */}
        <Popover
          className="d-flex flex-row align-center"
          placement="bottomRight"
          content={notificationList}
          trigger="click"
          visible={notificationVisible}
          overlayClassName="notification-popover"
          onVisibleChange={handleNotificationVisibleChange}
        >
          <img
            height={20}
            width={20}
            src={notificationFlag ? notificationDot : notification} // TODO: change to notification icon
            className="icon mr-10"
            alt="notification"
          />
        </Popover>
        {/* </div> */}

        <Popover
          className="d-flex flex-row align-center user-profile-popover"
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <Avatar
            src={<img src={profileImage} alt="profile" style={{ width: 32 }} />}
            className="gx-size-35 gx-pointer icon"
            alt="Avatar"
          />
        </Popover>
      </div>
    </div>
  );
};

export default UserProfile;
