import { LoadingOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { map } from 'lodash';
import React from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import CommonJobCard from './CommonJobCard';

const CompanyJobs = ({ loading, data }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="recommend-wrapper">
      <div className="recommend-section">
        <div className="header-section ">
          <span> My Jobs </span>
        </div>
        <Divider className="recommend-divider" />
        <LoaderComponent spinning={loading} indicator={antIcon}>
          <div className="job-section">
            {map(data, (item) => {
              return (
                <div key={item.id} className="single-job">
                  <CommonJobCard jobData={item} />
                </div>
              );
            })}
          </div>
        </LoaderComponent>
      </div>
    </div>
  );
};

export default CompanyJobs;
