/* eslint-disable no-console */
import { Button, Card, Divider } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import moment from 'moment';
import React from 'react';
import placeholder from '../../../assets/images/placeholder.jpg';
import { Map } from '../../../assets/svg';
import {
  ANALYTICS_EVENTS,
  JOB_APPLICATION_STATUS,
  JOB_WORK_TYPE
} from '../../../common/constants';
import { getLocationStrFromLocationArray } from '../../../common/utils';
import history from '../../../historyData';
import { analytics } from '../../auth/Firebase';
import './Applied.less';

const JobCardForUser = ({ item }) => {
  const location = getLocationStrFromLocationArray(item?.location);
  const handleClick = (id) => {
    analytics.logEvent(ANALYTICS_EVENTS.VIEW_JOB, {
      jobId: id,
      title: item?.title
    });
    history.push(`/job/${id}`);
  };
  return (
    <div>
      <Card
        key={item}
        className="applied-card pointer"
        type="inner"
        onClick={() => {
          handleClick(item?.id);
        }}
        title={
          <div className="d-flex flex-vertical">
            <h2 className="font-600">{item?.title}</h2>
            <div className="noMobileFlex d-flex align-center">
              <p className="mb-0 black-color">
                <img
                  src={item?.createdBy?.profileImage || placeholder}
                  alt="profile"
                  className="profile mr-10"
                />
                {item?.createdBy?.name}
              </p>
              <div className="d-flex">
                <Divider type="vertical" className="applied-divider" />
                <Map className="map mr-5" width="18px" height="18px" />{' '}
                <p className="mb-0 black-color mr-5">
                  {' '}
                  {JOB_WORK_TYPE[item?.workType]} :{' '}
                </p>
                <p className="mb-0">{location}</p>
              </div>
              <div className="d-flex">
                <Divider type="vertical" className="applied-divider" />
                <p className="mb-0 green-color font-600">
                  {moment(item?.createdAt).fromNow()}
                </p>
              </div>
            </div>
          </div>
        }
        extra={
          <>
            {item?.application?.isApplied ? (
              <Button
                type="primary"
                className="viewDetailWeb font-16 font-600 applied-btn"
              >
                Applied
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  handleClick(item?.id);
                }}
                className="viewDetailWeb font-16 font-600 view-detail-btn"
              >
                View Details
              </Button>
            )}
          </>
        }
      >
        <div>
          <h3 className="black-color">Qualifications:</h3>
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: 'more'
            }}
          >
            {item?.minQualification}
          </Paragraph>

          <div className="align-center">
            <>
              {item?.application?.isApplied ? (
                <Button
                  type="primary w-100"
                  className="viewDetailMobile applied-btn"
                >
                  Applied
                </Button>
              ) : (
                <Button
                  type="primary w-100"
                  onClick={() => {
                    handleClick(item?.id);
                  }}
                  className="viewDetailMobile view-detail-btn"
                >
                  View Details
                </Button>
              )}
            </>
          </div>

          <div
            style={{
              display: item?.application?.isApplied ? 'block' : 'none'
            }}
            className="application-status-div viewDetailWeb"
          >
            <p className="black-color mb-0">
              Application Status :{' '}
              <span>{JOB_APPLICATION_STATUS[item?.application?.status]}</span>
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default JobCardForUser;
