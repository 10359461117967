import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import CompanyAboutPage from './pages/CompanyAboutPage';
import CompanyFollowerPage from './pages/CompanyFollowerPage';
import CompanyJobPage from './pages/CompanyJobPage';
import CompanyReviewPage from './pages/CompanyReviewPage';

const CompanyTabs = ({ companyId }) => {
  const { TabPane } = Tabs;
  const { pathname } = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState(ROUTES.PROFILE_APPLIED_JOBS);

  useEffect(() => {
    if (pathname?.includes(ROUTES.COMPANY_PROFILE_JOBS)) {
      setActiveKey(ROUTES.COMPANY_PROFILE_JOBS);
    } else if (pathname?.includes(ROUTES.COMPANY_PROFILE_FOLLOWERS)) {
      setActiveKey(ROUTES.COMPANY_PROFILE_FOLLOWERS);
    } else if (pathname?.includes(ROUTES.COMPANY_PROFILE_FOLLOWERS)) {
      setActiveKey(ROUTES.COMPANY_PROFILE_FOLLOWERS);
    } else {
      setActiveKey(ROUTES.COMPANY_PROFILE_ABOUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="user-tabs">
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          let appendId = '';
          if (companyId) {
            appendId = `/${companyId}`;
          }
          setActiveKey(key);
          history.replace(key + appendId);
        }}
        className="mt-12"
      >
        {companyId && (
          <TabPane tab="About" key={ROUTES.COMPANY_PROFILE_ABOUT} forceRender>
            <CompanyAboutPage companyId={companyId} />
          </TabPane>
        )}
        <TabPane tab="Jobs" key={ROUTES.COMPANY_PROFILE_JOBS}>
          <div className="company-job">
            <CompanyJobPage companyId={companyId} />
          </div>
        </TabPane>
        <TabPane tab="Reviews" key={ROUTES.COMPANY_PROFILE_REVIEW} forceRender>
          <div className="review">
            <CompanyReviewPage />
          </div>
        </TabPane>
        {!companyId && (
          <TabPane
            tab="Followers"
            key={ROUTES.COMPANY_PROFILE_FOLLOWERS}
            forceRender
          >
            <CompanyFollowerPage />
          </TabPane>
        )}
        {!companyId && (
          <TabPane tab="About" key={ROUTES.COMPANY_PROFILE_ABOUT} forceRender>
            <CompanyAboutPage />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default CompanyTabs;
