import {
  CalendarOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  PlusOutlined,
  ReadOutlined,
  ShoppingOutlined,
  SnippetsOutlined
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Card, Col, Row, Space } from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { defaultDateFormat, JOB_TYPE, ROUTES } from '../../../common/constants';
import { GET_USER_ME } from '../../../common/graphql/Queries';
import { getLocationStrFromLocationObj } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import AboutCard from './AboutCard';

const About = ({ data }) => {
  const [loader, setLoader] = useState(false);
  const [userAboutData, setUserAboutData] = useState(data);
  const { id: userId } = useParams();

  const [getUserAboutMe] = useLazyQuery(GET_USER_ME, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      setUserAboutData(res?.userMe);
      setLoader(false);
    }
  });

  useEffect(() => {
    if (!userId) {
      setLoader(true);
      getUserAboutMe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  if (loader) return <LoaderComponent />;
  const skills = userAboutData?.skills || [];

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%'
      }}
    >
      {(userAboutData?.experience?.length > 0 || !userId) && (
        <div>
          <div>
            <h2>Experience</h2>
          </div>
          <Row>
            {!userId && (
              <Col
                role="button"
                span={6}
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                className="outer-card"
              >
                <div className="shadow">
                  <Card
                    className="adder"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      // eslint-disable-next-line no-undef
                      window.location.href = `${ROUTES?.PROFILE_EDIT}#experience`;
                    }}
                  >
                    <PlusOutlined size={10} twoToneColor="#000" />
                    <div>
                      <h3>Add Experience</h3>
                    </div>
                  </Card>
                </div>
              </Col>
            )}
            {map(userAboutData?.experience, (item) => {
              return (
                <Col
                  span={6}
                  key={item.id}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                  className="outer-card"
                >
                  <AboutCard
                    title={item?.title}
                    data={[
                      {
                        icon: (
                          <span>
                            <ShoppingOutlined />
                          </span>
                        ),
                        info: (
                          <span>
                            <strong>{item.company}</strong> (
                            {JOB_TYPE[item.type]})
                          </span>
                        )
                      },
                      {
                        icon: (
                          <span>
                            <CalendarOutlined />
                          </span>
                        ),
                        info: (
                          <span>
                            {moment(item?.startDate).format('MMM YYYY')}
                            {' - '}
                            {item?.isCurrent
                              ? 'Present'
                              : moment(item?.endDate).format('MMM YYYY')}{' '}
                            (
                            {item?.isCurrent
                              ? moment(item?.startDate).fromNow(true)
                              : moment(item?.startDate).from(
                                  moment(item?.endDate)
                                )}
                            )
                          </span>
                        )
                      },
                      {
                        icon: (
                          <span>
                            <EnvironmentOutlined />
                          </span>
                        ),
                        info: (
                          <span>
                            {getLocationStrFromLocationObj(item?.location)}
                          </span>
                        )
                      }
                    ]}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      )}
      {(userAboutData?.education.length > 0 || !userId) && (
        <div>
          <div>
            <h2>Education</h2>
          </div>
          <Row>
            {!userId && (
              <Col
                role="button"
                span={6}
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                className="outer-card"
              >
                <div className="shadow">
                  <Card
                    className="adder"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      // eslint-disable-next-line no-undef
                      window.location.href = `${ROUTES?.PROFILE_EDIT}#education`;
                    }}
                  >
                    <PlusOutlined size={10} twoToneColor="#000" />
                    <div>
                      <h3>Add Education</h3>
                    </div>
                  </Card>
                </div>
              </Col>
            )}
            {map(userAboutData?.education, (item) => {
              return (
                <Col
                  span={6}
                  key={item.id}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                  className="outer-card"
                >
                  <AboutCard
                    profileImage="https://joeschmoe.io/api/v1/random"
                    title={item?.school}
                    data={[
                      {
                        icon: (
                          <span>
                            <ReadOutlined />
                          </span>
                        ),
                        info: (
                          <span>
                            <strong>{item?.degree}</strong>
                          </span>
                        )
                      },
                      {
                        icon: (
                          <span>
                            <HomeOutlined />
                          </span>
                        ),
                        info: (
                          <span>
                            <strong>{item?.fieldOfStudy}</strong>
                          </span>
                        )
                      },
                      {
                        icon: (
                          <span>
                            <CalendarOutlined />
                          </span>
                        ),
                        info: (
                          <span>
                            {moment(item?.startDate).format('MMM YYYY')}
                            {' - '}
                            {item?.isCurrent
                              ? 'Present'
                              : moment(item?.endDate).format('MMM YYYY')}{' '}
                            (
                            {item?.isCurrent
                              ? moment(item?.startDate).fromNow(true)
                              : moment(item?.startDate).from(
                                  moment(item?.endDate)
                                )}
                            )
                          </span>
                        )
                      }
                    ]}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      )}
      {(skills?.length > 0 || !userId) && !userId && (
        <div>
          <div>
            <h2>Skills</h2>
          </div>
          <Space className="d-flex flex-wrap" style={{ padding: '10px' }}>
            {!userId && (
              <div
                onClick={() => {
                  // eslint-disable-next-line no-undef
                  window.location.href = `${ROUTES?.PROFILE_EDIT}#skills`;
                }}
              >
                <Button className="btn-grey btn-adder" icon={<PlusOutlined />}>
                  Add Skill
                </Button>
              </div>
            )}
            {skills.map((skill) => (
              <div key={skill}>
                <Button className="btn-grey">{skill}</Button>
              </div>
            ))}
          </Space>
        </div>
      )}
      {skills?.length > 0 && userId && (
        <div>
          <div>
            <h2>Skills</h2>
          </div>
          <Space className="d-flex flex-wrap" style={{ padding: '10px' }}>
            {skills.map((skill) => (
              <div key={skill}>
                <Button className="btn-grey">{skill}</Button>
              </div>
            ))}
          </Space>
        </div>
      )}
      {!userId && (
        <div>
          <div>
            <h2>Resume/CV</h2>
          </div>
          <div style={{ padding: '10px' }}>
            <Space className="resume-card">
              <span>
                <SnippetsOutlined />
              </span>
              <span>
                {userAboutData?.resumeUpdatedOn
                  ? `Last updated on
                  ${moment(userAboutData?.resumeUpdatedOn).format(
                    defaultDateFormat
                  )}`
                  : 'Resume Not Uploaded'}
              </span>
              <span>
                <Button
                  type="primary"
                  className="btn-follow"
                  onClick={() => {
                    // eslint-disable-next-line no-undef
                    window.location.href = `${ROUTES?.PROFILE_EDIT}#skills`;
                  }}
                >
                  Update
                </Button>
              </span>
            </Space>
          </div>
        </div>
      )}
      <div className="space-large-v" />
    </Space>
  );
};

export default About;
