import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const APPLY_TO_JOB = gql`
  mutation applyToJob($id: String!) {
    applyToJob(where: { id: $id }) {
      message
    }
  }
`;
