import {
  EllipsisOutlined,
  LeftOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { Avatar, Button, Col, Form, Image, Input, Row, Upload } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import axios from 'axios';
import { debounce, map, split, trim } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { Attach, Send } from '../../../assets/svg';
import {
  MEDIA_TYPE,
  ROUTES,
  SKIP_RECORD,
  USER
} from '../../../common/constants';
import { getBase64 } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import '../chat.less';
import {
  ADD_INTO_READ_BY_MESSAGE,
  GET_ROOM_SIGNED_URL,
  SEND_MESSAGE
} from '../graphql/Mutations';
import {
  GET_ID_FROM_PROFILE_ID,
  GET_MESSAGES,
  GET_ROOM
} from '../graphql/Queries';
import { NEW_MESSAGE } from '../graphql/subscription';

let chatDebounce;

const UserChat = ({ isDrawer, changePage, roomId, direct }) => {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  let { id } = useParams();
  if (roomId) id = roomId;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [userRoom, setUserRoom] = useState([]);
  const [messages, setMessages] = useState([]);
  const [uploadAttachment, setUploadAttachment] = useState();
  const [attachment, setAttachment] = useState();

  // eslint-disable-next-line no-undef
  const { profileId } = JSON.parse(localStorage.getItem(USER));
  const { data: newData } = useSubscription(NEW_MESSAGE, {
    variables: { where: { id: userRoom?.id } }
  });

  const [sendMessage] = useMutation(SEND_MESSAGE);
  const [addIntoReadByMessage] = useMutation(ADD_INTO_READ_BY_MESSAGE);
  const [getRoomSignedUrl] = useMutation(GET_ROOM_SIGNED_URL);
  const [idFromProfileId, setIdFromProfileId] = useState();

  const [getIdFromProfileId] = useLazyQuery(GET_ID_FROM_PROFILE_ID, {
    onCompleted: (res) => {
      setIdFromProfileId(res?.getIdFromProfileId);
    }
  });

  const [isAttachment, setIsAttachment] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [isReachTop, setIsReachTop] = useState(false);

  useEffect(() => {
    if (newData) {
      addIntoReadByMessage({
        variables: { where: { id: newData?.newMessage?.id } }
      });
      if (
        newData?.newMessage?.sender === profileId &&
        !newData?.newMessage?.mediaUrl
      ) {
        return;
      }
      const msgArray = messages.slice();
      msgArray.unshift(newData?.newMessage);
      setMessages(msgArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  const [getMessages] = useLazyQuery(GET_MESSAGES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (loading) setMessages(res?.getMessages);
      setMessages([...messages, ...res?.getMessages]);
      if (res?.getMessages?.length < SKIP_RECORD) {
        setIsReachTop(true);
      }
      setLoading(false);
      setMoreLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });
  const handleScroll = (event) => {
    if (chatDebounce) {
      chatDebounce?.cancel();
    }

    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    chatDebounce = debounce(() => {
      const scrolledToTop =
        Math.abs(scrollTop) + offsetHeight >= scrollHeight - 10;
      if (scrolledToTop && !moreLoading && !isReachTop) {
        setMoreLoading(true);
        getMessages({
          variables: {
            where: { id: userRoom.id },
            filters: {
              skip: messages.length,
              limit: SKIP_RECORD
            }
          }
        });
      }
    }, 500);

    chatDebounce();
  };

  const [getRoom] = useLazyQuery(GET_ROOM, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      setUserRoom(res?.getRoom);
      getMessages({
        variables: {
          where: { id: res?.getRoom?.id },
          filters: {
            skip: 0,
            limit: SKIP_RECORD
          }
        }
      });
    },
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    getRoom({
      variables: {
        where: { id: id }
      }
    });
    getIdFromProfileId({
      variables: {
        profileId: id
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (formValues) => {
    form.setFieldsValue({ message: null });
    const val = trim(formValues?.message);
    let url = '';
    if (formValues?.attachment) {
      setUploadAttachment(true);
      const response = await getRoomSignedUrl({
        variables: {
          data: {
            fileName: formValues?.attachment?.file?.name
          },
          where: { id: userRoom?.id }
        }
      });

      if (response) {
        const uploadImgResponse = await axios.put(
          response?.data?.getRoomSignedUrl?.signedUrl,
          formValues?.attachment?.file,
          {
            headers: {
              'access-control-allow-origin': '*',
              'Content-type': formValues?.attachment?.file?.type
            }
          }
        );
        if (uploadImgResponse.status === 200) {
          url = response?.data?.getRoomSignedUrl?.getUrl;
        }
      }
    }

    if (attachment || val?.length > 0) {
      const message = {
        text: val,
        mediaUrl: url,
        mediaType: MEDIA_TYPE[split(formValues?.attachment?.file?.type, '/')[0]]
      };
      sendMessage({
        variables: {
          where: { id: userRoom?.id },
          data: message
        }
      }).then(() => {
        setIsAttachment(false);
        setAttachment(null);
        setUploadAttachment(false);
        form.resetFields();
      });

      if (!isAttachment) {
        const allMessages = messages.slice();
        allMessages.unshift({
          ...message,
          createdAt: moment().toISOString(),
          sender: profileId
        });
        setMessages(allMessages);
      }
    }
  };

  const openProfile = async () => {
    if (idFromProfileId.role === 'USER')
      history?.push(`${ROUTES?.PROFILE_ABOUT}/${idFromProfileId?.id}`);
    else
      history?.push(`${ROUTES?.COMPANY_PROFILE_ABOUT}/${idFromProfileId?.id}`);
  };

  return (
    <div className="chat-wrapper-user-chat">
      <div className="header-wrapper">
        <div className="header">
          <Row justify="space-between" align="middle">
            <Col>
              <Button
                className="new-msg"
                htmlType="submit"
                icon={<LeftOutlined />}
                onClick={() => {
                  if (isDrawer) {
                    if (!direct) {
                      changePage('CHAT');
                    } else {
                      dispatch({
                        type: 'SET_DRAWER',
                        data: { showDrawer: false, profileId: '' }
                      });
                      dispatch({
                        type: 'SET_DRAWER',
                        data: { showDrawer: true, profileId: '' }
                      });
                    }
                  } else {
                    history.push(ROUTES.CHAT);
                  }
                }}
              />
            </Col>
            <Col>
              <Row
                gutter={10}
                align="middle"
                justify="center"
                style={{ cursor: 'pointer' }}
                onClick={() => openProfile()}
              >
                <Col>
                  {userRoom?.membersData?.[0]?.profileUrl ? (
                    <Avatar
                      className="user-profile"
                      size={32}
                      src={userRoom?.membersData?.[0]?.profileUrl}
                    />
                  ) : (
                    <Avatar
                      className="user-profile-empty"
                      size={32}
                      icon={<UserOutlined />}
                    />
                  )}
                </Col>
                <Col>
                  <div className="title">
                    {userRoom?.membersData?.[0]?.name}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="new-msg"
                htmlType="submit"
                style={{ visibility: 'hidden' }}
                icon={<EllipsisOutlined />}
              />
            </Col>
          </Row>
        </div>
      </div>

      <Content
        className="message-content"
        style={{ bottom: isDrawer ? '122px' : '50px' }}
        onScroll={handleScroll}
      >
        {map(messages, (data) => {
          if (data?.sender === profileId) {
            return (
              <div className="another-user">
                <Row gutter={12} align="middle" justify="end">
                  <Col> {moment(data?.createdAt).format('LT')}</Col>
                  {!data.mediaType && (
                    <Col className="another-user-msg">{data?.text}</Col>
                  )}
                  {data.mediaType && (
                    <Col className="owner-msg owner-msg-image">
                      {data.mediaType === 'PHOTO' ? (
                        <Image className="msg-image" src={data?.mediaUrl} />
                      ) : (
                        // eslint-disable-next-line jsx-a11y/media-has-caption
                        <video className="msg-image" type="video/mp4" controls>
                          <source src={data?.mediaUrl} />
                        </video>
                      )}
                    </Col>
                  )}
                </Row>
              </div>
            );
          }
          return (
            <div className="owner-user">
              <Row gutter={12} align="middle" justify="start">
                <Col>
                  {userRoom?.membersData?.[0]?.profileUrl ? (
                    <Avatar
                      className="user-profile"
                      size={32}
                      src={userRoom?.membersData?.[0]?.profileUrl}
                    />
                  ) : (
                    <Avatar
                      className="user-profile-empty"
                      size={32}
                      icon={<UserOutlined />}
                    />
                  )}
                </Col>
                {!data.mediaType && (
                  <Col className="owner-msg">{data?.text}</Col>
                )}
                {data.mediaType && (
                  <Col className="owner-msg owner-msg-image">
                    {data.mediaType === 'PHOTO' ? (
                      <div
                        className="msg-image"
                        style={{ backgroundImage: `url('${data?.mediaUrl}')` }}
                      />
                    ) : (
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      <video className="msg-image" type="video/mp4" controls>
                        <source src={data?.mediaUrl} />
                      </video>
                    )}
                  </Col>
                )}
                <Col> {moment(data?.createdAt).format('LT')}</Col>
              </Row>
            </div>
          );
        })}
      </Content>
      <div className="chat-footer" style={{ bottom: isDrawer ? '72px' : '0' }}>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row justify="space-between" gutter={12} align="middle">
            <Col className="side-col">
              <Form.Item className="d-flex" name="attachment">
                <Upload
                  beforeUpload={() => {
                    return false;
                  }}
                  accept="image/*,video/*"
                  showUploadList={false}
                  onChange={(info) => {
                    setIsAttachment(true);
                    getBase64(info?.file, (data) => {
                      setAttachment(data);
                      form.submit();
                    });
                  }}
                >
                  <Button className="new-msg" icon={<Attach />} />
                </Upload>
              </Form.Item>
            </Col>
            <Col className="message-col">
              <Form.Item name="message">
                <Input placeholder="Message..." className="user-message" />
              </Form.Item>
            </Col>
            <Col className="side-col">
              <Form.Item>
                <Button className="new-msg" htmlType="submit" icon={<Send />} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {uploadAttachment && (
        <div className="loader-attachment">
          <LoaderComponent />
        </div>
      )}
    </div>
  );
};

export default UserChat;
