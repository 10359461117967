import {
  DeleteOutlined,
  EllipsisOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Divider, message, Popover, Typography } from 'antd';
import { debounce, map, uniq } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import commentSvg from '../../../assets/images/comment.svg';
import placeholder from '../../../assets/images/placeholder.jpg';
import closeSvg from '../../../assets/svg/close.svg';
import likeFillSvg from '../../../assets/svg/like-fill.svg';
import likeSvg from '../../../assets/svg/like.svg';
import { ANALYTICS_EVENTS, SKIP_RECORD } from '../../../common/constants';
import CommonAvatar from '../../../components/CommonAvatar';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import { analytics } from '../../auth/Firebase';
import { DELETE_POST, LIKE_POST, USER_CONNECTION } from '../graphql/Mutations';
import { GET_COMMENTS, GET_POSTS } from '../graphql/Queries';

const postInitialFilter = {
  skip: 0,
  limit: 20
};

const { Paragraph } = Typography;

const initialCommentFilter = {
  skip: 0,
  limit: 20
};

let commentDebounceJob;

const SingleFeed = (props) => {
  const { postData } = props;
  const {
    dispatch,
    state: { currentUser }
  } = useContext(AppContext);

  // eslint-disable-next-line no-undef
  const isCompany = localStorage?.getItem('isCompanyVerified') || false;
  const [liked, setLiked] = useState(postData?.isLiked);
  const [likeCount, setLikeCount] = useState(postData?.likeCount);
  // const [commentCount, setCommentCount] = useState(postData?.commentCount);
  // eslint-disable-next-line no-unused-vars
  const [commentFilter, setCommentFilter] = useState(initialCommentFilter);
  const [comments, setComments] = useState([]);
  const [connectionLoading, setConnectionLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(true);
  const [commentdebounceCall, setCommentdebounceCall] = useState(0);
  const [commentIsEnd, setCommentIsEnd] = useState(false);
  const [showConnectionBtn, setShowConnectionBtn] = useState(
    postData?.createdBy?.userConnection
  );

  const userTitle =
    postData?.createdBy?.experience[
      postData?.createdBy?.experience?.length - 1
    ];

  const [getComments] = useLazyQuery(GET_COMMENTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.comments?.length < SKIP_RECORD) {
        setCommentIsEnd(true);
      }
      const uniqueComments = uniq([...comments, ...res?.comments]);
      setComments(uniqueComments);
      setCommentLoading(false);
    },
    onError() {
      setCommentLoading(false);
    }
  });

  useEffect(() => {
    dispatch({
      type: 'SET_DRAWER',
      data: { showDrawer: false, profileId: '' }
    });
    getComments({
      variables: {
        where: {
          id: postData?.id
        },
        filters: commentFilter
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [userConnection] = useMutation(USER_CONNECTION, {
    onCompleted: () => {
      setShowConnectionBtn(false);
      setConnectionLoading(false);
    },
    refetchQueries: [
      {
        query: GET_POSTS,
        variables: {
          filters: postInitialFilter
        }
      }
    ]
  });

  const [deletePost] = useMutation(DELETE_POST, {
    onCompleted: () => {
      // setLoading(false);
    },
    onError: () => {
      // setLoading(false);
    },
    refetchQueries: [
      {
        query: GET_POSTS,
        variables: {
          filters: postInitialFilter
        }
      }
    ]
  });

  const [likePost] = useMutation(LIKE_POST, {
    onCompleted: () => {
      // setLoading(false);
    },
    refetchQueries: [
      {
        query: GET_POSTS,
        variables: {
          filters: postInitialFilter
        }
      }
    ]
  });

  const handleConnectionRequest = () => {
    setShowConnectionBtn(false);
    setConnectionLoading(true);
    userConnection({
      variables: {
        data: {
          connectedTo: postData?.createdBy?.id
        }
      }
    });
  };

  const handleLike = () => {
    // setLoading(true);
    setLiked(!liked);
    if (liked) {
      likePost({
        variables: {
          where: {
            id: postData?.id
          },
          action: 'UNLIKE'
        }
      });
      setLikeCount(likeCount - 1);
    } else {
      analytics.logEvent(ANALYTICS_EVENTS.LIKE);
      likePost({
        variables: {
          where: {
            id: postData?.id
          },
          action: 'LIKE'
        }
      });
      setLikeCount(likeCount + 1);
    }
  };

  const handleDeletePost = () => {
    // setLoading(true);
    deletePost({
      variables: {
        where: {
          id: postData?.id
        }
      }
    });
  };

  const handleCommentScroll = (event) => {
    if (commentDebounceJob) {
      commentDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    commentDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !commentIsEnd) {
        setCommentLoading(true);
        setCommentdebounceCall((prevState) => prevState + 1);
        getComments({
          variables: {
            filters: {
              ...commentFilter,
              skip: (commentdebounceCall + 1) * SKIP_RECORD
            },
            where: {
              id: postData?.id
            }
          }
        });
      }
    }, 500);

    commentDebounceJob();
  };
  const renderActionButtons = () => {
    return (
      <div className="d-flex flex-vertical">
        {postData?.createdBy?.id === currentUser?.id && (
          <Button
            id="brand-table-edit-btn"
            className="b-0"
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeletePost()}
          >
            Delete
          </Button>
        )}
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          icon={<InfoCircleOutlined />}
          onClick={() => {
            message.info('Thank you for reporting');
            analytics.logEvent(ANALYTICS_EVENTS.REPORT_POST);
          }}
        >
          Report
        </Button>
      </div>
    );
  };

  const renderCommentButtons = () => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          icon={<InfoCircleOutlined />}
          onClick={() => {
            message.info('Thank you for reporting');
            analytics.logEvent(ANALYTICS_EVENTS.REPORT_POST);
          }}
        >
          Report
        </Button>
      </div>
    );
  };

  return (
    <div className="post-detail-wrapper d-flex">
      <div
        className="image-section"
        style={{
          width:
            // eslint-disable-next-line
            window.innerWidth > 769
              ? postData?.mediaUrl
                ? '75%'
                : '0%'
              : '100%'
        }}
      >
        {postData?.mediaUrl && postData?.mediaType === 'PHOTO' && (
          <div className="post-image">
            <div className="post-container">
              <img
                src={postData?.mediaUrl}
                alt={postData?.mediaUrl}
                className="align-center"
              />
            </div>
            <img
              onClick={() => history?.goBack()}
              className="close-icon"
              src={closeSvg}
              alt="close"
            />
          </div>
        )}

        {postData?.mediaUrl && postData?.mediaType === 'VIDEO' && (
          <div className="post-video">
            <img
              onClick={() => history?.goBack()}
              className="close-icon"
              src={closeSvg}
              alt="close"
            />
            <iframe
              title="post-video"
              className="video"
              src={postData?.mediaUrl}
              alt="post"
            />
          </div>
        )}
      </div>
      <div
        className="comment-section"
        style={{
          width:
            // eslint-disable-next-line
          window.innerWidth > 769
              ? postData?.mediaUrl
                ? '25%'
                : '100%'
              : '100%'
        }}
      >
        <div className="comment-header d-flex justify-between">
          <div className="d-flex align-center">
            <CommonAvatar
              src={
                <img
                  src={postData?.createdBy?.profileImage || placeholder}
                  alt="profile"
                />
              }
              size={40}
            />
            <div className="text-section">
              <span className="title">{`${postData?.createdBy?.firstName} ${postData?.createdBy?.lastName}`}</span>
              <span className="sub-title">{userTitle?.title}</span>
            </div>
          </div>
          {!isCompany ? (
            <div className="d-flex align-center">
              {postData?.createdBy?.id !== currentUser?.id &&
              showConnectionBtn === null ? (
                <Button
                  onClick={handleConnectionRequest}
                  className="connect-button"
                  type="primary"
                  loading={connectionLoading}
                >
                  Connect
                </Button>
              ) : (
                ''
              )}
              <Popover
                placement="bottom"
                overlayClassName="action-button"
                content={renderActionButtons}
              >
                <EllipsisOutlined />
              </Popover>
            </div>
          ) : (
            <div className="d-flex align-center">
              <Popover
                placement="bottom"
                overlayClassName="action-button"
                content={renderActionButtons}
              >
                <EllipsisOutlined />
              </Popover>
            </div>
          )}
        </div>
        {postData?.text && (
          <div className="bio-section">
            <Paragraph
              ellipsis={{
                rows: 4,
                expandable: true,
                symbol: 'more'
              }}
            >
              {postData?.text}
            </Paragraph>
          </div>
        )}
        <Divider className="post-divider" />
        <div className="like-section d-flex">
          <div className="d-flex justify-center align-end">
            <img
              className="image"
              // src={postObj?.isLiked ? likeFillSvg : likeSvg}
              src={liked ? likeFillSvg : likeSvg}
              onClick={() => handleLike()}
              alt="like"
              style={{ cursor: 'none' }}
            />
            <span className="info-section">{likeCount}</span>
          </div>
          <div className="ml-20">
            <img
              className="image comment-image"
              src={commentSvg}
              alt="comment"
              style={{ cursor: 'none' }}
            />
            <span className="info-section">
              {postData?.commentCount}{' '}
              {postData?.commentCount > 0 ? 'Comment' : 'Comments'}
            </span>
          </div>
        </div>
        <Divider className="post-divider" />
        <div onScroll={handleCommentScroll} className="comment-list">
          {map(comments, (comment) => {
            return (
              <>
                <div key={comment?.id} className="single-comment">
                  <div className="comment-header">
                    <div className="d-flex align-center">
                      <CommonAvatar
                        src={
                          <img
                            src={
                              comment?.createdBy?.profileImage || placeholder
                            }
                            alt="profile"
                          />
                        }
                        size={24}
                      />
                      <span className="author-name">{`${comment?.createdBy?.firstName} ${comment?.createdBy?.lastName}`}</span>
                    </div>
                    <div>
                      <Popover
                        placement="left"
                        overlayClassName="action-button"
                        content={renderCommentButtons(comment?.id)}
                      >
                        <EllipsisOutlined />
                      </Popover>
                    </div>
                  </div>
                  <div className="comment-content">{comment?.text}</div>
                </div>
              </>
            );
          })}
          {commentLoading && <LoaderComponent setHeight={12} />}
          {comments?.length === 0 && !commentLoading && (
            <div className="ant-result-subtitle">No comments yet.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleFeed;
