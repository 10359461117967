import { useMutation } from '@apollo/client';
import { Button, Divider, Modal } from 'antd';
import React from 'react';
import { CANCEL_MY_SUBSCRIPTION } from '../graphql/Mutation';

const CancelSubscriptionModal = ({ showModal, setShowModal, getUser }) => {
  const [cancelMySubscription, { loading }] = useMutation(
    CANCEL_MY_SUBSCRIPTION
  );
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOk = () => {
    cancelMySubscription().then(() => {
      setShowModal(false);
      getUser();
    });
  };

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      footer={null}
      centered
      className="cancel-subscription-modal"
      // onOk={handleOk}
    >
      <h2>Cancel Subscription</h2>
      <Divider />
      <p>
        This action will result in terminating your subscription for this
        account. You will not be charged for our services from your next billing
        date.
      </p>
      <p>
        <b>Please Note: </b>
        Cancellation is not refundable and it will effective immediately
      </p>
      <Button
        htmlType="submit"
        loading={loading}
        onClick={() => {
          handleOk();
        }}
      >
        Cancel Subscription
      </Button>
    </Modal>
  );
};

export default CancelSubscriptionModal;
