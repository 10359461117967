import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_ARCHIVE_JOB = gql`
  query archiveJob($filter: ArchiveJobFilterInput!) {
    archiveJob(filter: $filter) {
      id
      title
      workType
      location {
        city
        state
        country
      }
      preferredQualification
    }
  }
`;
