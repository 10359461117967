import {
  GlobalOutlined,
  LoadingOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { Button, Divider, Radio, Result } from 'antd';
import { map, remove } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import gallerySvg from '../../../assets/images/gallery.svg';
import placeholder from '../../../assets/images/placeholder.jpg';
import CommonAvatar from '../../../components/CommonAvatar';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import CommonPost from './CommonPost';
import PostModal from './PostModal';

const FeedList = (props) => {
  const { getCurrentUser } = useContext(AppContext);

  const {
    postData,
    postLoading,
    getPosts,
    postFilter,
    setPostFilter,
    scopeLoading,
    setScopeLoading,
    updatePostDataFromChild,
    setScrollFlag
  } = props;

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const onChange = (e) => {
    const { value } = e?.target;
    setScopeLoading(true);
    setScrollFlag(false);
    setPostFilter({
      ...postFilter,
      scope: value
    });
    updatePostDataFromChild([]);
    getPosts({
      variables: {
        filters: { ...postFilter, scope: value }
      }
    });
  };
  const [showModal, setShowModal] = useState(false);
  const [postTitle, setPostTitle] = useState(null);
  const userData = getCurrentUser();

  useEffect(() => {
    getPosts({
      variables: {
        filters: postFilter
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConnectionButton = (createdBy) => {
    const postDataCopy = postData.slice();

    for (let index = 0; index < postData?.length; index += 1) {
      const post = postData[index];
      if (post?.createdBy?.id === createdBy?.id) {
        postDataCopy[index] = { ...post, createdBy };
      }
    }
    updatePostDataFromChild(postDataCopy);
  };

  const addPost = (post) => {
    const postDataCopy = postData.slice();
    postDataCopy.unshift(post);
    updatePostDataFromChild(postDataCopy);
  };

  const removePost = (postId) => {
    const postDataCopy = postData.slice();
    remove(postDataCopy, function (post) {
      if (post?.id === postId) {
        return true;
      }
    });
    updatePostDataFromChild(postDataCopy);
  };

  const updatePost = (post) => {
    const postDataCopy = postData.slice();
    for (let i = 0; i < postDataCopy.length; i += 1) {
      const postObj = postDataCopy[i];
      if (postObj.id === post.id) {
        postDataCopy[i] = post;
        break;
      }
    }
    updatePostDataFromChild(postDataCopy);
  };

  return (
    <div className="upload-post-section-upper ">
      {showModal && (
        <PostModal
          postTitle={postTitle}
          postFilter={postFilter}
          showModal={showModal}
          addPost={addPost}
          setShowModal={setShowModal}
          profileImage={userData?.profileImage}
          placeholder={placeholder}
        />
      )}

      <div className="upload-post-section">
        <div className="d-flex align-center">
          <div>
            <CommonAvatar
              src={
                <img
                  src={userData?.profileImage || placeholder}
                  alt="profile"
                />
              }
              size={50}
            />
          </div>
          <div
            className="d-flex flex-vertical fill-width"
            onClick={() => setShowModal(true)}
          >
            <InputComponent
              onClick={() => setShowModal(true)}
              onChange={(e) => {
                setPostTitle(e?.target?.value);
              }}
              placeholder={`What's happening?`}
              suffix={
                <Button
                  onClick={() => setShowModal(true)}
                  type="primary"
                  shape="round"
                  className="post-button"
                >
                  Post
                </Button>
              }
              className="input-area"
              name="description"
            />
          </div>
        </div>
        <div className="upload-section d-flex justify-start">
          <div
            onClick={() => setShowModal(true)}
            className="d-flex justify-start align-center"
          >
            <img src={gallerySvg} alt="gallery" />
            <span className="upload-button"> Photos/Videos </span>
          </div>
        </div>
      </div>
      <div className="filter-section">
        <span className="label-text">You're Viewing</span>
        <Divider className="filter-divider" type="vertical" />
        <Radio.Group
          className="radio-button"
          onChange={onChange}
          defaultValue="GLOBAL"
        >
          <Radio.Button value="GLOBAL">
            <div className="d-flex align-center">
              <GlobalOutlined className="mr-5" />
              <p className="mb-0 feed-toggle">Global</p>
            </div>
          </Radio.Button>
          <Radio.Button value="CONNECTIONS">
            <div className="d-flex align-center">
              <TeamOutlined className="mr-5" />
              <p className="mb-0 feed-toggle">Connections</p>
            </div>
          </Radio.Button>
        </Radio.Group>
      </div>
      <LoaderComponent
        spinning={scopeLoading || postLoading}
        indicator={antIcon}
      >
        <div className="post-section">
          {postData?.length === 0 && !postLoading && (
            <Result
              status="404"
              title="No Post Uploaded yet"
              extra={
                <Button onClick={() => setShowModal(true)} type="primary">
                  Upload Post
                </Button>
              }
            />
          )}
          {map(postData, (item) => {
            return (
              <div key={item?.id} className="single-post">
                <CommonPost
                  handleConnectionButton={handleConnectionButton}
                  postLoading={postLoading}
                  postFilter={postFilter}
                  postObj={item}
                  updatePost={updatePost}
                  removePost={removePost}
                />
              </div>
            );
          })}
        </div>
      </LoaderComponent>
    </div>
  );
};

export default FeedList;
