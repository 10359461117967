import { LoadingOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { map } from 'lodash';
import React, { useEffect } from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import CommonPost from './CommonPost';

const FeedList = (props) => {
  const {
    postData,
    postLoading,
    getPosts,
    postFilter,
    scopeLoading,
    updatePostDataFromChild
  } = props;

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    getPosts({
      variables: {
        filters: postFilter
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConnectionButton = (createdBy) => {
    const postDataCopy = postData.slice();

    for (let index = 0; index < postData?.length; index += 1) {
      const post = postData[index];
      if (post?.createdBy?.id === createdBy?.id) {
        postDataCopy[index] = { ...post, createdBy };
      }
    }
    updatePostDataFromChild(postDataCopy);
  };

  return (
    <div className="upload-post-section-upper ">
      <LoaderComponent spinning={scopeLoading} indicator={antIcon}>
        <div className="post-section">
          {postLoading && (
            <LoaderComponent indicator={antIcon} size="large" setHeight={20} />
          )}
          {postData?.length === 0 && !postLoading && (
            <Result status="404" title="No Post Uploaded yet" />
          )}
          {map(postData, (item) => {
            return (
              <div key={item?.id} className="single-post">
                <CommonPost
                  handleConnectionButton={handleConnectionButton}
                  postLoading={postLoading}
                  postObj={item}
                />
              </div>
            );
          })}
        </div>
      </LoaderComponent>
    </div>
  );
};

export default FeedList;
