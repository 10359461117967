import { useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import placeholder from '../../../assets/images/placeholder.jpg';
import { ANALYTICS_EVENTS } from '../../../common/constants';
import CommonAvatar from '../../../components/CommonAvatar';
import InputComponent from '../../../components/InputComponent';
import ModalComponent from '../../../components/ModalComponent';
import { analytics } from '../../auth/Firebase';
import { COMMENT_POST } from '../graphql/Mutations';
import { GET_POSTS } from '../graphql/Queries';

const { TextArea } = InputComponent;

const postInitialFilter = {
  skip: 0,
  limit: 20
};

const CommentModal = (props) => {
  const { showModal, setShowModal, postId, setCommentCount } = props;

  const {
    state: { currentUser }
  } = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  // const [getUserInfo] = useLazyQuery(USER_ME, {
  //   onCompleted: () => {
  //     setLoading(false);
  //   },
  //   onError: () => {
  //     setLoading(false);
  //   }
  // });

  const [createComment] = useMutation(COMMENT_POST, {
    onCompleted: () => {
      setLoading(false);
      setCommentCount((prevCount) => prevCount + 1);
      setShowModal(false);
    },
    refetchQueries: [
      {
        query: GET_POSTS,
        variables: {
          filters: postInitialFilter
        }
      }
    ]
  });

  // useEffect(() => {
  //   getUserInfo();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleFinish = (values) => {
    setLoading(true);
    analytics.logEvent(ANALYTICS_EVENTS.COMMENT);
    createComment({
      variables: {
        where: {
          id: postId
        },
        data: {
          text: values?.comment
        }
      }
    });
  };

  return (
    <div>
      <ModalComponent
        className="comment-modal"
        width={576}
        footer={null}
        closable={false}
        visible={showModal}
        maskClosable
        onOk={handleFinish}
        onCancel={handleCancel}
        centered
      >
        <Form onFinish={handleFinish}>
          <div className="comment-wrapper">
            <div className="d-flex flex-horizontal justify-between align-center">
              <span className="main-title">Comment on Post</span>
              <div>
                <Button
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  shape="round"
                >
                  Comment
                </Button>
              </div>
            </div>
            <div className="d-flex align-center mt-25">
              <CommonAvatar
                src={
                  <img
                    src={currentUser?.profileImage || placeholder}
                    alt="profile"
                    onError={(e) => {
                      e.target.src = placeholder;
                    }}
                  />
                }
                size={40}
              />
              <div className="comment-user-info">
                <span className="title">{`${currentUser?.firstName} ${currentUser?.lastName}`}</span>
                <span className="sub-title">Founder & CEO </span>
              </div>
            </div>
            <div className="comment-input-section">
              <Form.Item name="comment">
                <TextArea
                  className="common-textarea comment-description"
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  placeholder="Enter Comment"
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </ModalComponent>
    </div>
  );
};
export default CommentModal;
