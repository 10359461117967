import {
  CheckCircleOutlined,
  DashOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
  StarFilled
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Popover,
  Space,
  Typography,
  message
} from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import placeholder from '../../../assets/images/placeholder.jpg';
import {
  ANALYTICS_EVENTS,
  ROUTES,
  SKIP_RECORD
} from '../../../common/constants';
import { FOLLOW_UNFOLLOW_COMPANY } from '../../../common/graphql/Mutation';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import { analytics } from '../../auth/Firebase';
import { GET_COMPANIES } from '../graphql/Queries';
import './Companies.less';

function Companies() {
  const companyInitialFilter = {
    skip: 0,
    limit: SKIP_RECORD
  };

  const [companyData, setCompanyData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [followUnfollowCompany] = useMutation(FOLLOW_UNFOLLOW_COMPANY);
  const [jobsDebounce, setJobsDebounce] = useState(false);
  const [jobsIsEnd, setJobsIsEnd] = useState(false);
  const [getCompanies] = useLazyQuery(GET_COMPANIES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      const searchResult = res?.searchCompanies?.data.filter(
        (comp) => comp.displayCompany === true
      );
      if (searchResult.length < SKIP_RECORD) {
        setJobsIsEnd(true);
      }
      if (searchResult) {
        setCompanyData([...companyData, ...searchResult]);
      }
      setLoader(false);
    }
  });

  useEffect(() => {
    getCompanies({
      variables: {
        filters: companyInitialFilter
      }
    });
  }, []);

  const renderActionButtons = () => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="brand-table-edit-btn"
          className="b-0"
          type="link"
          icon={<InfoCircleOutlined />}
          // onClick={(e) => e?.stopPropagation()}
          onClick={() => {
            message.info('Thank you for reporting');
            analytics.logEvent(ANALYTICS_EVENTS.REPORT_POST);
          }}
        >
          Report
        </Button>
      </div>
    );
  };

  const followUnfollow = (status, id) => {
    if (status) {
      confirm({
        okText: 'Yes, Continue',
        content: 'Are you sure you want to unfollow?',
        centered: true,
        cancelText: 'No',
        onOk: () => {
          followUnfollowCompany({
            variables: { id: id }
          });
          const unFrozenArray = companyData.slice();
          for (let index = 0; index < unFrozenArray?.length; index += 1) {
            const currentCompany = unFrozenArray[index];
            if (currentCompany?.id === id) {
              unFrozenArray[index] = {
                ...currentCompany,
                isFollowed: !currentCompany.isFollowed
              };
              break;
            }
          }
          setCompanyData(unFrozenArray);
        }
      });
    } else {
      followUnfollowCompany({
        variables: { id: id }
      });
      const unFrozenArray = companyData.slice();
      for (let index = 0; index < unFrozenArray?.length; index += 1) {
        const currentCompany = unFrozenArray[index];
        if (currentCompany?.id === id) {
          unFrozenArray[index] = {
            ...currentCompany,
            isFollowed: !currentCompany.isFollowed
          };
          break;
        }
      }
      setCompanyData(unFrozenArray);
    }
  };

  const handleClickOnCompany = (id) => {
    history.push(`${ROUTES.COMPANY_PROFILE_ABOUT}/${id}`);
  };

  const loadMore = () => {
    if (!jobsIsEnd) {
      setLoader(true);
      getCompanies({
        variables: {
          filters: {
            limit: SKIP_RECORD,
            skip: (jobsDebounce + 1) * SKIP_RECORD
          }
        }
      });
      setJobsDebounce((prevState) => prevState + 1);
    }
  };
  return (
    <div className="companies">
      <div>
        <Typography.Title className="title">Companies</Typography.Title>
        {/* <Typography.Text type="secondary">
          Based on your profile and search history
        </Typography.Text> */}
      </div>
      <Divider />
      <LoaderComponent spinning={loader} />
      {map(companyData, (item) => {
        return (
          <Col
            span={22}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 22 }}
            lg={{ span: 22 }}
            className="margin-bottom"
            key={item?.id}
          >
            <Card
              className="companies-card pointer"
              onClick={() => {
                handleClickOnCompany(item?.id);
              }}
            >
              <div className="w-100 center-div mb viewDetailMobile">
                <Avatar
                  className="img viewDetailMobile"
                  src={item?.profileImage || placeholder}
                />
              </div>
              <Card.Meta
                className="center-div"
                avatar={
                  <Avatar
                    className="img viewDetailWeb"
                    src={item?.profileImage || placeholder}
                  />
                }
                title={
                  <Typography.Title className="heading" level={5}>
                    {item.name}
                  </Typography.Title>
                }
                description={
                  <div className="flex-bet-row">
                    <Space direction="vertical" className="company-card-desc">
                      <Typography.Paragraph
                        ellipsis={{
                          rows: 2,
                          expandable: true,
                          symbol: 'more'
                        }}
                      >
                        {item?.description}
                      </Typography.Paragraph>
                      <div>
                        <Space style={{ paddingTop: '5px' }}>
                          <span style={{ paddingLeft: '5px' }}>
                            {item?.followerCount} Followers
                          </span>
                          <span
                            style={{ paddingLeft: '5px', paddingRight: '5px' }}
                          >
                            {' | '}
                          </span>
                          <span>
                            <StarFilled style={{ color: '#FFD700' }} />
                            <Typography.Text style={{ paddingRight: '5px' }}>
                              {' '}
                              {(item?.review?.avgRating || 0).toFixed(2)}
                            </Typography.Text>
                            {' | '}
                          </span>
                          <span>
                            <Typography.Text style={{ paddingRight: '5px' }}>
                              {item?.review?.totalReviews} reviews
                            </Typography.Text>
                            {' | '}
                          </span>
                          <span>
                            <CheckCircleOutlined />{' '}
                            <Typography.Text>
                              {item?.totalJobCreated} jobs
                            </Typography.Text>
                          </span>
                        </Space>
                      </div>
                    </Space>
                    <Space className="company-card-options">
                      <Button
                        type={item.isFollowed ? '' : 'primary'}
                        className="btn-follow viewDetailWeb"
                        onClick={(e) => {
                          e?.preventDefault();
                          e?.stopPropagation();
                          followUnfollow(item?.isFollowed, item?.id);
                        }}
                      >
                        {item.isFollowed ? 'Unfollow' : 'Follow'}
                      </Button>
                      <Popover
                        placement="bottom"
                        className="viewDetailWeb popover-design"
                        overlayClassName="action-button"
                        content={renderActionButtons()}
                      >
                        <EllipsisOutlined
                          onClick={(e) => {
                            e?.preventDefault();
                            e?.stopPropagation();
                          }}
                        />
                      </Popover>
                    </Space>
                  </div>
                }
              />
              <Space
                className="w-100 center-div company-card-options-mb mt viewDetailMobile"
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                }}
              >
                <Button
                  type={item.isFollowed ? '' : 'primary'}
                  className="btn-follow"
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    followUnfollow(item?.isFollowed, item?.id);
                  }}
                >
                  {item.isFollowed ? 'Unfollow' : 'Follow'}
                </Button>
                <Button
                  icon={<DashOutlined />}
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                  }}
                />
              </Space>
            </Card>
          </Col>
        );
      })}
      {!loader && !jobsIsEnd && (
        <div className="loadMoreButton">
          <Button className="follow-btn" onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
}

export default Companies;
