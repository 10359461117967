/* eslint-disable no-console */
import { useLazyQuery } from '@apollo/client';
import { Divider, Skeleton } from 'antd';
import { React, useEffect, useState } from 'react';
import { JOB_WORK_TYPE, ROUTES } from '../../../common/constants';
import { getLocationStrFromLocationArray } from '../../../common/utils';
import CommonAvatar from '../../../components/CommonAvatar';
import history from '../../../historyData';
import { GET_JOB } from '../../jobDetails/graphql/Queries';

const FeedRecommendedJobCard = ({ jobId, addRecCardResult }) => {
  const [loading, setLoading] = useState(true);
  const [job, setJob] = useState(null);
  const [location, setLocation] = useState();
  const [loadFail, setLoadFail] = useState(false);

  const [getJob] = useLazyQuery(GET_JOB, {
    variables: {
      id: jobId
    },
    fetchPolicy: 'network-only',
    onError: () => {
      addRecCardResult('fail');
      setLoadFail(true);
    },
    onCompleted: (res) => {
      if (res.job.displayJob) {
        setJob(res.job);
        addRecCardResult('success');
      } else {
        addRecCardResult('fail');
        setLoadFail(true);
      }
    }
  });
  // const location = getLocationStrFromLocationArray(job?.location);
  const handleClickOnJob = (id) => {
    history.push(`/job/${id}`);
  };
  const handleClickOnCompany = (id) => {
    history.push(`${ROUTES.COMPANY_PROFILE_ABOUT}/${id}`);
  };

  useEffect(() => {
    getJob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (job) {
      setLocation(getLocationStrFromLocationArray(job?.location));
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  return (
    <>
      {loadFail ? null : (
        <>
          <Skeleton loading={loading} active>
            {job?.displayJob ? (
              <div
                className="job-card"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClickOnJob(job?.id);
                }}
              >
                <p className="job-title" style={{ cursor: 'pointer' }}>
                  {job?.title}
                </p>
                <div className="d-flex align-center">
                  <CommonAvatar size={25} src={job?.createdBy?.profileImage} />
                  <span
                    className="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleClickOnCompany(job?.createdBy?.id);
                    }}
                  >
                    {' '}
                    {job?.createdBy?.name}{' '}
                  </span>
                </div>
                <Divider className="job-card-divider" />
                <div className="footer d-flex">
                  <span className="bold-text" style={{ paddingRight: '5px' }}>
                    {JOB_WORK_TYPE[job?.workType]}:
                  </span>
                  <span className="normal-text" style={{ paddingLeft: '5px' }}>
                    {location}
                  </span>
                </div>
              </div>
            ) : null}
          </Skeleton>
        </>
      )}
    </>
  );
};

export default FeedRecommendedJobCard;
