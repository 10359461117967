import { gql } from '@apollo/client';

export const GET_COMPANY_JOBS = gql`
  query jobs($filters: JobFilterInput!) {
    jobs(filters: $filters) {
      count
      data {
        id
        title
        type
        noOfVacancies
        industry {
          id
          key
          value
        }
        salary {
          minimum
          maximum
          currency
        }
        workType
        applicationCount {
          totalApplied
          totalAccepted
        }
        minQualification
        preferredQualification
        about
        responsibilities
        location {
          city
          coordinates {
            latitude
            longitude
          }
          country
          state
        }
      }
    }
  }
`;

export const GET_COMPANY_FOLLOWERS = gql`
  query getCompanyFollowers(
    $where: CommonWhereInput
    $filters: CompanyFilterFollowerInput
  ) {
    getCompanyFollowers(where: $where, filters: $filters) {
      userId {
        id

        profileImage
        firstName
        lastName
        experience {
          id
          title
          company
          type
          location {
            city
            state
            country
            coordinates {
              latitude
              longitude
            }
          }
          isCurrent
        }
      }
    }
  }
`;

export const GET_COMPANY_INFO_ME = gql`
  query {
    companyMe {
      id
      name
      email
      bio
      profileImage
      followerCount
      totalJobCreated
      description
      industry {
        id
        key
        value
      }
      website
      hqLocation {
        city
        state
        country
        coordinates {
          latitude
          longitude
        }
      }
      branches {
        city
        state
        country
        coordinates {
          latitude
          longitude
        }
      }
      review {
        totalReviews
        avgRating
      }
      employeeSize
      coverImage
      branches {
        city
        state
        country
        coordinates {
          latitude
          longitude
        }
      }
      establishedYear
      isFollowed
      funding {
        totalRounds
        lastRoundInvestor
        lastRoundDate
        fundsRaised
      }
      specialties
    }
  }
`;

export const GET_COMPANY_INFO = gql`
  query($id: String!) {
    company(where: { id: $id }) {
      id
      name
      email
      followerCount
      profileImage
      myReview {
        id
        reviewText
        ratings
        isAnonymous
        user {
          id
          firstName
          lastName
          profileImage
          bio
          experience {
            company
          }
        }
      }
      bio
      totalJobCreated
      description
      industry {
        id
        key
        value
      }
      website
      hqLocation {
        city
        state
        country
        coordinates {
          latitude
          longitude
        }
      }
      branches {
        city
        state
        country
        coordinates {
          latitude
          longitude
        }
      }
      review {
        totalReviews
        avgRating
      }
      employeeSize
      coverImage
      branches {
        city
        state
        country
        coordinates {
          latitude
          longitude
        }
      }
      establishedYear
      isFollowed
      funding {
        totalRounds
        lastRoundInvestor
        lastRoundDate
        fundsRaised
      }
      specialties
    }
  }
`;

export const GET_COMPANY_REVIEW = gql`
  query companyReviews(
    $filters: CompanyFilterInput!
    $where: CommonWhereInput!
  ) {
    companyReviews(filters: $filters, where: $where) {
      count
      data {
        id
        user {
          id
          firstName
          lastName
          profileImage
          bio
          experience {
            company
          }
        }
        company {
          id
          name
          email
        }
        isAnonymous
        ratings
        reviewText
      }
    }
  }
`;
