/* FIRBASE ANALYTICS EVENTS */
export const ANALYTICS_EVENTS = {
  // COMPANY EVENTS
  COMPANY_SIGNUP_OPENED: 'company_create_opened',
  COMPANY_NAME_ENTERED: 'company_name_entered',
  COMPANY_SIGNUP: 'company_signup',
  COMPANY_OTP_ENTERED: 'company_otp_entered',
  COMPANY_LOGO_ADDED: 'company_logo_added',
  COMPANY_INFO_ADDED: 'company_info_added',
  COMPANY_SUBMITS_APPLICATION: 'company_submits_application',
  POST_JOB: 'post_job',
  VIEWS_CANDIDATE_PROFILE: 'views_candidate_profile',
  MESSAGE_CANDIDATE: 'message_candidate',
  SELECT_CANDIDATE: 'select_candidate',
  MY_JOB: 'my_job',
  REJECT_CANDIDATE: 'reject_candidate',

  // USER EVENTS
  USER_SIGNUP_OPENED: 'candidate_create_opened',
  USER_CONTINUE_GOOGLE: 'candidate_continue_google',
  USER_SIGNUP: 'candidate_signup',
  USER_OTP_ENTERED: 'candidate_otp_entered',
  USER_IDENTIFIED_BLACK: 'candidate_identified_black',
  USER_PROFILE_PICTURE_ADDED: 'candidate_profile_picture_added',
  USER_EXPERIENCE_ADDED: 'candidate_experience_added',
  USER_EXPERIENCE_REMOVED: 'candidate_experience_removed',
  USER_EDUCATION_ADDED: 'candidate_education_added',
  USER_EDUCATION_REMOVED: 'candidate_education_removed',
  USER_SKILL_ADD: 'candidate_adds_skill',
  USER_SKILL_REMOVE: 'candidate_removes_skill',
  USER_PROFILE_SUBMIT: 'candidate_profile_submit',
  USER_POST_ADDED: 'post_added',
  FEED_PAGE: 'feed_page',
  JOB_FOR_YOU: 'job_for_you',
  COMPANIES: 'companies',
  APPLIED_JOBS: 'applied_job',
  MY_CONNECTIONS: 'my_connections',
  MY_FOLLOWING: 'my_following',
  ABOUT_ME: 'about_me',
  SEARCH: 'search',
  USER_UPDATE_PROFILE: 'candidate_update_profile',
  NOTIFICATION_VIEWED: 'notifications_viewed',
  INBOX_VIEWED: 'inbox_viewed',
  LIKE: 'post_like',
  COMMENT: 'post_comment',
  APPLY_JOB: 'apply_job',
  VIEW_JOB: 'view_job',
  REPORT_POST: 'report_post',
  POST_CLICKED: 'post_clicked',

  USER_TAB_POST: 'post_tab',
  USER_TAB_ABOUT: 'about_tab',
  USER_TAB_CONNECTIONS: 'connections_tab',
  USER_TAB_FOLLOWING: 'following_tab',
  USER_TAB_APPLIED_JOB: 'applied_job_tab',
  MESSAGE_PEER: 'message_fellow_candidate'
};

/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  USERS: '/users',
  LOGOUT: '/logout',
  LOGIN: '/login',
  COMPANY_LOGIN: '/company/login',
  SIGNUP: '/sign-up',
  COMPANY_SIGNUP: '/company/sign-up',
  CREATE_JOB: '/company/new-job',
  ARCHIVE: '/company/archive',
  MY_JOB: '/company/my-jobs',
  RESET: '/reset-password',
  CHANGE: '/reset-password-confirm',
  FILL_PROFILE: '/fill-profile',
  COMPANY_PROFILE: '/company-profile',
  VERIFY: '/verify',
  USERS_MANAGEMENT: '/users',
  FEED: '/feed',
  COMPANY_FEED: '/company/feed',
  MY_CONNECTIONS: '/my-connections',
  MY_FOLLOWING: '/my-following',
  PROFILE: '/profile',
  JOBS: '/jobs',
  SEARCH_JOB: '/search/jobs',
  SEARCH_PEOPLE: '/search/people',
  SEARCH_COMPANY: '/search/companies',
  COMPANIES: '/companies',
  VERIFY_EMAIL: '/verify-email',
  RESULT: '/result',
  SEARCH: '/search',
  APPLIED_JOBS: '/applied-jobs',
  PRIVACY_POLICY: '/profile/privacy-policy',
  CHAT: '/chat',
  NEW_CHAT: '/chat/new',
  USER_CHAT: '/chat/:id',
  COMPANY_EDITABLE_PROFILE: '/company/profile',
  PROFILE_APPLIED_JOBS: '/profile/applied-jobs',
  PROFILE_POSTS: '/profile/posts',
  PROFILE_CONNECTIONS: '/profile/connections',
  PROFILE_FOLLOWING: '/profile/following',
  PROFILE_ABOUT: '/profile/about',
  INDIVIDUAL_MY_JOB: '/company/my-jobs/:id',
  INDIVIDUAL_MY_JOB_EDIT: '/company/my-jobs/:id/edit',
  COMPANY_PROFILE_JOBS: '/company/profile/jobs',
  COMPANY_PROFILE_REVIEW: '/company/profile/reviews',
  COMPANY_PROFILE_FOLLOWERS: '/company/profile/followers',
  COMPANY_PROFILE_ABOUT: '/company/profile/about',
  COMPANY_PROFILE_EDIT: '/company/profile/edit',
  JOB_DETAILS: '/job',
  PROFILE_EDIT: '/profile/edit',
  PUBLIC_PRIVACY_POLICY: '/privacy-policy',
  SUCCESS: '/success',
  CANCEL: '/cancel'
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  USERS_MANAGEMENT: 'User Management',
  FEED: 'Feed',
  MY_CONNECTIONS: 'My Connections',
  MY_FOLLOWING: 'Following',
  Profile: 'About Me',
  ARCHIVE: 'Archive',
  MY_JOB: 'My Jobs',
  POST_JOB: 'Post Job',
  MY_REVIEWS: 'My reviews',
  MY_FOLLOWERS: 'My Followers'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin'
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN'
};

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 768,
  desktop: 1000,
  hd: 1200,
  laptop: 1024
};

export const EMPLOYEE_SIZE = [
  { key: 'LESS_THAN_100', value: '1 - 99 employees' },
  { key: 'LESS_THAN_250', value: '100 - 249 employees' },
  { key: 'LESS_THAN_500', value: '250 - 500 employees' },
  { key: 'LESS_THAN_1000', value: '500 - 999 employees' },
  { key: 'LESS_THAN_5000', value: '1,000 - 4,999 employees' },
  { key: 'LESS_THAN_10000', value: '5,000 - 9,999 employees' },
  { key: 'MORE_THAN_10000', value: '> 10,000 employees' }
];

export const EMPLOYEE_SIZE_OBJECT = {
  LESS_THAN_100: 'Less than 100',
  LESS_THAN_250: '100 - 249',
  LESS_THAN_500: '250 - 499',
  LESS_THAN_1000: '500 - 999',
  LESS_THAN_5000: '1000 - 4999',
  LESS_THAN_10000: '5000 - 9999',
  MORE_THAN_10000: 'over 10000 '
};

export const PLAN_TYPE = {
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly'
};

export const EMPLOYEE_TYPE = [
  {
    key: 'FULL_TIME',
    value: 'Full-Time'
  },
  {
    key: 'PART_TIME',
    value: 'Part-Time'
  },
  {
    key: 'CONTRACT',
    value: 'Contract'
  },
  {
    key: 'INTERNSHIP',
    value: 'Internship'
  }
];

export const WORK_TYPE = [
  {
    key: 'ON_SITE',
    value: 'In Office'
  },
  {
    key: 'REMOTE',
    value: 'Remote'
  },
  {
    key: 'HYBRID',
    value: 'Hybrid'
  }
];

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';
export const ISOStringDateFormat = 'YYYY-MM-DD HH:mm';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,30}$/,
  PHONE: /^(?=.*[0-9])[- +()0-9]+$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/
};

export const sortItems = ['All', 'Applied', 'Rejected', 'Shortlisted'];
export const searchTypes = ['Jobs', 'Companies', 'People'];

export const SKIP_RECORD = 20;
export const SKIP_RECORD_REVIEW = 5;
export const FOLLOWERS_SKIP_RECORD = 10;
export const SMALL_TABLET = 1000;
export const TABLET_BREAKPOINT = 1200;
export const BIG_SCREEN = 1600;
export const POST_SKIP_RECORD = 12;
export const MAX_PAST_DATE = new Date(1900, 0, 1, 0, 0, 0);

export const JOB_WORK_TYPE = {
  ON_SITE: 'In Office',
  REMOTE: 'Remote',
  HYBRID: 'Hybrid'
};

export const MEDIA_TYPE = {
  image: 'PHOTO',
  video: 'VIDEO'
};

export const JOB_APPLICATION_STATUS = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  WHITELISTED: 'Shortlisted'
};

export const JOB_TYPE = {
  FULL_TIME: 'Full-Time',
  PART_TIME: 'Part-Time',
  INTERNSHIP: 'Internship',
  CONTRACT: 'Contract'
};

export const JOB_APPLICATION_ACTION_STATUS = {
  ACCEPTED: 'Accept',
  REJECTED: 'Reject',
  WHITELISTED: 'Shortlist'
};
