import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import UpdateProfileForm from './components/UpdateProfileForm';
import UserProfile from './UserProfile';

const UserProfileWrapper = () => {
  return (
    <Switch>
      <Route path={`${ROUTES.PROFILE_ABOUT}/:id`} component={UserProfile} />
      <Route path={ROUTES.PROFILE_ABOUT} component={UserProfile} />
      <Route path={ROUTES.PROFILE_CONNECTIONS} component={UserProfile} />
      <Route path={ROUTES.PROFILE_FOLLOWING} component={UserProfile} />
      <Route path={`${ROUTES.PROFILE_POSTS}/:id`} component={UserProfile} />
      <Route path={ROUTES.PROFILE_POSTS} component={UserProfile} />
      <Route path={ROUTES.PROFILE_APPLIED_JOBS} component={UserProfile} />
      <Route path={ROUTES.PROFILE_EDIT} component={UpdateProfileForm} />
    </Switch>
  );
};

export default UserProfileWrapper;
