import { StarFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Space, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React from 'react';
import placeholder from '../../../../assets/images/placeholder.jpg';
import { ROUTES } from '../../../../common/constants';
import { FOLLOW_UNFOLLOW_COMPANY } from '../../../../common/graphql/Mutation';
import history from '../../../../historyData';

function CompanyCard({ company, updateCompanies }) {
  const [followUnfollowCompany] = useMutation(FOLLOW_UNFOLLOW_COMPANY);
  // eslint-disable-next-line no-undef
  const isCompany = localStorage.getItem('isCompanyVerified');

  return (
    <Col
      span={6}
      xs={{ span: 24 }}
      sm={{ span: 12 }}
      md={{ span: 12 }}
      lg={{ span: 6 }}
    >
      <div className="company-card">
        <header
          style={{
            backgroundImage: `url(${company.coverImage})`,
            backgroundSize: 'cover',
            height: '45%'
          }}
        />
        <div className="company-content">
          <div
            className="d-flex justify-center"
            onClick={() => {
              history?.push(`${ROUTES?.COMPANY_PROFILE_ABOUT}/${company?.id}`);
            }}
          >
            <h1 className="bold-text">{company.name}</h1>
          </div>
          <div className="d-flex justify-center">
            <Space>
              <span>
                <StarFilled style={{ color: '#FFD700' }} />
                <Typography.Text strong>
                  {(company?.review?.avgRating || 0).toFixed(2)}
                </Typography.Text>
                {' | '}
              </span>
              <span>
                <Typography.Text strong>
                  {company.review.totalReviews}
                </Typography.Text>
                <span>{company.review.totalReviews ? '' : 'No '}</span>Reviews
              </span>
            </Space>
          </div>
          <div className="space-small-v" />
          {!isCompany && (
            <div>
              {company.isFollowed ? (
                <Button
                  className="follow-btn w-100"
                  onClick={() => {
                    confirm({
                      okText: 'Yes, Continue',
                      content: 'Are you sure you want to unfollow?',
                      centered: true,
                      cancelText: 'No',
                      onOk: () => {
                        followUnfollowCompany({
                          variables: { id: company.id }
                        });
                        const updatedCompany = {
                          ...company,
                          isFollowed: false
                        };
                        updateCompanies(updatedCompany);
                      }
                    });
                  }}
                >
                  Unfollow
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="follow-btn w-100"
                  onClick={() => {
                    followUnfollowCompany({ variables: { id: company.id } });
                    const updatedCompany = { ...company, isFollowed: true };
                    updateCompanies(updatedCompany);
                  }}
                >
                  Follow
                </Button>
              )}
            </div>
          )}
        </div>
        <div
          className="floater"
          onClick={() => {
            history?.push(`${ROUTES?.COMPANY_PROFILE_ABOUT}/${company?.id}`);
          }}
        >
          <div className="company-img-wrapper">
            <div
              className="company-img"
              style={{
                backgroundImage: `url('${company.profileImage || placeholder}')`
              }}
              alt="company"
            />
          </div>
        </div>
      </div>
    </Col>
  );
}

export default CompanyCard;
