import { EnvironmentFilled } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Avatar, Button, Collapse, Divider, Space, Typography } from 'antd';
import AWS from 'aws-sdk';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import placeholder from '../../../assets/images/placeholder.jpg';
import {
  ANALYTICS_EVENTS,
  JOB_WORK_TYPE,
  ROUTES
} from '../../../common/constants';
import { getLocationStrFromLocationArray } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import { MapComponent } from '../../../components/MapComponent';
import history from '../../../historyData';
import { analytics } from '../../auth/Firebase';
import SimilarJobsContainer from '../../recommenders/component/SimilarJobsContainer';
import { APPLY_TO_JOB } from '../graphql/Mutation';
import { GET_JOB } from '../graphql/Queries';

export default function JobDetails() {
  const [loading, setLoading] = useState({});
  const [job, setJob] = useState({});
  const [similarJobs, setSimilarJobs] = useState([]);

  // eslint-disable-next-line no-undef
  const isCompany = localStorage.getItem('isCompanyVerified');
  const { id: jobId } = useParams();
  const [getJob] = useLazyQuery(GET_JOB, {
    variables: {
      id: jobId
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setJob(res.job);
      setLoading(false);
    }
  });

  const getSimilarjobs = () => {
    const options = {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    };

    const personalizeRuntime = new AWS.PersonalizeRuntime(options);

    const params = {
      campaignArn: process.env.REACT_APP_PERSONALIZE_CAMPAIGN_ARN_SIMILAR_JOBS,
      numResults: '3',
      itemId: jobId
    };

    personalizeRuntime.getRecommendations(params, function (err, data) {
      if (err) {
        // eslint-disable-next-line no-console
        console.log(err, err.stack);
      } else {
        const resultArray = [];
        data.itemList.forEach((item) => {
          resultArray.push(item.itemId);
        });
        setSimilarJobs(resultArray);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getJob();
    getSimilarjobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [applyToJob] = useMutation(APPLY_TO_JOB);

  const handleApplyJob = () => {
    analytics.logEvent(ANALYTICS_EVENTS.APPLY_JOB, {
      jobId,
      title: job.title
    });
    applyToJob({
      variables: {
        id: jobId
      }
    });
    setJob({
      ...job,
      application: {
        isApplied: true,
        status: 'PENDING'
      }
    });
  };

  if (loading) {
    return <LoaderComponent />;
  }
  const handleClickOnCompany = (id) => {
    history.push(`${ROUTES.COMPANY_PROFILE_ABOUT}/${id}`);
  };
  return (
    <div>
      <div className="job-details">
        <div className="d-flex justify-between upper-job">
          <Space
            style={{
              cursor: 'pointer'
            }}
            onClick={() => {
              handleClickOnCompany(job.createdBy?.id);
            }}
          >
            <Avatar
              alt="Company profile image"
              src={`${job.createdBy?.profileImage || placeholder}`}
            />
            <span>{`${job.createdBy.name}`} </span>
          </Space>
          {!isCompany && job.application.isApplied && (
            <Space>
              <span>Application status:</span>
              <span className="text-blue">{job.application.status}</span>
            </Space>
          )}
        </div>
        <Divider
          style={{
            margin: '0'
          }}
        />
        <div className="lower-job">
          <div className="d-flex justify-between mobile-flex">
            <Space direction="vertical">
              <Typography.Title className="heading">
                {job.title}
              </Typography.Title>
              <Space>
                <span>
                  <EnvironmentFilled style={{ color: '#34A853' }} />
                  <Typography.Text
                    strong
                    style={{ paddingLeft: '5px', color: '#34A853' }}
                  >
                    {JOB_WORK_TYPE[job?.workType]} :{' '}
                  </Typography.Text>
                  <Typography.Text style={{ paddingLeft: '5px' }}>
                    {getLocationStrFromLocationArray(job?.location)}
                  </Typography.Text>
                </span>
                {!!job?.salary?.minimum && job?.salary?.maximum && (
                  <span>
                    <Typography.Text strong style={{ paddingLeft: '5px' }}>
                      {' '}
                      {job?.salary?.currency} Salary:
                    </Typography.Text>{' '}
                    ${job?.salary?.minimum} - ${job?.salary?.maximum}
                  </span>
                )}
              </Space>
            </Space>
            <Space className="mobile-flex share-flex">
              {/* <div
                className="share-btn pointer"
                style={{
                  fontSize: '18px',
                  color: 'black',
                  marginRight: '10px'
                }}
              >
                <ShareAltOutlined width="20px" height="20px" className="mr-5" />
                Share
              </div> */}
              {!isCompany && job.application.isApplied && (
                <Button
                  type="primary"
                  className="viewDetailWeb font-16 font-600 applied-btn"
                >
                  Applied
                </Button>
              )}
              {!isCompany && !job.application.isApplied && (
                <Button
                  className="follow-btn"
                  type="primary"
                  onClick={() => {
                    handleApplyJob();
                  }}
                >
                  Apply
                </Button>
              )}
            </Space>
          </div>
          <Divider />
          <div>
            <h3>Minimum qualifications:</h3>
            <ul>
              <Space direction="vertical">{job?.minQualification}</Space>
            </ul>
          </div>
          <div>
            <h3>Preferred qualifications:</h3>
            <ul>
              <Space direction="vertical">{job?.preferredQualification}</Space>
            </ul>
          </div>
          <Divider />

          <Collapse
            className="collapse-wrapper"
            defaultActiveKey={['1']}
            style={{ border: 0 }}
          >
            <Collapse.Panel header="About the Job" key="1">
              <p>{job?.about}</p>
            </Collapse.Panel>
            <Collapse.Panel header="Responsibilities" key="2">
              <p>{job?.responsibilities}</p>
            </Collapse.Panel>
            <Collapse.Panel header="Location" key="3">
              <div className="d-flex justify-center">
                <MapComponent locationArray={job?.location} />
              </div>
            </Collapse.Panel>
          </Collapse>
          <div className="recommend-similar-wrapper">
            <SimilarJobsContainer similarJobs={similarJobs} />
          </div>
        </div>
        {/* <Divider />
      <div>
        <h2>More jobs from Starbucks</h2>
        <Row>
          {[0, 1, 2, 3, 4].map((val) => (
            <Col
              span={6}
              key={val}
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              className="m-all"
            >
              <Card
                className="shadow"
                title={
                  <h3>
                    Technical Solutions Specilast, Networking, Google Cloud
                    Platform
                  </h3>
                }
              >
                <div>
                  <Typography.Text strong style={{ paddingLeft: '5px' }}>
                    In Office :{' '}
                  </Typography.Text>
                  <Typography.Text style={{ paddingLeft: '5px' }}>
                    San Francisco, CA, USA Chicago, IL, USA Mountain View, CA,
                    USA
                  </Typography.Text>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div> */}
      </div>
    </div>
  );
}
