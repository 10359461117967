import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_JOB_DETAIL = gql`
  query job($where: CommonWhereInput) {
    job(where: $where) {
      id
      title
      workType
      noOfVacancies
      location {
        city
        state
        country
      }
      type
      industry {
        id
        key
        value
      }
      salary {
        minimum
        maximum
      }
      minQualification
      preferredQualification
      about
      responsibilities
    }
  }
`;
