import { useLazyQuery } from '@apollo/client';
import { Button, Col, Result, Row } from 'antd';
import { map, remove } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { SKIP_RECORD } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import { GET_USER_POSTS } from '../graphql/Queries';
import CommonPostCard from './CommonPostCard';

const Posts = ({ userId }) => {
  const postInitialFilter = {
    skip: 0,
    limit: 20
  };

  const [postData, setPostData] = useState([]);
  const { getCurrentUser } = useContext(AppContext);
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const [postLoading, setPostLoading] = useState(true);
  const [postIsEnd, setPostIsEnd] = useState(false);
  const [postDebounceCall, setPostDebounceCall] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [postFilter, setPostFilter] = useState(postInitialFilter);
  const id = userId || getCurrentUser().id;

  const [getPosts] = useLazyQuery(GET_USER_POSTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.userPublicPosts?.data?.length < SKIP_RECORD) {
        setPostIsEnd(true);
      }
      // need to spread
      setPostData([...postData, ...res?.userPublicPosts?.data]);
      setPostLoading(false);
    },
    onError() {
      setPostLoading(false);
    }
  });

  useEffect(() => {
    getPosts({
      variables: {
        filters: postFilter,
        where: { id }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    if (!postIsEnd) {
      setPostLoading(true);
      getPosts({
        variables: {
          filters: {
            ...postFilter,
            skip: (postDebounceCall + 1) * SKIP_RECORD
          },
          where: { id }
        }
      });
      setPostDebounceCall((prevState) => prevState + 1);
    }
  };
  const removePost = (postId) => {
    const postDataCopy = postData.slice();
    remove(postDataCopy, function (post) {
      if (post?.id === postId) {
        return true;
      }
    });
    setPostData(postDataCopy);
  };

  const updatePost = (post) => {
    const postDataCopy = postData.slice();
    for (let i = 0; i < postDataCopy.length; i += 1) {
      const postObj = postDataCopy[i];
      if (postObj.id === post.id) {
        postDataCopy[i] = post;
        break;
      }
    }
    setPostData(postDataCopy);
  };
  return (
    <div className="profile-post">
      <div>
        <div className="post-section">
          {/* {postLoading && (
            <LoaderComponent
              // indicator={antIcon}
              size="large"
              setHeight={20}
            />
          )} */}
          {postData?.length === 0 && !postLoading && (
            <Result status="404" title="No Post Uploaded yet" />
          )}
          <Row gutter={2}>
            {map(postData, (item) => {
              return (
                <>
                  {(item?.mediaUrl || item?.text) && (
                    <Col
                      span={24}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 8 }}
                      xl={{ span: 6 }}
                      className="single-post"
                    >
                      <CommonPostCard
                        removePost={removePost}
                        // handleConnectionButton={handleConnectionButton}
                        // postLoading={postLoading}
                        postObj={item}
                        updatePost={updatePost}
                      />
                    </Col>
                  )}
                </>
              );
            })}
          </Row>
          {!postLoading && !postIsEnd && (
            <div className="loadMoreButton">
              <Button className="follow-btn" onClick={loadMore}>
                Load More
              </Button>
            </div>
          )}
          {postLoading && <LoaderComponent setHeight={10} />}
        </div>
      </div>
    </div>
  );
};

export default Posts;
