import { useMutation } from '@apollo/client';
import { Button, Form, message, Upload } from 'antd';
import axios from 'axios';
import { trim } from 'lodash';
import React, { useState } from 'react';
import gallerySvg from '../../../assets/images/gallery.svg';
import closeSvg from '../../../assets/svg/close.svg';
import { ANALYTICS_EVENTS } from '../../../common/constants';
import { getBase64 } from '../../../common/utils';
import CommonAvatar from '../../../components/CommonAvatar';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import { analytics } from '../../auth/Firebase';
import { CREATE_POST, GET_SIGN_URL } from '../graphql/Mutations';
import { GET_POSTS } from '../graphql/Queries';

const { TextArea } = InputComponent;

const PostModal = (props) => {
  const {
    showModal,
    setShowModal,
    postTitle,
    addPost,
    postFilter,
    profileImage,
    placeholder
  } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [src, setSrc] = useState(null);
  const [decodingFile, setDecodingFile] = useState(false);
  const [mediaType, setMediaType] = useState([]);
  const [previewAvailable, setPreviewAvailable] = useState(true);

  const [createPost] = useMutation(CREATE_POST, {
    onError: () => {
      setSubmitLoading(false);
    },
    refetchQueries: [
      {
        query: GET_POSTS,
        variables: {
          filters: postFilter
        }
      }
    ]
  });

  const [uploadFile] = useMutation(GET_SIGN_URL, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleFinish = async (formValues) => {
    setSubmitLoading(true);
    let post;
    let variables;
    if (formValues?.post) {
      const { name, type } = formValues?.post?.file;

      const res = await uploadFile({
        variables: {
          data: {
            fileName: name
          }
        }
      });

      if (res?.data?.getPostSignedUrl) {
        const { signedUrl, getUrl } = res?.data?.getPostSignedUrl;
        try {
          const response = await axios.put(signedUrl, formValues?.post?.file, {
            headers: {
              'access-control-allow-origin': '*',
              'Content-type': type
            }
          });
          if (response?.status === 200) {
            post = getUrl;
          }
          analytics.logEvent(ANALYTICS_EVENTS.USER_POST_ADDED, {
            status: true
          });
        } catch (error) {
          analytics.logEvent(ANALYTICS_EVENTS.USER_POST_ADDED, {
            status: false
          });
          setSubmitLoading(false);
          setShowModal(false);
          message?.error('got some problem');
        }
      }
    }
    if (src) {
      variables = {
        text: formValues?.postTitle,
        mediaUrl: post,
        mediaType: mediaType[0] === 'image' ? 'PHOTO' : 'VIDEO'
      };
    } else {
      variables = {
        text: formValues?.postTitle
      };
    }
    try {
      const val = trim(formValues?.postTitle);
      if (src || val?.length > 0) {
        const response = await createPost({
          variables: { data: { ...variables } }
        });
        addPost(response.data?.createPost?.data);
        if (response) {
          setShowModal(false);
        }
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setShowModal(false);
      setSubmitLoading(false);
      return error;
    }
  };

  return (
    <div>
      <ModalComponent
        className="post-modal"
        width={576}
        footer={null}
        closable={false}
        maskClosable
        visible={showModal}
        onOk={handleFinish}
        onCancel={handleCancel}
      >
        <Form
          initialValues={{
            postTitle: postTitle
          }}
          onFinish={handleFinish}
        >
          <div className="post-wrapper d-flex">
            <div className="d-flex">
              <CommonAvatar size={50} src={profileImage || placeholder} />
            </div>
            <div className="fill-width">
              <Form.Item name="postTitle">
                <TextArea
                  className="common-textarea post-description"
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  placeholder="Enter Description"
                  autoFocus
                />
              </Form.Item>
            </div>
          </div>
          {decodingFile && <LoaderComponent setHeight="20" />}
          {!previewAvailable && (
            <div className="fill-width">
              <div className="d-flex justify-between align-center no-preview">
                Preview not available
                <img
                  onClick={() => {
                    setSrc(null);
                    setPreviewAvailable(true);
                  }}
                  className="close-icon"
                  src={closeSvg}
                  alt="close"
                />
              </div>
            </div>
          )}
          {previewAvailable && (
            <div className="fill-width d-flex justify-center align-center">
              {mediaType?.[0] === 'image' && src && (
                <div className="image-preview">
                  <img className="image" src={src} alt="post-preview" />
                  <img
                    onClick={() => setSrc(null)}
                    className="close-icon"
                    src={closeSvg}
                    alt="close"
                  />
                </div>
              )}
              {mediaType?.[0] === 'video' && src && (
                <div className="video-preview">
                  <iframe title="post" className="video" src={src} alt="post" />
                  <img
                    onClick={() => setSrc(null)}
                    className="video-close-icon"
                    src={closeSvg}
                    alt="close"
                  />
                </div>
              )}
            </div>
          )}
          <div className="fill-width">
            <div className="d-flex justify-between align-center mt-20">
              <Form.Item name="post">
                <Upload
                  beforeUpload={(file) => {
                    const fileType = file.type.toLocaleLowerCase();
                    if (
                      fileType.startsWith('image/') ||
                      fileType.startsWith('video/')
                    ) {
                      return false;
                    }
                    message.error('Only image & video supported');
                    return true;
                  }}
                  showUploadList={false}
                  onChange={(info) => {
                    const fileType = info.file.type;
                    if (
                      fileType.startsWith('image/') ||
                      fileType.startsWith('video/')
                    ) {
                      const mediaTypeArray = info?.file?.type?.split('/');
                      setMediaType(mediaTypeArray);
                      setDecodingFile(true);
                      if (info?.file.size > 10000000) {
                        setPreviewAvailable(false);
                      } else {
                        setPreviewAvailable(true);
                      }
                      getBase64(info?.file, (image) => {
                        setDecodingFile(false);
                        setSrc(image);
                      });
                    }
                  }}
                >
                  <div className="d-flex justify-start align-center upload-section">
                    <img src={gallerySvg} alt="gallery" />
                    <span className="upload-button"> Photos/Videos</span>
                  </div>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="post-btn"
                  shape="round"
                  loading={submitLoading}
                >
                  Post
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </ModalComponent>
    </div>
  );
};
export default PostModal;
