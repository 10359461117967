/* eslint-disable no-console */
import { EnvironmentFilled } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Card, Col, Divider, Result, Space, Typography } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { JOB_WORK_TYPE, ROUTES, SKIP_RECORD } from '../../../common/constants';
import { getLocationStrFromLocationArray } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import ApplicantRecommender from '../../recommenders/component/ApplicantRecommender';
import { GET_MY_JOBS } from '../graphql/Queries';
import './MyJobs.less';

const MyJobs = () => {
  const handleClick = (id) => {
    history.push(`${ROUTES.MY_JOB}/${id}`);
  };

  const { getCurrentUser } = useContext(AppContext);

  const userData = getCurrentUser();

  const myJobsInitialFilter = {
    skip: 0,
    limit: SKIP_RECORD,
    where: {
      companyId: userData.id
    },
    sortBy: 'createdAtDESC'
  };

  const [myJobData, setMyJobData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [myJobIsEnd, setMyJobIsEnd] = useState(false);
  const [myJobDebounce, setMyJobDebounce] = useState(false);

  const [getMyJobs] = useLazyQuery(GET_MY_JOBS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      setLoader(false);
      const myJob = res?.jobs?.data;
      setMyJobData([...myJobData, ...myJob]);
      if (myJob?.length < SKIP_RECORD) {
        setMyJobIsEnd(true);
      }
    },
    onError() {
      setLoader(false);
    }
  });

  useEffect(() => {
    getMyJobs({
      variables: {
        filters: myJobsInitialFilter
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    if (!myJobIsEnd) {
      setLoader(true);
      getMyJobs({
        variables: {
          filters: {
            ...myJobsInitialFilter,
            limit: 20,
            skip: (myJobDebounce + 1) * SKIP_RECORD
          }
        }
      });
      setMyJobDebounce((prevState) => prevState + 1);
    }
  };

  return (
    <div className="my-jobs">
      <div>
        <Typography.Title className="heading">My Jobs</Typography.Title>
        {/* <p>Based on your profile and search history</p> */}
      </div>
      <Divider />
      {map(myJobData, (item) => {
        const location = getLocationStrFromLocationArray(item.location);
        return (
          <Col span={22} className="margin-bottom" key={item?.id}>
            <Card
              className="shadow my-job-card"
              type="inner"
              // onClick={() => handleClick(item?.id)}
              title={
                <Space direction="vertical">
                  <Typography.Title level={5} className="heading">
                    {item?.title}
                  </Typography.Title>
                  <Space className="noMobileFlex">
                    <div>
                      <Button shape="round" className="dark-btn btn-follow">
                        Required Candidates:{' '}
                        {item?.applicationCount?.totalAccepted || 0}
                        {' / '}
                        {item?.noOfVacancies}
                      </Button>{' '}
                    </div>
                    <div className="mt-mobile">
                      <span
                        className="verticalLine"
                        style={{ paddingRight: '5px' }}
                      >
                        {' | '}
                      </span>
                      <EnvironmentFilled style={{ color: '#34A853' }} />{' '}
                      <Typography.Text
                        strong
                        style={{ paddingLeft: '5px', color: '#34A853' }}
                      >
                        {JOB_WORK_TYPE[item.workType]} :{' '}
                      </Typography.Text>
                      <Typography.Text style={{ paddingLeft: '5px' }}>
                        {location}
                      </Typography.Text>
                    </div>
                  </Space>
                </Space>
              }
              extra={
                <div className="viewDetailWeb text-blue">
                  {item?.applicationCount?.totalApplied || 0} Applied
                </div>
              }
            >
              <div>
                <h3>Qualifications:</h3>
                <ul>
                  <Space direction="vertical">
                    <Typography.Paragraph
                      type="secondary"
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: 'more'
                      }}
                    >
                      {item?.preferredQualification}
                    </Typography.Paragraph>
                  </Space>
                </ul>
                <div className="align-center-text">
                  <div className="viewDetailMobile text-blue">
                    {item?.applicationCount?.totalApplied} Applied
                  </div>
                </div>
              </div>
              <div className="buttonCenterMobile">
                <ApplicantRecommender
                  jobId={item?.id}
                  jobTitle={item?.title}
                  buttonType=""
                />
                <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                  {' | '}
                </span>
                <Button onClick={() => handleClick(item?.id)}>
                  View Details
                </Button>
              </div>
            </Card>
          </Col>
        );
      })}
      {loader && <LoaderComponent setHeight={10} />}
      {!loader && myJobData?.length === 0 && (
        <Result status="404" title="No Data" />
      )}
      {!loader && !myJobIsEnd && (
        <div className="loadMoreButton" style={{ textAlign: 'center' }}>
          <Button className="follow-btn" onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default MyJobs;
