/* eslint-disable no-undef */
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';
import history from './historyData';
import CompanyProfile from './modules/auth/CompanyProfile';
import FillProfile from './modules/auth/FillProfile';
import UnderReview from './modules/auth/UnderReview';
import VerifyEmail from './modules/auth/VerifyEmail';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { dispatch, getToken, initializeAuth, getCurrentUser } = useContext(
    AppContext
  );
  const idToken = getToken();
  const { pathname, search } = useLocation();
  const userData = getCurrentUser();
  const path = history?.location?.pathname;
  const isEmailVerified = localStorage.getItem('isEmailVerified');
  const isCompanyVerified = localStorage.getItem('isCompanyVerified');
  const isCompanyOnboardingCompleted = localStorage.getItem(
    'isCompanyOnboardingCompleted'
  );
  const isOnboardingCompleted = localStorage.getItem('isOnboardingCompleted');
  const subscriptionStatus = localStorage.getItem('subscriptionStatus');

  useEffect(() => {
    if (
      (path === ROUTES?.FEED || path === ROUTES?.COMPANY_FEED) &&
      window.innerWidth > 990
    ) {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: true, profileId: '' }
      });
    } else {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: false, profileId: '' }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (idToken) {
      const data = getCurrentUser();
      initializeAuth(idToken, data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idToken) {
      const isCompany = localStorage.getItem('isCompanyVerified');
      if (isEmailVerified !== 'true') {
        history.push(ROUTES.VERIFY_EMAIL);
      } else if (isOnboardingCompleted === 'false') {
        history.push(ROUTES.FILL_PROFILE);
      } else if (subscriptionStatus === 'INACTIVE') {
        history.push(ROUTES.COMPANY_PROFILE_EDIT);
      } else if (pathname === '/') {
        if (isCompany) {
          history.push(ROUTES.COMPANY_FEED);
        }
        history.push(ROUTES.FEED);
      } else if (search) {
        history.push(`${pathname}${search}`);
      } else history.push(`${pathname}`);
    }
    return <Redirect to="/login" />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      exact
      {...rest}
      render={(props) => {
        if (idToken && userData) {
          if (isEmailVerified !== 'true') {
            return <VerifyEmail />;
          }
          if (isOnboardingCompleted === 'false') {
            return <FillProfile />;
          }
          if (isCompanyOnboardingCompleted === 'false') {
            return <CompanyProfile />;
          }
          if (isCompanyVerified === 'false') {
            return <UnderReview />;
          }
          return <Component {...props} />;
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

export default PrivateRoute;
