import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_SIGNED_URL = gql`
  mutation getUserProfileSignedUrl($data: CommonFileInput!) {
    getUserProfileSignedUrl(data: $data) {
      fileName
      signedUrl
      getUrl
    }
  }
`;

export const GET_COMPANY_PROFILE_SIGNED_URL = gql`
  mutation getCompanyProfileSignedUrl($data: CommonFileInput!) {
    getCompanyProfileSignedUrl(data: $data) {
      fileName
      signedUrl
      getUrl
    }
  }
`;

export const FOLLOW_UNFOLLOW_COMPANY = gql`
  mutation followUnfollowCompany($id: String!) {
    followUnfollowCompany(where: { id: $id }) {
      message
    }
  }
`;

export const CONNECTION_REQUEST = gql`
  mutation connectionRequest($id: String!) {
    connectionRequest(data: { connectedTo: $id }) {
      id
      connectedBy
      connectedTo
      status
    }
  }
`;

export const CONNECTION_CANCEL = gql`
  mutation connectionCancel($id: String!) {
    connectionCancel(where: { id: $id }) {
      message
    }
  }
`;

export const DISCONNECT_CONNECTION = gql`
  mutation disconnectConnection($id: String!) {
    connectionDisconnect(where: { id: $id }) {
      message
    }
  }
`;

export const CONNECTION_STATUS_UPDATE = gql`
  mutation connectionStatusUpdate(
    $id: String!
    $status: ConnectionStatusAction!
  ) {
    connectionStatusUpdate(where: { id: $id }, data: { status: $status }) {
      connectedBy
      connectedTo
      status
    }
  }
`;
