import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import history from '../../historyData';
import './companyFeedModule.less';
import FeedPage from './pages/CompanyFeedPage';

const CompanyFeedWrapper = () => {
  // eslint-disable-next-line no-undef
  const isCompany = localStorage.getItem('isCompanyVerified');
  if (!isCompany) {
    history.replace(ROUTES.FEED);
  }
  return (
    <div className="company-feed-module">
      <Switch>
        <Route exact path={ROUTES?.COMPANY_FEED} component={FeedPage} />
      </Switch>
    </div>
  );
};
export default CompanyFeedWrapper;
