/* eslint-disable no-nested-ternary */
import { useMutation } from '@apollo/client';
import { Button, Col, DatePicker, Divider, Form, Input, Row } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import logo from '../../assets/logo.png';
import mobileLogo from '../../assets/mobileLogo.png';
import { Email, GoogleLogo, PasswordIcon, Phone, User } from '../../assets/svg';
import {
  ANALYTICS_EVENTS,
  BREAKPOINTS,
  MAX_PAST_DATE,
  REGEX,
  ROUTES,
  defaultDateFormat
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import {
  CreateUserWithEmailAndPassword,
  SignInWithGoogle,
  analytics
} from './Firebase';
import { USER_SIGNUP } from './graphql/Mutations';

const { required, email, password } = formValidatorRules;

const SignUp = () => {
  const history = useHistory();
  const [googleLoading, setGoogleLoading] = useState(false);
  const { initializeAuth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [userSignup] = useMutation(USER_SIGNUP);
  const [valuesChange, setValuesChange] = useState(true);
  const isLaptopViewPort = useMedia(`(max-width: ${BREAKPOINTS?.laptop}px)`);
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);

  useEffect(() => {
    analytics.logEvent(ANALYTICS_EVENTS.USER_SIGNUP_OPENED);
  });

  function successCallback(
    accessToken,
    userData,
    isEmailVerified,
    isOnboardingCompleted
  ) {
    initializeAuth(accessToken, userData);
    // eslint-disable-next-line no-undef
    localStorage.setItem('isEmailVerified', isEmailVerified || false);
    // eslint-disable-next-line no-undef
    localStorage.setItem(
      'isOnboardingCompleted',
      isOnboardingCompleted || false
    );
    setGoogleLoading(false);
    setLoading(false);
    if (!isEmailVerified) {
      history.push(ROUTES.VERIFY_EMAIL);
    } else if (!isOnboardingCompleted) {
      history.push(ROUTES.FILL_PROFILE);
    } else {
      history.push('/');
    }
  }

  const onFinish = async (formValues) => {
    try {
      const signUpSuccess = await CreateUserWithEmailAndPassword(
        formValues?.email,
        formValues?.password,
        setLoading
      );
      if (signUpSuccess) {
        const res = await userSignup({
          variables: {
            data: {
              token: signUpSuccess?.token,
              firstName: formValues?.firstName,
              lastName: formValues?.lastName,
              phoneNo: formValues?.phoneNo,
              DOB: moment(formValues?.DOB).format(defaultDateFormat)
            }
          }
        });
        if (res) {
          setLoading(false);
          analytics.logEvent(ANALYTICS_EVENTS.USER_SIGNUP, {
            name: res?.data?.userSignup?.name,
            email: res?.data?.userSignup?.email
          });
          successCallback(
            signUpSuccess?.token,
            res?.data?.userSignup?.data,
            res?.data?.userSignup?.isEmailVerified,
            res?.data?.userSignup?.success
          );
        }
      }
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const onChange = () => {
    setValuesChange(false);
  };

  return (
    <div
      className={`${
        isMobileViewPort ? 'signup d-flex' : 'signup d-flex flex-vertical'
      }`}
    >
      {isMobileViewPort && (
        <div className="signup-image-text">
          <img src={logo} alt="logo" className="logo" />
          <h1>
            Sophisticated, non-biased A.I. & M.L. algorithm that matches job
            openings to Black professional applicants.
          </h1>
        </div>
      )}

      {!isMobileViewPort && (
        <div className="mobile-login-header">
          <div className="d-flex align-center justify-between">
            <img src={mobileLogo} alt="logo" className="logo" height="35px" />
            <Button
              type="link"
              className="font-18 mobile-create-account-button"
              onClick={() => {
                history.push(ROUTES.LOGIN);
              }}
            >
              Login
            </Button>
          </div>
          <Divider />
        </div>
      )}
      <div className="signup-detail-section">
        <div className="d-flex justify-between align-center">
          <h1>Sign up for Candidate</h1>
          {isMobileViewPort && (
            <p className="mb-0">
              Already have a BlackUp account? <a href="/login">Login</a>
            </p>
          )}
        </div>
        <p className="mt-25">
          Not a candidate <a href={ROUTES.COMPANY_SIGNUP}>Sign up</a> for
          company?
        </p>
        <p>
          Make the world more equitable by creating jobs, events, and
          communities that celebrate diversity.
        </p>
        <Button
          className="d-flex align-center  mt-25 flex-wrap signup-google-button"
          icon={<GoogleLogo className="google-logo mr-5" />}
          onClick={async () => {
            try {
              const logInSuccess = await SignInWithGoogle(setGoogleLoading);
              analytics.logEvent(ANALYTICS_EVENTS.USER_CONTINUE_GOOGLE, {
                name: logInSuccess?.userData.name,
                email: logInSuccess?.userData.email
              });
              successCallback(
                logInSuccess?.token,
                logInSuccess?.userData,
                logInSuccess?.isEmailVerified,
                logInSuccess?.success
              );
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log(error);
            }
          }}
          loading={googleLoading}
        >
          Continue with Google
        </Button>
        <Form
          layout="vertical"
          className="mt-25 signup-detail-section-form"
          onFinish={onFinish}
          onValuesChange={onChange}
        >
          <Row gutter={isLaptopViewPort ? 10 : 50}>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item
                label="First Name"
                name="firstName"
                className="capitalize-input-wrapper"
                rules={[required]}
              >
                <Input placeholder="Enter your First Name" prefix={<User />} />
              </Form.Item>
            </Col>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item
                label="Last Name"
                name="lastName"
                className="capitalize-input-wrapper"
                rules={[required]}
              >
                <Input placeholder="Enter your Last Name" prefix={<User />} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={isLaptopViewPort ? 10 : 50}>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item label="Date Of Birth" name="DOB" rules={[required]}>
                <DatePicker
                  placeholder="Enter DOB"
                  format={defaultDateFormat}
                  disabledDate={(current) =>
                    current < MAX_PAST_DATE || current > moment()
                  }
                />
              </Form.Item>
            </Col>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item
                label="Phone Number"
                name="phoneNo"
                rules={[
                  required,
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value.split(' ').join('');
                        const numberPattern = REGEX.PHONE;
                        if (!numberPattern.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject(
                            'should be a valid phone number'
                          );
                        }
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Input
                  placeholder="Enter your Phone Number"
                  prefix={<Phone />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={isLaptopViewPort ? 10 : 50}>
            <Col span={24}>
              <Form.Item label="Email" name="email" rules={[required, email]}>
                <Input placeholder="Enter your Email" prefix={<Email />} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={isLaptopViewPort ? 10 : 50}>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item
                label="Password"
                name="password"
                rules={[required, password]}
              >
                <Input.Password
                  placeholder="Enter your Password"
                  prefix={<PasswordIcon />}
                />
              </Form.Item>
            </Col>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item
                label="Confirm Password"
                name="confirm-password"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(new Error('Required'));
                      }
                      if (value !== getFieldValue('password')) {
                        return Promise.reject(
                          new Error('Passwords do not match')
                        );
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Input.Password
                  placeholder="Enter your Confirm Password"
                  prefix={<PasswordIcon />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={isLaptopViewPort ? 10 : 50}
            className="signup-button-div"
          >
            <Col span={isMobileViewPort ? 12 : 24}>
              <p>
                By clicking “Sign up” or “Continue with Google,” you're agreeing
                to
                <a
                  href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  BlackUp Terms of Use
                </a>
                .
              </p>
            </Col>
            <Col span={isMobileViewPort ? 12 : 24}>
              <Form.Item>
                <Button
                  className="fill-width"
                  htmlType="submit"
                  loading={loading}
                  disabled={valuesChange}
                >
                  Sign Up
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default SignUp;
