import { useMutation } from '@apollo/client';
import { Button, Card, Col, Divider, Modal, Row } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import {
  defaultDateFormat,
  EMPLOYEE_SIZE_OBJECT,
  ISOStringDateFormat,
  PLAN_TYPE
} from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import { CHECK_UPDATE_PLAN } from '../graphql/Mutation';

const UpdatePricingModal = ({
  showModal,
  setShowModal,
  sizeSelected,
  setEmployeeSize,
  finalSize,
  billingDate
}) => {
  const [checkForUpdatedPlan, { data, loading }] = useMutation(
    CHECK_UPDATE_PLAN,
    {
      onError(err) {
        // eslint-disable-next-line no-console
        console.log('error', err);
      }
    }
  );

  const handleCancel = () => {
    setShowModal(false);
    setEmployeeSize(sizeSelected);
  };

  useEffect(() => {
    if (finalSize) {
      checkForUpdatedPlan({
        variables: {
          employeeSize: finalSize
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalSize]);

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      footer={null}
      centered
      className="update-pricing-modal"
    >
      <h2>You are about to update your pricing plan</h2>
      <Divider />
      {loading ? (
        <LoaderComponent setHeight={20} />
      ) : (
        <>
          <p>
            Updating the company size will update your pricing plan too. You'll
            be charged for the company size of{' '}
            <span className="size-color">
              {EMPLOYEE_SIZE_OBJECT[finalSize]}{' '}
            </span>{' '}
            from your next billing date. Here's your revised pricing:
          </p>
          <Row gutter={20}>
            <Col span={12}>
              <Card>
                <h2>{PLAN_TYPE[data?.checkForUpdatedPlan?.type]}</h2>
                <h1>$ {data?.checkForUpdatedPlan?.amount}</h1>
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <h2>Next Billing date</h2>
                <h1>
                  {moment(billingDate, ISOStringDateFormat).format(
                    defaultDateFormat
                  )}
                </h1>
              </Card>
            </Col>
          </Row>
          <Button
            onClick={() => {
              setEmployeeSize(finalSize);
              setShowModal(false);
            }}
          >
            Update Pricing
          </Button>
        </>
      )}
    </Modal>
  );
};

export default UpdatePricingModal;
