/* eslint-disable no-console */
import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import Companies from './companies/Companies';
import Jobs from './jobs/Jobs';
import People from './people/People';
import './Search.less';

const Search = () => {
  const history = useHistory();
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState('jobs');
  const { pathname, search } = useLocation();
  const [resultShowingPeople, setResultShowingPeople] = useState(0);
  const [resultShowingCompanies, setResultShowingCompanies] = useState(0);
  const [resultShowingJobs, setResultShowingJobs] = useState(0);

  useEffect(() => {
    if (pathname?.includes('companies')) {
      setActiveKey('companies');
    } else if (pathname?.includes('people')) {
      setActiveKey('people');
    } else {
      setActiveKey('jobs');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let value = 0;
  if (activeKey === 'people') {
    value = resultShowingPeople;
  } else if (activeKey === 'companies') {
    value = resultShowingCompanies;
  } else {
    value = resultShowingJobs;
  }
  const showingText = `Showing results: ${value}`;

  const updateResultShowingForPeople = (data) => {
    setResultShowingPeople(data);
  };

  const updateResultShowingForCompanies = (data) => {
    setResultShowingCompanies(data);
  };
  // eslint-disable-next-line no-undef
  const isCompany = localStorage.getItem('isCompanyVerified');

  return (
    <div className="search fill-width fill-height">
      <div className="fill-width d-flex align-center">
        <Tabs
          activeKey={activeKey}
          prefix
          onChange={(key) => {
            if (!key) {
              return;
            }
            setActiveKey(key);
            history.replace('');
            history.replace(`${ROUTES.SEARCH}/${key}${search}`);
          }}
          className="fill-width"
        >
          <TabPane tab={showingText} key="" />
          {!isCompany && (
            <TabPane tab="Jobs" key="jobs" className="details-tab">
              <div className="travel-history">
                <Jobs setResultShowingJobs={setResultShowingJobs} />
              </div>
            </TabPane>
          )}
          <TabPane
            tab="People"
            key="people"
            className="details-tab"
            forceRender
          >
            <div className="travel-history">
              <People updateResultShowing={updateResultShowingForPeople} />
            </div>
          </TabPane>
          <TabPane tab="Companies" key="companies" forceRender>
            <div className="shipment-facts">
              <Companies
                updateResultShowing={updateResultShowingForCompanies}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Search;
