import moment from 'moment';
import { defaultDateFormat, REGEX } from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) => {
  // eslint-disable-next-line no-undef
  return document.getElementById(portalId);
};

export const isPortalIdExists = (portalId) => {
  return !!injectUsingPortal(portalId);
};

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) => {
  // eslint-disable-next-line no-undef
  return document.getElementById(portalId);
};

export const isDocumentIdExist = (portalId) => {
  return !!getElementFromDocumentId(portalId);
};
// Check for document Id's exists end

export const formatDate = (
  datetime,
  format = `${defaultDateFormat} hh:mm A`
) => {
  if (datetime && moment && format) {
    return moment(datetime).format(format);
  }

  return datetime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required'
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!'
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Should be a valid Number');
      }
      return Promise.resolve();
    }
  }),
  password: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!REGEX.PASSWORD.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(
          'Password should be between 8-30, with at least a symbol, upper and lower case letters and a number!'
        );
      }
      return Promise.resolve();
    }
  })
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj.setUTCHours(timeObj.getUTCHours());
  formattedDateTime = new Date(formattedDateTime).setUTCMinutes(
    timeObj.getUTCMinutes()
  );
  formattedDateTime = new Date(formattedDateTime).toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice).toLocaleString('en', {
    style: 'currency',
    currency: 'USD'
  });
};

export const formItemProps = { normalize: (value) => value.trim() };

export const getBase64 = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getLocationStrFromLocationArray = (location) => {
  const locationData = location[0];
  const extra = location?.length - 1;
  let locationString = '';
  if (locationData) {
    locationString = `${locationData?.city ? `${locationData?.city},` : ''}`;
    locationString = locationData?.state
      ? `${locationString} ${locationData?.state}`
      : locationString;
    locationString = locationData?.country
      ? `${locationString}, ${locationData?.country}`
      : locationString;
  }

  if (!locationString) {
    return 'Location Unavailable';
  }

  if (extra !== 0) {
    locationString = `${locationString}, ${extra}+`;
  }

  return locationString;
};

export const getLocationStrFromLocationObj = (location) => {
  let locationString = '';

  if (location) {
    locationString = location?.city ? `${location?.city}` : '';
    locationString = location?.state
      ? `${locationString}, ${location?.state}`
      : locationString;
    locationString = location?.country
      ? `${locationString}, ${location?.country}`
      : locationString;
  }

  return locationString;
};

// export const recordJobPageVisit = async (jobId, userId) => {
//   const tokenPayload = {
//     // eslint-disable-next-line prettier/prettier
//     "key": process.env.REACT_APP_DEV_DB_API_KEY
//   };
//   const tokenRequestContentLength = new TextEncoder().encode(
//     JSON.stringify(tokenPayload)
//   ).byteLength;

//   const getToken = async () => {
//     const tokenHeaders = {
//       'Content-Type': 'application/json',
//       // eslint-disable-next-line prettier/prettier
//       'Host': process.env.REACT_APP_DEV_DB_APP_SERVICES_BASE_URL,
//       'Content-Length': tokenRequestContentLength.toString(),
//       'Cache-Control': 'no-cache'
//     };
//     // eslint-disable-next-line no-undef
//     const response = await fetch(process.env.REACT_APP_DEV_DB_TOKEN_API, {
//       method: 'POST',
//       body: tokenPayload,
//       headers: tokenHeaders
//     });
//     if (!response.ok) {
//       // eslint-disable-next-line no-console
//       console.error(`Error: ${response.status} ${response.statusText}`);
//       return [null, null];
//     }
//     const data = await response.json();
//     return [data.access_token, data.refresh_token];
//   };

//   const [accessToken, refreshToken] = await getToken();
//   const currentDate = new Date().getTime();
//   const createdDate = '?';
//   const payload = {
//     collection: 'interactions',
//     database: process.env.REACT_APP_DEV_DB,
//     dataSource: process.env.REACT_APP_DEV_DATASOURCE
//   };
//   const queryPayload = {
//     ...payload,
//     filter: {
//       jobId: { $oid: jobId },
//       userId: { $oid: userId }
//     }
//   };
//   const insertPayload = {
//     ...payload,
//     document: {
//       jobId: jobId,
//       userId: userId,
//       interactionType: 'JOB_VIEW',
//       interactionCount: 1,
//       createdAt: createdDate,
//       updatedAt: currentDate
//     }
//   };
//   const headers = {
//     'Content-Type': 'application/json',
//     'Access-Control-Request-Headers': '*',
//     // 'api-key': process.env.REACT_APP_DEV_DB_API_KEY
//     Authorization: `Bearer ${accessToken}`
//   };

//   if (accessToken) {
//     // eslint-disable-next-line no-undef
//     fetch(`${process.env.REACT_APP_DEV_DB_API_URL}/findOne`, {
//       method: 'POST',
//       body: JSON.stringify(queryPayload),
//       headers: headers
//       // mode: 'no-cors'
//     })
//       .then((response) => {
//         if (!response.ok) {
//           // this is where I would insert a new record as opposed to updating an existing record
//           // eslint-disable-next-line no-console
//           console.error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then((data) => {
//         // This is where I update the value if it already exists
//         // eslint-disable-next-line no-console
//         console.log('API response:', data);
//       })
//       .catch((error) => {
//         // eslint-disable-next-line no-console
//         console.log('Error:', error);
//       });
//   }
// };
