import { Divider, Typography } from 'antd';
import { map } from 'lodash';
import { React, useState } from 'react';
import FeedRecommendedJobCard from './FeedRecommendedJobCard';

const UserFeedRecommendJobs = ({ recommendedJobs }) => {
  const [recCardResults, setRecCardResults] = useState([]);
  const [recCardsFail, setRecCardsFail] = useState(false);

  const addRecCardResult = (result) => {
    const tempArray = recCardResults;
    tempArray.push(result);
    setRecCardResults(tempArray);
    if (
      tempArray.length === recommendedJobs.length &&
      recommendedJobs.length > 0
    ) {
      const recCardsFailed = !tempArray.includes('success');
      setRecCardsFail(recCardsFailed);
    }
  };

  return (
    <div className="recommend-section">
      <div className="header-section ">
        <span>Recommended Jobs</span>
      </div>
      <Divider className="recommend-divider" />
      <div className="job-section">
        {!recCardsFail ? (
          map(recommendedJobs, (item) => {
            return (
              <div key={item} className="single-job">
                <FeedRecommendedJobCard
                  jobId={item}
                  addRecCardResult={addRecCardResult}
                />
              </div>
            );
          })
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Typography.Text type="secondary">
              Recommendations Not Available
            </Typography.Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserFeedRecommendJobs;
