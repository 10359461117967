import { useLazyQuery } from '@apollo/client';
import { Button, Result, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SKIP_RECORD } from '../../../../common/constants';
import { GET_COMPANIES } from '../../../../common/graphql/Queries';
import LoaderComponent from '../../../../components/LoaderComponent';
import CompanyCard from './CompanyCard';

const Companies = (props) => {
  const initialCompaniesFilter = {
    skip: 0,
    limit: 20
  };
  const { updateResultShowing } = props;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const [companiesDebounce, setCompaniesDebounce] = useState(false);
  const [companiesIsEnd, setCompaniesIsEnd] = useState(false);
  const [count, setCount] = useState(0);
  const [companiesFilter, setCompaniesFilter] = useState(
    initialCompaniesFilter
  );
  const [companies, setCompanies] = useState([]);
  const [getCompanies] = useLazyQuery(GET_COMPANIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setCompaniesFilter({
        ...companiesFilter,
        search: query.get('query') || ''
      });
      const company = res?.searchCompanies?.data.filter(
        (comp) => comp.displayCompany === true
      );
      setCount(company?.length);
      updateResultShowing(company?.length);
      if (company?.length < SKIP_RECORD) {
        setCompaniesIsEnd(true);
      }
      setCompanies([...companies, ...company]);
      setCompaniesLoading(false);
    },
    onError() {
      setCompaniesLoading(false);
    }
  });

  const updateCompanies = (company) => {
    const unFrozenArray = companies.slice();
    for (let index = 0; index < unFrozenArray?.length; index += 1) {
      const currentCompany = unFrozenArray[index];
      if (currentCompany?.id === company?.id) {
        unFrozenArray[index] = company;
        break;
      }
    }
    setCompanies(unFrozenArray);
  };

  useEffect(() => {
    setCompaniesLoading(true);
    getCompanies({
      variables: {
        filters: { ...companiesFilter, search: query.get('query') || '' }
      }
    });
  }, []);
  if (companiesLoading && count === 0) {
    return <LoaderComponent />;
  }
  if (count === 0) {
    return <Result status="404" title="No results found, try something else" />;
  }

  const loadMore = () => {
    if (!companiesIsEnd) {
      setCompaniesLoading(true);
      getCompanies({
        variables: {
          filters: {
            ...companiesFilter,
            skip: (companiesDebounce + 1) * SKIP_RECORD
          }
        }
      });
      setCompaniesDebounce((prevState) => prevState + 1);
    }
  };
  return (
    <div className="site-card-wrapper company company-card">
      <Row className="companies-grid" gutter={12}>
        {companies.map((company) => (
          <CompanyCard
            key={company.id}
            updateCompanies={updateCompanies}
            company={company}
          />
        ))}
      </Row>
      {!companiesLoading && !companiesIsEnd && (
        <div className="loadMoreButton">
          <Button className="follow-btn" onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}
      {companiesLoading && <LoaderComponent setHeight={10} />}
    </div>
  );
};

export default Companies;
