import { useMutation } from '@apollo/client';
import { Button, Divider } from 'antd';
import React, { useContext, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import logo from '../../assets/logo.png';
import mobileLogo from '../../assets/mobileLogo.png';
import { ANALYTICS_EVENTS, BREAKPOINTS, ROUTES } from '../../common/constants';
import { analytics } from './Firebase';
import { RESEND_USER_OTP, VERIFY_USER_OTP } from './graphql/Mutations';

const VerifyEmail = () => {
  const { getCurrentUser } = useContext(AppContext);
  const history = useHistory();
  const { email } = getCurrentUser();
  const [otp, setOtp] = useState();
  const [verifyUserOtp, { loading: otpLoading }] = useMutation(VERIFY_USER_OTP);
  const [resendUserOtp] = useMutation(RESEND_USER_OTP);
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);

  const handleResend = () => {
    try {
      resendUserOtp({
        variables: {
          where: {
            email
          }
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleChange = (getOtp) => {
    setOtp(getOtp);
  };

  const handleSubmit = async () => {
    try {
      const response = await verifyUserOtp({
        variables: {
          where: {
            otp: otp,
            email
          }
        }
      });
      if (response) {
        // eslint-disable-next-line no-undef
        localStorage.setItem('isEmailVerified', 'true');
        // eslint-disable-next-line no-undef
        if (localStorage.getItem('isOnboardingCompleted')) {
          analytics.logEvent(ANALYTICS_EVENTS.USER_OTP_ENTERED, {
            status: true
          });
          history.replace(ROUTES.FILL_PROFILE);
          // eslint-disable-next-line no-undef
        } else if (localStorage.getItem('isCompanyOnboardingCompleted')) {
          analytics.logEvent(ANALYTICS_EVENTS.COMPANY_OTP_ENTERED, {
            status: true
          });
          history.replace(ROUTES.COMPANY_PROFILE);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-undef
      const isCompany = localStorage.getItem('isOnboardingCompleted');
      if (isCompany)
        analytics.logEvent(ANALYTICS_EVENTS.USER_OTP_ENTERED, {
          status: false
        });
      else
        analytics.logEvent(ANALYTICS_EVENTS.COMPANY_OTP_ENTERED, {
          status: false
        });
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <div
      className={`${
        isMobileViewPort
          ? 'verify-email d-flex'
          : 'verify-email d-flex flex-vertical'
      }`}
    >
      {isMobileViewPort && (
        <div className="verify-email-image-text">
          <img src={logo} alt="logo" className="logo" />
          <h1>
            Sophisticated, non-biased A.I. & M.L. algorithm that matches job
            openings to Black professional applicants.
          </h1>
        </div>
      )}

      {!isMobileViewPort && (
        <div className="mobile-login-header">
          <div className="d-flex align-center justify-between">
            <img src={mobileLogo} alt="logo" className="logo" height="35px" />
          </div>
          <Divider />
        </div>
      )}
      <div className="verify-email-detail-section">
        <div className="d-flex justify-between align-center">
          <h1>Verify email address</h1>
        </div>
        <div>
          <p className="verify-email-text">
            {`Please enter the OTP sent to “${email}”. Please check the promotions and spam folder as well.`}
          </p>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            separator={<span>-</span>}
            inputStyle={{
              width: '2.5rem',
              height: '2.5rem',
              margin: '0 0.5rem',
              fontSize: '1.5rem',
              borderRadius: 8,
              border: '1px solid rgba(0,0,0,0.3)'
            }}
          />
          <div className="d-flex mt-25 flex-vertical">
            <Button
              className="submit-button"
              onClick={() => {
                handleSubmit();
              }}
              type="primary"
              loading={otpLoading || false}
            >
              Submit
            </Button>
            <div className="d-flex align-center font-600 font-larger mt-25">
              <p className="mb-0">Didn't Receive OTP?</p>
              <span
                className="resend-button-link"
                onClick={() => {
                  handleResend();
                }}
              >
                Resend
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
