import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Tag,
  Tooltip
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { debounce, drop, map, omit, split, values, valuesIn } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
  CloseIcon,
  Company,
  DollarSign,
  Graduation
} from '../../../assets/svg';
import {
  ANALYTICS_EVENTS,
  BREAKPOINTS,
  EMPLOYEE_TYPE,
  ROUTES,
  SKIP_RECORD,
  WORK_TYPE
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import { analytics } from '../../auth/Firebase';
import SearchLocationInput from '../../auth/SearchLocation';
import { GET_INDUSTRIES } from '../../auth/graphql/Queries';
import '../companyJob.less';
import { CREATE_JOB, UPDATE_JOB } from '../graphql/Mutations';
import { GET_JOB_DETAIL } from '../graphql/Queries';

const { required } = formValidatorRules;
const { Option } = Select;

let industryDebounceJob;

const initialIndustryFilter = {
  skip: 0,
  limit: 20,
  search: ''
};
const { TextArea } = Input;

const NewJob = () => {
  const [form] = useForm();
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [createJob] = useMutation(CREATE_JOB);
  const [updateJob] = useMutation(UPDATE_JOB);
  const [valuesChange, setValuesChange] = useState(true);
  const isLaptopViewPort = useMedia(`(max-width: ${BREAKPOINTS?.laptop}px)`);
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);
  const [locationObject, setLocationObject] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [industryData, setIndustryData] = useState([]);
  const [industryLoading, setIndustryLoading] = useState(false);
  const [industrySearchFlag, setIndustrySearchFlag] = useState(false);
  const [industryIsEnd, setIndustryIsEnd] = useState(false);
  const [industryDebounceCall, setIndustryDebounceCall] = useState(0);

  const handleClose = (removedTag) => {
    setLocationObject(locationObject.filter((tag) => tag !== removedTag));
  };

  const [fetchIndustries] = useLazyQuery(GET_INDUSTRIES, {
    variables: {
      filter: {
        skip: 0
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.getIndustries?.industries?.length < SKIP_RECORD) {
        setIndustryIsEnd(true);
      }
      if (industrySearchFlag) {
        setIndustryData([...res?.getIndustries?.industries]);
      } else {
        setIndustryData([...industryData, ...res?.getIndustries?.industries]);
      }
      setIndustryLoading(false);
    },
    onError() {
      setIndustryLoading(false);
    }
  });

  const [job, { loading: jobDataLoading }] = useLazyQuery(GET_JOB_DETAIL, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      // eslint-disable-next-line no-console
      setLocationObject(
        map(res?.job?.location, (data) => drop(values(data), 1).join(','))
      );
      form.setFieldsValue({
        title: res?.job?.title,
        type: res?.job?.type,
        noOfVacancies: res?.job?.noOfVacancies,
        minimum: res?.job?.salary?.minimum || null,
        maximum: res?.job?.salary?.maximum || null,
        workType: res?.job?.workType,
        minQualification: res?.job?.minQualification,
        preferredQualification: res?.job?.preferredQualification,
        about: res?.job?.about,
        responsibilities: res?.job?.responsibilities,
        industry: res?.job?.industry?.id,
        location: valuesIn(res?.job?.location[0]).join(',')
      });
      // setLocationObject(res?.job?.location);
    }
  });

  useEffect(() => {
    job({
      variables: { where: { id: id } }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const industryScroll = (event) => {
    setIndustrySearchFlag(false);
    if (industryDebounceJob) {
      industryDebounceJob?.cancel();
    }

    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;

      if (scrolledToBottom && !industryIsEnd) {
        setIndustryLoading(true);
        setIndustryDebounceCall((prevState) => prevState + 1);
        fetchIndustries({
          variables: {
            filter: {
              ...initialIndustryFilter,
              skip: (industryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            }
          }
        });
      }
    }, 500);
    industryDebounceJob();
  };

  const handleIndustryChange = (value) => {
    setIndustrySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setIndustryLoading(true);
      fetchIndustries({
        variables: {
          filter: {
            ...initialIndustryFilter,
            search: value
          }
        }
      });
    } else {
      setIndustryLoading(false);
      fetchIndustries({
        variables: {
          filter: {
            ...initialIndustryFilter,
            search: value
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedProductTypeHandler = useCallback(
    debounce(handleIndustryChange, 500),
    []
  );

  const handleIndustryBlur = () => {
    setSearchValue('');
    setIndustryIsEnd(false);
    setIndustryDebounceCall(0);
  };

  useEffect(() => {
    // setIndustryLoading(true);
    fetchIndustries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (formValues) => {
    try {
      setLoading(true);
      const variables = {
        ...formValues,
        noOfVacancies: Number(formValues?.noOfVacancies),
        salary: {
          minimum: Number(formValues?.minimum),
          maximum: Number(formValues?.maximum)
        }
      };

      if (locationObject) {
        variables.location = map(locationObject, (val) => ({
          city: split(val, ',')[0] ? split(val, ',')[0].trim() : null,
          state: split(val, ',')[1] ? split(val, ',')[1].trim() : null,
          country: split(val, ',')[2] ? split(val, ',')[2].trim() : null
        }));
      }
      const data = omit(variables, ['minimum', 'maximum']);
      const mutation = id ? updateJob : createJob;
      const variable = id ? { where: { id: id }, data: data } : { data: data };
      const response = await mutation({
        variables: variable
      });
      if (response) {
        if (!id) {
          analytics.logEvent(ANALYTICS_EVENTS.POST_JOB);
        }
        history.push(ROUTES.COMPANY_FEED);
      }
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  const onChange = () => {
    setValuesChange(false);
  };

  useEffect(() => {
    form.setFieldsValue({ location: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationObject]);

  if (jobDataLoading) {
    return <LoaderComponent />;
  }
  return (
    <div className="new-job">
      <div className="header">
        <h1>Hire The Talent You Need</h1>
        <p>Start Hiring From Millions Of Creative Professionals</p>
      </div>
      <Form
        form={form}
        layout="vertical"
        className="mt-25 signup-detail-section-form"
        onFinish={onFinish}
        onValuesChange={onChange}
      >
        <Row gutter={50}>
          <Col span={isMobileViewPort ? 20 : 24}>
            <Form.Item label="Job Title" name="title" rules={[required]}>
              <Input placeholder="Enter job title" prefix={<Company />} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={isLaptopViewPort ? 10 : 50}>
          <Col span={isMobileViewPort ? 6 : 24}>
            <Form.Item label="Employment type" name="type" rules={[required]}>
              <Select allowClear placeholder="Select Employment type">
                {map(EMPLOYEE_TYPE, (employeeType) => {
                  return (
                    <Option key={employeeType?.key} value={employeeType?.key}>
                      {employeeType?.value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={isMobileViewPort ? 8 : 24}>
            <Form.Item
              label="Number of vacancies to fill:"
              name="noOfVacancies"
              rules={[
                { required: true, message: 'Required' },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise?.resolve();
                    }
                    if (value <= 0) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject(
                        'Vacancies should be greater than 0'
                      );
                    }
                    return Promise?.resolve();
                  }
                })
              ]}
            >
              <Input
                type="number"
                placeholder="Enter how many vacancies you wish to fill"
                prefix={<Graduation />}
              />
            </Form.Item>
          </Col>
          <Col span={isMobileViewPort ? 6 : 24}>
            <Form.Item label="Industry" name="industry" rules={[required]}>
              <Select
                loading={industryLoading}
                showSearch
                allowClear
                placeholder="Industry"
                optionFilterProp="children"
                getPopupContainer={(trigger) => trigger.parentNode}
                onPopupScroll={industryScroll}
                onClear={handleIndustryBlur}
                onSearch={debouncedProductTypeHandler}
                notFoundContent={
                  industryLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty />
                  )
                }
              >
                {map(industryData, (item) => (
                  <Option key={item?.key} value={item?.id}>
                    {item?.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={isLaptopViewPort ? 10 : 50}>
          <Col span={isMobileViewPort ? 12 : 24}>
            <p className="salary-label">Salary range</p>
            <Input.Group>
              <Row>
                <Col span={isMobileViewPort ? 11 : 24}>
                  <Form.Item
                    name="minimum"
                    className="salary"
                    rules={[
                      ({ getFieldValue }) => ({
                        required:
                          getFieldValue('minimum') || getFieldValue('maximum'),
                        message: 'Enter minimum salary'
                      }),
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise?.resolve();
                          }
                          if (value <= 0) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise?.reject(
                              'Salary should be greater than 0'
                            );
                          }
                          return Promise?.resolve();
                        }
                      })
                    ]}
                  >
                    <Input
                      placeholder="minimum"
                      type="number"
                      prefix={<DollarSign />}
                    />
                  </Form.Item>
                </Col>
                <Col className="margin-10">-</Col>
                <Col span={isMobileViewPort ? 11 : 24}>
                  <Form.Item
                    name="maximum"
                    rules={[
                      ({ getFieldValue }) => ({
                        required:
                          getFieldValue('minimum') || getFieldValue('maximum'),
                        message: 'Enter maximum salary'
                      }),
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise?.resolve();
                          }
                          if (value <= 0) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise?.reject(
                              'Salary should be greater than 0'
                            );
                          }
                          if (
                            getFieldValue('minimum') &&
                            value <= Number(getFieldValue('minimum'))
                          ) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise?.reject(
                              'Maximum salary should be greater than minimum salary'
                            );
                          }
                          return Promise?.resolve();
                        }
                      })
                    ]}
                  >
                    <Input
                      placeholder="maximum"
                      type="number"
                      prefix={<DollarSign />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
          </Col>
          <Col span={isMobileViewPort ? 12 : 24}>
            <Form.Item label="Onsite/Remote" name="workType" rules={[required]}>
              <Radio.Group>
                {map(WORK_TYPE, (val) => {
                  return <Radio value={val?.key}>{val?.value}</Radio>;
                })}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col span={isMobileViewPort ? 20 : 24}>
            <Form.Item
              label="Job location"
              name="location"
              rules={[
                {
                  required: locationObject?.length <= 0,
                  message: 'Select a location'
                }
              ]}
            >
              <SearchLocationInput
                onChange={onChange}
                setLocationArray={setLocationObject}
                locationArray={locationObject}
                isMultiLocation
              />
              {locationObject.map((tag) => {
                const isLongTag = tag.length > 20;
                const tagElem = (
                  <Tag
                    className="edit-tag"
                    key={tag}
                    closable
                    onClose={() => handleClose(tag)}
                    closeIcon={<CloseIcon />}
                  >
                    <span>{isLongTag ? `${tag.slice(0, 20)}...` : tag}</span>
                  </Tag>
                );
                return isLongTag ? (
                  <Tooltip title={tag} key={tag}>
                    {tagElem}
                  </Tooltip>
                ) : (
                  tagElem
                );
              })}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col span={isMobileViewPort ? 20 : 24}>
            <Form.Item
              label="Minimum qualifications:"
              name="minQualification"
              rules={[required]}
            >
              <TextArea
                placeholder="Enter your minimum qualifications"
                rows={7}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col span={isMobileViewPort ? 20 : 24}>
            <Form.Item
              label="Preferred qualifications:"
              name="preferredQualification"
              rules={[required]}
            >
              <TextArea
                placeholder="Enter your preferred qualifications"
                rows={7}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col span={isMobileViewPort ? 20 : 24}>
            <Form.Item label="About the job:" name="about" rules={[required]}>
              <TextArea placeholder="Enter details about the job" rows={7} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col span={isMobileViewPort ? 20 : 24}>
            <Form.Item
              label="Responsibilities:"
              name="responsibilities"
              rules={[required]}
            >
              <TextArea placeholder="Enter the job responsibilities" rows={7} />
            </Form.Item>
          </Col>
        </Row>

        <Row
          gutter={isLaptopViewPort ? 10 : 50}
          // justify="space-between"
          className="signup-button-div"
        >
          <Col span={isMobileViewPort ? 12 : 24}>
            <p>
              By clicking “Submit” you’re{' '}
              <a
                href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                agreeing{' '}
              </a>{' '}
              that the above information is accurate
            </p>
          </Col>
          <Col span={isMobileViewPort ? 8 : 24}>
            <Form.Item>
              <Button
                type="primary"
                className="fill-width"
                htmlType="submit"
                loading={loading}
                disabled={valuesChange}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default NewJob;
