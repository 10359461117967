import { gql } from '@apollo/client';

export const UPDATE_POST = gql`
  mutation updatePost($data: CreatePostInput!) {
    updatePost(data: $data) {
      data {
        id
        text
        mediaUrl
        createdBy {
          id
          firstName
          lastName
          bio
          profileImage
          isVerified
        }
        isLiked
        likeCount
        commentCount
        comments {
          postId
          text
        }
      }
      message
    }
  }
`;

export const CREATE_JOB = gql`
  mutation createJob($data: CreateJobInput!) {
    createJob(data: $data) {
      message
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation updateJob($data: UpdateJobInput!, $where: CommonWhereInput!) {
    updateJob(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_POST = gql`
  mutation deletePost($where: CommonWhereInput!) {
    deletePost(where: $where) {
      message
    }
  }
`;

export const GET_SIGN_URL = gql`
  mutation getPostSignedUrl($data: CommonFileInput!) {
    getPostSignedUrl(data: $data) {
      fileName
      signedUrl
      getUrl
    }
  }
`;

export const LIKE_POST = gql`
  mutation likePost($where: CommonWhereInput!, $action: PostAction!) {
    likePost(where: $where, action: $action) {
      message
    }
  }
`;

export const COMMENT_POST = gql`
  mutation commentPost(
    $where: CommonWhereInput!
    $data: CreateUpdateCommentInput!
  ) {
    commentPost(where: $where, data: $data) {
      message
    }
  }
`;
