import { Button, Divider, Form, Input } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import logo from '../../assets/logo.png';
import mobileLogo from '../../assets/mobileLogo.png';
import { Email, PasswordIcon } from '../../assets/svg';
import { BREAKPOINTS, ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { CompanySignInWithEmailAndPassword } from './Firebase';

const { required, email } = formValidatorRules;

const CompanyLogin = () => {
  const history = useHistory();
  const [loginLoading, setLoginLoading] = useState(false);
  const { initializeAuth } = useContext(AppContext);
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);

  async function successCallback(
    accessToken,
    userData,
    isEmailVerified,
    isCompanyOnboardingCompleted,
    isCompanyVerified,
    subscriptionStatus
  ) {
    initializeAuth(accessToken, userData);
    // eslint-disable-next-line no-undef
    localStorage.setItem('isEmailVerified', isEmailVerified);
    // eslint-disable-next-line no-undef
    localStorage.setItem(
      'isCompanyOnboardingCompleted',
      isCompanyOnboardingCompleted
    );
    // eslint-disable-next-line no-undef
    localStorage.setItem('isCompanyVerified', isCompanyVerified);
    setLoginLoading(false);
    // eslint-disable-next-line no-undef
    localStorage.setItem('subscriptionStatus', subscriptionStatus);
    if (!isEmailVerified) {
      history.replace(ROUTES.VERIFY_EMAIL);
    } else if (!isCompanyOnboardingCompleted) {
      history.replace(ROUTES.COMPANY_PROFILE);
    } else if (!isCompanyVerified) {
      history.replace(ROUTES.RESULT);
    } else {
      history.replace(ROUTES?.COMPANY_FEED);
    }
  }

  const onFinish = async (formValues) => {
    try {
      const loggedIn = await CompanySignInWithEmailAndPassword(
        formValues?.email,
        formValues?.password,
        setLoginLoading
      );
      if (loggedIn?.token) {
        successCallback(
          loggedIn?.token,
          loggedIn?.userData,
          loggedIn?.isCompanyEmailVerified,
          loggedIn?.success,
          loggedIn?.isCompanyVerified,
          loggedIn?.subscriptionStatus
        );
      } else {
        history.push(ROUTES.COMPANY_LOGIN);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <div style={{ height: '100%' }} className="d-flex">
      {isMobileViewPort && (
        <div className="login">
          <img src={logo} alt="logo" className="logo" height="120px" />
          <div className="login-text">
            <h1>Making job equity a reality</h1>
            <p className="mt-25 font-16">
              A new employment platform that leverages A.I. & M.L. to make it
              easy for companies to find and hire Black talents, onboard and
              manage their experiences.
            </p>
            <Button
              className="create-account-button"
              onClick={() => {
                history.push(ROUTES.COMPANY_SIGNUP);
              }}
            >
              Create Account Now
            </Button>
          </div>
        </div>
      )}
      <div>
        {!isMobileViewPort && (
          <div className="mobile-login-header">
            <div className="d-flex align-center justify-between">
              <img src={mobileLogo} alt="logo" className="logo" height="35px" />
              <Button
                type="link"
                className="font-18 mobile-create-account-button"
                onClick={() => {
                  history.push(ROUTES.COMPANY_SIGNUP);
                }}
              >
                Create Account
              </Button>
            </div>
            <Divider />
          </div>
        )}
        <div className="login-screen">
          <h1>Company Login</h1>
          <p>
            Make the world more equitable by creating jobs, events, and
            communities that celebrate diversity.
          </p>
          <Divider />
          <div className="login-form">
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item label="Email" name="email" rules={[required, email]}>
                <Input prefix={<Email />} placeholder="Enter your Email" />
              </Form.Item>
              <Form.Item
                label="Password"
                className="mt-25"
                name="password"
                rules={[required]}
              >
                <Input.Password
                  placeholder="Enter your Password"
                  prefix={<PasswordIcon />}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  className="fill-width"
                  htmlType="submit"
                  loading={loginLoading}
                >
                  Login
                </Button>
              </Form.Item>
              <div className="d-flex justify-center font-18 mt-25 flex-wrap">
                Forgot your password?
                <b
                  className="ml-5 pointer text-underline"
                  onClick={() => {
                    history.push(ROUTES.RESET);
                  }}
                >
                  Recover Password
                </b>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyLogin;
