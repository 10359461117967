import { useLazyQuery } from '@apollo/client';
import { debounce, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  BIG_SCREEN,
  POST_SKIP_RECORD,
  SMALL_TABLET,
  TABLET_BREAKPOINT
} from '../../../common/constants';
import CompanyJobs from '../component/CompanyJobs';
import FeedList from '../component/FeedList';
import FeedSideBar from '../component/FeedSideBar';
import { GET_MY_JOBS, GET_POSTS } from '../graphql/Queries';

let postDebounceJob;

const postInitialFilter = {
  skip: 0,
  limit: POST_SKIP_RECORD,
  scope: 'GLOBAL'
};

const FeedPage = () => {
  const {
    dispatch,
    getCurrentUser,
    state: { windowWidth }
  } = useContext(AppContext);
  const userData = getCurrentUser();

  const [postData, setPostData] = useState([]);
  const [postLoading, setPostLoading] = useState(true);
  const [postIsEnd, setPostIsEnd] = useState(false);
  const [postDebounceCall, setPostDebounceCall] = useState(0);
  const [postFilter, setPostFilter] = useState(postInitialFilter);
  const [scopeLoading, setScopeLoading] = useState(false);
  const [myJobs, setMyJob] = useState([]);
  const [jobLoader, setJobLoader] = useState(true);

  const [getPosts] = useLazyQuery(GET_POSTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.posts?.data?.length < POST_SKIP_RECORD) {
        setPostIsEnd(true);
      }

      setPostData(uniqBy([...postData, ...res?.posts?.data], 'id'));
      setPostLoading(false);
      setScopeLoading(false);
    },
    onError() {
      setPostLoading(false);
    }
  });

  const [getMyJobs] = useLazyQuery(GET_MY_JOBS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (res) => {
      setMyJob(res?.jobs?.data);
      setJobLoader(false);
    }
  });

  useEffect(() => {
    if (windowWidth < SMALL_TABLET) {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: false, profileId: '' }
      });
    } else if (windowWidth < TABLET_BREAKPOINT) {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: false, profileId: '' }
      });
    } else if (windowWidth < BIG_SCREEN) {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: false, profileId: '' }
      });
    } else {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: false, profileId: '' }
      });
    }
    // dispatch({
    //   type: 'SET_DRAWER',
    //   data: { showDrawer: false, profileId: '' }
    // });

    getPosts({
      variables: {
        filters: postFilter
      }
    });

    getMyJobs({
      variables: {
        companyId: userData.id
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPostScroll = (event) => {
    if (postDebounceJob) {
      postDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    postDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !postIsEnd) {
        setPostLoading(true);
        setPostDebounceCall((prevState) => prevState + 1);
        getPosts({
          variables: {
            filters: {
              ...postFilter,
              skip: (postDebounceCall + 1) * POST_SKIP_RECORD
              // search: searchValue
            }
          }
        });
      }
    }, 500);

    postDebounceJob();
  };

  const updatePostDataFromChild = (data) => {
    setPostData([]);
    setPostData(data);
  };

  return (
    <div onScroll={onPostScroll} className="wrapper-class">
      <FeedSideBar />
      <FeedList
        postData={postData}
        updatePostDataFromChild={updatePostDataFromChild}
        setPostLoading={setPostLoading}
        postLoading={postLoading}
        getPosts={getPosts}
        postFilter={postFilter}
        setPostFilter={setPostFilter}
        scopeLoading={scopeLoading}
        setScopeLoading={setScopeLoading}
      />
      <CompanyJobs loading={jobLoader} data={myJobs} />
    </div>
  );
};

export default FeedPage;
