import { Divider, Typography } from 'antd';
import AWS from 'aws-sdk';
import { map } from 'lodash';
import { React, useEffect, useState } from 'react';
import RecommendedAppCard from './RecommendedAppCard';

const AppRecommenderContainer = ({ jobId, jobTitle }) => {
  const [recommendedUserIds, setRecommendedUserIds] = useState([]);
  const [recCardsFail, setRecCardsFail] = useState(false);
  const [recCardResults, setRecCardResults] = useState([]);

  useEffect(() => {
    const options = {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    };

    const personalizeRuntime = new AWS.PersonalizeRuntime(options);

    const params = {
      campaignArn: process.env.REACT_APP_PERSONALIZE_CAMPAIGN_ARN_APPLICANTS,
      numResults: '8',
      userId: jobId
    };

    personalizeRuntime.getRecommendations(params, function (err, data) {
      if (err) {
        // eslint-disable-next-line no-console
        console.log(err, err.stack); // an error occurred
      } else {
        // Returns an array of user_detail IDs
        const resultArray = [];
        data.itemList.forEach((item) => {
          resultArray.push(item.itemId);
        });
        setRecommendedUserIds(resultArray);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addRecCardResult = (result) => {
    const tempArray = recCardResults;
    tempArray.push(result);
    setRecCardResults(tempArray);
    if (
      tempArray.length === recommendedUserIds.length &&
      recommendedUserIds.length > 0
    ) {
      const recCardsFailed = !tempArray.includes('success');
      setRecCardsFail(recCardsFailed);
    }
  };

  return (
    <div className="recommend-section">
      <div className="header-section ">
        <span>Recommended Candidates</span>
      </div>
      <Divider className="recommend-divider" />
      <div className="rec-app-section">
        {!recCardsFail ? (
          map(recommendedUserIds, (item) => {
            return (
              <div key={item} className="single-app">
                <RecommendedAppCard
                  userId={item}
                  jobId={jobId}
                  jobTitle={jobTitle}
                  addRecCardResult={addRecCardResult}
                />
              </div>
            );
          })
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Typography.Text type="secondary">
              Recommendations Not Available
            </Typography.Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppRecommenderContainer;
