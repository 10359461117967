import { Avatar, Card } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import placeholder from '../../../assets/images/placeholder.jpg';
import { ROUTES } from '../../../common/constants';
import history from '../../../historyData';

const NotificationCard = ({ data, setNotificationVisible }) => {
  // eslint-disable-next-line no-unused-vars
  const [src, setSrc] = useState(data?.image || placeholder);

  const handleOnTap = (actionType, actionId) => {
    if (
      actionType === 'CONNECTION_REQUEST' ||
      actionType === 'CONNECTION_ACCEPTED'
    ) {
      history?.replace(`${ROUTES?.PROFILE_ABOUT}/${actionId}`);
    } else if (
      actionType === 'APPLICATION_ACCEPTED' ||
      actionType === 'APPLICATION_REJECTED' ||
      actionType === 'APPLICATION_PENDING' ||
      actionType === 'APPLICATION_SHORTLISTED'
    ) {
      history?.replace(`${ROUTES?.JOB_DETAILS}/${actionId}`);
    }
    setNotificationVisible(false);
  };

  return (
    <div className="notification-card d-flex">
      <Card className="d-flex">
        <div
          className="d-flex align-center"
          onClick={() => {
            handleOnTap(data?.action, data?.actionId);
          }}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: ' 0px 10px',
            cursor: 'pointer'
          }}
        >
          <Avatar src={src} className="" />
          <div className="mb-0" style={{ width: '60%', padding: '8px' }}>
            {data?.message}
          </div>
          <div className="mb-0">{moment(data?.createdAt).fromNow()}</div>
        </div>
      </Card>
    </div>
  );
};

export default NotificationCard;
