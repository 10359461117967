import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import history from '../../historyData';
import './feedModule.less';
import FeedPage from './pages/FeedPage';

const FeedWrapper = () => {
  // eslint-disable-next-line no-undef
  const isCompany = localStorage.getItem('isCompanyVerified');
  if (isCompany) {
    history.replace(ROUTES.COMPANY_FEED);
  }

  return (
    <div className="feed-module">
      <Switch>
        <>
          <Route exact path={ROUTES?.FEED} component={FeedPage} />
          {/* <Route exact path={`${ROUTES?.FEED}/:id`} component={PostDetail} /> */}
        </>
      </Switch>
    </div>
  );
};

export default FeedWrapper;
