import { gql } from '@apollo/client';

export const UPDATE_JOB_APPLICATION_STATUS = gql`
  mutation updateJobApplicationStatus(
    $id: String!
    $status: JobApplicationStatus
  ) {
    updateJobApplicationStatus(where: { id: $id }, status: $status) {
      message
    }
  }
`;

export const ARCHIVE_JOB = gql`
  mutation archiveUnarchiveJob($id: String!) {
    archiveUnarchiveJob(where: { id: $id }, action: ARCHIVE) {
      message
    }
  }
`;

export const DELETE_JOB = gql`
  mutation deleteJob($id: String!) {
    deleteJob(where: { id: $id }) {
      message
    }
  }
`;
