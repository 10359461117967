/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Tag,
  Tooltip,
  Upload
} from 'antd';
import axios from 'axios';
import { debounce, drop, isObject, join, map, omit, split } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import placeholder from '../../../assets/images/placeholder.jpg';
import {
  Building,
  CloseIcon,
  Company,
  DollarSign,
  Email,
  LinkIcon,
  Plan
} from '../../../assets/svg';
import {
  BREAKPOINTS,
  defaultDateFormat,
  EMPLOYEE_SIZE,
  ISOStringDateFormat,
  MAX_PAST_DATE,
  REGEX,
  ROUTES,
  SKIP_RECORD,
  USER
} from '../../../common/constants';
import { GET_COMPANY } from '../../../common/graphql/Queries';
import { formValidatorRules, getBase64 } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import PaymentModal from '../../../components/PaymentModal';
import {
  EDIT_COMPANY_PROFILE,
  GET_COMPANY_PROFILE_SIGNED_URL
} from '../../auth/graphql/Mutations';
import { GET_INDUSTRIES } from '../../auth/graphql/Queries';
import SearchLocationInput from '../../auth/SearchLocation';
import CancelSubscriptionModal from '../component/CancelSubscriptionModal';
import SearchLocationInputSingle from '../component/SearchLocation';
import UpdatePricingModal from '../component/UpdatePricingModal';
import { GET_ACTIVATION_PLAN } from '../graphql/Mutation';

let industryDebounceJob;

const { required, number } = formValidatorRules;

const initialIndustryFilter = {
  skip: 0,
  limit: 20,
  search: ''
};

const UpdateCompanyForm = () => {
  const [locationObject, setLocationObject] = useState([]);
  const { dispatch, getCurrentUser } = useContext(AppContext);
  const [form] = Form.useForm();
  const { profileImage } = getCurrentUser();
  const [src, setSrc] = useState(profileImage || placeholder);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const { TextArea } = Input;
  const { Option } = Select;
  const isLaptopViewPort = useMedia(`(max-width: ${BREAKPOINTS?.laptop}px)`);
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);
  const [bookMarkKey, setBookMarkKey] = useState('profile');
  const [initialValues, setInitialValues] = useState(null);
  const [moreLocationObject, setMoreLocationObject] = useState([]);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [sizeSelected, setSizeSelected] = useState();
  const [finalSize, setFinalSize] = useState(sizeSelected);
  const [employeeSize, setEmployeeSize] = useState(sizeSelected);
  const [isDisable, setIsDisable] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [paymentModal, setPaymentModal] = useState(false);
  const [monthlyId, setMonthlyId] = useState('');
  const [yearlyId, setYearlyId] = useState('');
  const [monthlyAmount, setMonthlyAmount] = useState();
  const [yearlyAmount, setYearlyAmount] = useState();
  const [planLoading, setPlanLoading] = useState(false);
  const [billingDate, setBillingDate] = useState();

  const [getActivationPlan] = useMutation(GET_ACTIVATION_PLAN);
  const [getUser] = useLazyQuery(GET_COMPANY, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      // eslint-disable-next-line no-undef
      localStorage.setItem(USER, JSON.stringify(res?.companyMe));
      dispatch({ type: 'SET_CURRENT_USER', data: res?.companyMe });
      let locationTemp;
      if (res?.companyMe?.hqLocation) {
        const locationStr = join(
          drop(Object.values(res?.companyMe?.hqLocation), 1)
        );
        locationTemp = {
          city: split(locationStr, ',')[0]
            ? split(locationStr, ',')[0].trim()
            : null,
          state: split(locationStr, ',')[1]
            ? split(locationStr, ',')[1].trim()
            : null,
          country: split(locationStr, ',')[2]
            ? split(locationStr, ',')[2].trim()
            : null
        };
      }
      if (res?.companyMe?.branches) {
        const moreLocationTemp = [];
        map(res?.companyMe?.branches, (branch) => {
          const locationStr = join(drop(Object.values(branch), 1));
          const temp = {
            city: split(locationStr, ',')[0]
              ? split(locationStr, ',')[0].trim()
              : null,
            state: split(locationStr, ',')[1]
              ? split(locationStr, ',')[1].trim()
              : null,
            country: split(locationStr, ',')[2]
              ? split(locationStr, ',')[2].trim()
              : null
          };
          moreLocationTemp.push(
            `${temp?.city},${temp?.state},${temp?.country}`
          );
          setMoreLocationObject([...moreLocationTemp]);
        });
      }

      setSubscriptionStatus(res?.companyMe?.subscriptionStatus);
      setBillingDate(res?.companyMe?.subscription?.nextBillingDate);
      if (res?.companyMe?.subscription?.nextBillingDate) {
        const now = moment();
        const expirationDate = moment(
          res?.companyMe?.subscription?.nextBillingDate
        );
        const difference = expirationDate.diff(now, 'months', true);
        if (difference >= 0 && difference <= 1) {
          setIsDisable(false);
        } else {
          setIsDisable(true);
        }
      }

      const initialValueCopy = {
        profileImage: res?.companyMe?.profileImage,
        bio: res?.companyMe?.bio,
        name: res?.companyMe?.name,
        establishedYear: moment(
          new Date(res?.companyMe?.establishedYear),
          defaultDateFormat
        ),
        description: res?.companyMe?.description,
        industry: res?.companyMe?.industry?.id,
        employeeSize: res?.companyMe?.employeeSize,
        website: res?.companyMe?.website,
        specialties: res?.companyMe?.specialties,
        totalRounds:
          res?.companyMe?.funding?.totalRounds === 0
            ? null
            : res?.companyMe?.funding?.totalRounds,
        fundsRaised:
          res?.companyMe?.funding?.fundsRaised === 0
            ? null
            : res?.companyMe?.funding?.fundsRaised,
        lastRoundInvestor: res?.companyMe?.funding?.lastRoundInvestor,
        lastRoundDate: res?.companyMe?.funding?.lastRoundDate
          ? moment(res?.companyMe?.funding?.lastRoundDate, ISOStringDateFormat)
          : null,
        hqLocation: locationTemp,
        email: res?.companyMe?.email,
        plan:
          // eslint-disable-next-line no-nested-ternary
          res?.companyMe?.subscription?.plan === 'YEARLY'
            ? 'Yearly'
            : res?.companyMe?.subscription?.plan === 'MONTHLY'
            ? 'Monthly'
            : '',
        nextBillingDate: res?.companyMe?.subscription?.nextBillingDate
          ? moment(
              res?.companyMe?.subscription?.nextBillingDate,
              ISOStringDateFormat
            )
          : null,
        nextBillingAmount: res?.companyMe?.subscription?.nextBillingAmount,
        addressLine1:
          res?.companyMe?.subscription?.billingAddress?.addressLine1,
        addressLine2:
          res?.companyMe?.subscription?.billingAddress?.addressLine2,
        state: res?.companyMe?.subscription?.billingAddress?.state,
        country: res?.companyMe?.subscription?.billingAddress?.country,
        zipCode: res?.companyMe?.subscription?.billingAddress?.zipCode
      };
      setLocationObject(
        join(drop(Object.values(res?.companyMe?.hqLocation), 1))
      );
      setSizeSelected(res?.companyMe?.employeeSize);
      setEmployeeSize(res?.companyMe?.employeeSize);
      setFinalSize(res?.companyMe?.employeeSize);
      setInitialValues(initialValueCopy);

      if (saveLoading) {
        // eslint-disable-next-line no-undef
        window.location.href = ROUTES.COMPANY_PROFILE_JOBS;
      }
      setSaveLoading(false);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const [industryData, setIndustryData] = useState([]);
  const [industryLoading, setIndustryLoading] = useState(false);
  const [industrySearchFlag, setIndustrySearchFlag] = useState(false);
  const [industryIsEnd, setIndustryIsEnd] = useState(false);
  const [industryDebounceCall, setIndustryDebounceCall] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [getCompanyProfileSignedUrl] = useMutation(
    GET_COMPANY_PROFILE_SIGNED_URL
  );

  const [fetchIndustries] = useLazyQuery(GET_INDUSTRIES, {
    variables: {
      filter: {
        skip: 0
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.getIndustries?.industries?.length < SKIP_RECORD) {
        setIndustryIsEnd(true);
      }
      if (industrySearchFlag) {
        setIndustryData([...res?.getIndustries?.industries]);
      } else {
        setIndustryData([...industryData, ...res?.getIndustries?.industries]);
      }
      setIndustryLoading(false);
    },
    onError() {
      setIndustryLoading(false);
    }
  });

  useEffect(() => {
    form.setFieldsValue({
      employeeSize: employeeSize
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeSize]);

  const industryScroll = (event) => {
    setIndustrySearchFlag(false);
    if (industryDebounceJob) {
      industryDebounceJob?.cancel();
    }

    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;

      if (scrolledToBottom && !industryIsEnd) {
        setIndustryLoading(true);
        setIndustryDebounceCall((prevState) => prevState + 1);
        fetchIndustries({
          variables: {
            filter: {
              ...initialIndustryFilter,
              skip: (industryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            }
          }
        });
      }
    }, 500);
    industryDebounceJob();
  };

  const handleClose = (removedTag) => {
    setMoreLocationObject(
      moreLocationObject.filter((tag) => tag !== removedTag)
    );
  };

  const handleIndustryChange = (value) => {
    setIndustrySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setIndustryLoading(true);
      fetchIndustries({
        variables: {
          filter: {
            ...initialIndustryFilter,
            search: value
          }
        }
      });
    } else {
      setIndustryLoading(false);
      fetchIndustries({
        variables: {
          filter: {
            ...initialIndustryFilter,
            search: value
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedProductTypeHandler = useCallback(
    debounce(handleIndustryChange, 500),
    []
  );

  const handleIndustryBlur = () => {
    setSearchValue('');
    setIndustryIsEnd(false);
    setIndustryDebounceCall(0);
  };

  useEffect(() => {
    fetchIndustries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [editCompanyProfile] = useMutation(EDIT_COMPANY_PROFILE);

  const onFinish = async (formValues) => {
    try {
      setSaveLoading(true);
      const variables = {
        ...formValues,
        hqLocation: formValues?.hqLocation || {
          city: split(locationObject, ',')[0]
            ? split(locationObject, ',')[0].trim()
            : null,
          state: split(locationObject, ',')[1]
            ? split(locationObject, ',')[1].trim()
            : null,
          country: split(locationObject, ',')[2]
            ? split(locationObject, ',')[2].trim()
            : null
        },
        employeeSize: formValues?.employeeSize,
        funding: {
          totalRounds: Number(formValues?.totalRounds),
          lastRoundInvestor: formValues?.lastRoundInvestor,
          lastRoundDate: formValues?.lastRoundDate,
          fundsRaised: Number(formValues?.fundsRaised)
        },
        billingAddress: {
          addressLine1: formValues?.addressLine1,
          addressLine2: formValues?.addressLine2,
          state: formValues?.state,
          country: formValues?.country,
          zipCode: formValues?.zipCode
        }
      };

      if (moreLocationObject) {
        variables.branches = map(moreLocationObject, (val) => ({
          city: split(val, ',')[0] ? split(val, ',')[0].trim() : null,
          state: split(val, ',')[1] ? split(val, ',')[1]?.trim() : null,
          country: split(val, ',')[2] ? split(val, ',')[2]?.trim() : null
        }));
      }
      if (formValues?.profileImage) {
        if (isObject(formValues?.profileImage)) {
          const response = await getCompanyProfileSignedUrl({
            variables: {
              data: {
                fileName: formValues?.profileImage?.file?.name
              }
            }
          });

          if (response) {
            const uploadImgResponse = await axios.put(
              response?.data?.getCompanyProfileSignedUrl?.signedUrl,
              formValues?.profileImage?.file,
              {
                headers: {
                  'access-control-allow-origin': '*',
                  'Content-type': formValues?.profileImage?.file?.type
                }
              }
            );
            if (uploadImgResponse.status === 200) {
              variables.profileImage =
                response?.data?.getCompanyProfileSignedUrl?.getUrl;
            }
          }
        } else {
          delete variables.profileImage;
        }
      }
      delete variables.email;
      const data = omit(variables, [
        'totalRounds',
        'lastRoundInvestor',
        'lastRoundDate',
        'fundsRaised',
        'nextBillingAmount',
        'plan',
        'nextBillingDate',
        'addressLine1',
        'addressLine2',
        'state',
        'country',
        'zipCode'
      ]);

      const response = await editCompanyProfile({
        variables: {
          data: data
        }
      });
      if (response) {
        getUser();
      }
    } catch (error) {
      setSaveLoading(false);
      console.log('error', error);
    }
  };

  return (
    <>
      {subscriptionStatus === 'INACTIVE' && (
        <div className="d-flex justify-center not-subscribed align-center">
          <p className="mb-0">
            You have no active subscription.{' '}
            <span
              className="pointer"
              onClick={() => {
                setPlanLoading(true);
                setPaymentModal(true);
                getActivationPlan().then((res) => {
                  map(res?.data?.getActivationPlan?.plans, (plan) => {
                    if (plan?.type === 'YEARLY') {
                      setYearlyAmount(plan?.amount);
                      setYearlyId(plan?.id);
                    } else {
                      setMonthlyId(plan?.id);
                      setMonthlyAmount(plan?.amount);
                    }
                  });
                  setPlanLoading(false);
                });
              }}
            >
              {' '}
              Subscribe Now.
            </span>{' '}
          </p>
        </div>
      )}
      {paymentModal && (
        <PaymentModal
          showModal={paymentModal}
          setShowModal={setPaymentModal}
          monthlyAmount={monthlyAmount}
          monthlyId={monthlyId}
          yearlyAmount={yearlyAmount}
          yearlyId={yearlyId}
          loading={planLoading}
        />
      )}
      <div
        style={{
          backgroundColor: 'white'
        }}
        className={`${
          isMobileViewPort
            ? 'fill-profile d-flex'
            : 'fill-profile d-flex flex-vertical'
        }`}
      >
        {showCancelModal && (
          <CancelSubscriptionModal
            showModal={showCancelModal}
            setShowModal={setShowCancelModal}
            getUser={getUser}
          />
        )}
        {showPricingModal && (
          <UpdatePricingModal
            setShowModal={setShowPricingModal}
            showModal={showPricingModal}
            sizeSelected={sizeSelected}
            setEmployeeSize={setEmployeeSize}
            finalSize={finalSize}
            billingDate={billingDate}
          />
        )}

        <div className="editProfileSideBard">
          <div
            className={`editProfileSideBard-option ${
              bookMarkKey === 'profile'
                ? 'active font-600'
                : 'inactive font-600'
            }`}
          >
            <a
              href="#profile"
              onClick={() => {
                setBookMarkKey('profile');
              }}
            >
              Profile
            </a>
          </div>
          {/* <div
          className={`editProfileSideBard-option ${
            bookMarkKey === 'company' ? 'active' : ''
          }`}
        >
          <a
            onClick={() => {
              setBookMarkKey('company');
            }}
            href="#company"
          >
            Company
          </a>
        </div> */}
          <div
            className={`editProfileSideBard-option ${
              bookMarkKey === 'subscription'
                ? 'active font-600'
                : 'inactive font-600'
            }`}
          >
            <a
              href="#subscription"
              onClick={() => {
                setBookMarkKey('subscription');
              }}
            >
              Subscription
            </a>
          </div>
        </div>
        {loading ? (
          <div style={{ width: '100%' }}>
            <LoaderComponent />
          </div>
        ) : (
          <Form
            className="company-profile-form editProfile"
            layout="vertical"
            initialValues={initialValues}
            onFinish={onFinish}
            form={form}
          >
            <div id="profile" />
            <Form.Item className="d-flex mt-50" name="profileImage">
              <Upload
                beforeUpload={() => {
                  return false;
                }}
                accept="image/*"
                showUploadList={false}
                onChange={(info) => {
                  getBase64(info?.file, (image) => {
                    setSrc(image);
                  });
                }}
              >
                <img
                  src={src || placeholder}
                  alt="profile"
                  className="profile-image mr-25"
                />
                <Button className="">Change Picture</Button>
              </Upload>
            </Form.Item>

            <Row gutter={isLaptopViewPort ? 10 : 50}>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item
                  label="Company name"
                  name="name"
                  className="capitalize-input-wrapper"
                  rules={[required]}
                >
                  <Input
                    placeholder="Enter Company Name"
                    prefix={<Company />}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item
                  label="Established year"
                  name="establishedYear"
                  rules={[required]}
                >
                  <DatePicker
                    placeholder="Enter established year"
                    picker="year"
                    disabledDate={(current) =>
                      current < MAX_PAST_DATE || current > moment()
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Bio" name="bio" rules={[required]}>
              <TextArea />
            </Form.Item>
            {/* for future use */}
            {/* <Button
            className="mb-10"
            loading={saveLoading}
            onClick={() => setShowModal(true)}
          >
            Change Password
          </Button> */}
            <Row gutter={isLaptopViewPort ? 10 : 50}>
              <Col span={24}>
                <Form.Item label="Email" name="email">
                  <Input
                    placeholder="Enter your email"
                    prefix={<Email />}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="d-flex justify-between align-center" id="company">
              <h2 className="font-600 mb-0">Company Details</h2>
            </div>
            <Divider />
            <Row gutter={50}>
              <Col span={24}>
                <Form.Item
                  label="Overview (Limit 500 characters)"
                  name="description"
                  rules={[required]}
                >
                  <TextArea
                    placeholder="Enter your Overview"
                    maxLength="500"
                    rows={5}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={isLaptopViewPort ? 10 : 50}>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item label="Industry" name="industry" rules={[required]}>
                  <Select
                    loading={industryLoading}
                    showSearch
                    allowClear
                    placeholder="Industry"
                    optionFilterProp="children"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onPopupScroll={industryScroll}
                    onClear={handleIndustryBlur}
                    onSearch={debouncedProductTypeHandler}
                    notFoundContent={
                      industryLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty />
                      )
                    }
                  >
                    {map(industryData, (item) => (
                      <Option key={item?.key} value={item?.id}>
                        {item?.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item
                  label="Company Size"
                  name="employeeSize"
                  rules={[required]}
                >
                  <Select
                    allowClear
                    placeholder="Enter your company size"
                    onChange={(e) => {
                      if (e) {
                        if (subscriptionStatus === 'ACTIVE') {
                          setShowPricingModal(true);
                          setFinalSize(e);
                          form.setFieldsValue({
                            employeeSize: employeeSize
                          });
                        }
                      } else {
                        setShowPricingModal(false);
                      }
                    }}
                    disabled={isDisable}
                  >
                    {map(EMPLOYEE_SIZE, (size) => {
                      return (
                        <Option key={size?.key} value={size?.key}>
                          {size?.value}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={50}>
              <Col span={24}>
                <Form.Item
                  label="Website"
                  name="website"
                  rules={[
                    required,
                    { pattern: REGEX.WEB_URL, message: 'enter proper URL' }
                  ]}
                >
                  <Input
                    placeholder="Enter Company's website link"
                    prefix={<LinkIcon />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={isLaptopViewPort ? 10 : 50}>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item label="Headquarters location" name="hqLocation">
                  <SearchLocationInputSingle
                    onChange={() => null}
                    setLocation={setLocationObject}
                    location={locationObject}
                    initLocation={locationObject}
                    placeholder="Enter Headquarters Location"
                    isEdit
                  />
                </Form.Item>
              </Col>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item
                  label="Specialties"
                  name="specialties"
                  rules={[required]}
                >
                  <Input
                    placeholder="Enter your Specialties"
                    prefix={<Building />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={50}>
              <Col span={24}>
                <Form.Item label="More Company location" name="branches">
                  <SearchLocationInput
                    onChange={() => null}
                    setLocationArray={setMoreLocationObject}
                    locationArray={moreLocationObject}
                    isMultiLocation
                  />
                  {moreLocationObject.map((tag) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                      <Tag
                        className="edit-tag"
                        key={tag}
                        closable
                        onClose={() => handleClose(tag)}
                        closeIcon={<CloseIcon />}
                      >
                        <span>
                          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </span>
                      </Tag>
                    );
                    return isLongTag ? (
                      <Tooltip title={tag} key={tag}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                </Form.Item>
              </Col>
            </Row>
            <div className="d-flex justify-between align-center">
              <h2 className="font-600 mb-0">Funding</h2>
            </div>
            <Divider />

            <Row gutter={isLaptopViewPort ? 10 : 50}>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item
                  label="Total rounds"
                  name="totalRounds"
                  rules={[number]}
                >
                  <Input
                    placeholder="Enter your total rounds"
                    type="number"
                    prefix={<Building />}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item label="Last round investor" name="lastRoundInvestor">
                  <Input
                    placeholder="Enter Last round investor"
                    prefix={<DollarSign />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={isLaptopViewPort ? 10 : 50}>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item label="Last round date" name="lastRoundDate">
                  <DatePicker
                    placeholder="Enter Last round date"
                    disabledDate={(current) =>
                      current < MAX_PAST_DATE || current > moment()
                    }
                    format={defaultDateFormat}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobileViewPort ? 12 : 24}>
                <Form.Item
                  label="Funds raised"
                  name="fundsRaised"
                  rules={[number]}
                >
                  <Input
                    placeholder="Enter Last funds raised"
                    type="number"
                    prefix={<DollarSign />}
                  />
                </Form.Item>
              </Col>
            </Row>
            {subscriptionStatus === 'ACTIVE' && (
              <>
                <div
                  className="d-flex justify-between align-center"
                  id="subscription"
                >
                  <h2 className="font-600 mb-0">Subscription Details</h2>
                </div>
                <Divider />
                <Row gutter={isLaptopViewPort ? 10 : 50}>
                  <Col span={isMobileViewPort ? 12 : 24}>
                    <Form.Item label="Current active plan" name="plan">
                      <Input placeholder="Plan" prefix={<Plan />} disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={isLaptopViewPort ? 10 : 50}>
                  <Col span={isMobileViewPort ? 12 : 24}>
                    <Form.Item label="Next Billing Date" name="nextBillingDate">
                      <DatePicker format={defaultDateFormat} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={isMobileViewPort ? 12 : 24}>
                    <Form.Item
                      label="Next Billing Amount"
                      rules={[number]}
                      name="nextBillingAmount"
                    >
                      <Input
                        placeholder="Enter Last funds raised"
                        type="number"
                        prefix={<DollarSign />}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label="Address Line 1" name="addressLine1">
                  <Input placeholder="Enter Address" className="height-53" />
                </Form.Item>
                <Form.Item label="Address Line 2" name="addressLine2">
                  <Input placeholder="Enter Address" className="height-53" />
                </Form.Item>
                <Row gutter={isLaptopViewPort ? 10 : 50}>
                  <Col span={isMobileViewPort ? 12 : 24}>
                    <Form.Item label="State" name="state">
                      <Input placeholder="Enter State" className="height-53" />
                    </Form.Item>
                  </Col>
                  <Col span={isMobileViewPort ? 12 : 24}>
                    <Form.Item label="Country" name="country">
                      <Input
                        placeholder="Enter Country"
                        className="height-53"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={isLaptopViewPort ? 10 : 50}>
                  <Col span={isMobileViewPort ? 12 : 24}>
                    <Form.Item label="Pincode" rules={[number]} name="zipCode">
                      <Input
                        placeholder="Enter Pincode"
                        type="number"
                        className="height-53"
                      />
                    </Form.Item>
                  </Col>
                </Row>{' '}
                <Button
                  className="cancel-subscription-button"
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                >
                  Cancel Subscription
                </Button>
              </>
            )}
            <div
              className={`${
                isMobileViewPort
                  ? 'd-flex justify-between align-center pt-20 pb-40'
                  : 'd-flex flex-vertical align-center pt-20 pb-40'
              }`}
            >
              <p
                className={`${
                  isMobileViewPort
                    ? 'width-percent-50 black-color mb-0'
                    : 'fill-width black-color mb-0'
                }`}
              >
                By clicking “Submit” you're{' '}
                <a
                  href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  agreeing{' '}
                </a>{' '}
                that the above information is accurate
              </p>
              <Button
                type="primary"
                className={`${
                  isMobileViewPort ? 'width-percent-20' : 'fill-width'
                }`}
                htmlType="submit"
                loading={saveLoading}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
        {/* for future use */}
        {/* <ChangePassword showModal={showModal} setShowModal={setShowModal} /> */}
      </div>
    </>
  );
};

export default UpdateCompanyForm;
