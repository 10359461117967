/* eslint-disable no-console */
import { useLazyQuery } from '@apollo/client';
import { Button, Card, Col, Divider, Skeleton } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import moment from 'moment';
import { React, useEffect, useState } from 'react';
import placeholder from '../../../assets/images/placeholder.jpg';
import { Map } from '../../../assets/svg';
import {
  ANALYTICS_EVENTS,
  JOB_APPLICATION_STATUS,
  JOB_WORK_TYPE
} from '../../../common/constants';
import { getLocationStrFromLocationArray } from '../../../common/utils';
import history from '../../../historyData';
import '../../applied/component/Applied.less';
import { analytics } from '../../auth/Firebase';
import { GET_JOB } from '../../jobDetails/graphql/Queries';

const RecJobCardForUser = ({ jobId, addRecCardResult }) => {
  const [job, setJob] = useState();
  const [location, setLocation] = useState();
  const [loading, setLoading] = useState(true);
  const [loadFail, setLoadFail] = useState(false);

  const [getJob] = useLazyQuery(GET_JOB, {
    variables: {
      id: jobId
    },
    fetchPolicy: 'network-only',
    onError: () => {
      addRecCardResult('fail');
      setLoadFail(true);
    },
    onCompleted: (res) => {
      setJob(res.job);
      setLoading(false);
      if (res.job.displayJob) {
        addRecCardResult('success');
      } else {
        addRecCardResult('fail');
      }
    }
  });

  const handleClick = (id) => {
    analytics.logEvent(ANALYTICS_EVENTS.VIEW_JOB, {
      jobId: id,
      title: job?.title
    });
    history.push(`/job/${id}`);
  };

  useEffect(() => {
    getJob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (job) {
      setLocation(getLocationStrFromLocationArray(job?.location));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  return (
    <>
      {loadFail ? null : (
        <>
          <Col span={22} key={jobId} className="margin-bottom">
            <Skeleton loading={loading} active>
              {job?.displayJob ? (
                <Card
                  key={job?.id}
                  className="applied-card pointer"
                  type="inner"
                  onClick={() => {
                    handleClick(job?.id);
                  }}
                  title={
                    <div className="d-flex flex-vertical">
                      <h2 className="font-600">{job?.title}</h2>
                      <div className="noMobileFlex d-flex align-center">
                        <p className="mb-0 black-color">
                          <img
                            src={job?.createdBy?.profileImage || placeholder}
                            alt="profile"
                            className="profile mr-10"
                          />
                          {job?.createdBy?.name}
                        </p>
                        <div className="d-flex">
                          <Divider
                            type="vertical"
                            className="applied-divider"
                          />
                          <Map
                            className="map mr-5"
                            width="18px"
                            height="18px"
                          />{' '}
                          <p className="mb-0 black-color mr-5">
                            {' '}
                            {JOB_WORK_TYPE[job?.workType]} :{' '}
                          </p>
                          <p className="mb-0">{location}</p>
                        </div>
                        <div className="d-flex">
                          <Divider
                            type="vertical"
                            className="applied-divider"
                          />
                          <p className="mb-0 green-color font-600">
                            {moment(job?.createdAt).fromNow()}
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                  extra={
                    <>
                      {job?.application?.isApplied ? (
                        <Button
                          type="primary"
                          className="viewDetailWeb font-16 font-600 applied-btn"
                        >
                          Applied
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          onClick={() => {
                            handleClick(job?.id);
                          }}
                          className="viewDetailWeb font-16 font-600 view-detail-btn"
                        >
                          View Details
                        </Button>
                      )}
                    </>
                  }
                >
                  <div>
                    <h3 className="black-color">Qualifications:</h3>
                    <Paragraph
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: 'more'
                      }}
                    >
                      {job?.preferredQualification}
                    </Paragraph>

                    <div className="align-center">
                      <>
                        {job?.application?.isApplied ? (
                          <Button
                            type="primary w-100"
                            className="viewDetailMobile applied-btn"
                          >
                            Applied
                          </Button>
                        ) : (
                          <Button
                            type="primary w-100"
                            onClick={() => {
                              handleClick(job?.id);
                            }}
                            className="viewDetailMobile view-detail-btn"
                          >
                            View Details
                          </Button>
                        )}
                      </>
                    </div>

                    <div
                      style={{
                        display: job?.application?.isApplied ? 'block' : 'none'
                      }}
                      className="application-status-div viewDetailWeb"
                    >
                      <p className="black-color mb-0">
                        Application Status :{' '}
                        <span>
                          {JOB_APPLICATION_STATUS[job?.application?.status]}
                        </span>
                      </p>
                    </div>
                  </div>
                </Card>
              ) : null}
            </Skeleton>
          </Col>
        </>
      )}
    </>
  );
};

export default RecJobCardForUser;
