import React from 'react';

const SuccessPage = () => {
  return (
    <div className="m-10">
      <h1>Redirecting to Home Page.....</h1>
    </div>
  );
};

export default SuccessPage;
