import { gql } from '@apollo/client';

export const GET_POSTS = gql`
  query posts($filters: PostFilterInput!) {
    posts(filters: $filters) {
      count
      data {
        id
        text
        mediaUrl
        mediaType
        createdBy {
          id
          firstName
          lastName
          bio
          profileImage
          isVerified
          experience {
            title
          }
          userConnection {
            id
          }
        }
        isLiked
        likeCount
        commentCount
        lastComment
      }
    }
  }
`;

export const GET_POST = gql`
  query post($where: CommonWhereInput!) {
    post(where: $where) {
      id
      text
      mediaUrl
      mediaType
      createdBy {
        id
        firstName
        lastName
        bio
        profileImage
        isVerified
        experience {
          title
        }
        userConnection {
          id
        }
      }
      isLiked
      likeCount
      commentCount
    }
  }
`;

export const USER_ME = gql`
  query {
    userMe {
      isVerified
      email
      firstName
      lastName
      profileImage
      experience {
        title
      }
    }
  }
`;

export const GET_COMMENTS = gql`
  query comments($where: CommonWhereInput!, $filters: CommentFilterInput!) {
    comments(where: $where, filters: $filters) {
      id
      postId
      text
      createdBy {
        firstName
        lastName
        profileImage
      }
    }
  }
`;

export const GET_MY_JOBS = gql`
  query jobs($companyId: String) {
    jobs(
      filters: {
        skip: 0
        limit: 5
        sortBy: createdAtDESC
        where: { companyId: $companyId }
      }
    ) {
      count
      data {
        id
        title
        workType
        createdBy {
          id
          name
          profileImage
        }
        location {
          city
          state
          country
        }
        createdAt
      }
    }
  }
`;
