import { Layout, Menu } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import { UpArrow } from '../../../assets/svg';
import { ANALYTICS_EVENTS, MODULES, ROUTES } from '../../../common/constants';
import { analytics } from '../../../modules/auth/Firebase';
// import history from '../../../historyData';
import UserProfile from './UserProfile';

const { Header } = Layout;
// location: { pathname }
const AppHeader = ({ history, location: { pathname } }) => {
  const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);
  // eslint-disable-next-line no-undef
  const isCompanyVerified = localStorage.getItem('isCompanyVerified');
  const onMenuSelect = (e) => {
    history?.push(e.key);
  };

  const routes = [
    {
      route: ROUTES?.FEED
    },
    {
      route: ROUTES?.JOBS
    },
    {
      route: ROUTES?.APPLIED_JOBS
    },
    {
      route: ROUTES?.COMPANIES
    },
    {
      route: ROUTES?.COMPANY_FEED
    }
  ];

  useEffect(() => {
    map(routes, (item) => {
      if (pathname?.includes(item?.route)) {
        setDefaultOpenKeys([item?.moduleKey]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Header>
      <div className="d-flex justify-between align-center header-logo-menu">
        <div>
          <img
            src={logo}
            className="logo pointer"
            alt="logo"
            onClick={() => {
              // eslint-disable-next-line no-undef
              const isCompany = localStorage.getItem('isCompanyVerified');
              if (isCompany) {
                history?.push(ROUTES.COMPANY_FEED);
                analytics.logEvent(ANALYTICS_EVENTS.FEED_PAGE, {
                  as: 'COMPANY'
                });
              } else {
                history?.push(ROUTES.MAIN);
                analytics.logEvent(ANALYTICS_EVENTS.FEED_PAGE, {
                  as: 'CANDIDATE'
                });
              }
            }}
          />
        </div>
        <div className="main-header">
          <Menu
            mode="horizontal"
            selectedKeys={[
              `${`/${
                isCompanyVerified
                  ? `company/${pathname?.split('/')[2]}`
                  : pathname?.split('/')[1]
              }`}`
            ]}
            defaultOpenKeys={defaultOpenKeys}
            onSelect={onMenuSelect}
          >
            {isCompanyVerified ? (
              <>
                <Menu.Item
                  key={ROUTES?.COMPANY_FEED}
                  onClick={() => {
                    analytics.logEvent(ANALYTICS_EVENTS.FEED_PAGE, {
                      as: 'COMPANY'
                    });
                  }}
                >
                  {MODULES?.FEED}
                </Menu.Item>
                <Menu.Item
                  key={ROUTES?.MY_JOB}
                  onClick={() => {
                    analytics.logEvent(ANALYTICS_EVENTS.MY_JOB);
                  }}
                >
                  {MODULES?.MY_JOB}
                </Menu.Item>
                <Menu.Item key={ROUTES?.ARCHIVE}>{MODULES?.ARCHIVE}</Menu.Item>
                <Menu.Item
                  key={ROUTES?.CREATE_JOB}
                  icon={<UpArrow />}
                  className="custom-menu-item post-job"
                >
                  {MODULES?.POST_JOB}
                </Menu.Item>
              </>
            ) : (
              <>
                <Menu.Item
                  key={ROUTES?.FEED}
                  onClick={() => {
                    analytics.logEvent(ANALYTICS_EVENTS.FEED_PAGE, {
                      as: 'CANDIDATE'
                    });
                  }}
                >
                  {MODULES?.FEED}
                </Menu.Item>
                <Menu.Item
                  key={ROUTES?.JOBS}
                  onClick={() => {
                    analytics.logEvent(ANALYTICS_EVENTS.JOB_FOR_YOU);
                  }}
                >
                  Jobs For You
                </Menu.Item>
                <Menu.Item
                  key={ROUTES?.COMPANIES}
                  onClick={() => {
                    analytics.logEvent(ANALYTICS_EVENTS.COMPANIES);
                  }}
                >
                  Companies
                </Menu.Item>
                <Menu.Item
                  key={ROUTES?.APPLIED_JOBS}
                  onClick={() => {
                    analytics.logEvent(ANALYTICS_EVENTS.APPLIED_JOBS);
                  }}
                >
                  Applied Jobs
                </Menu.Item>
              </>
            )}

            {/* <Menu.Item key="uploadPost">
              <span>
                Upload Post{' '}
                <img src={upArrow} className="upload-post" alt="up-arrow" />
              </span>
            </Menu.Item> */}
          </Menu>
        </div>
      </div>
      <div>
        <UserProfile />
      </div>
    </Header>
  );
};
export default withRouter(AppHeader);
