import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation sendMessage($data: SendMessageInput!, $where: CommonWhereInput!) {
    sendMessage(data: $data, where: $where) {
      data {
        id
        text
        sender
        mediaUrl
        mediaType
        readBy
        sender
      }
    }
  }
`;

export const ADD_INTO_READ_BY_MESSAGE = gql`
  mutation addIntoReadByMessage($where: CommonWhereInput!) {
    addIntoReadByMessage(where: $where)
  }
`;

export const GET_ROOM_SIGNED_URL = gql`
  mutation getRoomSignedUrl(
    $data: CommonFileInput!
    $where: CommonWhereInput!
  ) {
    getRoomSignedUrl(data: $data, where: $where) {
      fileName
      signedUrl
      getUrl
    }
  }
`;
