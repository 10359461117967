import { Drawer } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../AppContext';
import { BREAKPOINTS, ROUTES } from '../common/constants';
import history from '../historyData';
import Chat from '../modules/chat/component/Chat';
import NewChat from '../modules/chat/component/NewChat';
import UserChat from '../modules/chat/component/UserChat';
import './styles/chatDrawer.less';

let roomId;
const ChatDrawer = ({ headerVisible }) => {
  const {
    dispatch,
    state: { showDrawer, profileId }
  } = useContext(AppContext);
  const path = history?.location?.pathname;
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);
  const [currentPage, setCurrentPage] = useState('CHAT');

  const onClose = () => {
    dispatch({
      type: 'SET_DRAWER',
      data: { showDrawer: false, profileId: '' }
    });
  };

  useEffect(() => {
    if (path === ROUTES?.FEED && !isMobileViewPort) {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: false, profileId: '' }
      });
    } else if (path === ROUTES?.FEED && isMobileViewPort) {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: true, profileId: '' }
      });
    } else {
      dispatch({
        type: 'SET_DRAWER',
        data: { showDrawer: false, profileId: '' }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePage = (page, id) => {
    roomId = id;
    setCurrentPage(page);
  };

  return (
    <div>
      <Drawer
        className={`${
          headerVisible ? 'common-drawer z-0' : 'common-drawer z-1000'
        }`}
        contentWrapperStyle={{
          boxShadow: 'none',
          borderRight: '1px solid #eeeeee'
        }}
        placement="right"
        mask={false}
        closable={false}
        onClose={onClose}
        visible={showDrawer}
      >
        {currentPage === 'CHAT' && !profileId && (
          <Chat isDrawer changePage={changePage} />
        )}
        {currentPage === 'NEW' && !profileId && (
          <NewChat isDrawer changePage={changePage} />
        )}
        {profileId && (
          <UserChat
            isDrawer
            changePage={changePage}
            roomId={profileId}
            direct
          />
        )}
        {currentPage === 'USER_CHAT' && (
          <UserChat
            isDrawer
            changePage={changePage}
            roomId={roomId}
            direct={false}
          />
        )}
      </Drawer>
    </div>
  );
};

export default ChatDrawer;
