import * as Sentry from '@sentry/react';
import { Card } from 'antd';
import React from 'react';
import { Router, Switch } from 'react-router-dom';
import App from './app/App';
import { ErrorIcon } from './assets/svg';
import { ROUTES } from './common/constants';
import history from './historyData';
import ChangePassword from './modules/auth/ChangePassword';
import CompanyLogin from './modules/auth/CompanyLogin';
import CompanyProfile from './modules/auth/CompanyProfile';
import CompanySignup from './modules/auth/CompanySignup';
import FillProfile from './modules/auth/FillProfile';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import ResetPassword from './modules/auth/ResetPassword';
import SignUp from './modules/auth/Signup';
import UnderReview from './modules/auth/UnderReview';
import VerifyEmail from './modules/auth/VerifyEmail';
import Chat from './modules/chat/component/Chat';
import NewChat from './modules/chat/component/NewChat';
import UserChat from './modules/chat/component/UserChat';
import CompanyPostDetail from './modules/companyFeed/pages/CompanyPostDetail';
import './modules/feed/feedModule.less';
import PostDetail from './modules/feed/pages/PostDetail';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
  const MyFallbackComponent = ({ error, componentStack }) => (
    <div className="d-flex flex-vertical align-center">
      <div className="d-flex flex-vertical align-center">
        <ErrorIcon className="mt-10" width="65px" height="90px" />
        <p className="font-xx-large">
          <strong>Oops! An error occurred!</strong>
        </p>
        <Card className="width-percent-60">
          <p className="d-flex font-18">
            <strong className="font-large mr-5">Error: </strong>
            <p className="line-24"> {error?.message?.toString()}</p>
          </p>
          <p>
            <strong className="font-large">Stacktrace:</strong>
            <p className="line-24">{componentStack}</p>
          </p>
        </Card>
      </div>
    </div>
  );

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router history={history}>
        <Switch>
          <PublicRoute exact path={ROUTES.RESET} component={ResetPassword} />
          <PublicRoute exact path={ROUTES.LOGIN} component={Login} />
          <PublicRoute
            exact
            path={ROUTES.COMPANY_LOGIN}
            component={CompanyLogin}
          />
          <PublicRoute
            exact
            path={ROUTES.PUBLIC_PRIVACY_POLICY}
            // eslint-disable-next-line no-return-assign
            component={() =>
              // eslint-disable-next-line no-undef
              (window.location.href = process.env.REACT_APP_PRIVACY_POLICY_URL)
            }
          />
          <PublicRoute
            exact
            path={ROUTES.COMPANY_SIGNUP}
            component={CompanySignup}
          />
          <PublicRoute exact path={ROUTES.SIGNUP} component={SignUp} />
          <PublicRoute exact path={ROUTES.CHANGE} component={ChangePassword} />
          <PrivateRoute
            exact
            path={ROUTES.VERIFY_EMAIL}
            component={VerifyEmail}
          />
          <PrivateRoute exact path={ROUTES.LOGOUT} component={Logout} />
          <PrivateRoute
            exact
            path={ROUTES.FILL_PROFILE}
            component={FillProfile}
          />
          <PrivateRoute
            exact
            path={ROUTES.COMPANY_PROFILE}
            component={CompanyProfile}
          />
          <PrivateRoute exact path={ROUTES.RESULT} component={UnderReview} />
          <PrivateRoute exact path={ROUTES?.CHAT} component={Chat} />
          <PrivateRoute exact path={ROUTES.NEW_CHAT} component={NewChat} />
          <PrivateRoute path={ROUTES.USER_CHAT} component={UserChat} />
          <PrivateRoute
            exact
            path={`${ROUTES?.FEED}/:id`}
            component={PostDetail}
          />
          <PrivateRoute
            exact
            path={`${ROUTES?.COMPANY_FEED}/:id`}
            component={CompanyPostDetail}
          />
          <PrivateRoute path="/" component={App} />
        </Switch>
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default Routes;
