import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { FOLLOWERS_SKIP_RECORD } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import CompanyFollowers from '../component/CompanyFollowers';
import { GET_COMPANY_FOLLOWERS } from '../graphql/Queries';

const initialFollowerFilter = {
  skip: 0,
  limit: 12
};

const CompanyFollowerPage = () => {
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const [followerData, setFollowerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [followerFilter, setFollowerFilter] = useState(initialFollowerFilter);
  const [fetchFollowerLoading, setFetchFollowerLoading] = useState(false);
  const [followerDebounce, setFollowerDebounce] = useState(false);
  const [followerIsEnd, setFollowerIsEnd] = useState(false);
  const [count, setCount] = useState(0);

  const [getCompanyFollowerInfo] = useLazyQuery(GET_COMPANY_FOLLOWERS, {
    onCompleted: (res) => {
      setFollowerData([...followerData, ...res?.getCompanyFollowers]);
      setLoading(false);
      setCount(res?.getCompanyFollowers?.length);
      setFetchFollowerLoading(false);
      if (res?.getCompanyFollowers?.length < FOLLOWERS_SKIP_RECORD) {
        setFollowerIsEnd(true);
      }
    },
    onError: () => {
      setLoading(false);
      setFetchFollowerLoading(false);
    }
  });

  useEffect(() => {
    getCompanyFollowerInfo({
      variables: {
        filters: followerFilter,
        where: {
          id: currentUser?.id
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    if (!followerIsEnd) {
      setFetchFollowerLoading(true);
      getCompanyFollowerInfo({
        variables: {
          filters: {
            ...followerFilter,
            skip: (followerDebounce + 1) * FOLLOWERS_SKIP_RECORD
          },
          where: {
            id: currentUser?.id
          }
        }
      });
      setFollowerDebounce((prevState) => prevState + 1);
    }
  };
  if (loading) {
    return <LoaderComponent />;
  }
  return (
    <CompanyFollowers
      loadMore={loadMore}
      followerData={followerData}
      setFollowerFilter={setFollowerFilter}
      followerIsEnd={followerIsEnd}
      fetchFollowerLoading={fetchFollowerLoading}
      count={count}
    />
  );
};

export default withRouter(CompanyFollowerPage);
