import { Button, Divider } from 'antd';
import React from 'react';
import mapSvg from '../../../assets/svg/mapGreen.svg';
import { JOB_WORK_TYPE, ROUTES } from '../../../common/constants';
import { getLocationStrFromLocationArray } from '../../../common/utils';
import history from '../../../historyData';

const CommonCompanyJobCard = ({ job }) => {
  const handleClick = (id) => {
    history.push(`${ROUTES.JOB_DETAILS}/${id}`);
  };
  const location = getLocationStrFromLocationArray(job.location);
  return (
    <div
      className="job-card"
      onClick={() => {
        handleClick(job.id);
      }}
    >
      <div className="info-section">
        <div className="job-left-section">
          <span className="big-text">{job?.title}</span>
          <div className="mt-15">
            <div>
              <Button shape="round" className="required-info">
                Required Candidates :{' '}
                {job?.applicationCount?.totalAccepted || 0}/
                {job?.noOfVacancies || 0}{' '}
              </Button>{' '}
            </div>
            <Divider type="vertical" />
            <span className="location-info">
              <img src={mapSvg} alt="location" />
              <span className="location-label">
                {JOB_WORK_TYPE[job.workType]} :
              </span>
              <span className="location-value">{location}</span>
            </span>
          </div>
        </div>
        <div className="job-right-section d-flex justify-center align-center">
          <span>{job?.applicationCount?.totalApplied || 0} Applied</span>
        </div>
      </div>
      <Divider style={{ margin: '10px' }} />
      <div className="qualification-section">
        <span className="big-text">Qualifications:</span>
        <ul>
          <li>{job?.preferredQualification}</li>
        </ul>
      </div>
    </div>
  );
};

export default CommonCompanyJobCard;
