import { Button, Divider, Select, Tag, Tooltip } from 'antd';
import { filter, includes, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { analytics } from './Firebase';

const EditableTagGroup = ({
  setSkills,
  skillsData,
  loading,
  debouncedSkill,
  newSkill,
  setNewSkill,
  skillToBeAdded,
  skills = [],
  addSkillEvent,
  removeSkillEvent
}) => {
  const [tags, setTags] = useState([]);
  const [visible, setVisible] = useState(false);
  const [skill, setSkill] = useState();
  const [value, setValue] = useState(undefined);

  useEffect(() => {
    if (skillsData) {
      if (skills?.length > 0) {
        const data = filter(skillsData, (o) => {
          if (!includes(skills, o?.value)) return o;
        });
        setSkill(data);
      } else {
        setSkill(skillsData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skills, skillsData]);

  const handleClose = (removedTag) => {
    if (removeSkillEvent)
      analytics.logEvent(removeSkillEvent, {
        skill: removedTag
      });
    setVisible(false);
    setTags(tags.filter((tag) => tag !== removedTag));
    setSkills(skills.filter((tag) => tag !== removedTag));
  };

  const handleInputConfirm = (e) => {
    setVisible(true);
    setValue(e);
    if (e && tags.indexOf(e) === -1) {
      setTags([...tags, e]);
      setSkills([...skills, e]);
    }
  };

  const addItem = () => {
    if (skillToBeAdded && tags.indexOf(skillToBeAdded) === -1) {
      if (addSkillEvent)
        analytics.logEvent(addSkillEvent, {
          skill: skillToBeAdded
        });
      setTags([...tags, skillToBeAdded]);
      setSkills([...skills, skillToBeAdded]);
      setNewSkill(false);
      setVisible(false);
    }
  };

  const handleOpen = (e) => {
    setVisible(e);
    setTimeout(() => {
      setValue(undefined);
    }, 800);
    if (e === false) {
      setNewSkill(false);
    }
  };

  return (
    <div className="d-flex flex-wrap">
      <Select
        className="tag-input mb-10"
        onChange={handleInputConfirm}
        allowClear
        onSelect={(element) => {
          if (addSkillEvent)
            analytics.logEvent(addSkillEvent, {
              skill: element
            });
        }}
        loading={loading}
        open={visible}
        onDropdownVisibleChange={handleOpen}
        placeholder="Select Skills"
        showSearch
        onSearch={debouncedSkill}
        value={value}
        onClear={() => {
          setNewSkill(false);
        }}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {newSkill && <Divider style={{ margin: '4px 0' }} />}
            {newSkill && !includes(skills, newSkill) && (
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Button
                  type="link"
                  style={{
                    flex: 'none',
                    padding: '8px',
                    display: 'block',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    addItem();
                    setVisible(false);
                  }}
                >
                  Add this Skill ?
                </Button>
              </div>
            )}
          </div>
        )}
      >
        {map(skill, (item) => {
          return (
            <Select.Option key={item?.key} value={item?.value}>
              {item?.value}
            </Select.Option>
          );
        })}
      </Select>
      {skills.map((tag) => {
        const isLongTag = tag.length > 20;
        const tagElem = (
          <Tag
            className="edit-tag"
            key={tag}
            closable
            onClose={() => handleClose(tag)}
          >
            <span>{isLongTag ? `${tag.slice(0, 20)}...` : tag}</span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
    </div>
  );
};

export default EditableTagGroup;
