/* eslint-disable no-console */
import { Divider } from 'antd';
import React from 'react';
import { JOB_WORK_TYPE, ROUTES } from '../../../common/constants';
import { getLocationStrFromLocationArray } from '../../../common/utils';
import CommonAvatar from '../../../components/CommonAvatar';
import history from '../../../historyData';

const CommonJobCard = ({ jobData }) => {
  const handleClickOnJob = (id) => {
    history.push(`/job/${id}`);
  };
  const handleClickOnCompany = (id) => {
    history.push(`${ROUTES.COMPANY_PROFILE_ABOUT}/${id}`);
  };
  const location = getLocationStrFromLocationArray(jobData.location);
  return (
    <div
      className="job-card"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        handleClickOnJob(jobData?.id);
      }}
    >
      <p className="job-title">{jobData?.title}</p>
      <div className="d-flex align-center">
        <CommonAvatar size={25} src={jobData?.createdBy?.profileImage} />
        <span
          className="link"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleClickOnCompany(jobData?.createdBy?.id);
          }}
        >
          {' '}
          {jobData?.createdBy?.name}{' '}
        </span>
      </div>
      <Divider className="job-card-divider" />
      <div className="footer d-flex">
        <span className="bold-text" style={{ paddingRight: '5px' }}>
          {jobData.workType !== null
            ? JOB_WORK_TYPE[jobData.workType]
            : 'Work Type Unavailable'}
          :
        </span>
        <span className="normal-text" style={{ paddingLeft: '5px' }}>
          {location}
        </span>
      </div>
    </div>
  );
};

export default CommonJobCard;
