import { Divider } from 'antd';
import { map } from 'lodash';
import React from 'react';
import CommonJobCard from './CommonJobCard';

const RecentJobs = ({ loading, data }) => {
  return (
    <div className="recommend-section">
      <div className="header-section ">
        <span>Recently Added Jobs</span>
      </div>
      <Divider className="recommend-divider" />
      <div className="job-section">
        {map(data, (item) => {
          return (
            <div key={item.id} className="single-job">
              <CommonJobCard jobData={item} loading={loading} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecentJobs;
