import { gql } from '@apollo/client';

export const GET_USER = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      email
      profileImage
      firstName
      resumeUpdatedOn
      lastName
      phoneNo
      profileId
      countryCode
      coverImage
      isVerified
      DOB
      bio
      skills
      experience {
        id
        title
        company
        industry {
          id
          key
          value
        }
        type
        startDate
        endDate
        location {
          city
          state
          country
        }
        isCurrent
      }
      resumeUrl
      education {
        id
        school
        degree
        fieldOfStudy
        startDate
        endDate
        isCurrent
      }
      userConnection {
        id
        connectedBy
        connectedTo
        status
      }
      connectionCount
      followingCount
    }
  }
`;

export const GET_USER_ME = gql`
  query userMe {
    userMe {
      id
      resumeUpdatedOn
      email
      profileImage
      firstName
      lastName
      phoneNo
      countryCode
      coverImage
      isVerified
      DOB
      bio
      profileId
      skills
      experience {
        id
        title
        company
        industry {
          id
          key
          value
        }
        type
        startDate
        endDate
        location {
          city
          state
          country
        }
        isCurrent
      }
      resumeUrl
      education {
        id
        school
        degree
        fieldOfStudy
        startDate
        endDate
        isCurrent
      }
      userConnection {
        id
        connectedBy
        connectedTo
        status
      }
      connectionCount
      followingCount
    }
  }
`;

export const GET_USERS = gql`
  query searchUsers($filters: UserFilterInput!) {
    searchUsers(filters: $filters) {
      count
      data {
        id
        profileImage
        profileId
        firstName
        lastName
        experience {
          company
        }
        userConnection {
          connectedBy
          connectedTo
          id
          status
        }
      }
    }
  }
`;

export const GET_COMPANIES = gql`
  query getCompanies($filters: CompanyFilterInput!) {
    searchCompanies(filters: $filters) {
      count
      data {
        id
        name
        profileImage
        coverImage
        website
        isFollowed
        review {
          avgRating
        }
        displayCompany
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query companyMe {
    companyMe {
      id
      name
      email
      bio
      description
      establishedYear
      profileImage
      coverImage
      industry {
        id
        key
        value
      }
      employeeSize
      website
      hqLocation {
        city
        state
        country
      }
      branches {
        city
        state
        country
      }
      specialties
      funding {
        totalRounds
        lastRoundInvestor
        lastRoundDate
        fundsRaised
      }
      isFollowed
      review {
        totalReviews
        avgRating
      }
      totalJobCreated
      followerCount
      profileId
      subscriptionStatus
      status
      subscription {
        plan
        nextBillingDate
        nextBillingAmount
        billingAddress {
          addressLine1
          addressLine2
          city
          state
          country
          zipCode
        }
      }
    }
  }
`;
