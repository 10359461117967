import { CloseOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Menu } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import MenuBar from '../../../assets/images/menuBar.png';
import { MODULES, ROUTES } from '../../../common/constants';
import UserProfile from './UserProfile';

const { Header } = Layout;

const MobileHeader = ({ location: { pathname }, setHeaderVisible }) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);
  // eslint-disable-next-line no-undef
  const isCompanyVerified = localStorage.getItem('isCompanyVerified');
  const onMenuSelect = (e) => {
    history?.push(e.key);
    setVisible(false);
  };

  const routes = [
    {
      route: ROUTES?.FEED
    },
    {
      route: ROUTES?.JOBS
    },
    {
      route: ROUTES?.APPLIED_JOBS
    },
    {
      route: ROUTES?.COMPANIES
    },
    {
      route: ROUTES?.COMPANY_FEED
    }
  ];

  useEffect(() => {
    map(routes, (item) => {
      if (pathname?.includes(item?.route)) {
        setDefaultOpenKeys([item?.moduleKey]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menu = (
    <Menu
      theme="lite"
      selectedKeys={[`/${pathname?.split('/')[1]}`]}
      defaultOpenKeys={defaultOpenKeys}
      onSelect={onMenuSelect}
      onClick={onMenuSelect}
    >
      {isCompanyVerified ? (
        <>
          <Menu.Item key={ROUTES?.COMPANY_FEED}>{MODULES?.FEED}</Menu.Item>
          <Menu.Item key={ROUTES?.MY_JOB}>{MODULES?.MY_JOB}</Menu.Item>
          <Menu.Item key={ROUTES?.ARCHIVE}>{MODULES?.ARCHIVE}</Menu.Item>
          <Menu.Item key={ROUTES?.CREATE_JOB}>{MODULES?.POST_JOB}</Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item key={ROUTES?.FEED}>{MODULES?.FEED}</Menu.Item>
          <Menu.Item key={ROUTES?.JOBS}>Jobs For You</Menu.Item>
          <Menu.Item key={ROUTES?.COMPANIES}>Companies</Menu.Item>
          <Menu.Item key={ROUTES?.APPLIED_JOBS}>Applied Jobs</Menu.Item>
        </>
      )}
    </Menu>
  );
  return (
    <Header>
      <div className="d-flex mobile-header justify-between align-center fill-width">
        <div className="d-flex align-center">
          <Dropdown
            overlay={menu}
            trigger={['click']}
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('#nav-bar-dropdown')
            }
            onVisibleChange={(value) => {
              setVisible(value);
              if (value === true) {
                setHeaderVisible(true);
              } else {
                setHeaderVisible(false);
              }
            }}
            visible={visible}
          >
            {visible ? (
              <CloseOutlined
                height={34}
                width={34}
                className="mr-10 close-icon"
              />
            ) : (
              <img
                src={MenuBar}
                height={34}
                width={34}
                className="mr-10"
                alt="menu-bar"
              />
            )}
          </Dropdown>
          <div className="logo">
            <img
              src={logo}
              alt="logo"
              className="header-logo"
              onClick={() => {
                // eslint-disable-next-line no-undef
                const isCompany = localStorage.getItem('isCompanyVerified');
                if (isCompany) {
                  history?.push(ROUTES.COMPANY_FEED);
                } else {
                  history?.push(ROUTES.MAIN);
                }
              }}
            />
          </div>
        </div>
        <div className="header-notification ">
          <UserProfile />
        </div>
      </div>
      <div id="nav-bar-dropdown" />
    </Header>
  );
};
export default withRouter(MobileHeader);
