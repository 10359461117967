import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_JOBS = gql`
  query jobs($filters: JobFilterInput!) {
    jobs(filters: $filters) {
      count
      data {
        id
        title
        type
        noOfVacancies
        industry {
          id
          key
          value
        }
        salary {
          minimum
          maximum
          currency
        }
        workType
        minQualification
        preferredQualification
        about
        responsibilities
        location {
          city
          state
          country
        }
        application {
          isApplied
          status
        }
        applicationCount {
          totalApplied
          totalAccepted
        }
        createdBy {
          id
          name
          profileImage
          email
        }
        createdAt
        displayJob
      }
    }
  }
`;
